import { Form, useFormikContext } from 'formik';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TChangePhoneNumber } from 'shared/validations/changePhoneNumber';
import {
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

export const ChangePhoneNumberForm = () => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<TChangePhoneNumber>();

  return (
    <Form>
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h4" component="div">
          Change phone number
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="div" mb={3}>
          New phone number
        </Typography>
        <ReactPhoneInput
          value={values.phone}
          onChange={(value) => setFieldValue('phone', value)}
          component={TextField}
          inputProps={{
            name: 'phone',
            error: touched?.phone && Boolean(errors?.phone),
            helperText: touched?.phone && errors?.phone,
            onBlur: handleBlur,
            onChange: handleChange,
          }}
        />
      </DialogContent>
    </Form>
  );
};

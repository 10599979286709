import { FC } from 'react';
import { useLocation } from 'react-router';
import { Box, Card, styled, Typography } from '@mui/material';

import { useResponsive } from 'shared';
import { ReactComponent as Logo } from 'App/assets/Logo.svg';
import { PathAuth } from 'App/helpers';
import WelcomeBack from 'App/assets/WelcomeBack.png';

const SectionStyle = styled(Card)({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '25px',
});

const LogoBox = ({ mdUp }: { mdUp: boolean | null }) => {
  return (
    <Box pt={mdUp ? 6 : 3} pl={mdUp ? 6 : 3} sx={{ position: 'absolute' }}>
      <Logo />
    </Box>
  );
};

const Layout: FC = ({ children }): JSX.Element => {
  const { pathname } = useLocation();
  const mdUp = useResponsive('up', 'md');
  const isLogin = pathname === PathAuth.login;
  const isResetPassword = pathname === PathAuth.resetPassword;

  return (
    <>
      {isResetPassword && <LogoBox mdUp={mdUp} />}
      {isLogin && !mdUp && <LogoBox mdUp={mdUp} />}
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {mdUp && isLogin && (
          <SectionStyle>
            <Logo />
            <Typography variant="h3" sx={{ mt: 3, mb: 10 }}>
              Hi, Welcome Back
            </Typography>
            <img src={WelcomeBack} alt="welcome back" />
          </SectionStyle>
        )}
        <Box width="100%" maxWidth="480px" px={2}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;

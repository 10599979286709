import { Grid } from '@mui/material';
import { IBusiness } from 'App/api/types/Business';
import { EmptyPage, SkeletonCardLoading } from 'shared';
import { CardBusinesses } from './CardBusinesses';

type PropsType = {
  businesses: IBusiness[];
  isLoading: boolean;
};

export const RenderBusinesses = ({ businesses, isLoading }: PropsType) => {
  if (isLoading) {
    return <SkeletonCardLoading />;
  }

  if (!isLoading && businesses.length === 0) {
    return <EmptyPage title="No businesses" />;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {businesses.map((business) => {
        return (
          <Grid key={business.id} item xs={12} sm={6} md={4}>
            <CardBusinesses business={business} />
          </Grid>
        );
      })}
    </Grid>
  );
};

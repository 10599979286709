export const RECURRENCE_OPTIONS = [
  { text: 'Never', value: 'NEVER' },
  { text: 'Daily', value: 'DAILY' },
  { text: 'Weekly', value: 'WEEKLY' },
  { text: 'Monthly', value: 'MONTHLY' },
  { text: 'Yearly', value: 'YEARLY' },
];

export const END_RECURRENCE_OPTIONS = [
  { text: 'Never', value: 'NEVER' },
  { text: 'Until', value: 'UNTIL' },
  { text: 'Count', value: 'COUNT' },
];

export const MONTH_OPTIONS = [
  { text: 'January', value: 'JAN' },
  { text: 'February', value: 'FEB' },
  { text: 'March', value: 'MAR' },
  { text: 'April', value: 'APR' },
  { text: 'May', value: 'MAY' },
  { text: 'June', value: 'JUN' },
  { text: 'July', value: 'JUL' },
  { text: 'August', value: 'AUG' },
  { text: 'September', value: 'SEP' },
  { text: 'October', value: 'OCT' },
  { text: 'November', value: 'NEV' },
  { text: 'December', value: 'DEC' },
];

export const WEEK_OPTIONS = [
  { text: 'Mon', value: 'MO', number: 0 },
  { text: 'Tue', value: 'TU', number: 1 },
  { text: 'Wed', value: 'WE', number: 2 },
  { text: 'Thu', value: 'TH', number: 3 },
  { text: 'Fri', value: 'FR', number: 4 },
  { text: 'Sat', value: 'SA', number: 5 },
  { text: 'Sun', value: 'SU', number: 6 },
];

export const WEEK_FOR_MONTHLY_OPTIONS = [
  { text: 'Monday', value: 'MO' },
  { text: 'Tuesday', value: 'TU' },
  { text: 'Wednesday', value: 'WE' },
  { text: 'Thursday', value: 'TH' },
  { text: 'Friday', value: 'FR' },
  { text: 'Saturday', value: 'SA' },
  { text: 'Sunday', value: 'SU' },
];

const days = [...Array(31)].map((_a, i) => i + 1);

export const DAY_OPTIONS = days.map((a) => {
  return { value: a, text: String(a) };
});

export const NUMBER_WEEK_OPTIONS = [
  { text: 'First', value: 'FIRST' },
  { text: 'Second', value: 'SECOND' },
  { text: 'Third', value: 'THIRD' },
  { text: 'Fourth', value: 'FOURTH' },
  { text: 'Last', value: 'LAST' },
];

export const INITIAL_RECURRENCE = {
  changeMonthField: 'BYMONTHDAY',
  typeEndRecurrence: 'NEVER',
  interval: null,
  count: null,
  bymonth: null,
  bymonthPos: null,
  byweekday: null,
  bymonthday: null,
  bysetpos: null,
  until: null,
};

import { IconButton, InputAdornment } from '@mui/material';
import { Eye, EyeSlash } from 'phosphor-react';

type PropType = {
  showPassword: boolean;
  handleShowPassword: () => void;
};

export const EyeComponent = ({
  handleShowPassword,
  showPassword,
}: PropType) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={handleShowPassword} edge="end">
        {showPassword ? (
          <Eye size={20} weight="fill" />
        ) : (
          <EyeSlash size={20} weight="fill" />
        )}
      </IconButton>
    </InputAdornment>
  );
};

import { Box, Typography, useTheme } from '@mui/material';
import EmptyContent from 'App/assets/EmptyContent.png';

export const EmptyPage = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={5}
    >
      <img src={EmptyContent} alt="epty" />
      <Typography
        variant="h6"
        component="div"
        mt={4}
        color={theme.palette.grey[500]}
      >
        {title}
      </Typography>
    </Box>
  );
};

export const EmptyPages = ({ title }: { title: string }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={5}
    >
      <Typography variant="h6" component="div" color={theme.palette.grey[500]}>
        {title}
      </Typography>
    </Box>
  );
};

import * as Yup from 'yup';
import { checkPhoneNumber } from './changePhoneNumber';

export const AddMemberPopulationGroup = Yup.object({
  garageId: Yup.string().required('Required'),
  populationGroupId: Yup.string().required('Required'),
  user: Yup.object({
    phone: Yup.string()
      .required('Phone number is required')
      .test('test-name', 'Enter Valid Phone Number', checkPhoneNumber),
    firstName: Yup.string(),
    lastName: Yup.string(),
  }).required('Required'),
  isEdit: Yup.boolean(),
});

export type TMemberPopulationGroup = Yup.InferType<
  typeof AddMemberPopulationGroup
>;

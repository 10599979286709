import { Box, Button, Grid, Typography } from '@mui/material';
import { PencilSimple } from 'phosphor-react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import pelette from 'App/theme/palette';
import { RateTablePricing } from 'App/api/types';
import { getPriceForTime } from 'shared/utils';

type Props = {
  data: RateTablePricing[];
  busy: boolean;
  handleOpenModal: () => void;
};

export const RateCard = ({ data, busy, handleOpenModal }: Props) => {
  return (
    <>
      <Timeline
        sx={{
          padding: 0,
          marginBottom: 2,
          height: '220px',
        }}
      >
        <Box sx={{ overflow: 'auto' }}>
          {getPriceForTime(data).map(({ priceIncrease, text, id }) => (
            <Grid key={id}>
              <Grid item xs={3}>
                <TimelineItem
                  sx={{
                    '&:before': {
                      padding: 0,
                      flex: 1,
                      content: 'none',
                    },
                  }}
                  position="right"
                >
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector sx={{ width: '1px' }} />
                  </TimelineSeparator>
                  <TimelineContent whiteSpace="nowrap">
                    <Typography variant="subtitle2" component="div">
                      ${priceIncrease}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color={pelette.grey[600]}
                    >
                      up to {text}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Timeline>
      <Button
        size="small"
        color="inherit"
        endIcon={<PencilSimple weight="bold" />}
        variant="contained"
        disabled={busy}
        onClick={handleOpenModal}
      >
        Edit
      </Button>
    </>
  );
};

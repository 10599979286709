import { ReactNode } from 'react';

import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { ApiProvider as Provider, IApiContext } from './ApiContext';
import useProvideApi from './UseProvideApi';

type Props = {
  children: ReactNode;
};

function ApiProvider({ children }: Props) {
  const api = useProvideApi();

  return <Provider value={api as IApiContext}>{children}</Provider>;
}

export default ApiProvider;

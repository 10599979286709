import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const ButtonAction = styled(Button)`
  color: #637381;
  text-decoration: none;
  min-height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  margin: 0 auto;
  justify-content: start;
`;

import { Container, Box, Typography, Button, IconButton } from '@mui/material';
import { PlusCircle } from 'phosphor-react';

import { useResponsive } from 'shared';
import { Search } from 'shared/components';
import { RenderValidations, AddValidation } from './components';
import { useValidations } from './hooks/useValidations';

export const Validations = () => {
  const {
    garage,
    validations,
    sponsors,
    canCreateValidation,
    createValidation,
    validationInfo,
    setPattern,
    handleOpenModal,
    handleCloseModal,
    openModal,
    isLoading,
    busy,
  } = useValidations();
  const isDesktop = useResponsive('up', 'sm');

  return (
    <Container>
      {garage && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: isDesktop ? 4 : 2,
            }}
          >
            <Search
              callback={setPattern}
              placeholder="Search by validation or business name"
            />
            {isDesktop && (
              <Button
                size="large"
                variant="outlined"
                startIcon={<PlusCircle size={24} weight="bold" />}
                onClick={handleOpenModal}
                disabled={!canCreateValidation}
              >
                <Typography variant="subtitle1">New Validation</Typography>
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: isDesktop ? '15px' : '40px',
            }}
          >
            <Typography variant="body1" component="span">
              {validationInfo}
            </Typography>
            {!isDesktop && (
              <IconButton
                onClick={handleOpenModal}
                type="submit"
                disabled={!canCreateValidation}
                sx={{ p: 1 }}
              >
                <PlusCircle weight="light" size={24} />
              </IconButton>
            )}
          </Box>
          <RenderValidations
            validations={validations}
            isLoading={isLoading}
            sponsors={sponsors}
          />

          <AddValidation
            handleCloseModal={handleCloseModal}
            openModal={openModal}
            createValidation={createValidation}
            busy={busy}
            sponsors={sponsors}
          />
        </>
      )}
    </Container>
  );
};

import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { ISchedulesModal } from 'shared/components/RulesAndExceptions/types';
import { SelectInput } from 'shared/controls';
import { INITIAL_RECURRENCE, RECURRENCE_OPTIONS } from './constants';
import { EndRecurrence } from './EndRecurrence';
import { RenderFieldByType } from './RenderFieldByType';

export const Recurrence = () => {
  const { values, setFieldValue } = useFormikContext<ISchedulesModal>();
  const type = values.newRule.recurrenceData?.frequencyData?.freq;

  const setInitialValueRecurrence = () => {
    setFieldValue(
      'newRule.recurrenceData.recurrenceOption',
      INITIAL_RECURRENCE,
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <SelectInput
        label="Repeat"
        name="newRule.recurrenceData.frequencyData.freq"
        setInitialValue={setInitialValueRecurrence}
        options={RECURRENCE_OPTIONS}
      />
      <RenderFieldByType typeField={type} />
      {type && type !== 'NEVER' ? <EndRecurrence /> : null}
    </Box>
  );
};

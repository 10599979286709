import styled from '@emotion/styled';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Calendar, Car, PencilSimple, QrCode } from 'phosphor-react';
import { ISpecialPass } from 'App/api/types';
import { getPassTimeFromTimeStamp } from 'shared/utils';

type PropsType = {
  specialPassData: ISpecialPass;
  // setSpecialPassData: ISpecialPass;
  handleOpenModal: () => void;
};

const WrapperPassCard = styled(Box)`
  padding: 24px;
  border: 1px solid #000;
  border-radius: 16px;
`;

export const SpecialCard = ({
  specialPassData,
  // setSpecialPassData,
  handleOpenModal,
}: PropsType) => {
  const theme = useTheme();

  const { numberOfParks, fixedEntry, fixedExit, name } = specialPassData;

  const entry = getPassTimeFromTimeStamp(fixedEntry);
  const exit = getPassTimeFromTimeStamp(fixedExit);

  const days = numberOfParks
    ? `${numberOfParks} park limit`
    : 'Unlimited parks';

  return (
    <WrapperPassCard>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography noWrap maxWidth="210px" variant="h6" component="div">
          {name}
        </Typography>
        <Box>
          <Button
            sx={{ minWidth: '38px', height: '26px', padding: '0' }}
            variant="outlined"
            color="inherit"
            onClick={() => {
              // setSpecialPassData(specialPassData);
              handleOpenModal();
            }}
          >
            <PencilSimple size={12} />
          </Button>

          <Button
            sx={{ minWidth: '38px', height: '26px', padding: '0', ml: '5px' }}
            variant="outlined"
            color="inherit"
          >
            <QrCode size={12} />
          </Button>
        </Box>
      </Box>

      <Box mt={3}>
        <Box
          display="flex"
          flexDirection="column"
          color={theme.palette.grey[600]}
          mb={3}
        >
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Car size={20} style={{ marginRight: '10px' }} />
            {days}
          </Typography>

          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Calendar size={20} style={{ marginRight: '10px' }} />
            {entry}
          </Typography>

          <Typography variant="subtitle1" display="flex" alignItems="center">
            <Calendar size={20} style={{ marginRight: '10px' }} />
            {exit}
          </Typography>
        </Box>
      </Box>
    </WrapperPassCard>
  );
};

import * as Yup from 'yup';
import { zipcodeRex } from './regexp';

export const location = Yup.object({
  address: Yup.object({
    street: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip: Yup.string()
      .matches(zipcodeRex, 'Zip Code is not valid')
      .required('Required'),
    state: Yup.string().required(),
  }),
  coordinate: Yup.object({
    lat: Yup.number().required('Required'),
    long: Yup.number().required('Required'),
  }).required('Required'),
});

export type TLocation = Yup.InferType<typeof location>;

/* eslint-disable react/jsx-props-no-spreading */
import { Form, useFormikContext } from 'formik';
import { MaskInput } from 'shared/controls/maskInput';
import { priceMask } from 'shared/utils/masks';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { SelectInput } from 'shared/controls';
import pelette from 'App/theme/palette';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import {
  getDollars,
  getTimeCompleteSession,
  TState,
  useResponsive,
} from 'shared';
import { useEffect } from 'react';
import { TSessionComplete } from 'shared/validations/sessionComplete';
import { useActions } from '../../../../hooks/useActions';

type Props = {
  lanes: { value?: string | null; text: string }[];
  sessionData: any;
  openModal: boolean;
};

export const CompleteSessionForm = ({
  lanes,
  sessionData,
  openModal,
}: Props): JSX.Element => {
  const { calculateSessionPrice, priceForSession } = useActions(
    sessionData.garageId,
  );
  const { values, handleBlur, handleChange, setFieldValue } =
    useFormikContext<TSessionComplete>();
  const isMobile = useResponsive('down', 'md');

  useEffect(() => {
    if (openModal) {
      calculateSessionPrice(
        values.sessionId,
        getTimeCompleteSession(values.date, values.time),
      );
    }
  }, [
    calculateSessionPrice,
    openModal,
    values.date,
    values.sessionId,
    values.time,
  ]);

  useEffect(() => {
    if (priceForSession)
      setFieldValue('price', getDollars(priceForSession.total));
  }, [priceForSession, setFieldValue]);

  return (
    <Form>
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h4" component="div">
          Complete Session
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          color={pelette.grey[500]}
        >
          Park ID: {sessionData.supportID}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="div" mb={4}>
          Exit time
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mb={3}
        >
          <FormControlLabel
            control={
              <Radio
                checked={!values.customTime}
                onChange={() => {
                  setFieldValue('date', new Date());
                  setFieldValue('customTime', false);
                }}
                name="customTime"
              />
            }
            label={<Typography variant="body2">Right now</Typography>}
          />
          <FormControlLabel
            control={
              <Radio
                checked={values.customTime}
                onChange={() => {
                  setFieldValue('date', new Date());
                  setFieldValue('customTime', true);
                }}
                name="customTime"
              />
            }
            label={<Typography variant="body2">Custom time</Typography>}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <DatePicker
            label="Date"
            disabled={!values.customTime}
            value={values.date}
            minDate={sessionData.entryTime.toDate()}
            onChange={(newValue: Date | null) => {
              setFieldValue('date', newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
          <TimePicker
            label="Time"
            disabled={!values.customTime}
            value={values.time || new Date()}
            onChange={(value) => {
              setFieldValue('time', value);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: '100%', ml: isMobile ? 1 : 3 }}
                onBlur={handleBlur}
                {...params}
              />
            )}
          />
        </Box>
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Current Price
        </Typography>
        <MaskInput
          sx={{ width: '100%' }}
          type="text"
          name="price"
          mask={priceMask.mask}
          label="Current price"
          placeholder={priceMask.label}
          blocks={priceMask.blocks}
          disabled
        />
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Override price
        </Typography>
        <MaskInput
          sx={{ width: '100%' }}
          type="text"
          name="overridePrice"
          mask={priceMask.mask}
          label="Override price"
          placeholder={priceMask.label}
          blocks={priceMask.blocks}
        />
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Exit lane
        </Typography>
        <SelectInput
          sx={{ mb: 2 }}
          onBlur={handleBlur}
          name="garageLaneId"
          options={lanes as TState[]}
          label="Choose lane"
        />
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Comments
        </Typography>
        <TextField
          fullWidth
          value={values.reason}
          name="reason"
          placeholder="Why was the session manually completed?"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </DialogContent>
    </Form>
  );
};

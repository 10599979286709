import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import UserDefaultImg from 'App/assets/UserDefaultImg.svg';

type Props = { userName: string | null; organization: string | null };

export default function UserProfile({ userName, organization }: Props) {
  return (
    <Card
      sx={{
        paddingX: 2,
        boxShadow: 0,
        backgroundColor: '#919EAB14',
        m: '0 auto',
        maxWidth: 358,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CardMedia
        sx={{
          maxWidth: 40,
          maxHeight: 40,
        }}
        component="img"
        alt="cardImg"
        image={UserDefaultImg}
      />
      <CardContent
        sx={{
          '&:last-child': {
            p: 2,
          },
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} color="black">
          {userName}
        </Typography>
        <Typography variant="body2" fontWeight={700} color="text.secondary">
          {organization}
        </Typography>
      </CardContent>
    </Card>
  );
}

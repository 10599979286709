import { SetStateAction } from 'react';
import { TabPanel } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { PlusCircle } from 'phosphor-react';
import {
  IRules,
  IScheduledRate,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { RuleCard } from '../RuleCard';

type PropsType = {
  data: IRules[];
  value: string;
  handleOpenModalRule: () => void;
  setSubscriptionData: React.Dispatch<
    SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
  >;
  setRuleData: React.Dispatch<SetStateAction<IRules | null>>;
};

export const TabsSubscriptionsItem = ({
  data,
  value,
  handleOpenModalRule,
  setRuleData,
  setSubscriptionData,
}: PropsType) => {
  const textBtn = value === 'Rules' ? 'Rule' : 'Exception';

  return (
    <TabPanel sx={{ px: 0 }} value={value}>
      <Button
        size="large"
        variant="outlined"
        startIcon={<PlusCircle size={24} weight="bold" />}
        onClick={handleOpenModalRule}
        sx={{ mb: 3 }}
      >
        <Typography variant="subtitle1">Add {textBtn}</Typography>
      </Button>
      <Box display="flex" overflow="auto">
        {data &&
          data.map((rule: IRules) => (
            <RuleCard
              key={rule.id}
              rule={rule}
              handleOpenModal={handleOpenModalRule}
              setRuleData={setRuleData}
              setSubscriptionData={setSubscriptionData}
            />
          ))}
      </Box>
    </TabPanel>
  );
};

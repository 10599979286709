import { Paper } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { SessionInterface } from 'App/api/types/Session';
import { columns } from 'shared/utils';

type Props = {
  data: SessionInterface[] | null;
  page: number;
  fileName: string;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
};

export const SessionsGrid = ({
  data,
  rowsPerPageOptions,
  page,
  fileName,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
}: Props) => {
  const CustomToolbar = () => {
    return (
      <GridToolbar
        csvOptions={{
          fileName,
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    );
  };

  return data ? (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <DataGrid
        disableSelectionOnClick
        autoHeight
        columns={columns}
        rows={data}
        pagination
        components={{ Toolbar: CustomToolbar }}
        rowsPerPageOptions={rowsPerPageOptions}
        page={page}
        onPageChange={handleChangePage}
        pageSize={rowsPerPage}
        onPageSizeChange={handleChangeRowsPerPage}
      />
    </Paper>
  ) : null;
};

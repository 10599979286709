import { IconProps, ArrowSquareOut } from 'phosphor-react';
import { IHeadCell } from 'shared';

export interface IAction {
  label: string;
  icon: React.ForwardRefExoticComponent<IconProps>;
}

export const headCellsLanes: IHeadCell[] = [
  {
    id: 'id',
    label: 'Lane ID',
    numeric: false,
  },
  {
    id: 'direction',
    label: 'Direction',
    numeric: true,
  },
  {
    id: 'description',
    label: 'Description',
    numeric: true,
  },
  {
    id: 'status',
    label: `Status`,
    numeric: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: true,
  },
];

export const items: IAction[] = [
  {
    label: 'Open gate',
    icon: ArrowSquareOut,
  },
];

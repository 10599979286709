import { useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import { IconButton, Menu, Typography } from '@mui/material';
import { DotsThree } from 'phosphor-react';
import { IAction } from 'App/api/types';
import { ButtonAction } from 'shared';
import { useActions } from '../hooks/useActions';
import {
  ApplyValidationModal,
  ChangePhoneNumberModal,
  CompleteSessionModal,
  OverridePriceModal,
  RefundModal,
  RemoveSessionModal,
  SupportInquiryModal,
  AddPaymentModal,
} from './modalWindows';
import { usePayment } from '../hooks/usePayment';

export type PropsType = {
  items: IAction[];
  sessionData: {
    parkId: string;
    supportId: string;
    sessionId: string;
    garageId: string;
    exitTime: firebase.firestore.Timestamp | null;
    entryTime: firebase.firestore.Timestamp;
  };
};

export const Actions = ({ items, sessionData }: PropsType) => {
  const {
    lanes,
    modalChangePhoneNumber,
    modalCompleteSession,
    modalRemoveSession,
    modalRefund,
    modalSupportInquiry,
    modalOverridePrice,
    modalApplyValidation,
    modalAddPayment,
    busy,
    changePhoneNumber,
    resendLinkToSession,
    sendLinkToReceipt,
    sendAddPaymentLink,
    completeSession,
    refundSession,
    supportInquiryFn,
    overridePriceFn,
    redirectToWithGarageId,
    removeSessionFn,
  } = useActions(sessionData.garageId);

  const { supportPaymentMethodsSetup } = usePayment();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { supportId } = sessionData;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = useCallback(
    (label) => {
      if (label === 'Change phone number')
        modalChangePhoneNumber.handleOpenModal();
      if (label === 'Send session URL')
        resendLinkToSession(sessionData.sessionId);
      if (label === 'Send add payment URL')
        sendAddPaymentLink(sessionData.sessionId);
      if (label === 'Send receipt URL')
        sendLinkToReceipt(sessionData.sessionId);
      // if (label === 'Add payment manually')
      if (label === 'Override price') modalOverridePrice.handleOpenModal();
      if (label === 'Complete session') modalCompleteSession.handleOpenModal();
      if (label === 'Cancel session') modalRemoveSession.handleOpenModal();
      if (label === 'Apply validation') modalApplyValidation.handleOpenModal();
      if (label === 'Refund') modalRefund.handleOpenModal();
      if (label === 'Open gates')
        redirectToWithGarageId('lanes', sessionData.garageId);
      if (label === 'Support inquiry') modalSupportInquiry.handleOpenModal();
      if (label === 'Add payment manually') {
        supportPaymentMethodsSetup(sessionData.sessionId);
        modalAddPayment.handleOpenModal();
      }
      handleClose();
    },
    [
      modalChangePhoneNumber,
      resendLinkToSession,
      sessionData.sessionId,
      sessionData.garageId,
      sendLinkToReceipt,
      sendAddPaymentLink,
      supportPaymentMethodsSetup,
      modalOverridePrice,
      modalCompleteSession,
      modalRemoveSession,
      modalApplyValidation,
      modalRefund,
      redirectToWithGarageId,
      modalSupportInquiry,
      modalAddPayment,
    ],
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <DotsThree size={16} />
      </IconButton>
      <Menu
        sx={{ px: 2 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items
          .filter(({ isComplete }) =>
            sessionData.exitTime !== undefined
              ? isComplete || isComplete === undefined
              : !isComplete || isComplete === undefined,
          )
          .map(({ label }: IAction) => (
            <ButtonAction key={label} onClick={() => handleAction(label)}>
              <Typography variant="body2" color="primary">
                {label}
              </Typography>
            </ButtonAction>
          ))}
      </Menu>
      <ChangePhoneNumberModal
        modalChangePhoneNumber={modalChangePhoneNumber}
        sessionData={sessionData}
        changePhoneNumber={changePhoneNumber}
      />
      <CompleteSessionModal
        busy={busy}
        modalCompleteSession={modalCompleteSession}
        sessionData={sessionData}
        lanes={lanes}
        completeSession={completeSession}
      />
      <RemoveSessionModal
        modalRemoveSession={modalRemoveSession}
        removeSessionFn={removeSessionFn}
        sessionId={sessionData.sessionId}
        busy={busy}
      />
      <RefundModal
        modalRefund={modalRefund}
        refundSession={refundSession}
        supportId={supportId}
        sessionId={sessionData.sessionId}
        busy={busy}
      />
      <SupportInquiryModal
        modal={modalSupportInquiry}
        supportInquiryFn={supportInquiryFn}
        sessionId={sessionData.sessionId}
        busy={busy}
      />
      <OverridePriceModal
        modal={modalOverridePrice}
        sessionId={sessionData.sessionId}
        busy={busy}
        overridePriceFn={overridePriceFn}
      />
      <ApplyValidationModal
        sessionData={sessionData}
        modal={modalApplyValidation}
      />
      <AddPaymentModal modal={modalAddPayment} />
    </>
  );
};

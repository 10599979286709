import * as Yup from 'yup';

export const addValidationsValidation = Yup.object({
  validationId: Yup.string(),
  name: Yup.string().required('Required'),
  business: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  isVisible: Yup.boolean(),
  isUserPays: Yup.boolean(),
  businessCharged: Yup.boolean(),
  businessValue: Yup.string().when('businessCharged', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  description: Yup.string().required('Required'),
  garageId: Yup.string().required('Required'),
  value: Yup.string().required('Required'),
});

export type TValidation = Yup.InferType<typeof addValidationsValidation>;

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { useFirebase } from 'App/firebase';
import { useToast } from 'shared';
import { generateRoutePath, RouteName } from 'App/helpers';

export function useResetPassword() {
  const navigate = useNavigate();
  const firebase = useFirebase();
  const toast = useToast();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const resetPassword = useCallback(
    async (code, password) => {
      const auth = firebase.auth();
      try {
        await auth.confirmPasswordReset(code, password);
        toast.success('Password updated successfully');
        navigate(generateRoutePath({ name: RouteName.SignIn }));
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [firebase, navigate, toast],
  );

  const resetPasswordWithEmail = useCallback(
    async (email) => {
      try {
        await firebase.auth().sendPasswordResetEmail(email);
        toast.success('Email sent successfully');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [firebase, toast],
  );

  const validationSchemaEmail = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const validationSchemaPasswords = Yup.object({
    password: Yup.string()
      .min(6, 'Must be 6 characters or more')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password is required'),
  });

  return {
    resetPasswordWithEmail,
    resetPassword,
    validationSchemaEmail,
    validationSchemaPasswords,
    showPassword,
    handleShowPassword,
  };
}

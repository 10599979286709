import { FunctionsError } from 'firebase/functions';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import {
  useApiFunction,
  useBusy,
  useGarageData,
  useModal,
  useToast,
} from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';

export const useAccountActions = () => {
  const { setErrorContent } = useErrorHandler();
  const { setBusy } = useBusy();
  const apiFn = useApiFunction();
  const toast = useToast();
  const { accountId, locationId } = useParams();
  const { garageId } = useGarageData();
  const modalActivateAccount = useModal();
  const modalDisableAccount = useModal();
  const modalMember = useModal();

  const createAccount = useCallback(
    async (name, businessID) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_create',
          data: {
            garageID: garageId,
            accountData: {
              name,
              businessID,
            },
          },
        });

        toast.success('Account created!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
      }
    },
    [apiFn, garageId, setBusy, setErrorContent, toast],
  );

  const editAccount = useCallback(
    async (name, businessID, id) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_edit',
          data: {
            garageID: garageId,
            accountData: {
              id,
              name,
              businessID,
            },
          },
        });

        toast.success('Account Edited!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
      }
    },
    [apiFn, garageId, setBusy, setErrorContent, toast],
  );

  const activateDeactivate = useCallback(
    async (id, action) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_edit',
          data: {
            garageID: garageId,
            accountData: {
              id,
              status: action === 'activate' ? 'active' : 'disabled',
            },
          },
        });

        if (action === 'activate') toast.success('Account Activated!');
        else toast.success('Account Disabled!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
      }
    },
    [apiFn, garageId, setBusy, setErrorContent, toast],
  );

  const editMember = useCallback(
    async (id, status) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_members_edit',
          data: {
            garageID: locationId,
            accountID: accountId,
            accountMemberData: {
              id,
              status,
            },
          },
        });

        toast.success('Status Changed!');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
    },
    [accountId, apiFn, locationId, setBusy, toast],
  );

  const removeMember = useCallback(
    async (id) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_members_remove',
          data: {
            garageID: locationId,
            accountID: accountId,
            accountMemberID: id,
          },
        });

        toast.success('Member Removed!');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
    },
    [accountId, apiFn, locationId, setBusy, toast],
  );

  const handleActionWithMember = useCallback(
    async (action, member) => {
      try {
        setBusy(true);
        if (action === 'Remove') return await removeMember(member.id);
        if (action === 'Suspend')
          return await editMember(member.id, 'suspended');
        if (action === 'Reactivate')
          return await editMember(member.id, 'active');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
        modalMember.handleCloseModal();
      }
      return null;
    },
    [editMember, modalMember, removeMember, setBusy, toast],
  );

  return {
    handleActionWithMember,
    activateDeactivate,
    modalActivateAccount,
    modalDisableAccount,
    modalMember,
    createAccount,
    accountId,
    editAccount,
  };
};

import { useEffect, useState } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import { Box, IconButton, InputBase } from '@mui/material';
import { useResponsive } from 'shared';

type Props = {
  callback: (val: string) => void;
  placeholder?: string;
  mb?: number;
};

export const Search = ({ callback, placeholder, mb }: Props): JSX.Element => {
  const [val, setValue] = useState<string>('');
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    callback(val);
  }, [callback, val]);

  return (
    <Box
      sx={{
        width: '100%',
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: isMobile ? '100%' : 358,
        border: '1px solid lightgrey',
        borderRadius: '5px',
        mb,
      }}
    >
      <IconButton type="submit" sx={{ p: '10px' }}>
        <MagnifyingGlass />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onChange={({ target: { value } }) => setValue(value)}
      />
    </Box>
  );
};

import { SetStateAction } from 'react';
import { Formik } from 'formik';

import { AccordionGeneral } from 'shared/components';
import { basicDetails } from 'shared/validations/basicDetails';
import { BasicDetailsForm } from './BasicDetailsForm';

type PropsType = {
  basicDetailsData: {
    name: string | undefined;
    status: string | undefined;
  };
  busy: boolean;
  saveBasicDetails: <T>(data: T) => void;
  editBasicDetails: {
    isEditBasicDetails: boolean;
    setIsEditBasicDetails: React.Dispatch<SetStateAction<boolean>>;
  };
};

export const BasicDetails = ({
  busy,
  basicDetailsData,
  saveBasicDetails,
  editBasicDetails,
}: PropsType): JSX.Element => {
  const { name, status } = basicDetailsData;
  const { isEditBasicDetails, setIsEditBasicDetails } = editBasicDetails;

  return (
    <AccordionGeneral title="Basic Details">
      <Formik
        initialValues={{
          status,
          name,
        }}
        enableReinitialize
        validationSchema={basicDetails}
        onSubmit={async (values) => {
          saveBasicDetails(values);
        }}
      >
        <BasicDetailsForm
          busy={busy}
          isEditBasicDetails={isEditBasicDetails}
          setIsEditBasicDetails={setIsEditBasicDetails}
        />
      </Formik>
    </AccordionGeneral>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { UserInterface } from 'App/api/types/User';
import { Formik } from 'formik';
import {
  AddMemberPopulationGroup,
  TMemberPopulationGroup,
} from 'shared/validations/memberPopulationGroup';
import { EditMembersForm } from './form/EditMembersForm';

type PropType = {
  closeModal: () => void;
  users: UserInterface[] | null;
  busy: boolean;
  busyMember: boolean;
  busyUser: string;
  setEditItem: Dispatch<SetStateAction<string>>;
  setPattern: Dispatch<SetStateAction<string>>;
  cleanData: () => void;
  modalAddMember: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  data: {
    populationGroupId?: string;
    garageId?: string;
  };
  addMemberPopulationGroup: (values: any, closeModal: any) => Promise<void>;
  removeMemberPopulationGroup: (values: any) => Promise<void>;
};

const NEW_POPULATION_GROUP_MEMBER: TMemberPopulationGroup = {
  garageId: '',
  populationGroupId: '',
  isEdit: false,
  user: {
    phone: '',
    firstName: '',
    lastName: '',
  },
};

export const EditMembersModal = ({
  closeModal,
  users,
  data,
  busy,
  busyMember,
  busyUser,
  setEditItem,
  setPattern,
  cleanData,
  addMemberPopulationGroup,
  removeMemberPopulationGroup,
  modalAddMember,
}: PropType) => {
  return users ? (
    <>
      <Typography variant="h4" component="h1" mb={3}>
        Edit Population Group
      </Typography>

      <Formik
        initialValues={{
          ...NEW_POPULATION_GROUP_MEMBER,
          garageId: data?.garageId,
          populationGroupId: data?.populationGroupId,
        }}
        validationSchema={AddMemberPopulationGroup}
        onSubmit={async (values) => {
          await addMemberPopulationGroup(
            values,
            modalAddMember.handleCloseModal,
          );
        }}
      >
        <EditMembersForm
          isBusy={busy}
          isBusyMember={busyMember}
          busyUser={busyUser}
          closeModal={closeModal}
          cleanData={cleanData}
          users={users}
          data={data}
          setEditItem={setEditItem}
          setPattern={setPattern}
          modalAddMember={modalAddMember}
          removeMemberPopulationGroup={removeMemberPopulationGroup}
        />
      </Formik>
    </>
  ) : null;
};

import { Form } from 'formik';
import { Box, Typography } from '@mui/material';
import { MaskInput } from 'shared/controls/maskInput';
import { priceMask } from 'shared/utils/masks';

export const OverridePriceForm = () => {
  return (
    <Form>
      <Box p={3}>
        <Typography variant="h4" component="h1" mb={3}>
          Override Price
        </Typography>
        <Typography variant="button" component="h1" mb={3}>
          Set a custom price for the parking session that will be applied
          whenever the user exits
        </Typography>
        <MaskInput
          sx={{ width: '100%' }}
          type="text"
          name="amount"
          mask={priceMask.mask}
          label="Override price"
          placeholder={priceMask.label}
          blocks={priceMask.blocks}
        />
      </Box>
    </Form>
  );
};

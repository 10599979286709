import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { GarageLaneInterface } from './interface';
import { GarageLaneValidator } from './validator';

export const GarageLaneConverter: firebase.firestore.FirestoreDataConverter<GarageLaneInterface> =
  {
    toFirestore(
      garageLane: GarageLaneInterface,
    ): firebase.firestore.DocumentData {
      if (GarageLaneValidator(garageLane)) return garageLane;
      throw new ConverterError(
        'Model validation error.',
        JSON.stringify(GarageLaneValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): GarageLaneInterface {
      const garageLane = snapshot.data();
      if (GarageLaneValidator(garageLane)) return garageLane;
      throw new ConverterError(
        'Model validation error.',
        JSON.stringify(GarageLaneValidator.errors),
      );
    },
  };

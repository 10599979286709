import { Dispatch, SetStateAction } from 'react';
import { Box, Grid, Typography } from '@mui/material';
// import { IPopulationGroup } from 'App/api/types';
import { AccordionGeneral } from 'shared/components';
import { IPopulationGroup } from 'App/api/types/GaragePopulationGroup';
import { CardPricing } from '../CardPricing';

type PropsType = {
  handleOpenModal: () => void;
  handleOpenMembers: () => void;
  values: IPopulationGroup[];
  setEditItem: Dispatch<SetStateAction<string>>;
};

export const PopulationGroup = ({
  values,
  setEditItem,
  handleOpenModal,
  handleOpenMembers,
}: PropsType) => {
  return (
    <AccordionGeneral title="Population Groups" createItem={handleOpenModal}>
      <Box px={1} py={2}>
        <Typography variant="body1" component="div" mb={2}>
          Create groups of users that will receive a discount as compared to the
          base rate selected above.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            overflow: 'auto',
          }}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {values.map((populationGroup) => (
              <Grid key={populationGroup.id} item xs={12} sm={6} md={4}>
                <CardPricing
                  id={populationGroup.id}
                  // priority={populationGroup.priority || 0}
                  name={populationGroup.name}
                  type={populationGroup.type}
                  amount={populationGroup.amount}
                  handleOpenModal={handleOpenModal}
                  handleOpenMembers={handleOpenMembers}
                  setEditItem={setEditItem}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </AccordionGeneral>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import ReactPhoneInput from 'react-phone-input-material-ui';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { SelectInput } from 'shared/controls';
import { TSession } from 'shared/validations/addSession';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TState, useResponsive } from 'shared';
import { IGarage } from 'App/api/types/Garage';

type Props = {
  garages: IGarage[];
  lanes: { value?: string | null; text: string }[];
  setCurrentGarageId: Dispatch<SetStateAction<string | null>>;
};

export const StartSessionForm = ({
  garages,
  lanes,
  setCurrentGarageId,
}: Props): JSX.Element => {
  const { handleBlur, handleChange, values, setFieldValue, touched, errors } =
    useFormikContext<TSession>();
  const isMobile = useResponsive('down', 'md');

  return (
    <Form>
      <DialogTitle sx={{ padding: '24px 24px' }}>
        <Typography variant="h4" component="div">
          Start Session
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="h6" component="div" mb={2}>
            Phone number
          </Typography>
          <ReactPhoneInput
            value={values.phone}
            onChange={handleChange}
            component={TextField}
            inputProps={{
              name: 'phone',
              error: touched.phone && Boolean(errors.phone),
              helperText: touched.phone && errors.phone,
              onBlur: handleBlur,
              onChange: handleChange,
            }}
          />
        </Box>
        <Typography variant="h6" component="div" mb={4}>
          Entry time
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mb={3}
        >
          <FormControlLabel
            control={
              <Radio
                checked={!values.customTime}
                onChange={() => {
                  setFieldValue('date', new Date());
                  setFieldValue('customTime', false);
                }}
                name="customTime"
              />
            }
            label={<Typography variant="body2">Right now</Typography>}
          />
          <FormControlLabel
            control={
              <Radio
                checked={values.customTime}
                onChange={() => {
                  setFieldValue('date', new Date());
                  setFieldValue('customTime', true);
                }}
                name="customTime"
              />
            }
            label={<Typography variant="body2">Custom time</Typography>}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <DatePicker
            label="Date"
            disabled={!values.customTime}
            value={values.date}
            onChange={(newValue: Date | null) => {
              setFieldValue('date', newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
          <TimePicker
            label="Time"
            disabled={!values.customTime}
            value={values.time || new Date()}
            onChange={(value) => {
              setFieldValue('time', value);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: '100%', ml: isMobile ? 1 : 3 }}
                onBlur={handleBlur}
                {...params}
              />
            )}
          />
        </Box>
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Garage
        </Typography>
        <SelectInput
          sx={{ mb: 2 }}
          value={values.garageId}
          onBlur={handleBlur}
          name="garageId"
          setData={setCurrentGarageId}
          options={
            garages.map((garage) => {
              return { text: garage.name, value: garage.id };
            }) as TState[]
          }
          label="Garage"
        />
        <Typography variant="h6" component="div" mt={2} mb={2}>
          Entry lane
        </Typography>
        <SelectInput
          sx={{ mb: 2 }}
          onBlur={handleBlur}
          name="garageLaneId"
          options={lanes as TState[]}
          label="Choose lane"
        />

        <Typography variant="h6" component="div" mt={2} mb={2}>
          Comments
        </Typography>
        <TextField
          fullWidth
          value={values.reason}
          name="reason"
          placeholder="Why was the session manually started?"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </DialogContent>
    </Form>
  );
};

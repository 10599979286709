import { ReactNode } from 'react';
import { ErrorProvider as Provider, EContext } from './ErrorContext';
import { useProvideError } from './UseProvideError';

type Props = {
  children: ReactNode;
};

function ErrorProvider({ children }: Props): JSX.Element {
  const error = useProvideError();

  return <Provider value={error as EContext}>{children}</Provider>;
}

export default ErrorProvider;

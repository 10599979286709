import { useContext } from 'react';
import { TSubsctiptions } from 'pages/management/passes/types';
import { TScheduled } from 'pages/management/pricing/components/scheduledRates/types';
import { RulesAndExeptionsContext, RuleAndExeptionContext } from '../context';

export const useContextRulesAndExeptions = () => {
  const values = useContext(RulesAndExeptionsContext);
  return values as TSubsctiptions;
};

export const useContextRuleAndExeption = () => {
  const values = useContext(RuleAndExeptionContext);
  return values as TScheduled;
};

import { Box, Typography } from '@mui/material';

function textAction(status: string | null, name: string, buisness?: string) {
  switch (status) {
    case 'Remove':
      return `Please confirm you want to remove ${name} from ${buisness} Admin account?`;

    case 'Reactivate':
      return `Please confirm you want to reactivate ${name} from ${buisness} Admin account?`;

    case 'Suspend':
      return `Please confirm you want to suspend ${name} from ${buisness} Admin account?`;

    default:
      return 'Not found';
  }
}

type PropType = {
  action: 'Reactivate' | 'Suspend' | 'Remove' | null;
  name?: string;
  businessName?: string;
};

export const ActionMemberForm = ({ action, name, businessName }: PropType) => {
  const nameCorrect = name && name.trim() ? name : 'Unknow';
  const subTitle = textAction(action, nameCorrect, businessName);

  return (
    <Box p={3}>
      <Typography variant="h4" component="div" mb={3}>
        {action} account member
      </Typography>
      <Typography variant="body1" component="div">
        {subTitle}
      </Typography>
    </Box>
  );
};

import { pathOr } from 'rambda';
import { GarageInterface } from 'App/api/types/Garage';
import { TFBQuerySnapshot, TQuery } from 'shared/types';
import { GarageAccountMemberInterface } from 'App/api/types/GarageAccountMember';
import { normalizeCollection } from './db';

type PropsInvites = {
  query: (path: string) => TQuery;
  garageId?: string;
  accountId: string;
};

export const getTimezone = (garage: GarageInterface) =>
  pathOr(null, 'timezone', garage);

export const getInvites = async ({
  query,
  garageId,
  accountId,
}: PropsInvites) => {
  const invites = await query(
    `garages/${garageId}/accounts/${accountId}/members`,
  ).get();
  return normalizeCollection(invites as unknown as TFBQuerySnapshot);
};

export const getUsedSeats = (invites: GarageAccountMemberInterface[]) =>
  invites.filter((obj) => obj.status === 'active').length;

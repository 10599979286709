import { useEffect, useState } from 'react';
import { normalizeCollection } from 'shared/utils';
import { TFBCollection, useFirebaseApi, TFBQuerySnapshot } from 'shared';
import { onSnapshot } from 'firebase/firestore';
import { IUser } from './types';

function useUsers() {
  const { dbQuery } = useFirebaseApi();
  const [users, setUsers] = useState<IUser[] | null>(null);

  useEffect(() => {
    const ref = dbQuery('users') as TFBCollection;
    const unsubscribe = onSnapshot(ref, (resUsers: unknown) => {
      const normalizeUsersData = normalizeCollection(
        resUsers as unknown as TFBQuerySnapshot,
      );
      setUsers(normalizeUsersData as IUser[]);
    });
    return () => {
      unsubscribe();
    };
  }, [dbQuery]);

  return {
    users,
  };
}

export default useUsers;

import { useCallback, useEffect, useState } from 'react';
import { IValidation, ValidationConverter } from 'App/api/types/Validation';
import {
  normalizeCollection,
  normalizeDoc,
  TFBCollection,
  TState,
  useApiFunction,
  useFirebaseApi,
  useToast,
} from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

export const useApplyValidation = (garageId: string) => {
  const { dbQuery } = useFirebaseApi();
  const apiFn = useApiFunction();
  const toast = useToast();
  const { setErrorContent } = useErrorHandler();

  const [validations, setValidations] = useState<IValidation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationsList: TState[] = validations.map((item: IValidation) => ({
    text: `${item.buisnessName} (${item.name})`,
    value: item.id,
  }));

  const getValidations = useCallback(async () => {
    try {
      const validationData = await (
        dbQuery(`garages/${garageId}/validations`) as TFBCollection
      )
        .withConverter(ValidationConverter)
        .where('status', '==', 'active')
        .get();

      const normalaizeValidations: IValidation[] =
        normalizeCollection(validationData);

      const fullData = await Promise.all(
        normalaizeValidations.map(async (validation) => {
          const businessData = await dbQuery(validation.business.path).get();
          const normalizeBusinessData = normalizeDoc(businessData as any);

          return {
            ...validation,
            buisnessName: normalizeBusinessData.name,
          };
        }),
      );

      setValidations(fullData as IValidation[]);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  }, [dbQuery, garageId, toast]);

  const applyValidationFn = useCallback(
    async (sessionID, validationID) => {
      try {
        setIsLoading(true);
        await apiFn({
          action: 'support_apply_validation',
          data: {
            sessionID,
            validationID,
          },
        });
        toast.success('validation applied successfully');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [apiFn, setErrorContent, toast],
  );

  useEffect(() => {
    getValidations();
  }, [getValidations]);

  return { validationsList, applyValidationFn, isLoading, getValidations };
};

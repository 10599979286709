import ApiProvider from 'App/api/ApiProvider';
import LocationProvider from 'App/contexts/location/LocationProvider';
import { BusyProvider, useProvideBusy } from 'App/layout/busy';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'shared';

export const PrivateOutlet = (): JSX.Element => {
  const { authenticated } = useAuth();
  const value = useProvideBusy();

  return authenticated ? (
    <BusyProvider value={value}>
      <ApiProvider>
        <LocationProvider>
          <Outlet />
        </LocationProvider>
      </ApiProvider>
    </BusyProvider>
  ) : (
    <Navigate replace to="/login" />
  );
};

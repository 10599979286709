import { Container } from '@mui/material';
import { BasicDetails, Photos, Location } from './components';
import { useOverview, usePhotos } from './hooks';

export const Overview = (): JSX.Element => {
  const {
    garageData: { garage },
    saveLocation,
    locationData,
    editLocation,
    busyLocation,
    saveBasicDetails,
    basicDetailsData,
    busyBasicDetails,
    editBasicDetails,
  } = useOverview();

  const { savePhotos, photosData, busy: busyPhotos, editPhotos } = usePhotos();

  // const {
  //   messagingData,
  //   editMessaging,
  //   saveMessaging,
  //   busy: busyMessaging,
  // } = useMessaging();

  return (
    <Container>
      {garage && (
        <>
          <BasicDetails
            busy={busyBasicDetails}
            basicDetailsData={basicDetailsData}
            editBasicDetails={editBasicDetails}
            saveBasicDetails={saveBasicDetails}
          />
          <Photos
            busyPhotos={busyPhotos}
            photosData={photosData}
            editPhotos={editPhotos}
            savePhotos={savePhotos}
          />
          <Location
            busyLocation={busyLocation}
            locationData={locationData}
            editLocation={editLocation}
            saveLocation={saveLocation}
          />
          {/* <Messaging
            busyMessaging={busyMessaging}
            messagingData={messagingData}
            editMessaging={editMessaging}
            saveMessaging={saveMessaging}
          /> */}
        </>
      )}
    </Container>
  );
};

import { Box, Typography } from '@mui/material';
import { Form } from 'formik';
import { SelectInput, TState } from 'shared';

type PropsType = {
  validationsList: TState[];
};

export const ApplyValidation = ({ validationsList }: PropsType) => {
  return (
    <Form>
      <Box p={3} width="100%">
        <Typography variant="h4" component="h1">
          Apply Validation
        </Typography>
        <Box sx={{ my: 3 }}>
          <SelectInput
            name="validationId"
            label="Choose validation"
            options={validationsList}
          />
        </Box>
      </Box>
    </Form>
  );
};

import { Route, Routes, Navigate } from 'react-router-dom';
import { generateRoutePath, RouteName } from 'App/routes/helpers';
import { unauthenticatedRoutes } from 'App/helpers';
import { useAuth } from 'shared';
import Layout from 'App/layout/public/Layout';

export function UnauthenticatedRoutes(): JSX.Element {
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Navigate to={generateRoutePath({ name: RouteName.Home })} />;
  }
  return (
    <Layout>
      <Routes>
        {unauthenticatedRoutes.map(({ label, path, Component }) => (
          <Route key={label} path={path} element={<Component />} />
        ))}
        <Route
          path="*"
          element={
            <Navigate to={generateRoutePath({ name: RouteName.SignIn })} />
          }
        />
      </Routes>
    </Layout>
  );
}

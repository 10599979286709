import { useState, useCallback, useEffect } from 'react';
import { onSnapshot, DocumentReference, Query } from 'firebase/firestore';
import {
  TFBCollection,
  useFirebaseApi,
  normalizeCollection,
  useApi,
  useToast,
  TFBQuerySnapshot,
  normalizeDoc,
  TFBDocumentSnapshot,
} from 'shared';
import { removeSymbolsForPhoneNumber } from 'shared/utils/phone';
import { checkPhoneNumber } from 'shared/validations/changePhoneNumber';
import { SessionConverter, SessionInterface } from 'App/api/types/Session';
import { UserConverter, UserInterface } from 'App/api/types/User';
import { IVehicle } from 'App/api/types/Vehicle';

export const useSessions = () => {
  const {
    garagesRef,
    garages,
    accounts,
    businesses,
    users,
    validations,
    // vehicles,
  } = useApi();
  const { dbQuery } = useFirebaseApi();
  const toast = useToast();

  const [typeOfSearch, setTypeOfSearch] = useState<
    'phone' | 'parkId' | 'licensePlate'
  >('phone');
  const [loading, setLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState<number>(0);

  const [data, setData] = useState<SessionInterface[]>([]);

  const [userSearch, setUserSearch] =
    useState<DocumentReference<UserInterface> | null>(null);
  const [vehicleSearch, setVehicleSearch] = useState<DocumentReference | null>(
    null,
  );
  const [phone, setPhone] = useState<string>('');

  const refRequest = useCallback(
    (typeWhere: string, value: DocumentReference | string | null) => {
      return (dbQuery('sessions') as TFBCollection)
        .withConverter(SessionConverter)
        .where('garage', 'in', garagesRef)
        .where(typeWhere, '==', value)
        .orderBy('entry.time', 'desc');
    },
    [dbQuery, garagesRef],
  );

  const getAccounstIdAndPassName = (
    payload: SessionInterface['pricing']['breakdown'],
  ) => {
    if (!payload) return null;
    const accountsId = payload?.map((br) =>
      br.rate.type === 'subscription' && br.rate.link
        ? br.rate.link.path.split('/')[3]
        : '',
    );
    const passName = payload.map((br) =>
      br.rate.type === 'subscription' ? br.rate.description : '',
    );
    return { accountsId, passName };
  };

  const getFullData = useCallback(
    (sessionData) => {
      const newArr = sessionData.map((session: SessionInterface) => {
        const netRevenue =
          session?.payment?.status === 'succeeded' ||
          session?.payment?.status === 'refunded'
            ? session?.pricing?.total -
              (session?.pricing.tax ?? 0) -
              session?.pricing?.processingFee?.amount -
              (session.refund?.amount ?? 0)
            : 0;

        const duration =
          session.exit &&
          session.exit?.time &&
          session.entry &&
          session.entry?.time
            ? session.exit?.time?.toMillis() - session.entry?.time?.toMillis()
            : new Date().valueOf() - (session.entry?.time?.toMillis() ?? 0);

        const { accountsId, passName } =
          getAccounstIdAndPassName(session?.pricing?.breakdown) || {};

        const accountsData =
          accountsId && accounts?.filter((acc) => accountsId.includes(acc.id));

        const busnessesId = accountsData?.map(
          (acc) => acc.business && acc.business.id,
        );
        const businessData = businesses?.filter((business) =>
          busnessesId?.includes(business.id),
        );

        const validation =
          validations &&
          validations.find((v) => v.id === session.validation?.id);

        // remove vehicle if it is a firestore reference
        if (session?.vehicle?.firestore) {
          // eslint-disable-next-line no-param-reassign
          session.vehicle = undefined;
        }

        return {
          ...session,
          garage: garages && garages.find((g) => g.id === session.garage?.id),
          user: users && users.find((u) => u.id === session.user?.id),
          validation,
          accounts: accountsData,
          businesses: businessData,
          validatingBusiness:
            validation &&
            businesses?.find((b) => b.id === validation.business.id),
          passes: passName,
          netRevenue,
          duration,
        };
      });
      return newArr;
    },

    [accounts, businesses, garages, users, validations],
  );

  const handleChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
  };

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    try {
      const user = await (dbQuery(`users`) as TFBCollection)
        .where('phone', '==', removeSymbolsForPhoneNumber(phone))
        .withConverter(UserConverter)
        .get();
      if (user.docs.length)
        setUserSearch(
          user.docs[0].ref as unknown as DocumentReference<UserInterface>,
        );
      else {
        toast.warning(`The user ${phone} does not exist`);
      }
    } catch (e) {
      setUserSearch(null);
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  }, [dbQuery, phone, toast]);

  const fetchVehicle = useCallback(async () => {
    setLoading(true);
    try {
      const vehicle = await (dbQuery(`vehicles`) as TFBCollection)
        .where('plate', '==', phone)
        .get();
      if (vehicle.docs.length)
        setVehicleSearch(vehicle.docs[0].ref as unknown as DocumentReference);
      else {
        setLoading(false);
        toast.info(`No parking sessions found with ${phone}`);
      }
    } catch (e) {
      setVehicleSearch(null);
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  }, [dbQuery, phone, toast]);

  useEffect(() => {
    const vehiclesUnsubscribe: Array<() => void> = [];
    let unsubscribe: () => void;
    try {
      if (!garagesRef.length || !phone) {
        return undefined;
      }
      if (typeOfSearch === 'licensePlate' && !vehicleSearch) {
        return undefined;
      }
      if (typeOfSearch === 'phone' && !userSearch) {
        return undefined;
      }
      setLoading(true);
      unsubscribe = onSnapshot(
        typeOfSearch === 'phone'
          ? refRequest('user', userSearch)
          : (typeOfSearch === 'licensePlate' &&
              refRequest('vehicle', vehicleSearch)) ||
              refRequest('supportID', phone),
        async (docs) => {
          // No sessions found
          if (docs.empty) {
            toast.info(`No parking sessions found with ${phone}`);
            setLoading(false);
          }
          const normalizeSessionData = normalizeCollection<SessionInterface>(
            docs as unknown as TFBQuerySnapshot,
          );
          // No sessions include vehicle doc ref
          if (!normalizeSessionData.some((e) => e.vehicle)) {
            setLoading(false);
            const result = getFullData(normalizeSessionData);
            setData(result as unknown as SessionInterface[]);
          }
          // Vehicle data listener
          normalizeSessionData.forEach((doc, index) => {
            // Last session has no vehicle
            if (index === normalizeSessionData.length - 1 && !doc.vehicle) {
              const result = getFullData(normalizeSessionData);
              setData(result as unknown as SessionInterface[]);
              setLoading(false);
            }
            if (!doc.vehicle) return;
            vehiclesUnsubscribe.push(
              onSnapshot(
                doc.vehicle as unknown as Query<unknown>,
                (resVehicle) => {
                  normalizeSessionData[index].vehicle = normalizeDoc(
                    resVehicle as unknown as TFBDocumentSnapshot,
                  ) as unknown as IVehicle;
                  if (index === normalizeSessionData.length - 1) {
                    const result = getFullData(normalizeSessionData);
                    setData(result as unknown as SessionInterface[]);
                    setLoading(false);
                  }
                },
              ),
            );
          });
        },
      );
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
        setLoading(false);
      }
    }
    return () => {
      if (garagesRef.length) {
        unsubscribe();
        setData([]);
        vehiclesUnsubscribe?.forEach((unsub) => unsub());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [garagesRef, userSearch, vehicleSearch, typeOfSearch, phone]);

  useEffect(() => {
    if (!data) return undefined;
    setData(getFullData(data));

    return () => {
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFullData]);

  useEffect(() => {
    if (typeOfSearch === 'phone' && checkPhoneNumber(phone)) {
      fetchUser();
    } else {
      setUserSearch(null);
      setData([]);
    }
    return () => {
      setUserSearch(null);
    };
  }, [fetchUser, phone, typeOfSearch]);

  useEffect(() => {
    if (
      typeOfSearch === 'licensePlate' &&
      phone.length >= 2 &&
      phone.length <= 10
    ) {
      fetchVehicle();
    } else {
      setVehicleSearch(null);
      setData([]);
    }
    return () => {
      setVehicleSearch(null);
    };
  }, [fetchVehicle, phone, typeOfSearch]);

  return {
    data,
    setData,
    loading,
    setLoading,
    phone,
    setPhone,
    garages,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    typeOfSearch,
    setTypeOfSearch,
    setUserSearch,
    setVehicleSearch,
  };
};

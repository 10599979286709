import { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Info, ArrowRight, HandbagSimple, UsersThree } from 'phosphor-react';

import { IModal } from 'shared';
import { IAccount } from 'App/api/types/GarageAccount';
import { itemsAccount } from '../helpers';
import { PayloadType } from '../types';
import WrapperCard from './WrapperCard';

type PropsType = {
  account: IAccount;
  editModal: IModal;
  setCurrentAccount: React.Dispatch<React.SetStateAction<IAccount | null>>;
  goToAccount: (id: string, payload: PayloadType) => void;
};

const AccountCard = ({
  account,
  goToAccount,
  editModal,
  setCurrentAccount,
}: PropsType) => {
  const { id, name, status, businessName, businessObj } = account;

  const payload = {
    name,
    status,
    businessName,
    business: { id: businessObj.id },
  };

  return (
    <WrapperCard
      data={account}
      actionItems={itemsAccount}
      editModal={editModal}
      setCurrentData={setCurrentAccount}
      goToAccount={() => goToAccount(id, payload)}
    >
      <Box>
        <Box mb={1} display="flex" alignItems="center">
          <Info weight="bold" size={20} />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {name}
          </Typography>
        </Box>
        <Box mb={1} display="flex" alignItems="center">
          <HandbagSimple weight="bold" size={20} />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {account.businessObj.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" position="relative">
          <UsersThree weight="bold" size={20} />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {account?.usedSeats} members
          </Typography>
          <Button sx={{ position: 'absolute', right: 0, minWidth: 30 }}>
            <ArrowRight weight="bold" />
          </Button>
        </Box>
      </Box>
    </WrapperCard>
  );
};

export default memo(AccountCard);

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Skeleton } from '@mui/material';

export const ParksSkeleton = () => {
  const theme = useTheme();

  const statisticsCards = [...Array(3).keys()];
  const tables = [...Array(10).keys()];
  const boxShadow = theme.customShadows.z12;

  return (
    <>
      <Grid container spacing={2} mb={8}>
        {statisticsCards.map((item) => (
          <Grid item xs={12} md={4} key={item}>
            <Box
              sx={{
                p: 3,
                width: '100%',
                boxShadow,
                borderRadius: '10px',
              }}
            >
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: 6 }}
                width={115}
                height={22}
              />
              <Box
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minHeight="85px"
              >
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 6 }}
                  width={70}
                  height={40}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 6 }}
                  width={90}
                  height={55}
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '50%', mr: 1 }}
                  width={24}
                  height={24}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 6 }}
                  width={166}
                  height={18}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box>
        {tables.map((t) => (
          <Skeleton height={50} sx={{ borderRadius: 0 }} key={t} />
        ))}
      </Box>
    </>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { find, equals } from 'rambda';

import { useApi } from './useApi';

export function useGarageData() {
  const { locationId: garageId } = useParams();
  const { garages, garagesRef } = useApi();

  const garage = useMemo(
    () => garages && find(({ id }) => equals(id, garageId), garages),
    [garages, garageId],
  );
  const garageRef = useMemo(
    () => find(({ id }) => equals(id, garageId), garagesRef),
    [garageId, garagesRef],
  );

  const timezone = useMemo(() => garage?.timezone || null, [garage]);

  return { garage, garageId, garageRef, timezone };
}

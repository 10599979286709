import { TMask } from './types/masks';

export const priceMask: TMask = {
  type: 'fixed' || 'flatFee' || 'capped',
  label: 'Amount',
  mask: '$DD.CC',
  blocks: {
    DD: {
      maxLength: 4,
      mask: /^[0-9]+$/,
    },
    CC: {
      maxLength: 2,
      mask: /^[0-9][0-9]$|^[0-9]$/,
    },
  },
};

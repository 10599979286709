import firebase from 'firebase/compat/app';
import moment from 'moment';
import { Chip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getDollars, getTimeOfMaxParkTimeRoster } from 'shared';
import { IAction } from 'App/api/types';
import {
  IBreakdown,
  ITransactionFee,
  SessionInterface,
} from 'App/api/types/Session';
import { IAccount } from 'App/api/types/GarageAccount';
import { IBusiness } from 'App/api/types/Business';
import { Actions } from 'pages/support/components/Actions';

type TData = {
  session: SessionInterface;
  garage: firebase.firestore.DocumentData;
  user?: firebase.firestore.DocumentData;
  validation: firebase.firestore.DocumentData | null;
  validatingBusiness: firebase.firestore.DocumentData | null;
  passes: (string | undefined)[];
  vehicle?: firebase.firestore.DocumentData | null;
  accounts: firebase.firestore.DocumentData[];
  businesses: firebase.firestore.DocumentData[];
};

function statusColor(status: string) {
  switch (status) {
    case 'succeeded':
      return 'success';
    case 'failed':
      return 'error';
    case 'refunded':
      return 'warning';
    default:
      return 'default';
  }
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

export const items: IAction[] = [
  {
    label: 'Change phone number',
    isComplete: false,
  },
  {
    label: 'Send session URL',
    isComplete: false,
  },
  {
    label: 'Send add payment URL',
    isComplete: false,
  },
  {
    label: 'Send receipt URL',
    isComplete: true,
  },
  {
    label: 'Add payment manually',
  },
  {
    label: 'Override price',
    isComplete: false,
  },
  {
    label: 'Complete session',
    isComplete: false,
  },
  {
    label: 'Cancel session',
    isComplete: false,
  },
  {
    label: 'Apply validation',
    isComplete: false,
  },
  {
    label: 'Refund',
    isComplete: true,
  },
  {
    label: 'Open gates',
  },
  {
    label: 'Support inquiry',
  },
];

export const columns: GridColDef[] = [
  {
    field: 'actions',
    filterable: false,
    disableExport: true,
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams) => (
      <Actions
        items={items}
        sessionData={{
          parkId: params.row.supportID,
          supportId: params.row.supportID,
          sessionId: params.row.id,
          garageId: params.row.garage.id,
          entryTime: params.row.entry?.time,
          exitTime: params.row.exit?.time,
        }}
      />
    ),
  },
  {
    field: 'supportID',
    headerName: 'Park ID',
    valueFormatter: ({ value }) => String(value),
    width: 100,
  },
  {
    field: 'garageName',
    headerName: 'Garage',
    valueGetter: (params) => params.row.garage.name,
    width: 150,
  },
  {
    field: 'name',
    headerName: 'User Name',
    width: 120,
    valueGetter: (params) =>
      params.row?.user?.firstName && params.row?.user?.lastName
        ? `${params.row.user.firstName}  ${params.row.user.lastName}`
        : `Unknown`,
  },
  {
    field: 'plate',
    headerName: 'Plate',
    width: 120,
    valueGetter: (params) =>
      params.row.vehicle
        ? `${params.row.vehicle.plate} (${params.row.vehicle.state})`
        : '',
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    width: 120,
    valueGetter: (params) => params.row?.user?.phone,
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) =>
      params.row.exit?.time ? 'Completed' : 'In progress',
    renderCell: (params: GridRenderCellParams) => (
      <strong>
        <Chip
          color={params.value === 'Completed' ? 'success' : 'default'}
          label={
            <Typography variant="caption" component="div">
              {params.value}
            </Typography>
          }
        />
      </strong>
    ),
  },
  {
    field: 'entryTimeUnix',
    headerName: `Entry Time`,
    valueGetter: (params) => params.row.entry?.time?.toMillis(),
    valueFormatter: ({ value }) =>
      value ? moment(value).format('MM-DD-YYYY - hh:mm a') : '',
    renderCell: (params: GridRenderCellParams<number>) => (
      <strong>
        {params.value
          ? moment(params.value).format('MM-DD-YYYY - hh:mm a')
          : ''}
      </strong>
    ),
    type: 'date',
    filterable: false,
    width: 150,
  },
  {
    field: 'entryLane',
    headerName: `Entry Lane`,
    valueGetter: (params) => params.row.entry?.laneID ?? 'none',
    width: 150,
  },
  {
    field: 'entryType',
    headerName: `Entry Type`,
    valueGetter: (params) => params.row.entry?.type ?? '',
    width: 150,
  },
  {
    field: 'exitTimeUnix',
    headerName: 'Exit Time',
    valueGetter: (params) => params.row.exit?.time?.toMillis(),
    valueFormatter: ({ value }) =>
      value ? moment(value).format('MM-DD-YYYY - hh:mm a') : '',
    renderCell: (params: GridRenderCellParams<number>) => (
      <strong>
        {params.value
          ? moment(params.value).format('MM-DD-YYYY - hh:mm a')
          : ''}
      </strong>
    ),
    type: 'date',
    filterable: false,
    width: 150,
  },
  {
    field: 'exitLane',
    headerName: `Exit Lane`,
    valueGetter: (params) => params.row.exit?.laneID ?? 'none',
    width: 150,
  },
  {
    field: 'exitType',
    headerName: `Exit Type`,
    valueGetter: (params) => params.row.exit?.type ?? '',
    width: 150,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    filterable: false,
    width: 150,
    valueGetter: (params) =>
      params.row.exit &&
      params.row.exit?.time &&
      params.row.entry &&
      params.row.entry?.time
        ? params.row.exit?.time?.toMillis() - params.row.entry?.time?.toMillis()
        : new Date().valueOf() - (params.row.entry?.time?.toMillis() ?? 0),
    valueFormatter: ({ value }) =>
      getTimeOfMaxParkTimeRoster(value).result || '0 mins',
    renderCell: (params: GridRenderCellParams<number>) => (
      <strong>
        {params.row.exit && params.row.exit?.time
          ? getTimeOfMaxParkTimeRoster(
              params.row.exit?.time?.toMillis() -
                params.row.entry?.time?.toMillis(),
            )?.result || '0 mins'
          : getTimeOfMaxParkTimeRoster(
              new Date().valueOf() - params.row.entry?.time?.toMillis(),
            )?.result}
      </strong>
    ),
  },
  {
    field: 'rateType',
    headerName: 'Rate Type',
    valueGetter: (params) =>
      params.row.pricing && params.row.pricing?.breakdown
        ? params.row.pricing.breakdown
            .map((breakdown: IBreakdown) => breakdown.rate.type)
            .reduce((acc: string[], cur: string) => {
              if (!acc.includes(cur)) {
                acc.push(cur);
              }
              return acc;
            }, [])
            .join(', ')
        : '',
  },

  {
    field: 'accounts',
    headerName: 'Accounts',
    valueGetter: (params) =>
      (params.row.accounts ?? [])
        .filter((account: IAccount) => account.name)
        .map((account: IAccount) => account.name)
        .join(', '),
  },
  {
    field: 'businesses',
    headerName: 'Businesses',
    valueGetter: (params) =>
      (params.row.businesses ?? [])
        .filter((busines: IBusiness) => busines.name)
        .map((busines: IBusiness) => busines.name)
        .join(', '),
  },
  {
    field: 'passes',
    headerName: 'Passes',
    valueGetter: (params) =>
      (params.row.passes ?? [])
        .filter((pass: string) => pass)
        .map((pass: string) => pass)
        .join(', '),
  },
  {
    field: 'validatingBusiness',
    headerName: 'Validating Business',
    width: 150,
    valueGetter: (params) => params.row.validatingBusiness?.name,
  },
  {
    field: 'validationName',
    headerName: 'Validation Name',
    width: 150,
    valueGetter: (params) => params.row.validation?.name,
  },
  {
    field: 'validationAmount',
    headerName: 'Validation Amount',
    width: 150,
    valueGetter: (params) => params.row.pricing?.validationAmount,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'parkingFare',
    headerName: 'Parking Fare',
    valueGetter: (params) => params.row.pricing?.parkingFare,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'validationCostToBusiness',
    headerName: 'Cost to Business',
    width: 150,
    valueGetter: (params) => params.row.pricing?.validationCostToBusiness,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'taxedTransactionFees',
    headerName: 'Taxed Transaction Fees',
    width: 180,
    valueGetter: (params) =>
      params.row.pricing?.transactionFees?.reduce(
        (acc: number, fee: ITransactionFee) =>
          acc + (fee.taxExempt ?? false ? 0 : fee.amount ?? 0),
        0,
      ),
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: 150,
    valueGetter: (params) => params.row.pricing?.subtotal,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'tax',
    headerName: 'Tax',
    width: 150,
    valueGetter: (params) => params.row.pricing?.tax,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'untaxedTransactionFees',
    headerName: 'Untaxed Transaction Fees',
    width: 180,
    valueGetter: (params) =>
      params.row.pricing?.transactionFees?.reduce(
        (acc: number, fee: ITransactionFee) =>
          acc + (fee.taxExempt ?? false ? 0 : fee.amount ?? 0),
        0,
      ),
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 150,
    valueGetter: (params) => params.row.pricing?.total,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'refundAmount',
    headerName: 'Refund Amount',
    width: 150,
    valueGetter: (params) => params.row.refund?.amount,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'processingFees',
    headerName: 'Processing Fees',
    width: 150,
    valueGetter: (params) => params.row.pricing?.processingFee.amount,
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'netRevenue',
    headerName: 'Net Revenue',
    width: 150,
    valueGetter: (params) =>
      params.row.payment?.status === 'succeeded' ||
      params.row.payment?.status === 'refunded'
        ? params.row.pricing?.total -
          params.row.pricing?.processingFee.amount -
          (params.row.refund?.amount ?? 0)
        : '',
    valueFormatter: ({ value }) => (value ? `$${getDollars(value)}` : '$0.00'),
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    width: 150,
    valueGetter: (params) => {
      return {
        status: toTitleCase(params.row.payment?.status) ?? '',
        color: statusColor(params.row.payment?.status),
      };
    },
    renderCell: (params: GridRenderCellParams) => (
      <strong>
        <Chip
          color={params.value.color}
          label={
            <Typography variant="caption" component="div">
              {params.value.status}
            </Typography>
          }
        />
      </strong>
    ),
  },
];

export const getSessionData = ({
  session,
  garage,
  user,
  validation,
  validatingBusiness,
  passes,
  vehicle,
  accounts,
  businesses,
}: TData) => {
  const netRevenue =
    session?.payment?.status === 'succeeded' ||
    session?.payment?.status === 'refunded'
      ? session?.pricing?.total -
        (session?.pricing.tax ?? 0) -
        session?.pricing?.processingFee?.amount -
        (session.refund?.amount ?? 0)
      : 0;

  const duration =
    session.exit && session.exit?.time && session.entry && session.entry?.time
      ? session.exit?.time?.toMillis() - session.entry?.time?.toMillis()
      : new Date().valueOf() - (session.entry?.time?.toMillis() ?? 0);

  return {
    ...session,
    user,
    garage,
    validation,
    validatingBusiness,
    passes,
    vehicle,
    accounts,
    businesses,
    netRevenue,
    duration,
  };
};

import { IUser } from 'App/api/types';
import { IAccount } from 'App/api/types/GarageAccount';
import { SessionInterface } from 'App/api/types/Session';

export interface IHeadCell {
  id: string;
  label: string;
  numeric: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = <T>(
  array: SessionInterface[] | IUser[] | Omit<IAccount, 'businessObj'>[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array.map(
    (el, index) => [el, index] as unknown as [T, number],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

import { useState } from 'react';

export function useModal() {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return {
    openModal,
    handleOpenModal,
    handleCloseModal,
  };
}

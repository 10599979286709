import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';

export const SkeletonCardLoading = ({
  numberItems = 6,
}: {
  numberItems?: number;
}) => {
  const items = [...Array(numberItems).keys()];
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {items.map((item) => (
        <Grid key={item} item xs={12} sm={6} md={4}>
          <Card
            sx={{
              m: '0 auto',
              maxWidth: 358,
              boxShadow: 3,
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box mb="25px" display="flex" justifyContent="space-between">
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 6 }}
                  width={125}
                  height={22}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 6 }}
                  width={100}
                  height={22}
                />
              </Box>
              <Box>
                <Box mb={1} display="flex" alignItems="center">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: 8, ml: 3, width: 130, height: 22 }}
                  />
                </Box>
                <Box mb={3} display="flex" alignItems="center">
                  <Skeleton variant="rectangular" width={20} height={20} />
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: 8, ml: 3, width: 81, height: 22 }}
                  />
                </Box>
                <Skeleton
                  variant="text"
                  sx={{ mb: 3, width: 135, height: 22 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: 8, width: 77, height: 30 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: 8 }}
                    width={38}
                    height={30}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

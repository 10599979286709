import { useURIQuery } from 'shared';
import { RequestToChangePassword, ChangePassword } from './components';

export function ResetPassword() {
  const query = useURIQuery();
  const code = query.get('oobCode');

  return (
    <>{code ? <ChangePassword code={code} /> : <RequestToChangePassword />}</>
  );
}

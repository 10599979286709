import { Formik } from 'formik';
import { Box } from '@mui/material';
import { CustomizedDialog, IModal } from 'shared';
import {
  sessionCompleteValidation,
  TSessionComplete,
} from 'shared/validations/sessionComplete';
import { CompleteSessionForm } from './form';

type PropsType = {
  modalCompleteSession: IModal;
  sessionData: any;
  completeSession: (values: any) => Promise<void>;
  lanes: { value?: string | null; text: string }[];
  busy: boolean;
};

export const CompleteSessionModal = ({
  modalCompleteSession,
  busy,
  sessionData,
  completeSession,
  lanes,
}: PropsType) => {
  const { openModal, handleCloseModal } = modalCompleteSession;

  const initialValues: TSessionComplete = {
    sessionId: sessionData.sessionId,
    date: new Date(),
    time: null,
    customTime: false,
    price: '',
    garageLaneId: null,
    overridenPrice: '',
    reason: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={sessionCompleteValidation}
      onSubmit={async (values) => {
        await completeSession(values);
      }}
    >
      {({
        submitForm,
        isValid,
        setValues,
        setTouched,
        initialTouched,
        setErrors,
        initialErrors,
      }) => (
        <CustomizedDialog
          busy={busy}
          submitForm={submitForm}
          open={openModal}
          btnTitle="Submit"
          cleanData={() => {
            setErrors(initialErrors);
            setTouched(initialTouched);
            setValues(initialValues);
          }}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <CompleteSessionForm
              lanes={lanes}
              sessionData={sessionData}
              openModal={openModal}
            />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

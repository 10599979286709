import { ToastProvider } from 'react-toast-notifications';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ErrorProvider from 'App/error/ErrorSandbarProvider';
import FirebaseProvider from './firebase';
import AuthProvider from './auth/AuthProvider';
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
import { AppRoutes } from './routes';
import './theme/montFont.css';

function App() {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <ErrorProvider>
        <FirebaseProvider>
          <ToastProvider>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppRoutes />
              </LocalizationProvider>
            </AuthProvider>
          </ToastProvider>
        </FirebaseProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
}

export default App;

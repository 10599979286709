import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot } from 'shared';
import { ISubscriptionPassOffering } from './types';

function usePasses() {
  const [passes, setPasses] = useState<ISubscriptionPassOffering[] | null>(
    null,
  );

  useEffect(() => {
    const ref = firebase
      .firestore()
      .collectionGroup('subscriptionPassOfferings');
    const unsubscribe = onSnapshot(ref, (docs) => {
      const normalizeBusinessesData =
        normalizeCollection<ISubscriptionPassOffering>(
          docs as unknown as TFBQuerySnapshot,
        );
      setPasses(normalizeBusinessesData as ISubscriptionPassOffering[]);
    });
    return () => unsubscribe();
  }, []);

  return {
    passes,
  };
}

export default usePasses;

import { Grid, Typography } from '@mui/material';
import { ISubscriptionPassOffering } from 'App/api/types';
import { AccordionGeneral, useContextRulesAndExeptions } from 'shared';
import { SubscriptionsCard, SubscriptionsModal } from '.';

export const Subscriptions = (): JSX.Element => {
  const { subscriptions, modalSubscriptions, editRule, editSubscription } =
    useContextRulesAndExeptions();
  const { openModal, handleOpenModal, handleCloseModal } = modalSubscriptions;
  const { setSubscriptionData } = editSubscription;

  return (
    <>
      <AccordionGeneral
        title="Subscription Pass Offerings"
        createItem={() => handleOpenModal()}
      >
        <Typography variant="body1" mb={2}>
          Create subscription pass types that can be distributed to users. Setup
          the schedule here, and distribute passes to users from the Accounts
          tab.
        </Typography>
        <Typography variant="body1" mb={5}>
          Often, this correlates to monthly parking but get creative! Create
          listings for packs of 3 parks per week or 10 parks per month to get
          your regular customers coming back more often.
        </Typography>
        <Grid container spacing={2}>
          {subscriptions &&
            subscriptions.map((subscription: ISubscriptionPassOffering) => (
              <Grid key={subscription.id} item xs={12} sm={6} md={4}>
                <SubscriptionsCard
                  handleOpenModal={handleOpenModal}
                  subscriptionsData={subscription}
                  setSubscriptionData={setSubscriptionData}
                />
              </Grid>
            ))}
        </Grid>
      </AccordionGeneral>
      <SubscriptionsModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        editSubscription={editSubscription}
        editRule={editRule}
      />
    </>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { drop } from 'rambda';
import {
  useModal,
  useApiFunction,
  useToast,
  useBusy,
  getTimeCompleteSession,
  getCents,
  useFirebaseApi,
  normalizeCollection,
  TFBCollection,
  TUnsubscribe,
} from 'shared';
import { removeSymbolsForPhoneNumber } from 'shared/utils/phone';
import { GetParkingPriceResultInterface } from 'App/api/types';
import { getLanes } from 'shared/utils/lanes';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';
import { GarageLaneConverter, ILane } from 'App/api/types/GarageLane';

export const useActions = (g: string) => {
  const { busy, setBusy } = useBusy();
  const navigate = useNavigate();
  const toast = useToast();
  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const modalChangePhoneNumber = useModal();
  const modalCompleteSession = useModal();
  const modalRemoveSession = useModal();
  const modalRefund = useModal();
  const modalSupportInquiry = useModal();
  const modalOverridePrice = useModal();
  const modalApplyValidation = useModal();
  const modalAddPayment = useModal();

  const [priceForSession, setPriceForSession] =
    useState<GetParkingPriceResultInterface | null>(null);

  const lanesUnsubscribe = useRef<TUnsubscribe | null>(null);

  const redirectToWithGarageId = useCallback(
    (to, garageId) => navigate(`/locations/${garageId}/${to}`),
    [navigate],
  );

  const { dbQuery } = useFirebaseApi();
  const [lanes, setLanes] = useState<ILane[]>([]);

  const getExitLanes = useCallback(
    (garageId) => {
      lanesUnsubscribe.current = (
        dbQuery(`garages/${garageId}/lanes`) as TFBCollection
      )
        .withConverter(GarageLaneConverter)
        .onSnapshot(async (docs) => {
          const normalaizeLanes = normalizeCollection(docs);
          setLanes(normalaizeLanes as ILane[]);
        });
    },
    [dbQuery],
  );

  useEffect(() => {
    getExitLanes(g);

    return () => {
      setLanes([]);
      lanesUnsubscribe?.current?.();
    };
  }, [getExitLanes, g]);

  const changePhoneNumber = useCallback(
    async (values) => {
      const normalizePhoneNumber = removeSymbolsForPhoneNumber(values.phone);
      try {
        setBusy(true);
        await apiFn({
          action: 'support_change_session_user',
          data: {
            sessionID: values.sessionId,
            phone: normalizePhoneNumber,
          },
        });
        toast.success('The Phone number has been changed');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        modalChangePhoneNumber.handleCloseModal();
        setBusy(false);
      }
    },
    [apiFn, modalChangePhoneNumber, setBusy, setErrorContent, toast],
  );

  const resendLinkToSession = useCallback(
    async (sessionID) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_send_session_link',
          data: {
            sessionID,
          },
        });
        toast.success('Link was sent successfully.');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, setErrorContent, toast],
  );

  const sendLinkToReceipt = useCallback(
    async (sessionID) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_send_receipt_link',
          data: {
            sessionID,
          },
        });
        toast.success('Link was sent successfully.');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, setErrorContent, toast],
  );

  const sendAddPaymentLink = useCallback(
    async (sessionID) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_send_add_payment_link',
          data: {
            sessionID,
          },
        });
        toast.success('Link was sent successfully.');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, setErrorContent, toast],
  );

  const removeSessionFn = useCallback(
    async (sessionID) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_cancel_session',
          data: {
            sessionID,
          },
        });
        toast.success('The session has been removed!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalRemoveSession.handleCloseModal();
      }
    },
    [apiFn, modalRemoveSession, setBusy, setErrorContent, toast],
  );

  const calculateSessionPrice = useCallback(
    async (sessionID, endTime) => {
      try {
        setBusy(true);
        const { data } = await apiFn({
          action: 'support_update_session_price',
          data: {
            sessionID,
            endTime,
          },
        });
        setPriceForSession(data as GetParkingPriceResultInterface);
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, setErrorContent],
  );

  const completeSession = useCallback(
    async (values) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_complete_session',
          data: {
            sessionID: values.sessionId,
            ...(values.garageLaneId
              ? { laneID: values.garageLaneId }
              : { laneID: null }),
            time: getTimeCompleteSession(values.date, values.time),
            ...(values.reason ? { reason: values.reason } : {}),
            ...(values.overridePrice
              ? {
                  override: {
                    amount: getCents(+drop(1, values.overridePrice)),
                  },
                }
              : {}),
          },
        });
        toast.success('The session has been completed!');
      } catch (e) {
        if (e instanceof Error) {
          if (e.message === '#000010') {
            toast.error('The payment method does not exist ');
          } else {
            toast.error(e.message);
          }
        }
      } finally {
        setBusy(false);
        modalCompleteSession.handleCloseModal();
      }
    },
    [apiFn, modalCompleteSession, setBusy, toast],
  );

  const refundSession = useCallback(
    async (values) => {
      const amount = getCents(parseFloat(values.amount.replace('$', '')));

      try {
        setBusy(true);
        await apiFn({
          action: 'support_refund_session',
          data: {
            sessionID: values.sessionId,
            ...(values.isPartial ? { amount } : {}),
          },
        });
        toast.success('The session has been refunded');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalRefund.handleCloseModal();
      }
    },
    [apiFn, modalRefund, setBusy, setErrorContent, toast],
  );

  const supportInquiryFn = useCallback(
    async (values) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_inquiry',
          data: {
            sessionID: values.sessionId,
            message: values.message,
            ...(values.email
              ? {
                  email: values.email,
                }
              : {}),
          },
        });
        toast.success('The message has been sent!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalSupportInquiry.handleCloseModal();
      }
    },
    [apiFn, modalSupportInquiry, setBusy, setErrorContent, toast],
  );

  const overridePriceFn = useCallback(
    async (values) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_override_price',
          data: {
            sessionID: values.sessionId,
            override: {
              amount: getCents(+drop(1, values.amount)),
              ...(values.reason
                ? {
                    reason: values.reason,
                  }
                : {}),
            },
          },
        });
        toast.success('The price override has been successfully applied.');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalOverridePrice.handleCloseModal();
      }
    },
    [apiFn, modalOverridePrice, setBusy, setErrorContent, toast],
  );

  return {
    lanes: getLanes(lanes, false),
    modalChangePhoneNumber,
    modalCompleteSession,
    modalRemoveSession,
    modalRefund,
    modalSupportInquiry,
    modalOverridePrice,
    modalApplyValidation,
    modalAddPayment,
    busy,
    changePhoneNumber,
    resendLinkToSession,
    sendLinkToReceipt,
    sendAddPaymentLink,
    calculateSessionPrice,
    priceForSession,
    setPriceForSession,
    completeSession,
    refundSession,
    supportInquiryFn,
    overridePriceFn,
    redirectToWithGarageId,
    removeSessionFn,
  };
};

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { GarageScheduledRateInterface } from './interface';
import { GarageScheduledRateValidator } from './validator';

export const GarageScheduledRateConverter: firebase.firestore.FirestoreDataConverter<GarageScheduledRateInterface> =
  {
    toFirestore(
      garageScheduledRate: GarageScheduledRateInterface,
    ): firebase.firestore.DocumentData {
      if (GarageScheduledRateValidator(garageScheduledRate))
        return garageScheduledRate;
      throw new ConverterError(
        'Data validation error 1.',
        JSON.stringify(GarageScheduledRateValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): GarageScheduledRateInterface {
      const garageScheduledRate = snapshot.data();
      if (GarageScheduledRateValidator(garageScheduledRate))
        return garageScheduledRate;
      throw new ConverterError(
        'Data validation error 1.',
        JSON.stringify(GarageScheduledRateValidator.errors),
      );
    },
  };

import * as Yup from 'yup';
import { checkPhoneNumber } from './changePhoneNumber';

export const addSession = Yup.object({
  garageId: Yup.string().required('Required'),
  garageLaneId: Yup.string().nullable(true),
  phone: Yup.string()
    .required('Phone number is required')
    .test('test-name', 'Enter Valid Phone Number', checkPhoneNumber),
  date: Yup.date().required('Required'),
  time: Yup.date().nullable(),
  reason: Yup.string(),
  customTime: Yup.boolean(),
});

export type TSession = Yup.InferType<typeof addSession>;

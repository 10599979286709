import { memo, ReactNode } from 'react';
import { alpha, Box, Card, CardContent, useTheme } from '@mui/material';

import { IAccount } from 'App/api/types/GarageAccount';
import { Badge, IModal, useResponsive } from 'shared';
import { AccountActions } from './AccountActions';
import { IAction } from '../helpers';
import { MemberType } from '../types';

type PropsType = {
  data: MemberType | IAccount;
  children: ReactNode;
  actionItems: IAction[];
  editModal: IModal;
  businessName?: string;
  setCurrentData: React.Dispatch<React.SetStateAction<IAccount | null>>;
  goToAccount?: () => void;
  refetchData?: () => Promise<void>;
};

const WrapperCard = ({
  data,
  children,
  actionItems,
  goToAccount,
  editModal,
  businessName,
  setCurrentData,
  refetchData,
}: PropsType) => {
  const { palette, customShadows } = useTheme();
  const isDesktop = useResponsive('up', 500);
  const boxShadow = customShadows.z12;

  return (
    <Card
      sx={{
        m: '0 auto',
        maxWidth: isDesktop ? 358 : '100%',
        boxShadow,
        cursor: goToAccount ? 'pointer' : 'default',
      }}
      onClick={goToAccount}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          mb={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Badge
            color={
              data.status === 'active'
                ? palette.success.dark
                : palette.error.dark
            }
            bgcolor={alpha(
              data.status === 'active'
                ? palette.success.main
                : palette.error.main,
              0.16,
            )}
            label={data.status || 'disabled'}
            capitalize
          />
          <AccountActions
            data={data}
            items={actionItems}
            editModal={editModal}
            refetchData={refetchData}
            businessName={businessName}
            setCurrentData={setCurrentData}
          />
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};

export default memo(WrapperCard);

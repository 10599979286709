import { SetStateAction } from 'react';
import {
  IRules,
  IScheduledRate,
  ISchedules,
  ISubscriptionPassOffering,
  TRecurrence,
} from 'App/api/types';

export type TSubsctiptions = {
  timezone: string | null;
  subscriptions: ISubscriptionPassOffering[] | null;
  // busySubscriptions: boolean;
  // saveSubscriptions: (payload: any) => Promise<void>;
  modalSubscriptions: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  modalRule: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  modalException: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  editSubscription: {
    setSubscriptionData: React.Dispatch<
      SetStateAction<ISubscriptionPassOffering | null>
    >;
    subscriptionData: ISubscriptionPassOffering | null;
  };
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<IRules | null>>;
    ruleData: IRules | null;
  };
};

export type TScheduled = {
  timezone: string | null;
  schedulerate: IScheduledRate[] | null;
  // busyschedulerate: boolean;
  // saveschedulerate: (payload: any) => Promise<void>;
  modalScheduleRate: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  modalRule: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  modalException: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  editScheduleRate: {
    setScheduleRateData: React.Dispatch<SetStateAction<IScheduledRate | null>>;
    scheduleRateData: IScheduledRate | null;
  };
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<IRules | null>>;
    ruleData: IRules | null;
  };
};

export type TRuleForm = {
  startDate: Date;
  enterAfter: string | null;
  enterBefore: string | null;
  enterAfterOffset: number | null;
  enterBeforeOffset: number | null;
  exitAfter: string | null;
  exitBefore: string | null;
  exitAfterOffset: number | null;
  exitBeforeOffset: number | null;
  maxParkingTime: {
    days: number | null;
    hours: number | null;
    minutes: number | null;
  };
  minParkingTime: {
    days: number | null;
    hours: number | null;
    minutes: number | null;
  };
  recurrence: string;
  recurrenceData: TRecurrence;
};

export interface ISchedulesModal extends Omit<ISchedules, 'id'> {
  newRule: TRuleForm;
}

export type TParkingTimeForm = {
  days: number | null;
  hours: number | null;
  minutes: number | null;
};

export enum Frequency {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
}

export enum Byweekday {
  MO = 0,
  TU = 1,
  WE = 2,
  TH = 3,
  FR = 4,
  SA = 5,
  SU = 6,
}

export enum Bysetpos {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  LAST = -1,
}

export enum Bymonth {
  JAN = 1,
  FEB = 2,
  MAR = 3,
  APR = 4,
  MAY = 5,
  JUN = 6,
  JUL = 7,
  AUG = 8,
  SEP = 9,
  OCT = 10,
  NEV = 11,
  DEC = 12,
}

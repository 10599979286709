import { useCallback, useState } from 'react';
import { Button, Menu, Typography } from '@mui/material';
import { DotsThree } from 'phosphor-react';
import { ButtonAction, CustomizedDialog } from 'shared';
import { IAction } from 'App/api/types';
import { IValidation } from 'App/api/types/Validation';
import { IBusiness } from 'App/api/types/Business';
import { useValidationAction } from '../hooks/useValidationAction';
import { ApplyValidationModal } from './ApplyValidationModal';
import ValidationQr from './ValidationQr';
import AddValidation from './AddValidation';

export type PropsType = {
  items: IAction[];
  validation: IValidation;
  sponsors: IBusiness[];
};

export const ValidationActions = ({
  items,
  validation,
  sponsors,
}: PropsType) => {
  const {
    applyValidationModal,
    viewQRModal,
    editValidationModal,
    currentValidation,
    selectValidation,
    busy,
    editValidation,
  } = useValidationAction();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = useCallback(
    (label) => {
      if (label === 'Edit validation')
        selectValidation(validation, editValidationModal.handleOpenModal);
      if (label === 'View QR code')
        selectValidation(validation, viewQRModal.handleOpenModal);
      if (label === 'Apply to session')
        selectValidation(validation, applyValidationModal.handleOpenModal);
      handleClose();
    },
    [
      applyValidationModal,
      editValidationModal,
      selectValidation,
      validation,
      viewQRModal,
    ],
  );

  return (
    <>
      <Button
        sx={{ minWidth: '30px', height: '36px' }}
        variant="contained"
        color="inherit"
        size="small"
        onClick={handleClick}
      >
        <DotsThree size={16} />
      </Button>
      <Menu
        sx={{ px: 10 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items?.map(({ label }: IAction) => (
          <ButtonAction key={label} onClick={() => handleAction(label)}>
            <Typography variant="body2" color="black" sx={{ paddingRight: 4 }}>
              {label}
            </Typography>
          </ButtonAction>
        ))}
      </Menu>
      <ApplyValidationModal
        modal={applyValidationModal}
        validationId={currentValidation?.id}
      />
      <CustomizedDialog
        open={viewQRModal.openModal}
        handleClose={viewQRModal.handleCloseModal}
        busy={false}
      >
        <ValidationQr validation={currentValidation} />
      </CustomizedDialog>
      <AddValidation
        validation={currentValidation}
        openModal={editValidationModal.openModal}
        handleCloseModal={editValidationModal.handleCloseModal}
        sponsors={sponsors}
        createValidation={editValidation}
        busy={busy}
      />
    </>
  );
};

export default function Menu() {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          paddingRight: 8,
          paddingLeft: 8,
        },
      },
    },
  };
}

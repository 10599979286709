import { Box } from '@mui/material';
import { NavSectionProps } from './type';
import { NavListRoot } from './NavList';

export default function NavSection({
  navConfig,
  isCollapse = false,
  permissions,
}: NavSectionProps) {
  return (
    <Box sx={{ px: 2.5 }}>
      {navConfig
        .filter((item) => permissions && permissions[item.permissionName])
        .map((route) => (
          <NavListRoot key={route.label} list={route} isCollapse={isCollapse} />
        ))}
    </Box>
  );
}

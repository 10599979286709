import { ILane } from 'App/api/types/GarageLane';
import { filter } from 'rambda';

const isEntry = (item: ILane) => item.direction === 'entry';
const isExit = (item: ILane) => item.direction === 'exit';

export const getLanes = (lanes: ILane[], entry: boolean) => {
  const noLaneData = { value: null, text: 'No lane' };
  const onlyLaneNumbers = filter(entry ? isEntry : isExit, lanes);
  const normalizeLanes = onlyLaneNumbers.map((lane: ILane) => {
    return {
      value: lane.laneID,
      text: `${lane.laneID} (${lane.description || 'No description'})`,
    };
  });
  const lanesResult = [noLaneData, ...normalizeLanes];
  return lanesResult;
};

import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot } from 'shared';
import { IBusiness } from './types/Business';

function useBusinesses() {
  const [businesses, setBusinesses] = useState<IBusiness[] | null>(null);

  useEffect(() => {
    const ref = firebase.firestore().collectionGroup('businesses');
    const unsubscribe = onSnapshot(ref, (docs) => {
      const normalizeBusinessesData = normalizeCollection(
        docs as unknown as TFBQuerySnapshot,
      );
      setBusinesses(normalizeBusinessesData as IBusiness[]);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    businesses,
  };
}

export default useBusinesses;

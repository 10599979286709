import { drop } from 'rambda';
import * as Yup from 'yup';

export const AddRate = Yup.object({
  basePrice: Yup.string()
    .test('rate', 'More than $0.5', (val?: string) => {
      if (val) {
        const integerValue = +drop(1, val).split('.')[0];
        const fractionalValue = val.split('.')[1];
        if (integerValue < 1) {
          return fractionalValue >= '5';
        }
      }
      return true;
    })
    .required('Required'),
  pricingRows: Yup.array().of(
    Yup.object({
      priceIncrease: Yup.string()
        .test('rate', 'More than $0.5', (val?: string) => {
          if (val) {
            const integerValue = +drop(1, val).split('.')[0];
            const fractionalValue = val.split('.')[1];
            if (integerValue < 1) {
              return fractionalValue >= '5';
            }
          }
          return true;
        })
        .required('Required'),
      durationThreshold: Yup.string().required('Required'),
    }),
  ),
});

export type TRate = Yup.InferType<typeof AddRate>;

import { SetStateAction, useState } from 'react';
import { Tab, Box } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import {
  IRules,
  IScheduledRate,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { TabsSubscriptionsItem } from './TabsSubscriptionsItem';

type ModalType = {
  openModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
};

type PropsType = {
  rules: IRules[];
  exceptions: IRules[];
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  modalRule: ModalType;
  modalException: ModalType;
  editSubscription: {
    subscriptionData: ISubscriptionPassOffering | IScheduledRate | null;
    setSubscriptionData: React.Dispatch<
      SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
    >;
  };
};

export const TabsSubscriptions = ({
  rules,
  exceptions,
  modalRule,
  editRule,
  modalException,
  editSubscription,
}: PropsType): JSX.Element => {
  const RULES = 'Rules';
  const EXCEPTIONS = 'Exceptions';
  const [value, setValue] = useState(RULES);

  const { handleOpenModal: handleOpenModalRule } = modalRule;
  const { handleOpenModal: handleOpenModalException } = modalException;
  const { setRuleData } = editRule;
  const { setSubscriptionData } = editSubscription;

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <TabList
          value={RULES}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab value={RULES} label={RULES} />
          <Tab value={EXCEPTIONS} label={EXCEPTIONS} />
        </TabList>
        <TabsSubscriptionsItem
          handleOpenModalRule={handleOpenModalRule}
          setRuleData={setRuleData}
          setSubscriptionData={setSubscriptionData}
          data={rules}
          value={RULES}
        />
        <TabsSubscriptionsItem
          handleOpenModalRule={handleOpenModalException}
          setRuleData={setRuleData}
          setSubscriptionData={setSubscriptionData}
          data={exceptions}
          value={EXCEPTIONS}
        />
      </TabContext>
    </Box>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { onSnapshot, Query } from 'firebase/firestore';
import moment from 'moment';
import { SessionInterface } from 'App/api/types/Session';

import {
  getTimeStamp,
  normalizeCollection,
  normalizeDoc,
  TFBCollection,
  TFBQuerySnapshot,
  useApi,
  useFirebaseApi,
  useGarageData,
  useLocationData,
  TFBDocumentSnapshot,
} from 'shared';
import { IVehicle } from 'App/api/types/Vehicle';
import { getStatistics, StatisticsType } from '../helpers/statistics';

export const useParks = () => {
  const DAY_MILLISECONDS = 86400000;
  const CURENT_DATE = moment(new Date()).startOf('day').format();

  const { dbQuery } = useFirebaseApi();
  const { users } = useApi();
  const { validations, accounts, businesses } = useLocationData();
  const { garage, garageRef } = useGarageData();

  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(new Date(CURENT_DATE));
  const [sessionData, setSessionData] = useState<SessionInterface[] | null>(
    null,
  );
  const [sessionPreviosData, setSessionPreviosData] = useState<
    SessionInterface[]
  >([]);
  const [statisticsData, setStatisticsData] = useState<StatisticsType[] | null>(
    null,
  );

  const getAccounstIdAndPassName = (
    payload: SessionInterface['pricing']['breakdown'],
  ) => {
    if (!payload) return null;
    const accountsId = payload?.map((br) =>
      br.rate.type === 'subscription' && br.rate.link
        ? br.rate.link.path.split('/')[3]
        : '',
    );
    const passName = payload.map((br) =>
      br.rate.type === 'subscription' ? br.rate.description : '',
    );
    return { accountsId, passName };
  };

  const getFullData = useCallback(
    (data) => {
      const newArr = data.map((session: SessionInterface) => {
        const netRevenue =
          session?.payment?.status === 'succeeded' ||
          session?.payment?.status === 'refunded'
            ? session?.pricing?.total -
              (session?.pricing.tax ?? 0) -
              session?.pricing?.processingFee?.amount -
              (session.refund?.amount ?? 0)
            : 0;

        const duration =
          session.exit &&
          session.exit?.time &&
          session.entry &&
          session.entry?.time
            ? session.exit?.time?.toMillis() - session.entry?.time?.toMillis()
            : new Date().valueOf() - (session.entry?.time?.toMillis() ?? 0);

        const { accountsId, passName } =
          getAccounstIdAndPassName(session?.pricing?.breakdown) || {};

        const accountsData =
          accountsId && accounts?.filter((acc) => accountsId.includes(acc.id));

        const busnessesId = accountsData?.map(
          (acc) => acc.business && acc.business.id,
        );
        const businessData = businesses?.filter((business) =>
          busnessesId?.includes(business.id),
        );

        return {
          ...session,
          garage,
          user: users && users.find((u) => u.id === session.user?.id),
          validation:
            validations &&
            validations.find((v) => v.id === session.validation?.id),
          accounts: accountsData,
          businesses: businessData,
          passes: passName,
          netRevenue,
          duration,
        };
      });
      return newArr;
    },

    [accounts, businesses, garage, users, validations],
  );

  const titleExport = `${garage?.name}-${moment(date).format('MMMM Do YYYY')}`;

  const handleChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
  };

  useMemo(() => {
    setStatisticsData(getStatistics(sessionData, sessionPreviosData));
  }, [sessionData, sessionPreviosData]);

  useEffect(() => {
    const vehiclesUnsubscribe: Array<() => void> = [];
    if (!date || !garageRef || !garage) return undefined;

    const refCurrent = (dbQuery('sessions') as TFBCollection)
      .where('garage', '==', garageRef)
      .where('entry.time', '>=', getTimeStamp(date))
      .where(
        'entry.time',
        '<',
        getTimeStamp(new Date(date.valueOf() + DAY_MILLISECONDS)),
      )
      .orderBy('entry.time', 'desc');

    const unsubscribeCurrent = onSnapshot(refCurrent, async (snapshot) => {
      if (snapshot.size) {
        const data: SessionInterface[] = normalizeCollection(
          snapshot as unknown as TFBQuerySnapshot,
        );
        // Vehicle data listener
        if (!data.some((e) => e.vehicle)) {
          // no sessions include vehicle doc ref
          const currentSessions: SessionInterface[] = getFullData(data);
          setSessionData(currentSessions);
          setIsLoading(false);
        }
        data.forEach(async (document: SessionInterface, index) => {
          data[index] = document;
          if (!document.vehicle) return;
          vehiclesUnsubscribe.push(
            onSnapshot(
              document.vehicle as unknown as Query<unknown>,
              (resVehicle) => {
                data[index].vehicle = normalizeDoc(
                  resVehicle as unknown as TFBDocumentSnapshot,
                ) as unknown as IVehicle;
                const currentSessions: SessionInterface[] = getFullData(data);
                setSessionData(currentSessions);
                setIsLoading(false);
              },
            ),
          );
        });
      } else {
        setSessionData([]);
        setIsLoading(false);
      }
    });

    const refPrev = (dbQuery('sessions') as TFBCollection)
      .where('garage', '==', garageRef)
      .where(
        'entry.time',
        '>=',
        getTimeStamp(new Date(date.valueOf() - DAY_MILLISECONDS)),
      )
      .where('entry.time', '<', getTimeStamp(new Date(date.valueOf())));

    const unsubscribePrevios = onSnapshot(refPrev, (snapshot) => {
      const data = normalizeCollection(snapshot as unknown as TFBQuerySnapshot);
      const previosSessions = getFullData(data);
      setSessionPreviosData(previosSessions as unknown as SessionInterface[]);
    });

    return () => {
      unsubscribeCurrent();
      unsubscribePrevios();
      setSessionData([]);
      vehiclesUnsubscribe.forEach((unsubscribe) => unsubscribe());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [garage, date, dbQuery, garageRef]);

  useEffect(() => {
    if (!sessionData) return undefined;
    setSessionData(getFullData(sessionData));

    return () => {
      setSessionData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFullData]);

  return {
    garage,
    date,
    setDate,
    sessionData,
    statisticsData,
    isLoading,
    setIsLoading,
    handleChangeRowsPerPage,
    rowsPerPage,
    titleExport,
  };
};

import * as Yup from 'yup';

export const basicDetails = Yup.object({
  name: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .required('Required'),
  status: Yup.string().required('Required'),
});

export type TBasicDetails = Yup.InferType<typeof basicDetails>;

/* eslint-disable no-underscore-dangle */
import { SetStateAction } from 'react';
import { Formik } from 'formik';

import states from 'shared/constants/states';
import { GarageInterface } from 'App/api/types';
import { location, TLocation } from 'shared/validations/location';
import { AccordionGeneral } from 'shared/components/AccordionGeneral';
import { LocationForm } from './LocationForm';

type PropsType = {
  locationData: {
    address: GarageInterface['address'];
    coordinate: GarageInterface['coordinate'];
  };
  editLocation: {
    setIsEditLocation: React.Dispatch<SetStateAction<boolean>>;
    isEditLocation: boolean;
  };
  saveLocation: (payload: TLocation) => void;
  busyLocation: boolean;
};

export const Location = ({
  locationData,
  editLocation,
  saveLocation,
  busyLocation,
}: PropsType): JSX.Element => {
  const lat = locationData.coordinate?.latitude;
  const long = locationData.coordinate?.longitude;

  const findState = states.filter(
    (stat) => stat.value === locationData.address?.state,
  );

  return (
    <AccordionGeneral title="Location">
      <Formik
        initialValues={{
          ...locationData,
          address: {
            ...locationData.address,
            state: findState[0]?.value,
          },
          coordinate: { lat, long },
        }}
        validationSchema={location}
        enableReinitialize
        onSubmit={async (values) => {
          saveLocation(values as TLocation);
        }}
      >
        <LocationForm editLocation={editLocation} busyLocation={busyLocation} />
      </Formik>
    </AccordionGeneral>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import { IAccount } from 'App/api/types/GarageAccount';
import { EmptyPage, IModal, SkeletonCardLoading } from 'shared';
import { MemberType } from '../types';
import { MembersCard } from './MembersCard';

type PropsType = {
  isLoading: boolean;
  members: MemberType[] | null;
  businessName?: string;
  setCurrentData: Dispatch<SetStateAction<IAccount | null>>;
  editModal: IModal;
  refetchData: () => Promise<void>;
};

export const RenderMembers = ({
  isLoading,
  members,
  setCurrentData,
  businessName,
  editModal,
  refetchData,
}: PropsType) => {
  if (isLoading) {
    return <SkeletonCardLoading numberItems={3} />;
  }

  if (!isLoading && members?.length === 0) {
    return <EmptyPage title="There are no members" />;
  }
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {members?.map((member) => {
        return (
          <Grid key={member.id} xs={12} sm={6} md={4} item>
            <MembersCard
              refetchData={refetchData}
              editModal={editModal}
              member={member}
              setCurrentData={setCurrentData}
              businessName={businessName}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

import firebase from 'firebase/compat/app';

import { ConverterError } from '../converterError';
import { ValidationInterface } from './interface';
import { ValidationValidator } from './validator';

export const ValidationConverter: firebase.firestore.FirestoreDataConverter<ValidationInterface> =
  {
    toFirestore(
      validation: ValidationInterface,
    ): firebase.firestore.DocumentData {
      if (ValidationValidator(validation)) return validation;
      throw new ConverterError(
        'Validation Model validation error.',
        JSON.stringify(ValidationValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): ValidationInterface {
      const validation = snapshot.data();
      if (ValidationValidator(validation))
        return validation as ValidationInterface;
      throw new ConverterError(
        'Validation Model validation error.',
        JSON.stringify(ValidationValidator.errors),
      );
    },
  };

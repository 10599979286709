import { Container } from '@mui/material';
import { RulesAndExeptionsProvider, useGarageData } from 'shared';
import { Subscriptions } from './components';
import { useSubscriptions } from './hooks';

export const Passes = (): JSX.Element | null => {
  const { garage } = useGarageData();
  const value = useSubscriptions();

  return garage ? (
    <Container>
      <RulesAndExeptionsProvider value={value}>
        <Subscriptions />
      </RulesAndExeptionsProvider>
    </Container>
  ) : null;
};

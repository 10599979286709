import TopBarProgress from 'react-topbar-progress-indicator';
import pelette from 'App/theme/palette';

TopBarProgress.config({
  barColors: {
    0: pelette.primary.main,
    1: pelette.primary.main,
  },
  shadowBlur: 0,
  barThickness: 2,
});

export const Loader = () => <TopBarProgress />;

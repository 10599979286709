type ITypeOfSearch = {
  label: string;
  value: 'phone' | 'parkId' | 'licensePlate';
};
export const typesOfSearch: ITypeOfSearch[] = [
  {
    label: 'Phone number',
    value: 'phone',
  },
  {
    label: 'Park ID',
    value: 'parkId',
  },
  {
    label: 'License plate',
    value: 'licensePlate',
  },
];

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PrivateOutlet } from 'App/helpers';
import { useAuth } from 'shared';
import Layout from 'App/layout/dashboard/Layout';
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes';
import { protectedRoutes } from './helpers';

export function AppRoutes(): JSX.Element {
  const session = useAuth();

  if (session.initializing) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        <Route element={<PrivateOutlet />}>
          {protectedRoutes.map(({ label, path, Component }) => (
            <Route
              key={label}
              path={path}
              element={
                <Layout>
                  <Component />
                </Layout>
              }
            />
          ))}
        </Route>
        <Route path="*" element={<UnauthenticatedRoutes />} />
      </Routes>
    </Router>
  );
}

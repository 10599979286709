import { Box, Typography } from '@mui/material';

type Props = {
  action: 'activate' | 'disable';
};

export const DisableActivateAccountForm = ({ action }: Props) => {
  const title = action === 'activate' ? 'Activate account' : 'Disable account';
  const text =
    action === 'activate'
      ? 'Are you sure you want to activate this account? This will reinstate all members within the account and they will then have access to their passes.'
      : 'Are you sure you want to disable this account? This will invalidate all members within the account so they will no longer have access to their passes.';

  return (
    <Box onClick={(e) => e.stopPropagation()} p={3}>
      <Typography variant="h4" component="div" mb={3}>
        {title}
      </Typography>
      <Typography variant="body1" component="div">
        {text}
      </Typography>
    </Box>
  );
};

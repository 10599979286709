import { Grid, Typography } from '@mui/material';
import { IScheduledRate } from 'App/api/types';
import { AccordionGeneral, useContextRuleAndExeption } from 'shared';
import { ScheduledCard, ScheduledModal } from '.';

export const ScheduledRates = (): JSX.Element => {
  const { schedulerate, modalScheduleRate, editRule, editScheduleRate } =
    useContextRuleAndExeption();
  const { openModal, handleOpenModal, handleCloseModal } = modalScheduleRate;
  const { setScheduleRateData } = editScheduleRate;

  return (
    <>
      <AccordionGeneral
        title="Scheduled Rates"
        createItem={() => handleOpenModal()}
      >
        <Typography variant="body1" mb={2}>
          Create rates that take effect when users enter and exit within
          specific windows of time.
        </Typography>
        <Grid container spacing={2}>
          {schedulerate &&
            schedulerate.map((schedulerates: IScheduledRate) => (
              <Grid key={schedulerates.id} item xs={12} sm={6} md={4}>
                <ScheduledCard
                  handleOpenModal={handleOpenModal}
                  scheduleRateData={schedulerates}
                  setScheduleRateData={setScheduleRateData}
                />
              </Grid>
            ))}
        </Grid>
      </AccordionGeneral>
      <ScheduledModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        editScheduleRate={editScheduleRate}
        editRule={editRule}
      />
    </>
  );
};

import { createContext } from 'react';
import { IBusiness } from 'App/api/types/Business';
import { IAccount } from 'App/api/types/GarageAccount';
import { IValidation } from 'App/api/types/Validation';
import { ISubscriptionPassOffering } from 'App/api/types';

export interface ILocationContext {
  validations: IValidation[] | null;
  accounts: IAccount[] | null;
  businesses: IBusiness[] | null;
  passes: ISubscriptionPassOffering[] | null;
}

const LocationContext = createContext<ILocationContext>({
  validations: null,
  accounts: null,
  businesses: null,
  passes: null,
});

export const LocationProvider = LocationContext.Provider;
export default LocationContext;

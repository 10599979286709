import { RouteConfig } from 'App/helpers';
import { matchPath, useLocation } from 'react-router-dom';
import { NavItemRoot } from './NavItem';

type NavListRootProps = {
  list: RouteConfig;
  isCollapse: boolean;
};

export function NavListRoot({ list, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();

  const active = list.path
    ? !!matchPath({ path: list.path, end: false }, pathname)
    : false;

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

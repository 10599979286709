import { SetStateAction } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { PencilSimple } from 'phosphor-react';

type PropsType = {
  isBusy: boolean;
  isValid?: boolean;
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
  setInicialValues: () => void;
  onSubmit: () => void;
  disabled?: boolean;
};

export const ButtonLocation = ({
  isBusy,
  setIsEdit,
  isEdit,
  isValid = true,
  setInicialValues,
  onSubmit,
  disabled,
}: PropsType): JSX.Element => {
  return (
    <Box mt={5}>
      {isEdit ? (
        <Button
          onClick={() => setIsEdit(false)}
          sx={{ width: '85px' }}
          size="large"
          variant="outlined"
          color="inherit"
          startIcon={<PencilSimple size={22} />}
          disabled={disabled || false}
        >
          Edit
        </Button>
      ) : (
        <>
          <Button
            sx={{ mr: '10px' }}
            color="inherit"
            size="large"
            variant="contained"
            onClick={() => {
              setIsEdit(true);
              setInicialValues();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '85px' }}
            size="large"
            variant="contained"
            disabled={!isValid || isBusy}
            onClick={onSubmit}
          >
            {isBusy ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </>
      )}
    </Box>
  );
};

import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Resizer from 'react-image-file-resizer';
import { compose, prop, replace, split, takeLast } from 'rambda';

export function readAsDataURL(format: string, file: Blob, size: number) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      format,
      100,
      0,
      (uri: any) => {
        resolve({ base64: uri });
      },
      'base64',
    );
  });
}

export const getSampleImage = async (
  garageId: string,
  setState: React.Dispatch<React.SetStateAction<any>>,
  directory: any,
  isLogo: boolean,
) => {
  const imageRefs = await firebase
    .storage()
    .ref()
    .child(`/${directory}/${garageId}/`)
    .listAll();

  if (isLogo) {
    const url = (await imageRefs.items[0]?.getDownloadURL()) || {};
    setState(url);
  } else {
    const urls = await Promise.all(
      imageRefs.items.map((ref) => ref.getDownloadURL()),
    );

    setState(urls);
  }
};

export const getPhotoName = (photo: string) => {
  return compose(
    replace('?alt=media', ''),
    replace(/&token..+/, ''),
    prop(0) as <T>(photo: T) => string,
    takeLast(1) as unknown as (a: string[]) => string,
    split('/'),
    decodeURIComponent,
  )(photo);
};

export const getPhotosGarage = async (garageId: string, directory: string) => {
  const imageRefs = await firebase
    .storage()
    .ref()
    .child(`/${directory}/${garageId}/`)
    .listAll();

  const urls = await Promise.all(
    imageRefs.items.map((ref) => ref.getDownloadURL()),
  );
  return urls;
};

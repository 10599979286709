import { Box, Button } from '@mui/material';
import { XCircle } from 'phosphor-react';
import { MaskInput } from 'shared/controls/maskInput';
import { priceMask, timeMask } from 'shared/utils/masks';

type Props = {
  removeBox: <T>(index: number) => T | undefined;
  index: number;
};

export const PricePerTime = ({ removeBox, index }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <MaskInput
        type="text"
        sx={{ width: '44%' }}
        name={`pricingRows[${index}].durationThreshold`}
        mask={timeMask.mask}
        label="After"
        placeholder="0h 30m"
        blocks={timeMask.blocks}
      />
      <MaskInput
        type="text"
        sx={{ width: '44%' }}
        name={`pricingRows[${index}].priceIncrease`}
        mask={priceMask.mask}
        label="New Amount"
        placeholder="$10"
        blocks={priceMask.blocks}
      />
      <Button
        sx={{ width: '2%', height: 56 }}
        variant="outlined"
        color="error"
        onClick={() => removeBox(index)}
      >
        <XCircle color="red" size={24} />
      </Button>
    </Box>
  );
};

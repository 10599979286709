import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { CurrencyCircleDollar, PencilSimple, UsersFour } from 'phosphor-react';
import { getDiscount } from 'shared/utils/data_tables/validations';
import pelette from 'App/theme/palette';
import { ValidationInterface } from 'App/api/types/Validation';

type Props = {
  id: string;
  // priority: number;
  name?: string;
  type: ValidationInterface['type'];
  amount: ValidationInterface['value'];
  setEditItem: Dispatch<SetStateAction<string>>;
  handleOpenModal: () => void;
  handleOpenMembers: () => void;
};

export const CardPricing = ({
  id,
  // priority,
  name,
  type,
  amount,
  setEditItem,
  handleOpenModal,
  handleOpenMembers,
}: Props) => {
  return (
    <Card
      sx={{
        border: '1px solid black',
        boxShadow: 'none',
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          />
        </Box>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div" noWrap>
            {name}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => {
              if (id) setEditItem(id);
              handleOpenModal();
            }}
          >
            <PencilSimple size={18} />
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            color={pelette.grey[600]}
          >
            <CurrencyCircleDollar />
            <Typography variant="subtitle1" component="div" ml={0.5}>
              {getDiscount(type, amount)}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<UsersFour />}
            onClick={() => {
              if (id) setEditItem(id);
              handleOpenMembers();
            }}
          >
            Members
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

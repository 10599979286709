import { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { CaretDown, PlusCircle } from 'phosphor-react';

type PropsType = {
  title: string;
  secondary?: boolean;
  disabled?: boolean;
  isEdit?: boolean;
  createItem?: () => void;
  // withoutCaret?: boolean;
};

export const AccordionGeneral: FC<PropsType> = ({
  children,
  title,
  createItem,
  disabled,
  secondary = false,
  isEdit,
  // withoutCaret = false,
}): JSX.Element => {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(!secondary);
  const variant = secondary ? 'subtitle1' : 'h4';
  const backgroundColor = secondary ? theme.palette.grey[200] : '';
  const borderTop = open ? '1px solid rgba(145, 158, 171, 0.24);' : '';
  const padding = secondary ? '8px 16px 16px' : '24px';

  useEffect(() => {
    if (isEdit) setOpen(false);
  }, [isEdit]);

  return (
    <Accordion
      disabled={disabled}
      disableGutters={secondary}
      expanded={open}
      onChange={() => !createItem && setOpen((o) => !o)}
    >
      <AccordionSummary
        sx={{ backgroundColor }}
        expandIcon={
          createItem ? (
            <IconButton onClick={createItem} type="submit" sx={{ p: 1 }}>
              <PlusCircle
                weight="light"
                size={24}
                color={theme.palette.primary.main}
              />
            </IconButton>
          ) : (
            <CaretDown />
          )
        }
      >
        <Typography variant={variant} component="h2">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop, padding }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

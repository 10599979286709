/* eslint-disable react/jsx-props-no-spreading */
import moment from 'moment';
import { Container, Grid, Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';

import { columns, EmptyPage } from 'shared';
import { ParksSkeleton, StatisticCard } from './components';
import { useParks } from './hooks';

export const Parks = () => {
  const theme = useTheme();
  const {
    date,
    setDate,
    setIsLoading,
    sessionData,
    isLoading,
    statisticsData,
    garage,
    handleChangeRowsPerPage,
    rowsPerPage,
    titleExport,
  } = useParks();
  const boxShadow = theme.customShadows.z12;

  const CustomToolbar = () => {
    return (
      <GridToolbar
        csvOptions={{
          fileName: titleExport,
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    );
  };

  return (
    <Container>
      {garage && (
        <Box sx={{ p: '20px 10px', boxShadow, borderRadius: 2 }}>
          <DatePicker
            views={['day']}
            value={date}
            onChange={(e) => {
              setIsLoading(true);
              setDate(new Date(moment(e).startOf('day').format()));
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
          {isLoading ? (
            <ParksSkeleton />
          ) : (
            <>
              <Grid container spacing={2} mb={8}>
                {sessionData &&
                  sessionData?.length > 0 &&
                  statisticsData &&
                  statisticsData.map((item) => (
                    <Grid item xs={12} md={4} key={item.title}>
                      <StatisticCard
                        title={item.title}
                        value={item.value}
                        percent={item.percent}
                        image={item.image}
                      />
                    </Grid>
                  ))}
              </Grid>
              {sessionData && sessionData.length && !isLoading ? (
                <DataGrid
                  disableSelectionOnClick
                  autoHeight
                  columns={columns}
                  rows={sessionData}
                  components={{ Toolbar: CustomToolbar }}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onPageSizeChange={handleChangeRowsPerPage}
                />
              ) : (
                <EmptyPage title="No parking sessions found for selected date" />
              )}
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

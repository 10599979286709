import * as Yup from 'yup';
import { checkPhoneNumber } from 'shared/validations/changePhoneNumber';

export const applyValidation = Yup.object({
  sessionsId: Yup.string().required('Required'),
  validationId: Yup.string().required('Required'),
});

export const applyValidationForValidation = Yup.object({
  validationId: Yup.string().required('Required'),
  isPhone: Yup.boolean(),
  value: Yup.string()
    .required('Required')
    .when('isPhone', {
      is: true,
      then: Yup.string()
        .required('Phone number is required')
        .test('test-name', 'Enter Valid Phone Number', checkPhoneNumber),
    }),
});

export const applyValidationData = Yup.object({
  supportID: Yup.number(),
});

export type TApplyValidation = Yup.InferType<typeof applyValidation>;

export type TApplyValidationData = Yup.InferType<typeof applyValidationData>;

export type TApplyValidationForValidation = Yup.InferType<
  typeof applyValidationForValidation
>;

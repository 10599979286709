import { Box } from '@mui/material';
import { CustomizedDialog, IModal } from 'shared';
import { RemoveSession } from './RemoveSession';

type Props = {
  modalRemoveSession: IModal;
  sessionId: string;
  removeSessionFn: (sessionId: any) => Promise<void>;
  busy: boolean;
};

export const RemoveSessionModal = ({
  modalRemoveSession,
  removeSessionFn,
  sessionId,
  busy,
}: Props) => {
  const { openModal, handleCloseModal } = modalRemoveSession;

  return (
    <CustomizedDialog
      btnTitle="Submit"
      btnColor="error"
      busy={busy}
      isValid
      submitForm={async () => {
        await removeSessionFn(sessionId);
      }}
      open={openModal}
      handleClose={handleCloseModal}
    >
      <Box sx={{ width: '100%' }}>
        <RemoveSession />
      </Box>
    </CustomizedDialog>
  );
};

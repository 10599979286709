import { NavLink as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './styles';
import { NavItemProps } from './type';

const isExternalLink = (path: string) => path.includes('http');

export function NavItemRoot({ item, isCollapse, active }: NavItemProps) {
  const { label, path, icon: Icon } = item;

  const renderContent = (
    <>
      {Icon && (
        <ListItemIconStyle>
          <Icon />
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        disableTypography
        primary={label}
        isCollapse={isCollapse}
      />
    </>
  );

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

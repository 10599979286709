import { useState, MouseEvent, ChangeEvent } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
} from '@mui/material';
import { getComparator, Order, stableSort, EnhancedTableHead } from 'shared';
// import { ILane } from 'App/api/types/GarageLane';
import { headCellsLanes, items } from '../helpers';
import { Actions } from './Actions';

type Props = {
  data: any;
  openGateFn: (laneID: string) => Promise<void>;
};

export const LanesTable = ({ data, openGateFn }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCellsLanes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lane) => {
                  return (
                    <TableRow
                      sx={{ whiteSpace: 'nowrap' }}
                      hover
                      tabIndex={-1}
                      key={lane.id}
                    >
                      <TableCell component="th" scope="row">
                        {lane.laneID}
                      </TableCell>
                      <TableCell>{lane.direction}</TableCell>
                      <TableCell>{lane.description}</TableCell>
                      <TableCell>
                        {lane.status ? 'Online' : 'Offline'}
                      </TableCell>
                      <TableCell>
                        <Actions
                          items={items}
                          laneID={lane.laneID}
                          openGateFn={openGateFn}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

import { Stack, Button, Typography } from '@mui/material';
import { ReactComponent as Docs } from 'App/assets/Docs.svg';

type Props = {
  userName: string | null;
};

export default function NavbarDocs({ userName }: Props) {
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <Docs />
      <div>
        <Typography gutterBottom variant="subtitle1">
          {userName && `Hi, ${userName}`}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Contact us for support
        </Typography>
      </div>
      <Button variant="contained" href="Tel:+1(202)952-6846">
        Get Support
      </Button>
    </Stack>
  );
}

import * as Yup from 'yup';
import { isValidNumber } from 'libphonenumber-js';

export const checkPhoneNumber = (value: any) => {
  let isValidPhone = false;
  try {
    isValidPhone = isValidNumber(value);
  } catch (error) {
    isValidPhone = false;
  }
  return isValidPhone;
};

export const changePhoneNumberValidation = Yup.object().shape({
  phone: Yup.string()
    .required('Phone number is required')
    .test('test-name', 'Enter Valid Phone Number', checkPhoneNumber),
});

export const changePhoneNumberNotRequire = Yup.object().shape({
  phone: Yup.string().test(
    'test-name',
    'Enter Valid Phone Number',
    checkPhoneNumber,
  ),
});

export type TChangePhoneNumber = Yup.InferType<
  typeof changePhoneNumberValidation
>;

import { Formik } from 'formik';
import { Box } from '@mui/material';
import { CustomizedDialog, IModal, useBusy } from 'shared';
import { changePhoneNumberValidation } from 'shared/validations/changePhoneNumber';
import { ChangePhoneNumberForm } from './form/ChangePhoneNumberForm';

type TChangePhoneNumber = {
  phone: string;
  sessionId: string;
};

type Props = {
  sessionData: { parkId: string; sessionId: string };
  modalChangePhoneNumber: IModal;
  changePhoneNumber: <T>(data: T) => Promise<void>;
};

export const ChangePhoneNumberModal = ({
  modalChangePhoneNumber,
  sessionData,
  changePhoneNumber,
}: Props) => {
  const { busy } = useBusy();
  const { openModal, handleCloseModal } = modalChangePhoneNumber;

  const initialValues: TChangePhoneNumber = {
    phone: '',
    sessionId: sessionData.sessionId,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePhoneNumberValidation}
      onSubmit={async (values) => {
        await changePhoneNumber(values);
      }}
    >
      {({ submitForm, isValid, resetForm }) => (
        <CustomizedDialog
          btnTitle="Submit"
          open={openModal}
          handleClose={() => {
            handleCloseModal();
            resetForm();
          }}
          submitForm={async () => {
            await submitForm();
            resetForm();
          }}
          busy={busy}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <ChangePhoneNumberForm />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

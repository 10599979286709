import { Box, Tab, Tabs } from '@mui/material';

export const FilteredMember = ({ value, handleChange }: any) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab value="all" label="All Members" />
        <Tab value="active" label="Active" />
        <Tab value="suspended" label="Suspended" />
      </Tabs>
    </Box>
  );
};

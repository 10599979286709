import { Grid } from '@mui/material';
import { IAccount } from 'App/api/types/GarageAccount';
import { EmptyPage, IModal, SkeletonCardLoading } from 'shared';
import AccountCard from './AccountCard';

type PropsType = {
  accounts: IAccount[];
  setCurrentAccount: React.Dispatch<React.SetStateAction<IAccount | null>>;
  loading: boolean;
  editModal: IModal;
  goToAccount: (id: string, payload: any) => void;
};

export const RenderAccounts = ({
  accounts,
  setCurrentAccount,
  loading,
  editModal,
  goToAccount,
}: PropsType) => {
  if (loading) {
    return <SkeletonCardLoading />;
  }

  if (!loading && accounts.length === 0) {
    return <EmptyPage title="There are no matching accounts" />;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {accounts.map((account) => {
        return (
          <Grid key={account.id} item xs={12} sm={6} md={4}>
            <AccountCard
              account={account}
              editModal={editModal}
              setCurrentAccount={setCurrentAccount}
              goToAccount={goToAccount}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { GarageAccountInterface } from './interface';
import { GarageAccountValidator } from './validator';

export const GarageAccountConverter: firebase.firestore.FirestoreDataConverter<GarageAccountInterface> =
  {
    toFirestore(
      garageAccount: GarageAccountInterface,
    ): firebase.firestore.DocumentData {
      if (GarageAccountValidator(garageAccount)) return garageAccount;
      throw new ConverterError(
        'Garage Account model validation error.',
        JSON.stringify(GarageAccountValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): GarageAccountInterface {
      const garageAccount = snapshot.data();
      if (GarageAccountValidator(garageAccount)) return garageAccount;
      throw new ConverterError(
        'Garage Account model validation error.',
        JSON.stringify(GarageAccountValidator.errors),
      );
    },
  };

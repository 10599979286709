import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { GarageAccountConverter, IAccount } from 'App/api/types/GarageAccount';
import {
  normalizeCollection,
  normalizeDoc,
  TFBCollection,
  TFBDocumentSnapshot,
  TUnsubscribe,
  useBusy,
  useFilteredData,
  useFirebaseApi,
  useGarageData,
  useModal,
  useResponsive,
  useToast,
} from 'shared';
import { getInvites, getUsedSeats } from 'shared/utils/garages';
import { BusinessConverter, IBusiness } from 'App/api/types/Business';
import {
  GarageSubscriptionPassOfferingConverter,
  ISubscriptionPassOffering,
} from 'App/api/types/GarageSubscriptionPassOffering';
import { routeMapping } from 'App/helpers';

export const useAccounts = () => {
  const isDesktop = useResponsive('up', 'sm');
  const { dbQuery } = useFirebaseApi();
  const { garage, garageId } = useGarageData();
  const { busy } = useBusy();
  const navigate = useNavigate();
  const modal = useModal();
  const toast = useToast();

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [currentAccount, setCurrentAccount] = useState<IAccount | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [subscriptionPassOfferings, setSubscriptionPassOfferings] = useState<
    ISubscriptionPassOffering[]
  >([]);

  const accountsUnsubscribe = useRef<TUnsubscribe | null>(null);
  const businessesUnsubscribe = useRef<TUnsubscribe | null>(null);
  const subscriptionPassOfferingsUnsubscribe = useRef<TUnsubscribe | null>(
    null,
  );

  const goToAccount = (id: string, payload: IAccount) => {
    const path = `${routeMapping.locations}/${garageId}${routeMapping.accountPrefix}/${id}`;
    return navigate(path, { state: { ...payload } });
  };

  const fetchAccounts = useCallback(() => {
    setLoading(true);
    accountsUnsubscribe.current = (
      dbQuery(`garages/${garageId}/accounts`) as TFBCollection
    )
      .withConverter(GarageAccountConverter)
      .onSnapshot(async (docs) => {
        try {
          const normalaizeAccounts: IAccount[] = normalizeCollection(docs);
          const resultAccounts = await Promise.all(
            normalaizeAccounts.map(async (account: IAccount) => {
              const businessData =
                account.business &&
                (await dbQuery(account.business.path).get());
              const businessObj = normalizeDoc(
                businessData as TFBDocumentSnapshot,
              );
              const invites = await getInvites({
                query: dbQuery,
                garageId,
                accountId: account.id,
              });
              return {
                ...account,
                usedSeats: getUsedSeats(invites),
                businessObj,
                businessName: businessObj.name,
              };
            }),
          );
          setAccounts(resultAccounts as unknown as IAccount[]);
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        } finally {
          setLoading(false);
        }
      });
  }, [dbQuery, garageId, toast]);

  const getSubscriptionPassOfferings = useCallback(() => {
    subscriptionPassOfferingsUnsubscribe.current = (
      dbQuery(`garages/${garageId}/subscriptionPassOfferings`) as TFBCollection
    )
      .withConverter(GarageSubscriptionPassOfferingConverter)
      .onSnapshot(async (docs) => {
        const normalaizeValidations = normalizeCollection(docs);
        setSubscriptionPassOfferings(
          normalaizeValidations as ISubscriptionPassOffering[],
        );
      });
  }, [dbQuery, garageId]);

  const getBusinesses = useCallback(() => {
    businessesUnsubscribe.current = (
      dbQuery(`garages/${garageId}/businesses`) as TFBCollection
    )
      .withConverter(BusinessConverter)
      .onSnapshot(async (docs) => {
        const normalaizeValidations = normalizeCollection(docs);
        setBusinesses(normalaizeValidations as IBusiness[]);
      });
  }, [dbQuery, garageId]);

  const { setPattern, output } = useFilteredData(accounts, [
    'name',
    'businessName',
  ]);

  const accountsInfo = useMemo(() => {
    if (!output) {
      return '0 accounts';
    }
    return output.length === 1
      ? `${output.length} account`
      : `${output?.length} accounts`;
  }, [output]);

  useEffect(() => {
    fetchAccounts();
    getBusinesses();
    getSubscriptionPassOfferings();

    return () => {
      setBusinesses([]);
      setAccounts([]);
      setSubscriptionPassOfferings([]);
      businessesUnsubscribe?.current?.();
      accountsUnsubscribe?.current?.();
      subscriptionPassOfferingsUnsubscribe?.current?.();
    };
  }, [fetchAccounts, getBusinesses, getSubscriptionPassOfferings]);

  return {
    accounts: output as IAccount[],
    currentAccount,
    setCurrentAccount,
    loading,
    businesses,
    subscriptionPassOfferings,
    garageId,
    garage,
    fetchAccounts,
    accountsInfo,
    setPattern,
    isDesktop,
    modal,
    busy,
    goToAccount,
  };
};

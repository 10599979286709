import { useRef } from 'react';

import { TFunction } from 'shared/types';
import { useFirebaseApi } from './useFirebaseApi';

export const useApiFunction = (): TFunction => {
  const { apiFunction } = useFirebaseApi();
  const apiFn = useRef(apiFunction());

  return apiFn.current;
};

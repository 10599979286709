import { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import { Typography } from '@mui/material';
import { getDollars } from 'shared/utils';
// import { IPopulationGroup } from 'App/api/types';
import {
  AddPopulationGroup,
  TPopulationGroup,
} from 'shared/validations/populationGroup';
import { IPopulationGroup } from 'App/api/types/GaragePopulationGroup';
import { conversionValue } from 'shared/utils/data_tables/validations';
import { EditPopulationGroup } from './form/EditPopulationGroup';

type PropType = {
  closeModal: () => void;
  data?: IPopulationGroup;
  setEditItem: Dispatch<SetStateAction<string>>;
  savePopulationGroup: (
    values: any,
    populationGroupId: any,
    closeModal: any,
  ) => Promise<void>;
  busy: boolean;
};

const NEW_POPULATION_GROUP: TPopulationGroup = {
  data: {
    priority: 999,
    name: '',
    type: 'percent',
    amount: '',
  },
};

export const EditPopulationGroupModal = ({
  closeModal,
  setEditItem,
  data,
  savePopulationGroup,
  busy,
}: PropType) => {
  return (
    <>
      <Typography variant="h4" component="h1" mb={3}>
        {data ? 'Edit Population Group' : 'Create Population Group'}
      </Typography>
      <Formik
        initialValues={
          data
            ? {
                data: {
                  priority: data.priority,
                  name: data.name,
                  type: data.type,
                  amount:
                    data.amount && data.type && data.type === 'fixed'
                      ? getDollars(data.amount)
                      : data.amount && data.amount.toString(),
                },
              }
            : NEW_POPULATION_GROUP
        }
        validationSchema={AddPopulationGroup}
        onSubmit={(values) => {
          const amount = {
            amount: conversionValue(
              values.data.type as string,
              String(values.data.amount),
            ),
          };
          const myValues = {
            ...amount,
            name: values.data.name,
            priority: values.data.priority,
            type: values.data.type,
          };
          savePopulationGroup(myValues, data?.id, closeModal);
        }}
      >
        <EditPopulationGroup
          setEditItem={setEditItem}
          isBusy={busy}
          closeModal={closeModal}
        />
      </Formik>
    </>
  );
};

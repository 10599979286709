import { useCallback, useState, Dispatch, SetStateAction } from 'react';
import { Button, Menu, Typography } from '@mui/material';
import { DotsThree } from 'phosphor-react';
import { ButtonAction, IModal } from 'shared';
import { IAccount } from 'App/api/types/GarageAccount';
import { IAction } from '../helpers';
import { DisableActivateAccountModal } from './DisableActivateAccountModal';
import { useAccountActions } from '../hooks';
import { MemberActionsModal } from './MemberActionsModal';
import { MemberType } from '../types';

export type PropsType = {
  items: IAction[];
  data: MemberType | IAccount;
  editModal?: IModal;
  businessName?: string;
  setCurrentData: Dispatch<SetStateAction<any>>;
  refetchData?: () => Promise<void>;
};

export const AccountActions = ({
  items,
  data,
  editModal,
  setCurrentData,
  businessName,
  refetchData,
}: PropsType) => {
  const {
    modalActivateAccount,
    modalDisableAccount,
    accountId,
    modalMember,
    handleActionWithMember,
  } = useAccountActions();

  const [status, setStatus] = useState<
    'Remove' | 'Reactivate' | 'Suspend' | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAction = useCallback(
    (label, event) => {
      event.stopPropagation();
      if (label === 'Re-activate account')
        modalActivateAccount.handleOpenModal();
      if (label === 'Disable account') modalDisableAccount.handleOpenModal();

      if (label === 'Edit account details' && !accountId) {
        setCurrentData(data);
        editModal?.handleOpenModal();
      }
      if (label === 'Edit account details' && accountId) {
        setCurrentData(data);
        editModal?.handleOpenModal();
      }

      if (label === 'Remove member') {
        setStatus('Remove');
        modalMember.handleOpenModal();
      }
      if (label === 'Re-activate member') {
        setStatus('Reactivate');
        modalMember.handleOpenModal();
      }
      if (label === 'Suspend member') {
        setStatus('Suspend');
        modalMember.handleOpenModal();
      }
      if (label === 'Edit member details') {
        setCurrentData(data);
        editModal?.handleOpenModal();
      }

      handleClose(event);
    },
    [
      modalActivateAccount,
      modalDisableAccount,
      accountId,
      setCurrentData,
      data,
      editModal,
      modalMember,
    ],
  );

  return (
    <>
      <Button
        sx={{ minWidth: '30px', height: '36px' }}
        variant="contained"
        color="inherit"
        size="small"
        onClick={(event) => handleClick(event)}
      >
        <DotsThree size={16} />
      </Button>
      <Menu
        sx={{ px: 10 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items
          .filter(({ isActive }) =>
            data?.status === 'active'
              ? isActive || isActive === undefined
              : !isActive || isActive === undefined,
          )
          .map(({ label }: IAction) => (
            <ButtonAction
              key={label}
              onClick={(event) => handleAction(label, event)}
            >
              <Typography
                variant="body2"
                color="black"
                sx={{ paddingRight: 4 }}
              >
                {label}
              </Typography>
            </ButtonAction>
          ))}
      </Menu>
      <DisableActivateAccountModal
        action="activate"
        modal={modalActivateAccount}
        accountId={data?.id}
      />
      <DisableActivateAccountModal
        action="disable"
        modal={modalDisableAccount}
        accountId={data?.id}
      />
      <MemberActionsModal
        refetchData={refetchData}
        member={data}
        action={status}
        modal={modalMember}
        businessName={businessName}
        handleActionWithMember={handleActionWithMember}
        name={data.name}
      />
    </>
  );
};

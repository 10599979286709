import { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { keys } from 'rambda';
import { Container, Typography, Stack, Breadcrumbs } from '@mui/material';
import { useAuth, useGarageData } from 'shared';
import { protectedRoutes, routeTo } from 'App/helpers';
import { ReactComponent as Dot } from 'App/assets/Dot.svg';

export default function BreadCrumbs(): JSX.Element {
  const { user } = useAuth();
  const { garage } = useGarageData();
  const { pathname } = useLocation();
  const params = useParams();

  const greeting = useMemo(() => {
    return (garage && garage.name) || (user && `Welcome, ${user?.firstName}!`);
  }, [garage, user]);

  const breadcrumbs = protectedRoutes
    .filter(({ label }) =>
      pathname.split('/').includes(label.toLocaleLowerCase()),
    )
    .map(({ path: crumbsPath, label }) => ({
      path: keys(params).length
        ? keys(params).reduce(
            (path, param) => routeTo(path, { [param]: params[param] }),
            crumbsPath,
          )
        : crumbsPath,
      label,
    }))
    .map(({ path, label }) => (
      <Link
        style={{ color: '#212B36', fontSize: '14px', textDecoration: 'none' }}
        key={label}
        to={path}
      >
        {label}
      </Link>
    ));

  return (
    <Container>
      <Typography variant="h4" component="h1">
        {greeting}
      </Typography>
      <Stack spacing={2} marginTop={1} marginBottom={4}>
        <Breadcrumbs separator={<Dot />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </Container>
  );
}

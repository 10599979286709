import { Formik } from 'formik';
import { CustomizedDialog } from 'shared';
import { useBusinesses } from '../hooks';
import { AddBusinessesForm as EditBusinessesForm } from './form/AddBusinessesForm';
import { IBusiness } from '../../../../App/api/types/Business';

type PropType = {
  business: IBusiness;
  handleCloseModal: () => void;
  openModal: boolean;
};

export default function EditBusiness({
  business,
  openModal,
  handleCloseModal,
}: PropType): JSX.Element {
  const { editBusiness } = useBusinesses();
  return (
    <Formik
      initialValues={{
        businessName: business.name,
        street: business.address?.street,
        city: business.address?.city,
        state: business.address?.state,
        country: business.address?.country,
        zipCode: business.address?.zip,
      }}
      onSubmit={async (value) => {
        await editBusiness({ ...value, id: business.id });
        handleCloseModal();
      }}
    >
      {({ submitForm, isValid }) => (
        <>
          <CustomizedDialog
            open={openModal}
            handleClose={handleCloseModal}
            submitForm={submitForm}
            busy={false}
            isValid={isValid}
          >
            <EditBusinessesForm />
          </CustomizedDialog>
        </>
      )}
    </Formik>
  );
}

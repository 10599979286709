import { useState, useCallback, useEffect, useRef } from 'react';
import {
  TFBCollection,
  useFirebaseApi,
  normalizeCollection,
  useModal,
  useApiFunction,
  useBusy,
  useToast,
  getTimeCompleteSession,
  useSessions,
  TUnsubscribe,
} from 'shared';
import { GarageLaneConverter, ILane } from 'App/api/types/GarageLane';
import { getLanes } from 'shared/utils/lanes';
import { removeSymbolsForPhoneNumber } from 'shared/utils/phone';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

export function useSupport() {
  const {
    data,
    setData,
    phone,
    setPhone,
    garages,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    loading,
    setLoading,
    typeOfSearch,
    setTypeOfSearch,
    setUserSearch,
    setVehicleSearch,
  } = useSessions();

  const { setErrorContent } = useErrorHandler();
  const { dbQuery } = useFirebaseApi();
  const modalStartSession = useModal();
  const { busy, setBusy } = useBusy();
  const apiFn = useApiFunction();
  const toast = useToast();

  const [currentGarageId, setCurrentGarageId] = useState<string | null>(null);
  const [lanes, setLanes] = useState<ILane[]>([]);
  const lanesUnsubscribe = useRef<TUnsubscribe | null>(null);

  const startSessionFn = useCallback(
    async (values) => {
      const normalizePhoneNumber = removeSymbolsForPhoneNumber(values.phone);
      try {
        setBusy(true);
        await apiFn({
          action: 'support_start_session',
          data: {
            garageID: values.garageId,
            ...(values.garageLaneId
              ? { laneID: values.garageLaneId }
              : { laneID: null }),
            phone: normalizePhoneNumber,
            time: values.customTime
              ? getTimeCompleteSession(values.date, values.time)
              : getTimeCompleteSession(new Date()),
            ...(values.reason ? { reason: values.reason } : {}),
          },
        });

        toast.success('The session has been started!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalStartSession.handleCloseModal();
      }
    },
    [apiFn, modalStartSession, setBusy, setErrorContent, toast],
  );

  const getEntryLanes = useCallback(
    (garageId) => {
      lanesUnsubscribe.current = (
        dbQuery(`garages/${garageId}/lanes`) as TFBCollection
      )
        .withConverter(GarageLaneConverter)
        .onSnapshot(async (docs) => {
          const normalaizeLanes = normalizeCollection(docs);
          setLanes(normalaizeLanes as ILane[]);
        });
    },
    [dbQuery],
  );

  useEffect(() => {
    if (currentGarageId) {
      getEntryLanes(currentGarageId);
    }
    return () => {
      setLanes([]);
      lanesUnsubscribe?.current?.();
    };
  }, [currentGarageId, getEntryLanes]);

  return {
    setCurrentGarageId,
    lanes: getLanes(lanes, true),
    startSessionFn,
    modalStartSession,
    busy,
    data,
    setData,
    loading,
    setLoading,
    phone,
    setPhone,
    garages,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    typeOfSearch,
    setTypeOfSearch,
    setUserSearch,
    setVehicleSearch,
  };
}

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { GarageSubscriptionPassOfferingInterface } from './interface';
import { GarageSubscriptionPassOfferingValidator } from './validator';

export const GarageSubscriptionPassOfferingConverter: firebase.firestore.FirestoreDataConverter<GarageSubscriptionPassOfferingInterface> =
  {
    toFirestore(
      garageSubscriptionPassOffering: GarageSubscriptionPassOfferingInterface,
    ): firebase.firestore.DocumentData {
      if (
        GarageSubscriptionPassOfferingValidator(garageSubscriptionPassOffering)
      )
        return garageSubscriptionPassOffering;
      throw new ConverterError(
        'Subscription Pass Offering Model validation error.',
        JSON.stringify(GarageSubscriptionPassOfferingValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): GarageSubscriptionPassOfferingInterface {
      const garageSubscriptionPassOffering = snapshot.data();
      if (
        GarageSubscriptionPassOfferingValidator(garageSubscriptionPassOffering)
      )
        return garageSubscriptionPassOffering as GarageSubscriptionPassOfferingInterface;
      throw new ConverterError(
        'Subscription Pass Offering Model validation error.',
        JSON.stringify(GarageSubscriptionPassOfferingValidator.errors),
      );
    },
  };

import { RefObject, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import QRGenerator from 'react-qr-code';
import { Printer, QrCode } from 'phosphor-react';
import { Box, Button, Card, Typography } from '@mui/material';
import { IValidation } from 'App/api/types/Validation';

type PropsType = {
  validation: IValidation;
};

export const ValidationQrForm = ({ validation }: PropsType) => {
  const componentRef = useRef<RefObject<HTMLDivElement> | null>(null);
  const handlePrint = useReactToPrint({
    content: () =>
      (componentRef as unknown as RefObject<HTMLDivElement>).current,
  });

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          ref={componentRef as RefObject<HTMLDivElement>}
        >
          <div>
            <Typography align="center" variant="h2" component="h2" m={2}>
              Scan to validate
            </Typography>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <QRGenerator size={263} value={validation.deepLink || ''} />
            </Box>
            <Typography align="center" variant="h5" component="div" m={3}>
              {validation.name} Validation
            </Typography>
            <Typography
              align="center"
              variant="subtitle2"
              component="div"
              m={3}
            >
              Business: {validation.businessName}
            </Typography>
            <Typography align="center" variant="body2" component="div" m={3}>
              Please visit <b>https://tap.vendpark.io</b> to access your parking
              session. Click the &quot;Redeem button&quot; in the
              &quot;Validations&quot; section to scan the QR code above. This
              will apply the validated rate to your parking session.
            </Typography>
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'primary.dark',
                textDecoration: 'non',
                textDecorationColor: 'white',
                padding: 3,
                borderRadius: 2,
              }}
            >
              <p style={{ color: 'white' }}>
                {' '}
                <h4>Still need help?</h4>
                Call <b>+1 (202) 952-6846</b> for 24/7 support
                <br /> Visit https://tap.vendpark.io to access your parking
                session
              </p>
            </Box>
          </div>
        </Box>

        <Box display="flex" alignItems="center" mt={3}>
          <Button
            sx={{ width: '85px', mr: 1 }}
            startIcon={<Printer />}
            size="medium"
            color="primary"
            variant="contained"
            onClick={handlePrint}
          >
            Print
          </Button>
          {/* <Button
            sx={{ ml: 3 }}
            variant="outlined"
            color="inherit"
            disabled={!validation.deepLink}
            startIcon={<QrCode weight="regular" />}
          >
            Generate New QR Code
          </Button> */}
          <Button
            sx={{ ml: 3 }}
            variant="outlined"
            color="inherit"
            disabled={!validation.deepLink}
            startIcon={<QrCode weight="regular" />}
            onClick={() => {
              navigator.clipboard.writeText(validation.deepLink ?? '');
            }}
          >
            Copy link
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

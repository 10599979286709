import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot } from 'shared';
import { IValidation } from './types/Validation';

function useValidations() {
  const [validations, setValidations] = useState<IValidation[] | null>(null);

  useEffect(() => {
    const ref = firebase.firestore().collectionGroup('validations');
    const unsubscribe = onSnapshot(ref, (docs) => {
      const normalizeValidationsData = normalizeCollection(
        docs as unknown as TFBQuerySnapshot,
      );
      setValidations(normalizeValidationsData as IValidation[]);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    validations,
  };
}

export default useValidations;

import { useCallback, useState } from 'react';
import { IconButton, Menu } from '@mui/material';
import { DotsThree } from 'phosphor-react';
import { ButtonAction } from 'shared';
import { IAction } from '../helpers';

export type PropsType = {
  items: IAction[];
  laneID: string;
  openGateFn: (laneID: string) => Promise<void>;
};

export const Actions = ({ items, openGateFn, laneID }: PropsType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = useCallback(
    (label) => {
      if (label === 'Open gate') openGateFn(laneID);
      handleClose();
    },
    [openGateFn, laneID],
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <DotsThree size={16} />
      </IconButton>
      <Menu
        sx={{ px: 10 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items?.map(({ label, icon: Icon }: IAction) => (
          <ButtonAction
            key={label}
            startIcon={<Icon />}
            onClick={() => handleAction(label)}
          >
            {label}
          </ButtonAction>
        ))}
      </Menu>
    </>
  );
};

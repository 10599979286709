import { styled } from '@mui/material/styles';
import {
  DASHBOARD_HEADER_DESKTOP,
  DASHBOARD_HEADER_MOBILE,
  DASHBOARD_NAVBAR_WIDTH,
} from 'App/config';

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: '100%',
  },
}));

export const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: DASHBOARD_HEADER_MOBILE + 16,
  paddingBottom: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: DASHBOARD_HEADER_DESKTOP + 24,
    paddingBottom: DASHBOARD_HEADER_DESKTOP + 24,
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

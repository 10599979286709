import { memo, SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { XCircle } from 'phosphor-react';

import { ButtonLocation } from 'shared/components/ButtonLocation';
import { useResponsive } from 'shared';
import DropImage from 'pages/management/overview/components/DropImage';
import { TPhoto } from './TPhoto';

type PropsType = {
  busy: boolean;
  isEditPhotos: boolean;
  setIsEditPhotos: React.Dispatch<SetStateAction<boolean>>;
};

const PhotosForm = ({
  busy,
  isEditPhotos,
  setIsEditPhotos,
}: PropsType): JSX.Element => {
  const { submitForm, values, setFieldValue, setValues, initialValues } =
    useFormikContext<TPhoto>();
  const isMobile = useResponsive('down', 'md');

  return (
    <Form>
      <Typography variant="h6" component="div" mb={2}>
        Brand Logo
      </Typography>
      {values.logo.length ? (
        <Box width="144px">
          <Box justifyContent="flex-end" display="flex" m={-2}>
            <IconButton
              disabled={isEditPhotos}
              onClick={() => {
                setFieldValue('logo', '');
                setFieldValue('deleteLogo', values.logo);
              }}
              aria-label="delete"
              color="primary"
            >
              <XCircle />
            </IconButton>
          </Box>
          <img
            src={
              Array.isArray(values.logo) ? values.logo[0]?.preview : values.logo
            }
            alt="logo"
          />
        </Box>
      ) : (
        <>
          <DropImage
            edit={isEditPhotos}
            name="logo"
            isLogo
            photoFormat="image/png"
            onDropped={setFieldValue}
          />
          <Typography variant="caption" component="div" mt={2}>
            Show off your brand by adding a 500px by 500px image to be displayed
            when users enter and exit your facility!
          </Typography>
        </>
      )}
      <Box>
        <Typography variant="h6" component="div" mt={5} mb={2}>
          Location Photos
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={isMobile ? 'space-between' : 'start'}
        >
          <DropImage
            edit={isEditPhotos}
            name="newPhotos"
            onDropped={setFieldValue}
            locationPhotos={values?.photos}
            newPhotos={values.newPhotos}
          />

          {values.photos.map((photo: string) => (
            <Box
              key={photo}
              sx={{
                marginLeft: isMobile ? '0' : '16px',
                marginBottom: '20px',
                position: 'relative',
              }}
            >
              <Box position="absolute" right="0" top="0">
                <Button
                  sx={{ minWidth: '30px', height: '30px', padding: '0' }}
                  variant="contained"
                  color="primary"
                  disabled={isEditPhotos}
                  onClick={() => {
                    setFieldValue(
                      'photos',
                      values.photos.filter((p: string) => p !== photo),
                    );
                    setFieldValue('deletePhotos', [
                      ...values.deletePhotos,
                      ...values.photos.filter(
                        (p: string) => p === photo && !!p.indexOf('blob'),
                      ),
                    ]);
                    setFieldValue(
                      'newPhotos',
                      values.newPhotos.filter(
                        (p: string) =>
                          Array.isArray(p) && p[0].preview !== photo,
                      ),
                    );
                  }}
                >
                  <XCircle size={20} color="#fff" />
                </Button>
              </Box>
              <img
                style={{
                  width: '144px',
                  height: '144px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
                src={photo}
                alt="Photos"
              />
            </Box>
          ))}
        </Box>
        <Typography variant="caption" component="div" mt={2}>
          Add photos of the location for users to view when trying to find the
          facility. We recommend adding photos of the facade, entrance, and
          exit. Provide images with a minimum of 500px by 500px and a maximum of
          2000px by 2000px.
        </Typography>
      </Box>
      <ButtonLocation
        disabled
        isBusy={busy}
        setIsEdit={setIsEditPhotos}
        isEdit={isEditPhotos}
        setInicialValues={() => setValues(initialValues)}
        onSubmit={submitForm}
      />
    </Form>
  );
};

export default memo(PhotosForm);

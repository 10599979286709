import { Dispatch, SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { MaskInput } from 'shared/controls/maskInput';
import { TPopulationGroup } from 'shared/validations/populationGroup';
import {
  getMask,
  TYPE_OF_VALIDATION,
} from 'shared/utils/data_tables/validations';

type PropsType = {
  isBusy: boolean;
  closeModal: () => void;
  setEditItem: Dispatch<SetStateAction<string>>;
};

export const EditPopulationGroup = ({
  isBusy,
  closeModal,
  setEditItem,
}: PropsType) => {
  const {
    isValid,
    submitForm,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<TPopulationGroup>();

  const mask = getMask(values.data.type);
  return (
    <Form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" component="div" fontWeight={700} mb={2}>
          Name
        </Typography>
        <Typography variant="body2" component="div">
          Set the user-facing name of the population group.
        </Typography>

        <TextField
          sx={{ mb: 5, mt: 1 }}
          onBlur={handleBlur}
          value={values.data.name}
          label="Name"
          name="data.name"
          type="text"
          onChange={handleChange}
          error={touched.data?.name && Boolean(errors.data?.name)}
          helperText={touched.data?.name && errors.data?.name}
        />

        <Typography variant="h6" component="div" fontWeight={700} mb={2}>
          Priority
        </Typography>
        <Typography variant="body2" component="div">
          Select a priority between 0 and 99. Scheduled rates with a higher
          priority number will take precedence when multiple Scheduled rates are
          applicable
        </Typography>
        <TextField
          sx={{ mb: 5, mt: 1 }}
          onBlur={handleBlur}
          value={values.data.priority}
          label="Priority"
          name="data.priority"
          type="number"
          InputProps={{ inputProps: { max: 100, min: 10 } }}
          onChange={handleChange}
          error={touched.data?.priority && Boolean(errors.data?.priority)}
          helperText={touched.data?.priority && errors.data?.priority}
        />
        <Typography variant="h6" component="div" fontWeight={700} mb={2}>
          Discount
        </Typography>
        <Typography variant="body2" component="div" mb={1}>
          Select the discount that each member of the population group will
          receive when they park.
        </Typography>

        <RadioGroup sx={{ ml: 1 }} value={values.data.type} name="data.type">
          {TYPE_OF_VALIDATION.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.value}
              onChange={handleChange}
              control={<Radio />}
              label={<Typography variant="body2">{item.text}</Typography>}
            />
          ))}
        </RadioGroup>
        {values.data.type && mask && (
          <MaskInput
            type="text"
            sx={{ mt: 2 }}
            name="data.amount"
            placeholder={values.data.type === 'fixed' ? '$5.00' : '%'}
            mask={mask.mask}
            label={mask.label}
            blocks={mask.blocks}
          />
        )}
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: 5,
          mt: 5,
        }}
      >
        <Typography variant="h6" component="div" fontWeight={700} mb={2}>
          Price Reset
        </Typography>
        <Typography variant="body2" component="div" mb={1}>
          Decide how the price accumulates or if it is a fixed amount that does
          not reset. At the rest time, the parking fare begins accumulating.
        </Typography>

        <RadioGroup sx={{ ml: 1 }} value={values.data.type} name="data.type">
          {VALIDATION_OF_TYPE.map((item) => (
            <FormControlLabel
              value={item.value}
              onChange={handleChange}
              control={<Radio />}
              label={<Typography variant="body2">{item.text}</Typography>}
            />
          ))}
        </RadioGroup>
        {values.data.type && mask && (
          <MaskInput
            type="text"
            sx={{ mt: 2 }}
            name="data.amount"
            placeholder={values.data.type === 'fixed' ? '$5.00' : '%'}
            mask={mask.mask}
            label={mask.label}
            blocks={mask.blocks}
          />
        )}
      </Box> */}
      <Divider sx={{ mt: 5, mx: -4 }} />
      <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={2}>
        <Button
          sx={{ width: '85px', mr: 1 }}
          size="large"
          variant="contained"
          disabled={!isValid || isBusy}
          onClick={submitForm}
        >
          {isBusy ? <CircularProgress size={20} color="inherit" /> : 'Save'}
        </Button>
        <Button
          sx={{ mr: '10px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={() => {
            setEditItem('');
            closeModal();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

import { TMask } from './types/masks';

export const timeMask: TMask = {
  type: 'time',
  label: 'Time',
  mask: 'HHh MMm',
  blocks: {
    HH: {
      mask: /^1[0-9]$|^2[0,1-3]$|^0?[1-9]$|^0$/,
    },

    MM: {
      mask: /^[0-5][0-9]$|^[0-9]$/,
    },
  },
};

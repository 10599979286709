import { Grid } from '@mui/material';
import { IBusiness } from 'App/api/types/Business';
import { IValidation } from 'App/api/types/Validation';
import { EmptyPage, SkeletonCardLoading } from 'shared/components';
import ValidationCard from './ValidationCard';

type PropsType = {
  validations: IValidation[];
  isLoading: boolean;
  sponsors: IBusiness[];
};

export const RenderValidations = ({
  validations,
  isLoading,
  sponsors,
}: PropsType) => {
  if (isLoading) {
    return <SkeletonCardLoading />;
  }

  if (!isLoading && validations.length === 0) {
    return <EmptyPage title="No validations" />;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {validations.map((validation) => {
        return (
          <Grid key={validation.id} item xs={12} sm={6} md={4}>
            <ValidationCard validation={validation} sponsors={sponsors} />
          </Grid>
        );
      })}
    </Grid>
  );
};

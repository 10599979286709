import { memo } from 'react';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Info, QrCode, ShoppingCart } from 'phosphor-react';

import { IValidation } from 'App/api/types/Validation';
import { IBusiness } from 'App/api/types/Business';
import { Badge, useResponsive } from 'shared';
import { getDiscount } from 'shared/utils/data_tables/validations';
import { items } from '../constants/validationStatuses';
import { ValidationActions } from './ValidationActions';

type PropsType = {
  validation: IValidation;
  sponsors: IBusiness[];
};

const ValidationCard = ({ validation, sponsors }: PropsType) => {
  const { description, count, businessName, type, value, name } = validation;
  const { palette, customShadows } = useTheme();
  const isDesktop = useResponsive('up', 500);
  const boxShadow = customShadows.z12;

  return (
    <Card
      sx={{
        m: '0 auto',
        maxWidth: isDesktop ? 358 : '100%',
        boxShadow,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          mb={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Badge
            color={palette.info.dark}
            bgcolor="#3D93F829"
            label={`${count} uses this month`}
          />

          <ValidationActions
            items={items}
            validation={validation}
            sponsors={sponsors}
          />
        </Box>
        <Box>
          <Box mb={1} display="flex" alignItems="center">
            <Info weight="bold" size={20} />
            <Typography ml="13px" variant="h6" fontWeight={700}>
              {name}
            </Typography>
          </Box>
          <Box mb={1} display="flex" alignItems="center">
            <ShoppingCart weight="bold" size={20} />
            <Typography ml="13px" variant="h6" fontWeight={700}>
              {businessName}
            </Typography>
          </Box>
          <Box mb={3} display="flex" alignItems="center">
            <QrCode weight="bold" size={20} />
            <Typography ml="13px" variant="h6" fontWeight={700}>
              {getDiscount(type, value)}
            </Typography>
          </Box>
          <Typography variant="body2">
            {description || 'Without Description'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(ValidationCard);

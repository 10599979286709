/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as AddPhotos } from 'App/assets/AddPhotos.svg';

type PropsType = {
  edit: boolean;
  name: string;
  onDropped: <T>(
    field: string,
    value: T,
    shouldValidate?: boolean | undefined,
  ) => void;
  isLogo?: boolean;
  photoFormat?: string;
  locationPhotos?: string[] | null;
  newPhotos?: string[];
};

interface IFile extends Blob {
  preview: string;
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  border: 1px dashed rgba(145, 158, 171, 0.32);
  border-radius: 50%;
  color: #bdbdbd;
  transition: border 0.24s ease-in-out;
`;

export default function DropImage(props: PropsType): JSX.Element {
  const [files, setFiles] = useState<IFile[]>([]);
  const {
    name,
    onDropped,
    isLogo,
    photoFormat,
    locationPhotos,
    newPhotos,
    edit,
  } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: edit,
    accept: photoFormat,
    multiple: !isLogo,
    maxSize: 20971520,
    onDropAccepted: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(() => {
    if (files) {
      onDropped(name, files);
    }
    if (files[0]?.preview && !isLogo && locationPhotos && newPhotos) {
      onDropped('photos', [...locationPhotos, files[0]?.preview]);
      onDropped(name, [...newPhotos, ...files]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, isLogo, name, onDropped]);

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AddPhotos />
        <Typography variant="body2" component="span">
          Upload photo
        </Typography>
      </Box>
    </Container>
  );
}

import { TFBQuerySnapshot, TFBDocumentSnapshot, TDocumentData } from '../types';

type TCollection<T> = T & {
  id: string;
};

export function normalizeCollection<T>(collection: TFBQuerySnapshot) {
  const data: TCollection<T>[] = [];
  collection.forEach((item) => {
    data.push({ id: item.id, ...item.data() } as unknown as TCollection<T>);
  });
  return data;
}

export const normalizeDoc = (doc: TFBDocumentSnapshot): TDocumentData => ({
  id: doc.id,
  ...doc.data(),
});

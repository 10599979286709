import { Box, Typography } from '@mui/material';

export const RemoveSession = () => {
  return (
    <Box p={3}>
      <Typography variant="h4" component="h1" mb={2}>
        Cancel session
      </Typography>
      <Typography variant="button" component="div">
        Are you sure you want to cancel this session? No revenue will be
        collected and the user will be notified that the session has been
        canceled.
      </Typography>
    </Box>
  );
};

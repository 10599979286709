import { useState, MouseEvent, ChangeEvent } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Button,
  CircularProgress,
} from '@mui/material';

import { getComparator, stableSort, Order } from 'shared';
import { UserInterface } from 'App/api/types/User';
import { EnhancedTableHead } from 'shared/components';
import { PencilSimple } from 'phosphor-react';
import { headCellsUsers } from './helpers';

type Props = {
  users: UserInterface[];
  removeMemberPopulationGroup: (values: any) => Promise<void>;
  data: {
    populationGroupId?: string;
    garageId?: string;
  };
  busyUser: string;
  setValues: any;
  openModalMember: () => void;
};

export const TableUsers = ({
  users,
  removeMemberPopulationGroup,
  data,
  busyUser,
  setValues,
  openModalMember,
}: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            headCells={headCellsUsers}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(users, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => {
                return (
                  <TableRow
                    sx={{ whiteSpace: 'nowrap' }}
                    hover
                    tabIndex={-1}
                    key={user.id}
                  >
                    <TableCell component="th" scope="row">
                      {user.phone}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 200,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : 'Unknown'}
                    </TableCell>
                    <TableCell>
                      {busyUser !== user.phone && (
                        <Button
                          sx={{
                            mr: 1,
                          }}
                          variant="contained"
                          color="inherit"
                          startIcon={<PencilSimple />}
                          onClick={() => {
                            setValues({
                              ...data,
                              isEdit: true,
                              user: {
                                phone: user.phone,
                                id: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                              },
                            });
                            openModalMember();
                          }}
                        >
                          Edit
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          removeMemberPopulationGroup({
                            ...data,
                            user: { phone: user.phone, id: user.id },
                          });
                        }}
                        disabled={busyUser === user.phone}
                      >
                        {busyUser === user.phone ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          ' Remove user'
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

import { createContext } from 'react';

interface IErrorcontent {
  errorHeader: string;
  errorBody: string;
  openError: boolean;
}
export interface EContext {
  errorContent: {
    errorHeader: string | null;
    errorBody: string | null;
    openError: boolean;
  };
  setErrorContent: (errorContent: IErrorcontent) => void;
}

const ErrorContext = createContext<EContext>({
  errorContent: { errorHeader: '', errorBody: '', openError: false },
  setErrorContent: () => {},
});

export const ErrorProvider = ErrorContext.Provider;
export default ErrorContext;

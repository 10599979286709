import PaymentElementWrapper from 'App/helpers/payment/PaymentElementWrapper';
import { IModal } from 'shared';
import { AddPaymentForm } from './form/AddPaymentForm';

type Props = {
  modal: IModal;
};

export const AddPaymentModal = ({ modal }: Props) => {
  return (
    <PaymentElementWrapper>
      <AddPaymentForm modal={modal} />
    </PaymentElementWrapper>
  );
};

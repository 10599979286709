import { useState, useCallback, useEffect, useRef } from 'react';
import {
  normalizeCollection,
  TFBCollection,
  TUnsubscribe,
  useApiFunction,
  useBusy,
  useFirebaseApi,
  useGarageData,
  useResponsive,
  useToast,
} from 'shared';
import { GarageLaneConverter, ILane } from 'App/api/types/GarageLane';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

export const useLanes = () => {
  const isDesktop = useResponsive('up', 'sm');
  const { setBusy } = useBusy();
  const { dbQuery } = useFirebaseApi();
  const { garage, garageId } = useGarageData();
  const toast = useToast();
  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const [lanes, setLanes] = useState<ILane[] | null>(null);

  const garageLaneUnsubscribe = useRef<TUnsubscribe | null>(null);

  const fetchLanes = useCallback(() => {
    garageLaneUnsubscribe.current = (
      dbQuery(`garages/${garageId}/lanes`) as TFBCollection
    )
      .withConverter(GarageLaneConverter)
      .onSnapshot(async (docs) => {
        const normalaizeLanes = normalizeCollection(docs);
        setLanes(normalaizeLanes as unknown as ILane[]);
      });
  }, [dbQuery, garageId]);

  const openGateFn = useCallback(
    async (laneID: string) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'support_open_gate',
          data: {
            laneID,
          },
        });
        toast.success(`The gate (${laneID}) is open!`);
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, setErrorContent, toast],
  );

  useEffect(() => {
    let mounted = true;
    if (mounted && garageId) {
      fetchLanes();
    }
    return () => {
      mounted = false;
      garageLaneUnsubscribe.current?.();
    };
  }, [fetchLanes, garageId]);

  return {
    lanes,
    garageId,
    garage,
    fetchLanes,
    openGateFn,
    isDesktop,
  };
};

import * as Yup from 'yup';

export const sessionCompleteValidation = Yup.object({
  sessionId: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
  time: Yup.date().nullable(),
  customTime: Yup.boolean().nullable().required('Required'),
  price: Yup.string(),
  garageLaneId: Yup.string().nullable(true),
  overridenPrice: Yup.string(),
  reason: Yup.string(),
});

export type TSessionComplete = Yup.InferType<typeof sessionCompleteValidation>;

export class ConverterError extends Error {
  /**
   *
   * @param msg - main error message
   *
   * @param details - detailed error message
   */
  constructor(msg: string, details: string) {
    super(msg);
    this.details = details;
  }

  /**
   * detailed error message
   */
  details: string;
}

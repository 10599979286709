import { Formik } from 'formik';
import { Box } from '@mui/material';
import { CustomizedDialog, IModal } from 'shared';
import { overridePriceValidation } from 'shared/validations/overridePrice';
import { OverridePriceForm } from './form/OverridePriceForm';

type Props = {
  modal: IModal;
  sessionId: string;
  busy: boolean;
  overridePriceFn: <T>(data: T) => Promise<void>;
};

export const OverridePriceModal = ({
  modal,
  sessionId,
  busy,
  overridePriceFn,
}: Props) => {
  const { openModal, handleCloseModal } = modal;

  return (
    <Formik
      initialValues={{ amount: '', sessionId }}
      enableReinitialize
      validationSchema={overridePriceValidation}
      onSubmit={async (values, { resetForm }) => {
        await overridePriceFn(values);
        resetForm();
      }}
    >
      {({ submitForm, isValid }) => (
        <CustomizedDialog
          btnTitle="Submit"
          busy={busy}
          submitForm={submitForm}
          open={openModal}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <OverridePriceForm />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

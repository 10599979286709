import { Formik } from 'formik';
import { IBusiness } from 'App/api/types/Business';
import { ISubscriptionPassOffering } from 'App/api/types';
import { memberValidation } from 'shared/validations/member';
import { CustomizedDialog } from 'shared/components';
import { IModal } from 'shared';
import { AddMemberForm } from './forms';

type PropsType = {
  modal: IModal;
  busy: boolean;
  subscriptionPassOfferings: ISubscriptionPassOffering[];
  memberData: any;
  setCurrentMember: any;
  createMember: (value: any) => Promise<void>;
  editMember: (value: any) => Promise<void>;
};

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  subscriptionPassOfferingID: '',
  spotNumber: '',
};

export const AddMemberModal = ({
  busy,
  modal,
  subscriptionPassOfferings,
  memberData,
  setCurrentMember,
  createMember,
  editMember,
}: PropsType) => {
  const subscriptionPassOfferingOptions = subscriptionPassOfferings.map(
    (business: IBusiness) => {
      return { text: business.name ?? '', value: business.id };
    },
  );

  const initialMemberData = {
    firstName: memberData?.name.split(' ')[0] || '',
    lastName: memberData?.name.split(' ')[1] || '',
    phone: memberData?.phone,
    subscriptionPassOfferingID: memberData?.subscriptionPassOffering?.id,
    spotNumber: memberData?.spotNumber || '',
  };

  return (
    <Formik
      initialValues={memberData ? initialMemberData : initialValues}
      validationSchema={memberValidation}
      enableReinitialize
      onSubmit={async (value) => {
        if (memberData) {
          await editMember({
            ...value,
            id: memberData.id,
            status: memberData.status,
          });
        } else {
          await createMember(value);
        }
      }}
    >
      {({ submitForm, resetForm, isValid }) => (
        <>
          <CustomizedDialog
            open={modal.openModal}
            handleClose={() => {
              modal.handleCloseModal();
              resetForm();
              setCurrentMember(null);
            }}
            submitForm={async () => {
              await submitForm();
              resetForm();
            }}
            busy={busy}
            isValid={isValid}
          >
            <AddMemberForm
              isMemberData={!!memberData}
              subscriptionPassOfferingOptions={subscriptionPassOfferingOptions}
            />
          </CustomizedDialog>
        </>
      )}
    </Formik>
  );
};

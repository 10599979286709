import { SetStateAction } from 'react';
import { useFormikContext } from 'formik';
import styled from '@emotion/styled';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { PencilSimple } from 'phosphor-react';
import {
  IRules,
  IScheduledRate,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { getTimeOfMaxParkTimeRoster } from 'shared/utils';
import { getGapTimeText, getRecurrenceText, getStartDate } from '../helpers';

type PropsType = {
  rule: IRules;
  handleOpenModal: () => void;
  setSubscriptionData: React.Dispatch<
    SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
  >;
  setRuleData: React.Dispatch<SetStateAction<IRules | null>>;
};

const WrapperRuleCard = styled(Box)`
  min-width: 296px;
  max-width: 315px;
  padding: 24px;
  border: 1px solid #000;
  border-radius: 16px;
  :not(:last-child) {
    margin-right: 24px;
  }
`;

export const RuleCard = ({
  rule,
  handleOpenModal,
  setRuleData,
  setSubscriptionData,
}: PropsType): JSX.Element => {
  const theme = useTheme();
  const { values } = useFormikContext<any>();
  const { enterAfter, enterBefore, exitAfter, exitBefore } = rule;

  const startDate = getStartDate(rule.startDate);
  const minDurations = getTimeOfMaxParkTimeRoster(rule.minParkingTime);
  const maxDurations = getTimeOfMaxParkTimeRoster(rule.maxParkingTime);
  const recurrence = getRecurrenceText(rule.recurrence);
  const enterBetween = getGapTimeText(enterBefore, enterAfter);
  const exitBetween = getGapTimeText(exitBefore, exitAfter);

  return (
    <WrapperRuleCard>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography noWrap maxWidth="210px" variant="h6" component="div">
          Pattern
        </Typography>
        <Box mb={1}>
          <Button
            sx={{ minWidth: '38px', height: '26px', padding: '0' }}
            variant="outlined"
            color="inherit"
            onClick={() => {
              setRuleData(rule);
              setSubscriptionData(values);
              handleOpenModal();
            }}
          >
            <PencilSimple size={12} />
          </Button>
        </Box>
      </Box>
      <Box mb={3} color={theme.palette.grey[600]}>
        <Typography variant="subtitle1">Start: {startDate}</Typography>
        <Typography variant="subtitle1">Repeats: {recurrence}</Typography>
        <Typography variant="subtitle1">
          Min Durations: {minDurations?.result || 'Unlimited'}
        </Typography>
        <Typography variant="subtitle1">
          Max Durations: {maxDurations?.result || 'Unlimited'}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" mb={1}>
          Enter
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.grey[600]}>
          {enterBetween}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" mb={1}>
          Exit
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.grey[600]}>
          {exitBetween}
        </Typography>
      </Box>
    </WrapperRuleCard>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from '@mui/x-date-pickers';
import { Box, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ISchedulesModal } from 'shared/components/RulesAndExceptions/types';
import { SelectInput } from 'shared/controls';
import { END_RECURRENCE_OPTIONS } from './constants';

const RenderField = () => {
  const { values, handleChange, setFieldValue } =
    useFormikContext<ISchedulesModal>();
  const type = values.newRule.recurrenceData.recurrenceOption.typeEndRecurrence;

  if (type === 'COUNT')
    return (
      <TextField
        fullWidth
        variant="outlined"
        onChange={handleChange}
        name="newRule.recurrenceData.recurrenceOption.count"
        type="number"
        value={values.newRule.recurrenceData.recurrenceOption.count || ''}
      />
    );

  if (type === 'UNTIL')
    return (
      <DatePicker
        value={values.newRule.recurrenceData.recurrenceOption.until}
        onChange={(newValue) => {
          setFieldValue(
            'newRule.recurrenceData.recurrenceOption.until',
            newValue,
          );
        }}
        renderInput={(params) => <TextField fullWidth {...params} />}
      />
    );

  return null;
};

export const EndRecurrence = () => {
  const { values, setFieldValue } = useFormikContext<ISchedulesModal>();
  const type = values.newRule.recurrenceData.recurrenceOption.typeEndRecurrence;

  const setInitialValueEnd = () => {
    setFieldValue('newRule.recurrenceData.recurrenceOption.until', null);
    setFieldValue('newRule.recurrenceData.recurrenceOption.count', null);
  };
  return (
    <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
      <Box width="45%">
        <SelectInput
          label="End"
          name="newRule.recurrenceData.recurrenceOption.typeEndRecurrence"
          setInitialValue={setInitialValueEnd}
          defaultValue={type || 'NEVER'}
          options={END_RECURRENCE_OPTIONS}
        />
      </Box>
      <Box width="45%">
        <RenderField />
      </Box>
    </Box>
  );
};

import GoogleMapReact from 'google-map-react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

type PropsType = {
  coordinate: any;
};

type PropsMarker = {
  text: string;
  lat?: number;
  lng?: number;
};

const MarkerComponent = styled.div`
  min-width: 150px;
  text-align: center;
  position: relative;
  padding: 8px 12px;
  background-color: #000;
  border-radius: 36px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  :before {
    content: '';
    position: absolute;
    background-color: #000;
    bottom: -13px;
    width: 4px;
    left: 50%;
    height: 14px;
  }
`;

const Marker = (props: PropsMarker) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { text, lng, lat } = props;
  return <MarkerComponent>{text}</MarkerComponent>;
};

export const GarageMap = ({ coordinate }: PropsType): JSX.Element => {
  const { lat, long } = coordinate;

  return (
    <Box
      sx={{
        width: '100%',
        height: '346px',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_FIREBASE_API_KEY as string,
          language: 'en',
        }}
        defaultCenter={{
          lat,
          lng: long,
        }}
        center={{ lat, lng: long }}
        defaultZoom={13}
        options={{ fullscreenControl: false, zoomControl: false }}
      >
        <Marker lat={lat} lng={long} text="Garage entrance" />
      </GoogleMapReact>
    </Box>
  );
};

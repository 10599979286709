import { Grid } from '@mui/material';

import { IGarage } from 'App/api/types/Garage';
import { EmptyPage } from 'shared/components';
import { CardGarage } from './CardGarage';

export const RenderLocations = ({
  locations,
}: {
  locations: IGarage[] | null;
}) => {
  if (locations === null) {
    return <span>...loading</span>;
  }

  if (locations.length === 0) {
    return <EmptyPage title="No locations found" />;
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {locations.map((garage: IGarage) => {
        return (
          <Grid key={garage.id} item xs={12} sm={6} md={4}>
            <CardGarage
              imgSrc={garage.photos && garage.photos[0]}
              name={garage.name}
              address={garage.address?.street}
              status={garage.status}
              garageId={garage.id}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import { TimePicker } from '@mui/x-date-pickers';
import { Box, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { useResponsive, MaskInput, offSet } from 'shared';
import { ISchedulesModal } from '../../types';

type PropsType = {
  type: 'enter' | 'exit';
  title: string;
  startTime: string | null;
  endTime: string | null;
};

export const Between = ({ type, startTime, endTime, title }: PropsType) => {
  const { handleBlur, setFieldValue, values } =
    useFormikContext<ISchedulesModal>();
  const isMobile = useResponsive('down', 'md');
  const afterOffset = `newRule.${type}AfterOffset`;
  const beforeOffset = `newRule.${type}BeforeOffset`;

  return (
    <>
      <Typography variant="h6" component="div" mt={5} mb={2}>
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box display={isMobile ? '' : 'flex'}>
          <TimePicker
            label="Start time"
            value={startTime}
            onChange={(value) => {
              setFieldValue(`newRule.${type}After`, value);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: '100%' }}
                onBlur={handleBlur}
                {...params}
              />
            )}
          />

          <MaskInput
            type="text"
            sx={{ ml: isMobile ? 0 : 3, mt: isMobile ? 2 : 0, width: '100%' }}
            name={afterOffset}
            mask={offSet.mask}
            label={offSet.label}
            disabled={!values.newRule[`${type}After`]}
            placeholder="+1 days"
            blocks={offSet.blocks}
          />
        </Box>

        <Typography
          sx={{ display: 'inline-block', mx: 2 }}
          variant="body2"
          component="span"
        >
          and
        </Typography>

        <Box display={isMobile ? '' : 'flex'}>
          <TimePicker
            label="End time"
            value={endTime}
            onChange={(value) => setFieldValue(`newRule.${type}Before`, value)}
            renderInput={(params) => (
              <TextField
                sx={{ width: '100%' }}
                onBlur={handleBlur}
                {...params}
              />
            )}
          />
          <MaskInput
            type="text"
            sx={{ ml: isMobile ? 0 : 3, mt: isMobile ? 2 : 0, width: '100%' }}
            name={beforeOffset}
            mask={offSet.mask}
            label={offSet.label}
            disabled={!values.newRule[`${type}Before`]}
            placeholder="+1 days"
            blocks={offSet.blocks}
          />
        </Box>
      </Box>
    </>
  );
};

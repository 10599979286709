import {
  ChartPie,
  CurrencyCircleDollar,
  House,
  Percent,
  TagSimple,
  UsersFour,
  ArrowsLeftRight,
  Car,
  HandbagSimple,
} from 'phosphor-react';

export const ICON_NAV_BAR_LOCATIONS = [
  {
    label: 'Overview',
    icon: <House size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Lanes',
    icon: <ArrowsLeftRight size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Pricing',
    icon: <CurrencyCircleDollar size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Passes',
    icon: <TagSimple size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Businesses',
    icon: <HandbagSimple size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Accounts',
    icon: <UsersFour size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Validations',
    icon: <Percent size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Parks',
    icon: <Car size={20} style={{ marginRight: '5px' }} />,
  },
  {
    label: 'Reports',
    icon: <ChartPie size={20} style={{ marginRight: '5px' }} />,
  },
];

import { Formik } from 'formik';
import { Box } from '@mui/material';
import { CustomizedDialog, IModal } from 'shared';
import { RefundForm } from './form/RefundForm';

type Props = {
  modalRefund: IModal;
  refundSession: (values: any) => Promise<void>;
  supportId: string;
  sessionId: string;
  busy: boolean;
};

const initialValues = {
  isPartial: false,
  amount: '',
};

export const RefundModal = ({
  modalRefund,
  refundSession,
  supportId,
  sessionId,
  busy,
}: Props) => {
  const { openModal, handleCloseModal } = modalRefund;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values) => {
        await refundSession({
          ...values,
          sessionId,
        });
      }}
    >
      {({ submitForm, isValid, resetForm }) => (
        <CustomizedDialog
          btnTitle="Confirm"
          busy={busy}
          submitForm={async () => {
            await submitForm();
            resetForm();
          }}
          open={openModal}
          handleClose={() => {
            handleCloseModal();
            resetForm();
          }}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <RefundForm supportId={supportId} />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { drop, isEmpty } from 'rambda';
import { v4 as uuidv4 } from 'uuid';
import { IBucketRate, RateTablePricing } from 'App/api/types';
import { getMilisFromString } from './dates';

type TRate = {
  basePrice: string;
  pricingRows: {
    priceIncrease: string;
    durationThreshold: string;
  }[];
};

export const getDollars = (val: number) => (val / 100.0).toFixed(2);

export const getCents = (val: number) => Math.round(val * 100);

export const checkDayForResetTime = (resetTimeMillis: number) => {
  if (resetTimeMillis >= 86400000) {
    return resetTimeMillis - 86400000;
  }
  if (resetTimeMillis < -86400000) {
    return resetTimeMillis + 86400000;
  }
  return resetTimeMillis;
};

const getDurationPerTime = (date: string) => {
  return getMilisFromString(date, 'HH:mm').toString();
};

export const getStringPerTime = (data: RateTablePricing[]) => {
  return data.map((item) => {
    const h = Math.floor(item.durationThreshold / 1000 / 60 / 60);
    return {
      priceIncrease: getDollars(item.priceIncrease),
      durationThreshold: `${h}h ${
        (item.durationThreshold - h * 1000 * 60 * 60) / 1000 / 60
      }m`,
    };
  });
};

export const sendObjectRate = ({ basePrice, pricingRows = [] }: TRate) => {
  const tableRate = [];

  if (basePrice === '') {
    tableRate.push({ durationThreshold: 0, priceIncrease: 0 });
  } else {
    tableRate.push({
      durationThreshold: 0,
      priceIncrease: getCents(+drop(1, basePrice)),
    });
  }

  if (!isEmpty(pricingRows)) {
    pricingRows.forEach((row) => {
      const duration = getDurationPerTime(row.durationThreshold || '0');
      tableRate.push({
        durationThreshold: +duration,
        priceIncrease: getCents(+drop(1, row.priceIncrease || '0')),
      });
    });
  }
  return tableRate;
};

export const getPriceForTime = (rates: RateTablePricing[]) => {
  const result = getStringPerTime(rates).map((rate) => {
    return {
      id: uuidv4(),
      priceIncrease: rate.priceIncrease,
      text: rate.durationThreshold,
    };
  });
  return result;
};

export const getBucket = (buckets: IBucketRate) =>
  Object.keys(buckets).map((name) => {
    return {
      ...buckets[name],
      name,
    };
  });

import { Typography } from '@mui/material';
import { iconProperties } from '../helpers/icon';

type Props = {
  status: string;
};

export default ({ status }: Props) => {
  const currentStatus = iconProperties.find((item) => item.name === status);

  return (
    <Typography
      variant="body2"
      sx={{
        background: currentStatus?.background,
        position: 'absolute',
        color: currentStatus?.color,
        right: 0,
        m: 1,
        px: 2,
        py: 0.5,
        borderRadius: '20px',
        textTransform: 'capitalize',
      }}
    >
      {currentStatus?.name}
    </Typography>
  );
};

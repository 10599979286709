import { Box, Typography } from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CustomizedDialog, IModal } from 'shared';
import { Formik } from 'formik';
import { usePayment } from 'pages/support/hooks/usePayment';

type Props = {
  modal: IModal;
};

export const AddPaymentForm = ({ modal }: Props) => {
  const { openModal, handleCloseModal } = modal;
  const { handleSubmitCreatePaymentMethod, setClientSecret } = usePayment();
  const elements = useElements();
  const stripe = useStripe();

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      // validationSchema={applyValidation}
      onSubmit={() => {
        handleSubmitCreatePaymentMethod(stripe, elements);
      }}
    >
      {({ submitForm, isValid }) => (
        <CustomizedDialog
          btnTitle="Submit"
          busy={false}
          submitForm={submitForm}
          open={openModal}
          handleClose={() => {
            handleCloseModal();
            setClientSecret(undefined);
          }}
          isValid={isValid}
        >
          <Box p={3}>
            <Typography variant="h4" mb={3}>
              Add payment
            </Typography>
            <PaymentElement />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { Box } from '@mui/material';
import { Formik } from 'formik';
import { CustomizedDialog, IModal, useBusy } from 'shared';
import { useAccountActions } from '../hooks/useAccountActions';
import { DisableActivateAccountForm } from './forms/DisableActivateAccountForm';

type Props = {
  action: 'activate' | 'disable';
  accountId: string;
  modal: IModal;
};

export const DisableActivateAccountModal = ({
  action,
  modal,
  accountId,
}: Props) => {
  const { activateDeactivate } = useAccountActions();
  const { busy } = useBusy();
  const { openModal, handleCloseModal } = modal;

  return (
    <Formik
      initialValues={{ accountId }}
      enableReinitialize
      onSubmit={(value) => {
        activateDeactivate(value.accountId, action);
        handleCloseModal();
      }}
    >
      {({ submitForm, isValid }) => (
        <CustomizedDialog
          btnColor={action === 'activate' ? 'success' : 'error'}
          btnTitle={action === 'activate' ? 'Activate' : 'Disable'}
          open={openModal}
          handleClose={handleCloseModal}
          submitForm={submitForm}
          busy={busy}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <DisableActivateAccountForm action={action} />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

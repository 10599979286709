import * as Yup from 'yup';

export const messaging = Yup.object({
  arrivalInstructions: Yup.string(),
  templates: Yup.object({
    sms: Yup.object({
      webAppStartSession: Yup.string().max(
        150,
        ' Must be 150 characters or more',
      ),
      webAppStartSession2: Yup.string().max(
        150,
        ' Must be 150 characters or more',
      ),
      webAppCompleteSessionPaid: Yup.string().max(
        150,
        ' Must be 150 characters or more',
      ),
      webAppCompleteSessionFree: Yup.string().max(
        100,
        ' Must be 100 characters or more',
      ),
      webCompleteSessionPaid: Yup.string().max(
        150,
        ' Must be 150 characters or more',
      ),
      webCompleteSessionFree: Yup.string().max(
        100,
        ' Must be 100 characters or more',
      ),
    }).required(),
  }),
});

export type TMessaging = Yup.InferType<typeof messaging>;

/* eslint-disable react/jsx-props-no-spreading */
import { Form, useFormikContext } from 'formik';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';

import { SelectInput } from 'shared/controls';
import {
  getMask,
  TYPE_OF_VALIDATION,
} from 'shared/utils/data_tables/validations';
import { MaskInput } from 'shared/controls/maskInput';
import { TValidation } from 'shared/validations/addValidation';
import { IBusiness } from 'App/api/types/Business';
import { priceMask } from 'shared/utils/masks';

type PropsType = {
  title: string;
  sponsors: IBusiness[];
};

export const AddValidationForm = ({ title, sponsors }: PropsType) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<TValidation>();

  const sponsorOptions = sponsors.map((sponsor: IBusiness) => {
    return { text: sponsor.name ?? '', value: sponsor.id };
  });

  const mask = getMask(values.type);

  return (
    <Form>
      <Box p={3}>
        <Typography variant="h4" mb={3}>
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" mb={3}>
            Name
          </Typography>
          <TextField
            sx={{ mb: 7 }}
            value={values.name}
            onBlur={handleBlur}
            label="Name"
            name="name"
            type="text"
            onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
          <Typography variant="h6" mb={3}>
            Description
          </Typography>
          <TextField
            sx={{ mb: 7 }}
            value={values.description}
            onBlur={handleBlur}
            label="Description"
            name="description"
            type="text"
            onChange={handleChange}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
            multiline
          />
          <Typography variant="h6" mb={3}>
            Business
          </Typography>
          <SelectInput
            sx={{ mb: 7 }}
            onBlur={handleBlur}
            name="business"
            options={sponsorOptions}
            label="Select business"
          />
          <Typography variant="h6" mb={3}>
            Display
          </Typography>
          <FormControlLabel
            sx={{ mb: 7 }}
            label={
              <Typography variant="body2">
                Should the validation be visible to all users from the parking
                session screen?
              </Typography>
            }
            control={
              <Checkbox
                checked={values.isVisible}
                onChange={handleChange}
                name="isVisible"
              />
            }
          />
          <Typography variant="h6" mb={3}>
            Payment Type
          </Typography>
          <Typography variant="body2">
            Who pays for the parking session?
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={4}
            mb={values.isUserPays ? 7 : 3}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={values.isUserPays}
                  onChange={() => setFieldValue('isUserPays', true)}
                  name="isUserPays"
                />
              }
              label={
                <Typography variant="body2">
                  Paid individually by the users
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!values.isUserPays}
                  onChange={() => {
                    setFieldValue('isUserPays', false);
                  }}
                  name="isUserPays"
                />
              }
              label={
                <Typography variant="body2">
                  Billed to master account for the business
                </Typography>
              }
            />
          </Box>
          {!values.isUserPays && (
            <>
              <Typography variant="body2">
                How is the business charged for the validation?
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mt={4}
                mb={values.businessCharged ? 7 : 3}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={values.businessCharged}
                      onChange={() => setFieldValue('businessCharged', true)}
                      name="businessCharged"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Charge the business the validated rate
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={!values.businessCharged}
                      onChange={() => setFieldValue('businessCharged', false)}
                      name="businessCharged"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Charge the business a custom amount per use
                    </Typography>
                  }
                />
              </Box>
              {!values.businessCharged && (
                <MaskInput
                  sx={{ width: '100%', mb: 7 }}
                  type="text"
                  name="businessValue"
                  mask={priceMask.mask}
                  label="Billing amount"
                  placeholder={priceMask.label}
                  blocks={priceMask.blocks}
                />
              )}
            </>
          )}

          <Typography variant="h6" mb={3}>
            Rate Adjustment
          </Typography>
          <Typography variant="body2">
            Choose the pricing type for the validation. Flat fee and capped
            modify the session to a different rate, which can be more or less
            than the garage’s base rate. Time-based, percentage, and dollar
            amount discounts modify and decrease the session price.
          </Typography>
          <Box
            paddingY={3}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {TYPE_OF_VALIDATION.map((item) => (
              <FormControlLabel
                key={item.value}
                control={
                  <Radio
                    checked={values.type === item.value}
                    onChange={() => setFieldValue('type', item.value)}
                    name="type"
                  />
                }
                label={<Typography variant="body2">{item.text}</Typography>}
              />
            ))}
          </Box>
          {values.type && mask && (
            <MaskInput
              sx={{ mb: 7 }}
              type="text"
              name="value"
              mask={mask.mask}
              label={mask.label}
              blocks={mask.blocks}
              placeholder={values.name}
            />
          )}
        </Box>
      </Box>
    </Form>
  );
};

import { IHeadCell } from 'shared';

export const headCellsUsers: IHeadCell[] = [
  {
    id: 'phone',
    label: 'Phone',
    numeric: false,
  },
  {
    id: 'firstName',
    label: 'Name',
    numeric: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: true,
  },
];

import { TMask } from './types/masks';

export const offSet: TMask = {
  type: 'offSet',
  label: 'Offset',
  mask: '+NN',
  blocks: {
    NN: {
      maxLength: 3,
      mask: /^[0-9]$|^[0-9]$|^10$/,
    },
  },
};

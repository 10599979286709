const interval = 15;
const totalMinutes = 24 * 60 + interval;
const amountItems = totalMinutes / interval;

export const optionsGracePeriod = Array.from(Array(amountItems).keys()).map(
  (item) => {
    const intervalTime = item * interval;
    const condotionMins =
      intervalTime % 60 !== 0 ? `${intervalTime % 60} mins` : '';
    const time =
      item * interval >= 60
        ? {
            text: `${Math.floor(intervalTime / 60)} hours ${condotionMins}`,
            value: intervalTime.toString(),
          }
        : { text: `${intervalTime % 60} mins`, value: intervalTime.toString() };
    return time;
  },
);

import { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import { Box, TextField, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ButtonLocation } from 'shared/components/ButtonLocation';
import { useResponsive } from 'shared';
import { TBasicDetails } from 'shared/validations/basicDetails';
import { STATUS_GARAGE } from '../../constants/statusGarage';

type PropsType = {
  busy: boolean;
  isEditBasicDetails: boolean;
  setIsEditBasicDetails: React.Dispatch<SetStateAction<boolean>>;
};

export const BasicDetailsForm = ({
  busy,
  isEditBasicDetails,
  setIsEditBasicDetails,
}: PropsType): JSX.Element => {
  const {
    isValid,
    submitForm,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
    initialValues,
    touched,
    errors,
  } = useFormikContext<TBasicDetails>();
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  return (
    <Form>
      <Typography variant="h6" component="div" mb={2}>
        Status
      </Typography>
      <Box
        sx={{
          border: '1px solid rgba(145, 158, 171, 0.32)',
          borderRadius: '8px',
          padding: '4px',
          display: isMobile ? 'grid' : 'inline-block',
        }}
      >
        {STATUS_GARAGE.map((status) => {
          const isActive = status.value === values.status;
          return (
            <Button
              disabled={isEditBasicDetails}
              key={status.value}
              variant={isActive ? 'contained' : 'text'}
              sx={{
                px: '16px',
                color: isActive ? '#fff' : theme.palette.grey[600],
                height: '56px',
              }}
              onClick={() => {
                setFieldValue('status', status.value);
              }}
            >
              {status.label}
            </Button>
          );
        })}
      </Box>
      <Typography variant="h6" component="div" mt={5} mb={2}>
        Name
      </Typography>
      <TextField
        fullWidth
        label="Garage name"
        variant="outlined"
        name="name"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isEditBasicDetails}
        value={values.name}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />
      <ButtonLocation
        isBusy={busy}
        setIsEdit={setIsEditBasicDetails}
        isEdit={isEditBasicDetails}
        isValid={isValid}
        setInicialValues={() => setValues(initialValues)}
        onSubmit={submitForm}
      />
    </Form>
  );
};

import { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import { TextField, Typography } from '@mui/material';

import { TLocation } from 'shared/validations/location';
import { SelectInput } from 'shared/controls';
import states from 'shared/constants/states';
import { ButtonLocation } from 'shared/components/ButtonLocation';
import { GarageMap } from '../GarageMap';

type PropsType = {
  busyLocation: boolean;
  editLocation: {
    isEditLocation: boolean;
    setIsEditLocation: React.Dispatch<SetStateAction<boolean>>;
  };
};

export const LocationForm = ({
  editLocation,
  busyLocation,
}: PropsType): JSX.Element => {
  const { setIsEditLocation, isEditLocation } = editLocation;

  const {
    isValid,
    submitForm,
    values,
    handleChange,
    handleBlur,
    setValues,
    errors,
    touched,
    initialValues,
  } = useFormikContext<TLocation>();

  return (
    <Form>
      <Typography variant="h6" component="div" mb={2}>
        Map Location
      </Typography>
      <TextField
        onBlur={handleBlur}
        disabled={isEditLocation}
        sx={{ mb: '16px' }}
        fullWidth
        onChange={handleChange}
        label="Latitude"
        variant="outlined"
        name="coordinate.lat"
        type="number"
        value={values.coordinate.lat}
        error={touched.coordinate?.lat && Boolean(errors.coordinate?.lat)}
        helperText={touched.coordinate?.lat && errors.coordinate?.lat}
      />
      <TextField
        onBlur={handleBlur}
        disabled={isEditLocation}
        sx={{ mb: '16px' }}
        fullWidth
        onChange={handleChange}
        label="Longitude"
        variant="outlined"
        name="coordinate.long"
        type="number"
        value={values.coordinate.long}
        error={touched.coordinate?.long && Boolean(errors.coordinate?.long)}
        helperText={touched.coordinate?.long && errors.coordinate?.long}
      />
      <GarageMap coordinate={values.coordinate} />
      <Typography variant="h6" component="div" mt={5} mb={2}>
        Address
      </Typography>
      <TextField
        onBlur={handleBlur}
        disabled={isEditLocation}
        sx={{ mb: '16px' }}
        fullWidth
        onChange={handleChange}
        label="Street"
        variant="outlined"
        name="address.street"
        type="text"
        value={values.address.street}
        error={touched.address?.street && Boolean(errors.address?.street)}
        helperText={touched.address?.street && errors.address?.street}
      />
      <TextField
        onBlur={handleBlur}
        disabled={isEditLocation}
        sx={{ mb: '16px' }}
        fullWidth
        onChange={handleChange}
        label="City"
        variant="outlined"
        name="address.city"
        type="text"
        value={values.address.city}
        error={touched.address?.city && Boolean(errors.address?.city)}
        helperText={touched.address?.city && errors.address?.city}
      />
      <SelectInput
        disabled={isEditLocation}
        onBlur={handleBlur}
        name="address.state"
        options={states}
        label="State"
      />
      <TextField
        onBlur={handleBlur}
        disabled={isEditLocation}
        sx={{ mt: '16px', mb: '16px' }}
        fullWidth
        onChange={handleChange}
        label="Zip"
        variant="outlined"
        name="address.zip"
        type="text"
        value={values.address.zip}
        error={touched.address?.zip && Boolean(errors.address?.zip)}
        helperText={touched.address?.zip && errors.address?.zip}
      />
      <ButtonLocation
        disabled
        isBusy={busyLocation}
        setIsEdit={setIsEditLocation}
        isEdit={isEditLocation}
        isValid={isValid}
        setInicialValues={() => setValues(initialValues)}
        onSubmit={submitForm}
      />
    </Form>
  );
};

import React from 'react';
import { IMaskInput } from 'react-imask';
import { MaskedElement } from 'react-imask/dist/mixin';
import { useField, useFormikContext } from 'formik';

import { InputBaseComponentProps, TextField, SxProps } from '@mui/material';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  blocks: { [key: string]: IMask.AnyMaskedOptions };
  mask: string;
}

type Props = {
  sx?: SxProps;
  name: string;
  blocks: { [key: string]: IMask.AnyMaskedOptions };
  mask: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  type: string;
};

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, blocks, mask, ...other } = props;

    return (
      <IMaskInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        style={{ width: '100%' }}
        mask={mask}
        blocks={blocks}
        inputRef={ref as ((el: MaskedElement) => void) | undefined}
        onAccept={(value: string) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  },
);

export const MaskInput = ({
  sx,
  name,
  blocks,
  mask,
  label,
  placeholder,
  disabled,
  type,
}: Props) => {
  const [{ onChange, ...field }, meta] = useField(name);
  const { setFieldValue, handleBlur } = useFormikContext();
  const { touched, error } = meta;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, event.target.value);
  };

  return (
    <TextField
      sx={sx}
      name={name}
      placeholder={placeholder}
      type={type}
      label={label}
      value={field.value}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      InputProps={{
        inputComponent:
          TextMaskCustom as unknown as React.ElementType<InputBaseComponentProps>,
        inputProps: {
          blocks,
          mask,
        },
      }}
      variant="outlined"
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  );
};

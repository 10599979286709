import { FunctionsError } from 'firebase/functions';
import { useCallback } from 'react';
import { useApiFunction, useBusy, useToast } from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { sendObjectRate } from 'shared/utils';
import { usePricing } from './usePricing';

export function useRateTables() {
  const { baseRate, garageId } = usePricing();
  const { busy, setBusy } = useBusy();

  const toast = useToast();
  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const saveBaseRate = useCallback(
    async (values, closeModal) => {
      const table = sendObjectRate({
        basePrice: values.basePrice,
        pricingRows: values.pricingRows,
      });
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_pricing_edit_base_rate',
          data: {
            garageID: garageId,
            details: { table },
          },
        });
        toast.success(`Base rate has beed updated`);
        closeModal();
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [apiFn, garageId, setBusy, setErrorContent, toast],
  );

  return {
    saveBaseRate,
    baseRate,
    busy,
  };
}

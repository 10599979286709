import { ReactNode } from 'react';
import useProvidePayment from './useProvidePayment';
import { PaymentProvider as Provider, IPaymentContext } from './PaymentContext';

type Props = {
  children: ReactNode;
};

function PaymentProvider({ children }: Props) {
  const payment = useProvidePayment();

  return <Provider value={payment as IPaymentContext}>{children}</Provider>;
}

export default PaymentProvider;

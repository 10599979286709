import firebase from 'firebase/compat/app';
import { Formik } from 'formik';
import { CustomizedDialog, IModal } from 'shared';
import {
  applyValidation,
  TApplyValidation,
} from 'shared/validations/applyValidation';
import { ApplyValidation } from './form';
import { useApplyValidation } from './useApplyValidation';

type Props = {
  sessionData: {
    parkId: string;
    sessionId: string;
    garageId: string;
    entryTime: firebase.firestore.Timestamp;
  };
  modal: IModal;
};

export const ApplyValidationModal = ({ modal, sessionData }: Props) => {
  const { openModal, handleCloseModal } = modal;
  const { validationsList, applyValidationFn, isLoading } = useApplyValidation(
    sessionData.garageId,
  );
  const initialValues: TApplyValidation = {
    sessionsId: sessionData.sessionId,
    validationId: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={applyValidation}
      onSubmit={async (values) => {
        const { sessionsId, validationId } = values;
        await applyValidationFn(sessionsId, validationId);
        handleCloseModal();
      }}
    >
      {({ submitForm, isValid, resetForm }) => (
        <CustomizedDialog
          btnTitle="Submit"
          busy={isLoading}
          cleanData={resetForm}
          submitForm={submitForm}
          open={openModal}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <ApplyValidation validationsList={validationsList} />
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { ReactNode } from 'react';

import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import customShadows from './shadows';
import componentsOverride from './overrides';

type Props = {
  children: ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  const theme = createTheme({
    palette,
    typography,
    breakpoints,
    shape: { borderRadius: 8 },
    customShadows,
  });
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;

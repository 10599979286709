import moment from 'moment';
import { RRule, rrulestr } from 'rrule';
import { v4 as uuidv4 } from 'uuid';
import {
  formatTime,
  getPassTimeFromTimeStamp,
  getTimeOfMaxParkTimeRoster,
  getTimeStamp,
} from 'shared';
import { IRules, ITimeStamp, TRecurrence } from 'App/api/types';
import { MMMM_DD_YYYY } from 'shared/constants/time';
import {
  Bymonth,
  Bysetpos,
  Byweekday,
  Frequency,
  TParkingTimeForm,
} from '../types';

export const getRecurrenceText = (recurrence: string | null) => {
  if (!recurrence) return 'Unlimited';
  const rruleStr = rrulestr(recurrence);
  return rruleStr.toText();
};

export const splitTime = (numberOfHours: string | null) => {
  if (typeof numberOfHours !== 'string') return { days: null, hours: null };
  const arrNum = numberOfHours.split(':');
  const num = parseInt(arrNum[0], 10);
  const days = Math.floor(num / 24);
  const remainder = num % 24;
  const hours = `${Math.floor(remainder)}:${arrNum[1]}`;

  return { days, hours };
};

const get12HourClock = (hour: string) => {
  return moment(hour, 'HH').format('ha');
};

export const getGapTimeText = (before: string | null, after: string | null) => {
  if (!before && !after) return 'Unlimited';

  const beforeTime = before ? splitTime(before) : null;
  const afterTime = after ? splitTime(after) : null;

  const beforeHours =
    typeof beforeTime?.hours === 'string'
      ? get12HourClock(beforeTime.hours)
      : 'unlimited';
  const afterHours =
    typeof afterTime?.hours === 'string'
      ? get12HourClock(afterTime.hours)
      : 'not limited';

  const afterDay = afterTime?.days ? `(+${afterTime.days} days)` : '';
  const beforeDay = beforeTime?.days ? `(+${beforeTime.days} days)` : '';

  if (afterHours === 'not limited') {
    return `${afterHours} to ${beforeHours} ${beforeDay}`;
  }

  return `between ${afterHours} ${afterDay} and ${beforeHours} ${beforeDay}`;
};

export const getTime = (time: string | null, day: number | null) => {
  if (!time) return null;

  const daysInHours = day ? day * 24 : 0;
  const timeArr = moment(time).format('HH:mm').split(':');
  timeArr[0] = String(+timeArr[0] + daysInHours);

  return timeArr.join(':');
};

export const getStartDate = (startDate: ITimeStamp | number) => {
  if (typeof startDate === 'object')
    return getPassTimeFromTimeStamp(startDate as ITimeStamp);

  return formatTime(startDate, MMMM_DD_YYYY);
};

export const getDurationMilliseconds = (payload: {
  days: number | null;
  hours: number | null;
  minutes: number | null;
}) => {
  if (!payload.days && !payload.hours && !payload.minutes) return null;

  const daysMillis = payload.days ? payload.days * (1000 * 60 * 60 * 24) : 0;
  const hoursMillis = payload.hours ? payload.hours * (1000 * 60 * 60) : 0;
  const minutesMillis = payload.minutes ? payload.minutes * (1000 * 60) : 0;
  const resultMillis = daysMillis + hoursMillis + minutesMillis;

  return resultMillis;
};

const getRRuleString = (
  rRuleData: TRecurrence,
  startDate: Date,
  timezone: string | null,
) => {
  const {
    frequencyData: { freq },
    recurrenceOption: {
      interval = undefined,
      until,
      bymonthday,
      bysetpos,
      byweekday,
      count,
      bymonth,
      bymonthPos,
    },
  } = rRuleData;

  if (freq === 'NEVER') return null;

  const rRule = new RRule({
    freq: freq && Frequency[freq],
    count,
    interval,
    tzid: timezone,
    bymonth:
      (bymonth && Bymonth[bymonth]) || (bymonthPos && Bymonth[bymonthPos]),
    byweekday:
      byweekday &&
      (Array.isArray(byweekday) ? byweekday : Byweekday[byweekday]),
    bymonthday,
    bysetpos: bysetpos && Bysetpos[bysetpos],
    dtstart: startDate,
    until,
  });
  return rRule.toString();
};

export const convertRuleObject = (
  payload: IRules<Date, TParkingTimeForm>,
  timezone: string | null,
) => {
  const rule = {
    id: uuidv4(),
    startDate: getTimeStamp(payload.startDate),
    recurrence:
      payload?.recurrenceData &&
      getRRuleString(payload.recurrenceData, payload.startDate, timezone),

    enterAfter: getTime(payload.enterAfter, payload.enterAfterOffset),
    enterBefore: getTime(payload.enterBefore, payload.enterBeforeOffset),
    exitAfter: getTime(payload.exitAfter, payload.exitAfterOffset),
    exitBefore: getTime(payload.exitBefore, payload.exitBeforeOffset),
    minParkingTime: getDurationMilliseconds(payload?.minParkingTime),
    maxParkingTime: getDurationMilliseconds(payload?.maxParkingTime),
    partiallyApplicable: payload?.partiallyApplicable,
  };

  return rule;
};

export const recurrenceAutocomplete = (payload: string | null) => {
  if (!payload) return null;

  const { origOptions, options } = rrulestr(payload);

  const isMountly = origOptions.freq === Frequency.MONTHLY;
  const isYearly = origOptions.freq === Frequency.YEARLY;
  const isMontlyOrYearly = isMountly || isYearly;

  const untilType = origOptions?.until && 'UNTIL';
  const countType = origOptions?.count && 'COUNT';

  const dayType = isMontlyOrYearly && origOptions?.bymonthday && 'BYMONTHDAY';
  const dayWeekType = isMontlyOrYearly && origOptions?.byweekday && 'BYSETPOS';

  return {
    frequencyData: {
      freq:
        typeof origOptions?.freq === 'number' && Frequency[origOptions.freq],
    },
    recurrenceOption: {
      count: origOptions?.count,
      interval: origOptions?.interval,
      bymonth:
        origOptions?.bymonth &&
        dayType &&
        Bymonth[origOptions.bymonth as number],

      bymonthPos:
        origOptions?.bymonth &&
        dayWeekType &&
        Bymonth[origOptions.bymonth as number],

      byweekday:
        isMontlyOrYearly && options?.byweekday
          ? Byweekday[options?.byweekday[0]]
          : options?.byweekday,

      bymonthday: origOptions?.bymonthday,
      bysetpos:
        origOptions?.bysetpos && Bysetpos[origOptions.bysetpos as number],

      until: origOptions?.until || null,
      typeEndRecurrence: untilType || countType,
      changeMonthField: dayType || dayWeekType,
    },
  };
};

export const getRuleFormAutocomplete = (payload: any) => {
  const timeEnterAfter = splitTime(payload.enterAfter);
  const timeEnterBefore = splitTime(payload.enterBefore);
  const timeExitAfter = splitTime(payload.exitAfter);
  const timeExitBefore = splitTime(payload.exitBefore);
  const { partiallyApplicable } = payload;

  const {
    days: daysMin,
    hours: hoursMin,
    minutes: minutesMin,
  } = getTimeOfMaxParkTimeRoster(payload.minParkingTime);
  const {
    days: daysMax,
    hours: hoursMax,
    minutes: minutesMax,
  } = getTimeOfMaxParkTimeRoster(payload.maxParkingTime);

  const ruleForm = {
    id: payload.id,
    startDate: payload.startDate && payload.startDate.toDate(),
    recurrenceData:
      payload.recurrence && recurrenceAutocomplete(payload.recurrence),
    enterAfter:
      typeof timeEnterAfter.hours === 'string'
        ? `${payload.startDate.toDate().toDateString()}  ${
            timeEnterAfter.hours
          }`
        : null,
    enterBefore:
      typeof timeEnterBefore.hours === 'string'
        ? `${payload.startDate.toDate().toDateString()}  ${
            timeEnterBefore.hours
          }`
        : null,

    enterAfterOffset: timeEnterAfter.days ? `+${timeEnterAfter.days}` : null,
    enterBeforeOffset: timeEnterBefore.days ? `+${timeEnterBefore.days}` : null,

    exitAfter:
      typeof timeExitAfter.hours === 'string'
        ? `${payload.startDate.toDate().toDateString()}  ${timeExitAfter.hours}`
        : null,
    exitBefore:
      typeof timeExitBefore.hours === 'string'
        ? `${payload.startDate.toDate().toDateString()}  ${
            timeExitBefore.hours
          }`
        : null,

    exitAfterOffset: timeExitAfter.days ? `+${timeExitAfter.days}` : null,
    exitBeforeOffset: timeExitBefore.days ? `+${timeExitBefore.days}` : null,

    minParkingTime: {
      days: daysMin && String(daysMin),
      hours: hoursMin && String(hoursMin),
      minutes: minutesMin && String(minutesMin),
    },
    maxParkingTime: {
      days: daysMax && String(daysMax),
      hours: hoursMax && String(hoursMax),
      minutes: minutesMax && String(minutesMax),
    },
    ...({ partiallyApplicable } || {}),
  };
  return ruleForm;
};

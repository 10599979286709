import { useMemo } from 'react';
import { pathOr } from 'rambda';
import { useGarageData, useModal } from 'shared';
import { RateTablePricing } from 'App/api/types';

export function usePricing() {
  const { garage, garageId, timezone } = useGarageData();
  const { openModal, handleCloseModal, handleOpenModal } = useModal();
  const {
    openModal: openGroup,
    handleCloseModal: handleCloseGroup,
    handleOpenModal: handleOpenGroup,
  } = useModal();
  const {
    openModal: openShedualRates,
    handleCloseModal: handleCloseShedualRates,
    handleOpenModal: handleOpenShedualRates,
  } = useModal();
  const {
    openModal: openMembers,
    handleCloseModal: handleCloseMembers,
    handleOpenModal: handleOpenMembers,
  } = useModal();

  const gracePeriod = useMemo<number | null>(
    () => pathOr(null, 'pricing.base.gracePeriod', garage),
    [garage],
  );

  const resetTime = useMemo<number | null>(
    () => pathOr(null, 'pricing.base.resetLogic', garage),
    [garage],
  );

  const baseRate = useMemo<RateTablePricing[] | null>(
    () => pathOr(null, 'pricing.base.table', garage),
    [garage],
  );

  return {
    gracePeriod,
    garage,
    garageId,
    resetTime,
    timezone,
    baseRate,
    modalRate: { openModal, handleCloseModal, handleOpenModal },
    modalPopulationGroup: { openGroup, handleCloseGroup, handleOpenGroup },
    modalShedualRates: {
      openShedualRates,
      handleCloseShedualRates,
      handleOpenShedualRates,
    },
    modalMembers: { openMembers, handleCloseMembers, handleOpenMembers },
  };
}

import * as Yup from 'yup';

export const coordinatesValidationScheme = Yup.object({
  latitude: Yup.number()
    .test(
      'is-latitude',
      'Invalid latitude',
      (val: number | undefined) =>
        Math.abs(val as number) >= -90 && Math.abs(val as number) <= 90,
    )
    .required('Required'),
  longitude: Yup.number()
    .test(
      'is-longitude',
      'Invalid longitude',
      (val: number | undefined) =>
        Math.abs(val as number) >= -180 && Math.abs(val as number) <= 180,
    )
    .required('Required'),
});

import * as Yup from 'yup';
import { useState } from 'react';
import { useAuth } from 'shared';

export function useLogin() {
  const { signin } = useAuth();

  const [busy, setBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
      .min(6, 'Must be 6 characters or more')
      .required('Required'),
  });

  return {
    validationSchema,
    signin,
    busy,
    setBusy,
    handleShowPassword,
    showPassword,
  };
}

import { Box, Radio, TextField, Typography } from '@mui/material';
import { TFrequency } from 'App/api/types';
import { useFormikContext } from 'formik';
import { useResponsive, SelectInput, ToggleButtonsMultiple } from 'shared';
import { ISchedulesModal } from 'shared/components/RulesAndExceptions/types';

import {
  DAY_OPTIONS,
  NUMBER_WEEK_OPTIONS,
  WEEK_FOR_MONTHLY_OPTIONS,
  WEEK_OPTIONS,
  MONTH_OPTIONS,
} from './constants';

const RepeatEvery = ({ title }: { title: string }) => {
  const { values, handleChange } = useFormikContext<ISchedulesModal>();

  return (
    <Box display="flex" alignItems="center">
      <TextField
        sx={{ mr: 2 }}
        variant="outlined"
        label="Repeat every"
        onChange={handleChange}
        name="newRule.recurrenceData.recurrenceOption.interval"
        type="number"
        value={values.newRule.recurrenceData.recurrenceOption.interval || ''}
      />
      <Typography variant="body2" component="span">
        {title}
      </Typography>
    </Box>
  );
};

export const RenderFieldByType = ({
  typeField,
}: {
  typeField: TFrequency | undefined;
}) => {
  const { values, setFieldValue } = useFormikContext<ISchedulesModal>();
  const isMobile = useResponsive('down', 'sm');

  const monthField =
    values.newRule.recurrenceData?.recurrenceOption?.changeMonthField;

  if (typeField === 'DAILY')
    return (
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
        <RepeatEvery title="day(s)" />
      </Box>
    );

  if (typeField === 'WEEKLY')
    return (
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        sx={{ mt: 2 }}
      >
        <RepeatEvery title="week(s)" />
        <ToggleButtonsMultiple
          size={isMobile ? 'small' : 'medium'}
          sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 2 : 0, width: '90%' }}
          name="newRule.recurrenceData.recurrenceOption.byweekday"
          defaultValue={
            values.newRule.recurrenceData.recurrenceOption.byweekday
          }
          options={WEEK_OPTIONS}
          setValue={setFieldValue}
        />
      </Box>
    );

  if (typeField === 'MONTHLY')
    return (
      <>
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <RepeatEvery title="month(s)" />
        </Box>
        <Box display="flex" flexDirection="column" my={2}>
          <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
            Repeat On
          </Typography>
          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Radio
              sx={{ mr: 1 }}
              checked={monthField === 'BYMONTHDAY'}
              onChange={() => {
                setFieldValue(
                  'newRule.recurrenceData.recurrenceOption.changeMonthField',
                  'BYMONTHDAY',
                );
                setFieldValue(
                  'newRule.recurrenceData.recurrenceOption.bysetpos',
                  null,
                );
                setFieldValue(
                  'newRule.recurrenceData.recurrenceOption.byweekday',
                  null,
                );
              }}
              value="BYMONTHDAY"
              name="radio-buttons"
            />
            <SelectInput
              label="Day"
              sx={{ width: isMobile ? '100%' : '150px' }}
              name="newRule.recurrenceData.recurrenceOption.bymonthday"
              disabled={monthField !== 'BYMONTHDAY'}
              options={DAY_OPTIONS}
            />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Radio
              sx={{ mr: 1 }}
              checked={monthField === 'BYSETPOS'}
              onChange={() => {
                setFieldValue(
                  'newRule.recurrenceData.recurrenceOption.changeMonthField',
                  'BYSETPOS',
                );
                setFieldValue(
                  'newRule.recurrenceData.recurrenceOption.bymonthday',
                  null,
                );
              }}
              value="BYSETPOS"
              name="radio-buttons"
            />
            <Box
              width="100%"
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <SelectInput
                label="Position of the week"
                sx={{
                  width: isMobile ? '100%' : '190px',
                  mr: isMobile ? 0 : 1,
                  mb: isMobile ? 1 : 0,
                }}
                name="newRule.recurrenceData.recurrenceOption.bysetpos"
                disabled={monthField !== 'BYSETPOS'}
                options={NUMBER_WEEK_OPTIONS}
              />
              <SelectInput
                label="Day of the week"
                sx={{
                  width: isMobile ? '100%' : '190px',
                  mr: isMobile ? 0 : 1,
                  mb: isMobile ? 1 : 0,
                }}
                name="newRule.recurrenceData.recurrenceOption.byweekday"
                disabled={monthField !== 'BYSETPOS'}
                options={WEEK_FOR_MONTHLY_OPTIONS}
              />
            </Box>
          </Box>
        </Box>
      </>
    );

  if (typeField === 'YEARLY')
    return (
      <Box display="flex" flexDirection="column" my={2}>
        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
          Repeat On
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
          <Radio
            sx={{ mr: 1 }}
            checked={monthField === 'BYMONTHDAY'}
            onChange={() => {
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.changeMonthField',
                'BYMONTHDAY',
              );
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.bysetpos',
                null,
              );
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.byweekday',
                null,
              );
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.bymonthPos',
                null,
              );
            }}
            value="BYMONTHDAY"
            name="radio-buttons"
          />
          <SelectInput
            label="Month"
            sx={{ width: '150px', mr: 1 }}
            name="newRule.recurrenceData.recurrenceOption.bymonth"
            disabled={monthField !== 'BYMONTHDAY'}
            options={MONTH_OPTIONS}
          />
          <SelectInput
            label="Day"
            sx={{ width: '150px' }}
            name="newRule.recurrenceData.recurrenceOption.bymonthday"
            disabled={monthField !== 'BYMONTHDAY'}
            options={DAY_OPTIONS}
          />
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
          <Radio
            sx={{ mr: 1 }}
            checked={monthField === 'BYSETPOS'}
            onChange={() => {
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.changeMonthField',
                'BYSETPOS',
              );
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.bymonthday',
                null,
              );
              setFieldValue(
                'newRule.recurrenceData.recurrenceOption.bymonth',
                null,
              );
            }}
            value="BYSETPOS"
            name="radio-buttons"
          />
          <Box
            width="100%"
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <SelectInput
              label="Position of the week"
              sx={{
                width: isMobile ? '100%' : '185px',
                mr: isMobile ? 0 : 1,
                mb: isMobile ? 1 : 0,
              }}
              name="newRule.recurrenceData.recurrenceOption.bysetpos"
              disabled={monthField !== 'BYSETPOS'}
              options={NUMBER_WEEK_OPTIONS}
            />
            <SelectInput
              label="Day of the week"
              sx={{
                width: isMobile ? '100%' : '155px',
                mr: isMobile ? 0 : 1,
                mb: isMobile ? 1 : 0,
              }}
              name="newRule.recurrenceData.recurrenceOption.byweekday"
              disabled={monthField !== 'BYSETPOS'}
              options={WEEK_FOR_MONTHLY_OPTIONS}
            />
            <SelectInput
              label="Month"
              sx={{ width: isMobile ? '100%' : '100px' }}
              name="newRule.recurrenceData.recurrenceOption.bymonthPos"
              disabled={monthField !== 'BYSETPOS'}
              options={MONTH_OPTIONS}
            />
          </Box>
        </Box>
      </Box>
    );

  return null;
};

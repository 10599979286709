import { SetStateAction } from 'react';
import { Typography } from '@mui/material';
import {
  IRules,
  IScheduledRate,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { BasicModal } from '../Modal';
import { TabsSubscriptions } from './components/TabsSubscriptions';
import { RuleForm } from './form';
import { useRules } from './hooks/useRules';

type PropsType = {
  rules: IRules[];
  exceptions: IRules[];
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  editSubscription: {
    subscriptionData: ISubscriptionPassOffering | IScheduledRate | null;
    setSubscriptionData: React.Dispatch<
      SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
    >;
  };
};

export const RulesAndExceptions = ({
  rules,
  exceptions,
  editRule,
  editSubscription,
}: PropsType) => {
  const { modalRule, modalException, timezone } = useRules();
  const { setSubscriptionData } = editSubscription;
  const { ruleData, setRuleData } = editRule;

  const { openModal: openModalRule, handleCloseModal: handleCloseModalRule } =
    modalRule;

  const {
    openModal: openModalException,
    handleCloseModal: handleCloseModalException,
  } = modalException;

  const closeModalRule = () => {
    handleCloseModalRule();
    setRuleData(null);
  };
  const closeModalException = () => {
    handleCloseModalException();
    setRuleData(null);
  };

  return (
    <>
      <Typography variant="h6" component="div" mt={5} mb={2}>
        Validity
      </Typography>
      <Typography variant="body2" mb={2}>
        At least one of the validity rules must match, and none of the exception
        rules can match, in order for the scheduled rate to apply
      </Typography>
      <TabsSubscriptions
        rules={rules}
        exceptions={exceptions}
        modalRule={modalRule}
        editRule={editRule}
        modalException={modalException}
        editSubscription={editSubscription}
      />
      <BasicModal
        open={openModalRule}
        setClose={closeModalRule}
        btnClose={false}
      >
        <RuleForm
          timezone={timezone}
          type="rules"
          setSubscriptionData={setSubscriptionData}
          ruleData={ruleData}
          handleCloseModal={closeModalRule}
        />
      </BasicModal>

      <BasicModal
        open={openModalException}
        setClose={closeModalException}
        btnClose={false}
      >
        <RuleForm
          timezone={timezone}
          type="exceptions"
          setSubscriptionData={setSubscriptionData}
          ruleData={ruleData}
          handleCloseModal={closeModalException}
        />
      </BasicModal>
    </>
  );
};

import { SetStateAction } from 'react';
import { Formik } from 'formik';
import { BasicModal, SUBSCRIPTION, RULE } from 'shared/components';
import {
  IScheduledRate,
  ISchedules,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { SubscriptionsForm } from './form';
import { usePasses } from '../../hooks';

type PropsType = {
  // busySubscription: boolean;
  // saveSubscription: (payload: ISchedules) => Promise<void>;
  handleCloseModal: () => void;
  openModal: boolean;
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  editSubscription: {
    subscriptionData: ISubscriptionPassOffering | IScheduledRate | null;
    setSubscriptionData: React.Dispatch<
      SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
    >;
  };
};

export const SubscriptionsModal = ({
  handleCloseModal,
  openModal,
  editRule,
  editSubscription,
}: PropsType) => {
  const { subscriptionData, setSubscriptionData } = editSubscription;
  const { ruleData } = editRule;
  const { saveSchedule } = usePasses();

  const title = subscriptionData
    ? 'Edit Subscription Pass Offering'
    : 'Create Subscription Pass Offering';

  return (
    <Formik
      initialValues={
        subscriptionData
          ? {
              ...subscriptionData,
              newRule: ruleData || RULE,
              isCustomParkingLimit: !!subscriptionData.parkLimit,
            }
          : { ...SUBSCRIPTION, newRule: RULE }
      }
      enableReinitialize
      // validationSchema={rule}
      onSubmit={async (values, { resetForm }) => {
        await saveSchedule(values as unknown as ISchedules);
        resetForm();
        handleCloseModal();
      }}
    >
      {({ setValues, initialValues }) => (
        <>
          <BasicModal
            open={openModal}
            setClose={() => {
              handleCloseModal();
              setSubscriptionData(null);
              setValues(initialValues);
            }}
          >
            <SubscriptionsForm
              title={title}
              busySchedule={false}
              handleCloseModal={handleCloseModal}
              editRule={editRule}
              editSubscription={editSubscription}
            />
          </BasicModal>
        </>
      )}
    </Formik>
  );
};

import { useEffect, useCallback, useState, useMemo } from 'react';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

import { useFirebase } from 'App/firebase';
import { useAuth, useBusy, useFirebaseApi, useToast } from 'shared';
import useGarages from './UseGarages';
import useEmployee from './UseEmployee';
import useUsers from './UseUsers';
import useAccounts from './UseAccounts';
import useValidations from './UseValidations';
import useBusinesses from './UseBusinesses';
import usePasses from './UsePasses';

function useProvideApi() {
  const { user } = useAuth();
  const { setBusy } = useBusy();
  const toast = useToast();
  const firebase = useFirebase();
  const { dbQuery } = useFirebaseApi();
  const [operatorData, setOperatorData] = useState<{
    operatorId: string;
    userId: string;
  } | null>(null);

  const { garages, garagesRef, fetchGarages, garageUnsubscribe } = useGarages();
  const { users } = useUsers();
  const { accounts } = useAccounts();

  const { validations } = useValidations();
  const { businesses } = useBusinesses();
  const { passes } = usePasses();

  const { employee, fetchEmployee } = useEmployee(dbQuery);

  const fetchApiData = useCallback(
    (currentUser) => {
      const fetch = async () => {
        try {
          setBusy(true);
          const token = await currentUser.getIdTokenResult();
          const { operator } = token.claims;
          setOperatorData({ operatorId: operator, userId: currentUser.uid });
          await fetchEmployee(operator, currentUser.uid);
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        } finally {
          setBusy(false);
        }
      };
      fetchGarages();
      fetch();
    },
    [fetchEmployee, fetchGarages, setBusy, toast],
  );

  useEffect(() => {
    if (user) fetchApiData(firebase.auth().currentUser);
    const unsubscribeGarage = garageUnsubscribe.current;

    return () => {
      unsubscribeGarage?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApiData, firebase, user]);

  const keysOperator = useMemo(() => operatorData, [operatorData]);

  return {
    garages,
    garagesRef,
    fetchGarages,
    employee,
    keysOperator,
    users,
    accounts,
    validations,
    businesses,
    passes,
  };
}

export default useProvideApi;

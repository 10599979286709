import { FunctionsError } from 'firebase/functions';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  normalizeCollection,
  TFBCollection,
  TUnsubscribe,
  useApiFunction,
  useBusy,
  useFilteredData,
  useFirebaseApi,
  useGarageData,
  useModal,
  useToast,
} from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import {
  BusinessConverter,
  BusinessInterface,
  IBusiness,
} from 'App/api/types/Business';

export function useBusinesses() {
  const { garage, garageId } = useGarageData();
  const { openModal, handleCloseModal, handleOpenModal } = useModal();
  const modalEditBusiness = useModal();

  const { setBusy } = useBusy();
  const [isLoading, setIsLoading] = useState(false);
  const [businessesData, setBusinessesData] = useState<IBusiness[]>([]);
  const { setErrorContent } = useErrorHandler();
  const toast = useToast();
  const businessApi = useApiFunction();
  const { dbQuery } = useFirebaseApi();

  const businessesUnsubscribe = useRef<TUnsubscribe | null>(null);

  const { setPattern, output } = useFilteredData<IBusiness[]>(businessesData, [
    'businessName',
    'name',
  ]);

  const businessInfo = useMemo(
    () =>
      output.length === 1
        ? `${output.length} Business`
        : `${output.length} Businesses`,
    [output],
  );

  const getBusinesses = useCallback(() => {
    setIsLoading(true);
    businessesUnsubscribe.current = (
      dbQuery(`garages/${garageId}/businesses`) as TFBCollection
    )
      .withConverter(BusinessConverter)
      .onSnapshot(async (docs) => {
        try {
          const normalaizeBusinesses =
            normalizeCollection<BusinessInterface>(docs);

          const businessData = await Promise.all(
            normalaizeBusinesses.map(async (business) => {
              return {
                ...business,
              };
            }),
          );
          setBusinessesData(businessData as IBusiness[]);
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        } finally {
          setIsLoading(false);
        }
      });
  }, [dbQuery, garageId, toast]);

  const creatBusiness = useCallback(
    async (data) => {
      try {
        setBusy(true);
        await businessApi({
          action: 'garages_businesses_create',
          data: {
            garageID: garage?.id,
            businessData: {
              address: {
                city: data.city,
                country: data.country,
                state: data.state,
                street: data.street,
                zip: data.zipCode,
              },
              name: data.businessName,
            },
          },
        });

        toast.success('Business account created!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [setBusy, businessApi, garage?.id, toast, setErrorContent],
  );

  const editBusiness = useCallback(
    async (data) => {
      try {
        setBusy(true);
        await businessApi({
          action: 'garages_businesses_edit',
          data: {
            garageID: garage?.id,
            businessData: {
              id: data.id,
              name: data.businessName,
              address: {
                street: data.street,
                city: data.city,
                state: data.state,
                country: data.country,
                zip: data.zipCode,
              },
            },
          },
        });

        toast.success('Business account edited!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [setBusy, businessApi, garage?.id, toast, setErrorContent],
  );

  useEffect(() => {
    getBusinesses();
    return () => {
      setBusinessesData([]);
      businessesUnsubscribe?.current?.();
    };
  }, [getBusinesses]);

  return {
    creatBusiness,
    editBusiness,
    garage,
    openModal,
    handleCloseModal,
    handleOpenModal,
    businessesData: output as IBusiness[],
    isLoading,
    modalEditBusiness,
    setPattern,
    businessInfo,
  };
}

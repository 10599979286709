import { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  TextField,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Radio,
  FormControlLabel,
} from '@mui/material';

import { IScheduledRate, ISubscriptionPassOffering } from 'App/api/types';
import { PERIOD, RulesAndExceptions, SelectInput } from 'shared';

type PropsType = {
  title: string;
  busySchedule: boolean;
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  editSubscription: {
    subscriptionData: ISubscriptionPassOffering | IScheduledRate | null;
    setSubscriptionData: React.Dispatch<
      SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
    >;
  };
  handleCloseModal: () => void;
};

export const SubscriptionsForm = ({
  handleCloseModal,
  busySchedule,
  title,
  editRule,
  editSubscription,
}: PropsType) => {
  const {
    handleBlur,
    isValid,
    submitForm,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    initialValues,
  } = useFormikContext<any>();

  return (
    <Form>
      <Typography variant="h4" component="div" mb={5}>
        {title}
      </Typography>
      <Typography variant="h6" component="div" mb={2}>
        Name
      </Typography>
      <Typography variant="body2" mb={2}>
        Set the user-facing name of the subscription pass offering.
      </Typography>
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        name="name"
        type="text"
        value={values.name}
        error={touched?.name && Boolean(errors?.name)}
        helperText={touched?.name && errors?.name}
      />

      <Typography variant="h6" component="div" mt={5} mb={2}>
        Renewal
      </Typography>
      <Typography variant="body2" mb={2}>
        Choose the schedule on which the subscription renews and how many
        parking sessions are allowed during each renewal period.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TextField
          sx={{ mr: 1 }}
          fullWidth
          label="Interval"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          name="interval"
          type="number"
          value={values.interval}
          error={touched?.interval && Boolean(errors?.interval)}
          helperText={touched?.interval && errors?.interval}
        />
        <SelectInput
          onBlur={handleBlur}
          sx={{ width: '100%', ml: 1 }}
          name="period"
          options={PERIOD}
          label="Period"
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" my={3}>
        <FormControlLabel
          control={
            <Radio
              checked={!values.isCustomParkingLimit}
              onChange={() => {
                setFieldValue('isCustomParkingLimit', false);
                setFieldValue('parkLimit', 0);
              }}
              name="isCustomParkingLimit"
            />
          }
          label={
            <Typography variant="body2">Unlimited parks allowed</Typography>
          }
        />
        <FormControlLabel
          control={
            <Radio
              checked={values.isCustomParkingLimit}
              onChange={() => {
                setFieldValue('isCustomParkingLimit', true);
              }}
              name="isCustomParkingLimit"
            />
          }
          label={
            <Typography variant="body2">
              Custom parking session limit
            </Typography>
          }
        />
      </Box>
      <TextField
        fullWidth
        name="parkLimit"
        type="number"
        label="Number of parks"
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={!values.isCustomParkingLimit}
        value={values.parkLimit}
        error={touched?.parkLimit && Boolean(errors?.parkLimit)}
        helperText={touched?.parkLimit && errors?.parkLimit}
      />

      <RulesAndExceptions
        rules={values.validity.rules}
        exceptions={values.validity.exceptions}
        editRule={editRule}
        editSubscription={editSubscription}
      />
      <Divider sx={{ mt: 4, mx: -4 }} />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          paddingTop: '32px',
        }}
      >
        <Button
          sx={{ width: '85px' }}
          size="large"
          variant="contained"
          disabled={!isValid}
          onClick={submitForm}
        >
          {busySchedule ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            'Save'
          )}
        </Button>
        <Button
          sx={{ ml: '12px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={() => {
            handleCloseModal();
            setValues(initialValues);
            editSubscription.setSubscriptionData(null);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

import { Formik } from 'formik';
import { Typography } from '@mui/material';
import { AddRate } from 'shared/validations/rate';
import { RateTablePricing } from 'App/api/types';
import { getStringPerTime, getDollars } from 'shared';
import { EditBaseRateForm } from './form/EditBaseRateForm';
import { useRateTables } from '../../hooks';

type PropType = {
  data: RateTablePricing[] | null;
  closeModal: () => void;
};

export const EditRateModal = ({ data, closeModal }: PropType) => {
  const { saveBaseRate, busy } = useRateTables();

  const initialValues = data && {
    basePrice: getDollars(data[0].priceIncrease),
    pricingRows: getStringPerTime(
      data.filter((item) => item.durationThreshold !== 0),
    ),
  };

  return (
    <>
      <Typography variant="h4" component="h1" mb={3}>
        Edit base rate
      </Typography>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        validationSchema={AddRate}
        onSubmit={(values) => {
          saveBaseRate(values, closeModal);
        }}
      >
        <EditBaseRateForm isBusy={busy} closeModal={closeModal} />
      </Formik>
    </>
  );
};

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { SessionInterface } from './interface';
import { SessionValidator } from './validator';

export const SessionConverter: firebase.firestore.FirestoreDataConverter<SessionInterface> =
  {
    toFirestore(session: SessionInterface): firebase.firestore.DocumentData {
      if (SessionValidator(session)) return session;
      throw new ConverterError(
        'Model validation error.',
        JSON.stringify(SessionValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): SessionInterface {
      const session = snapshot.data();
      if (SessionValidator(session)) return session as SessionInterface;
      throw new ConverterError(
        'Model validation error.',
        JSON.stringify(SessionValidator.errors),
      );
    },
  };

import { IHeadCell } from 'shared';

export interface IAction {
  label: string;
  isActive?: boolean;
}

export const headCellsAccounts: IHeadCell[] = [
  {
    id: 'overrideID',
    label: 'Name',
    numeric: false,
  },
  {
    id: 'garage',
    label: 'Status',
    numeric: true,
  },
  {
    id: 'status',
    label: 'Seats',
    numeric: true,
  },
  {
    id: 'entryTime',
    label: `Subscription`,
    numeric: true,
  },
  {
    id: 'exitTime',
    label: 'Price',
    numeric: true,
  },
  {
    id: 'duration',
    label: 'Actions',
    numeric: true,
  },
];

export const itemsAccount: IAction[] = [
  {
    label: 'Edit account details',
  },
  {
    label: 'Disable account',
    isActive: true,
  },
  {
    label: 'Re-activate account',
    isActive: false,
  },
];

export const itemsMember: IAction[] = [
  {
    label: 'Edit member details',
  },
  {
    label: 'Suspend member',
    isActive: true,
  },
  {
    label: 'Re-activate member',
    isActive: false,
  },
  { label: 'Remove member' },
];

import {
  Box,
  Button,
  Container,
  IconButton,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowLeft, PlusCircle } from 'phosphor-react';
import { Search, useResponsive } from 'shared';

import { CreateAccountModal, RenderMembers } from './components';
import { AccountActions } from './components/AccountActions';
import { AddMemberModal } from './components/AddMemberModal';
import { FilteredMember } from './components/FilteredMember';
import { itemsAccount } from './helpers';
import { useAccount } from './hooks';

export const Account = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const {
    busy,
    name,
    isLoading,
    businesses,
    membersInfo,
    membersData,
    filterMember,
    accountData,
    businessName,
    currentAccount,
    modalEditAccount,
    goToBack,
    setPattern,
    setCurrentAccount,
    handleFilterMember,
    modalAddMember,
    subscriptionPassOfferings,
    createMember,
    editMember,
    refetchMembersData,
  } = useAccount();

  const boxShadow = theme.customShadows.z12;
  const renderMembersInfo = !membersInfo ? (
    <Skeleton variant="rectangular" width={81} height={24} />
  ) : (
    membersInfo
  );

  return (
    <Container>
      <Button onClick={goToBack} startIcon={<ArrowLeft size={32} />}>
        <Typography variant="subtitle1" component="div">
          Back to all accounts
        </Typography>
      </Button>
      <Box sx={{ mt: 1, p: '31px 38px', boxShadow, borderRadius: 2 }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h4" component="h4">
              {name}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {businessName}
            </Typography>
          </Box>
          <AccountActions
            items={itemsAccount}
            editModal={modalEditAccount}
            data={accountData}
            setCurrentData={setCurrentAccount}
          />
        </Box>

        <Box mb={3} mt={4}>
          <FilteredMember
            value={filterMember}
            handleChange={handleFilterMember}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
            mb: isDesktop ? 4 : 2,
          }}
        >
          <Search
            callback={setPattern}
            placeholder="Search by name, phone, or pass name"
          />
          {isDesktop && (
            <Button
              size="large"
              variant="outlined"
              startIcon={<PlusCircle size={24} weight="bold" />}
              onClick={modalAddMember.handleOpenModal}
            >
              <Typography variant="subtitle1">Add member</Typography>
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: isDesktop ? '15px' : '40px',
          }}
        >
          <Typography variant="body1" component="span">
            {renderMembersInfo}
          </Typography>
          {!isDesktop && (
            <IconButton
              onClick={modalAddMember.handleOpenModal}
              type="submit"
              sx={{ p: 1 }}
            >
              <PlusCircle weight="light" size={24} />
            </IconButton>
          )}
        </Box>
        <Box>
          <RenderMembers
            refetchData={refetchMembersData}
            editModal={modalAddMember}
            isLoading={isLoading}
            members={membersData}
            businessName={businessName}
            setCurrentData={setCurrentAccount}
          />
        </Box>
      </Box>

      <CreateAccountModal
        modal={modalEditAccount}
        busy={busy}
        accountData={currentAccount}
        businesses={businesses}
        setCurrentAccount={setCurrentAccount}
      />
      <AddMemberModal
        editMember={editMember}
        busy={busy}
        createMember={createMember}
        memberData={currentAccount}
        setCurrentMember={setCurrentAccount}
        modal={modalAddMember}
        subscriptionPassOfferings={subscriptionPassOfferings}
      />
    </Container>
  );
};

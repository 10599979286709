import { SetStateAction } from 'react';
import { Formik } from 'formik';

import { AccordionGeneral } from 'shared/components';
import PhotosForm from './PhotosForm';
import { TPhoto } from './TPhoto';

type PropsType = {
  photosData: {
    logo: string;
    photos: string[];
  };
  busyPhotos: boolean;
  editPhotos: {
    isEditPhotos: boolean;
    setIsEditPhotos: React.Dispatch<SetStateAction<boolean>>;
  };
  savePhotos: (values: TPhoto) => void;
};

const PHOTOS: TPhoto = {
  logo: '',
  photos: [],
  newPhotos: [],
  deleteLogo: '',
  deletePhotos: [],
};

export const Photos = ({
  photosData,
  busyPhotos,
  savePhotos,
  editPhotos,
}: PropsType): JSX.Element => {
  const { isEditPhotos, setIsEditPhotos } = editPhotos;
  const { logo, photos } = photosData;

  return (
    <AccordionGeneral title="Photos">
      <Formik
        initialValues={{ ...PHOTOS, logo, photos }}
        enableReinitialize
        onSubmit={async (values) => {
          savePhotos(values);
        }}
      >
        <PhotosForm
          busy={busyPhotos}
          isEditPhotos={isEditPhotos}
          setIsEditPhotos={setIsEditPhotos}
        />
      </Formik>
    </AccordionGeneral>
  );
};

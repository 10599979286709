import { useState } from 'react';

export const useProvideError = () => {
  const [errorContent, setErrorContent] = useState({
    errorHeader: '',
    errorBody: '',
    openError: false,
  });

  return {
    errorContent,
    setErrorContent,
  };
};

import { createContext } from 'react';
import { TSubsctiptions, TScheduled } from '../types';

export const RulesAndExeptionsContext = createContext<TSubsctiptions | null>(
  null,
);

export const RuleAndExeptionContext = createContext<TScheduled | null>(null);

type PropsType = {
  children: React.ReactNode;
  value: any;
};

export const RulesAndExeptionsProvider = ({ children, value }: PropsType) => {
  return (
    <RulesAndExeptionsContext.Provider value={value}>
      {children}
    </RulesAndExeptionsContext.Provider>
  );
};

export const RuleAndExeptionProvider = ({ children, value }: PropsType) => {
  return (
    <RuleAndExeptionContext.Provider value={value}>
      {children}
    </RuleAndExeptionContext.Provider>
  );
};

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { drop, pathOr } from 'rambda';
import {
  TFBCollection,
  TFBDocumentSnapshot,
  useApiFunction,
  useBusy,
  useFilteredData,
  useFirebaseApi,
  useGarageData,
  useModal,
  useToast,
  useApi,
  TFBDocument,
  TUnsubscribe,
} from 'shared';
import {
  firstDayOfMonth,
  getCents,
  lastDayOfMonth,
  normalizeCollection,
  normalizeDoc,
} from 'shared/utils';
import {
  IValidation,
  ValidationConverter,
  ValidationInterface,
} from 'App/api/types/Validation';
import {
  BusinessConverter,
  BusinessInterface,
  IBusiness,
} from 'App/api/types/Business';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';
import { useParams } from 'react-router';
import {
  getCountFromServer,
  collection,
  query,
  where,
} from 'firebase/firestore';
import { useFirebase } from 'App/firebase';

export function useValidations() {
  const [validationsData, setValidationsData] = useState<IValidation[]>([]);
  const [sponsors, setSponsors] = useState<IBusiness[]>([]);
  const { setErrorContent } = useErrorHandler();

  const toast = useToast();
  const { employee } = useApi();

  const { busy, setBusy } = useBusy();
  const [isLoading, setIsLoading] = useState(false);
  const { openModal, handleCloseModal, handleOpenModal } = useModal();

  const { locationId } = useParams();
  const { garageId, garage } = useGarageData();
  const { dbQuery } = useFirebaseApi();

  const setOneValidation = useApiFunction();

  const businessesUnsubscribe = useRef<TUnsubscribe | null>(null);
  const validationsUnsubscribe = useRef<TUnsubscribe | null>(null);

  const firebase = useFirebase();

  const canCreateValidation = useMemo(
    () => pathOr(false, 'permissions.createValidation', employee),
    [employee],
  );

  const canEditValidation = useMemo(
    () => pathOr(false, 'permissions.editValidation', employee),
    [employee],
  );

  const createValidation = useCallback(
    async (sponsorId, data, values) => {
      try {
        const type = values.businessCharged ? 'validatedRate' : 'perUse';
        setBusy(true);
        await setOneValidation({
          action: 'garages_validations_create',
          data: {
            garageID: values.garageId,
            validationData: {
              name: values.name,
              description: values.description,
              type: values.type,
              value: data.value,
              isPublic: values.isVisible,
              businessID: sponsorId,
              billToMaster: {
                type: values.isUserPays ? 'none' : type,
                ...(!values.businessCharged && {
                  amount: getCents(+drop(1, values.businessValue)),
                }),
              },
              // businessCharged: values.businessCharged,
              // isUserPays: values.isUserPays,
              // resetTime: values.resetTime,
            },
          },
        });

        toast.success('Validation created!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [setBusy, setErrorContent, setOneValidation, toast],
  );

  const getBusinesses = useCallback(() => {
    businessesUnsubscribe.current = (
      dbQuery(`garages/${locationId}/businesses`) as TFBCollection
    )
      .withConverter(BusinessConverter)
      .onSnapshot(async (docs) => {
        const normalaizeValidations = normalizeCollection(docs);
        setSponsors(normalaizeValidations as IBusiness[]);
      });
  }, [dbQuery, locationId]);

  const { setPattern, output } = useFilteredData<IValidation[]>(
    validationsData,
    ['businessName', 'name'],
  );

  const validationInfo = useMemo(
    () =>
      output.length === 1
        ? `${output.length} validation`
        : `${output.length} validations`,
    [output],
  );

  const getValidations = useCallback(() => {
    setIsLoading(true);
    validationsUnsubscribe.current = (
      dbQuery(`garages/${garageId}/validations`) as TFBCollection
    )
      .where('status', '==', 'active')
      .withConverter(ValidationConverter)
      .onSnapshot(async (docs) => {
        try {
          const normalaizeValidations: ValidationInterface[] =
            normalizeCollection(docs);

          const validationData = await Promise.all(
            normalaizeValidations.map(async (validation) => {
              const businessData = await (
                dbQuery(validation?.business.path) as TFBDocument
              )
                .withConverter(BusinessConverter)
                .get();
              const businessObj: BusinessInterface = normalizeDoc(
                businessData as TFBDocumentSnapshot,
              );
              const validationId = dbQuery(
                `garages/${garageId}/validations/${validation.id}`,
              );
              // Validation count - uses this month
              const garageRef = dbQuery(`garages/${garageId}`);
              const now = new Date();
              const coll = collection(firebase.firestore(), 'sessions');
              const q = query(
                coll,
                where('garage', '==', garageRef),
                where('validation', '==', validationId),
                where('entry.time', '>=', firstDayOfMonth(now)),
                where('entry.time', '<=', lastDayOfMonth(now)),
              );
              const snapshot = await getCountFromServer(q);
              const { count } = snapshot.data();

              return {
                ...validation,
                businessName: businessObj.name,
                businessObj,
                count,
              };
            }),
          );
          setValidationsData(validationData as IValidation[]);
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        } finally {
          setIsLoading(false);
        }
      });
  }, [dbQuery, garageId, toast, firebase]);

  useEffect(() => {
    getValidations();
    getBusinesses();
    return () => {
      setSponsors([]);
      setValidationsData([]);
      businessesUnsubscribe?.current?.();
      validationsUnsubscribe?.current?.();
    };
  }, [getValidations, getBusinesses]);

  return {
    garage,
    validations: output as IValidation[],
    canCreateValidation,
    canEditValidation,
    createValidation,
    handleCloseModal,
    handleOpenModal,
    validationInfo,
    setPattern,
    openModal,
    sponsors,
    busy,
    isLoading,
  };
}

import { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { IGarage } from 'App/api/types/Garage';
import { Formik } from 'formik';
import { CustomizedDialog, IModal } from 'shared';
import { addSession, TSession } from 'shared/validations/addSession';
import { StartSessionForm } from './form';

type PropsType = {
  modalStartSession: IModal;
  garages: IGarage[];
  startSession: (payload: any) => Promise<void>;
  lanes: { value?: string | null; text: string }[];
  setCurrentGarageId: Dispatch<SetStateAction<string | null>>;
  busy: boolean;
};

export const StartSessionModal = ({
  modalStartSession,
  busy,
  garages,
  startSession,
  lanes,
  setCurrentGarageId,
}: PropsType) => {
  const { openModal, handleCloseModal } = modalStartSession;

  const initialValues: TSession = {
    garageId: '',
    garageLaneId: null,
    phone: '',
    time: null,
    date: new Date(),
    reason: '',
    customTime: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addSession}
      onSubmit={async (values) => {
        await startSession(values);
      }}
    >
      {({
        submitForm,
        isValid,
        setValues,
        setTouched,
        initialTouched,
        setErrors,
        initialErrors,
      }) => (
        <CustomizedDialog
          busy={busy}
          submitForm={submitForm}
          open={openModal}
          btnTitle="Submit"
          cleanData={() => {
            setErrors(initialErrors);
            setTouched(initialTouched);
            setValues(initialValues);
          }}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <StartSessionForm
              garages={garages}
              lanes={lanes}
              setCurrentGarageId={setCurrentGarageId}
            />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { ReactNode } from 'react';
import { AuthProvider as Provider, TAuthContext } from './AuthContext';
import useProvideAuth from './UseProvideAuth';

type Props = {
  children: ReactNode;
};

function AuthProvider({ children }: Props): JSX.Element {
  const auth = useProvideAuth();

  return <Provider value={auth as TAuthContext}>{children}</Provider>;
}

export default AuthProvider;

import { useField, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import { TState } from 'shared';

interface Props extends SelectProps {
  name: string;
  label?: string;
  options: TState[];
  disabled?: boolean;
  setInitialValue?: () => void;
  setData?: any;
}

export const SelectInput = (props: Props): JSX.Element => {
  const { name, label, options, sx, disabled, setInitialValue, setData } =
    props;

  const [{ onChange, ...field }, meta] = useField(name);
  const { handleChange } = useFormikContext();
  const { touched, error } = meta;

  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={field.value || ''}
          name={field.name}
          label={label}
          onChange={(e) => {
            handleChange(e);
            if (setData) {
              setData(e.target.value);
            }
            if (setInitialValue) {
              setInitialValue();
            }
          }}
          error={touched && Boolean(error)}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

import { TMask } from './types/masks';

export const days: TMask = {
  type: 'days',
  label: 'Days',
  mask: 'dd',
  blocks: {
    dd: {
      maxLength: 2,
      mask: /^[0-9][0-9]$|^[0-9]$|^99$/,
    },
  },
};

export const hours: TMask = {
  type: 'hours',
  label: 'Hours',
  mask: 'hh',
  blocks: {
    hh: {
      maxLength: 2,
      mask: /^1[0-9]$|^2[0,1-3]$|^0?[1-9]$|^0$/,
    },
  },
};

export const minutes: TMask = {
  type: 'minutes',
  label: 'Minutes',
  mask: 'mm',
  blocks: {
    mm: {
      maxLength: 2,
      mask: /^[0-5][0-9]$|^[0-9]$/,
    },
  },
};

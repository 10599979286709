import * as Yup from 'yup';

export const memberValidation = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  phone: Yup.string().required('Required'),
  subscriptionPassOfferingID: Yup.string().required('Required'),
  spotNumber: Yup.string(),
});

export type TMember = Yup.InferType<typeof memberValidation>;

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as ArrowNegativeResult } from 'App/assets/arrowNegativeResult.svg';
import { ReactComponent as ArrowPositiveResult } from 'App/assets/arrowPositiveResult.svg';

type PropsType = {
  value?: string;
  title: string;
  percent: number;
  image: React.ReactNode;
};

export const StatisticCard = ({ value, title, percent, image }: PropsType) => {
  const theme = useTheme();
  const boxShadow = theme.customShadows.z12;

  return (
    <Box
      sx={{
        p: 3,
        width: '100%',
        boxShadow,
        borderRadius: '10px',
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <Box
        my={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        minHeight="85px"
      >
        <Typography variant="statistic">{value ?? '~'}</Typography>
        {image}
      </Box>
      <Box display="flex" alignItems="center">
        {percent > 0 ? <ArrowPositiveResult /> : <ArrowNegativeResult />}
        <Typography ml={1} variant="body2">
          {percent}% from previous day
        </Typography>
      </Box>
    </Box>
  );
};

import { useState, useMemo, useCallback } from 'react';
import {
  filter,
  is,
  isEmpty,
  values,
  map,
  flatten,
  compose,
  includes,
  join,
  trim,
} from 'rambda';

const checkArr = (data: any) => is(Array, data) && !isEmpty(data);
const toLower = (str: string) => str.toLowerCase();

export const useFilteredData = <T>(data: T, keys: string[] = []) => {
  const [pattern, setPattern] = useState<string>('');

  const filterByPattern = useCallback(
    (item) => {
      const objToArr = (key: string) =>
        is(Object, item[key]) ? values(item[key]) : item[key];

      return compose(
        includes(trim(toLower(pattern))),
        toLower,
        join(' '),
        flatten,
        map(objToArr),
      )(keys);
    },
    [keys, pattern],
  );

  const shouldFilter = useCallback(
    () => pattern.length && checkArr(data) && checkArr(keys),
    [data, pattern, keys],
  );

  const output = useMemo(() => {
    return shouldFilter() && data ? filter(filterByPattern, data) : data;
  }, [shouldFilter, filterByPattern, data]);

  return { pattern, setPattern, output };
};

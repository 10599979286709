import moment from 'moment-timezone';
import firebase from 'firebase/compat/app';
import { ITimeStamp } from 'App/api/types';
import { MMMM_DD_YYYY, TIME } from 'shared/constants/time';

export const formatTime = (date: number, format: string = TIME) =>
  moment(date).format(format);

export const getMillisFromMinutes = (value: string) => Number(value) * 60000;

export const getTimeStamp = (date: Date) => {
  const timeStamp = firebase.firestore.Timestamp.fromMillis(date.valueOf());
  return timeStamp;
};

export const getMinutesFromGracePeriod = (milliseconds: number) => {
  if (!milliseconds) return '0';
  let time = milliseconds;

  const minutes = Math.floor(time / (1000 * 60));
  time %= 1000 * 60;

  return `${minutes}`;
};

export const getMilisFromString = (str: string, format = 'HH : mm') =>
  moment(str, format).diff(moment().startOf('day'), 'seconds') * 1000;

export const getFormatedTime = (val: number) => moment.utc(val).valueOf();

export const getTimeOfMaxParkTimeRoster = (milliseconds: number | null) => {
  if (!milliseconds) return { days: null, hours: null, minutes: null };
  let time = milliseconds;
  let result = '';

  const days = Math.floor(time / (1000 * 60 * 60 * 24)) || null;
  time %= 1000 * 60 * 60 * 24;
  result += days ? `${days}days ` : '';

  const hours = Math.floor(time / (1000 * 60 * 60)) || null;
  time %= 1000 * 60 * 60;
  result += hours ? `${hours}hrs ` : '';

  const minutes = Math.floor(time / (1000 * 60)) || null;
  time %= 1000 * 60;
  result += minutes ? `${minutes}mins` : '';

  return { result, days, hours, minutes };
};

export const getMillisecondsOfPeriod = (period: string) => {
  if (period.length > 2) {
    const periodArr = period.split(':');
    const getMilisOfDay = periodArr[0] ? Number(periodArr[0]) * 86400000 : 0;
    const getMilisOfHours = periodArr[1] ? Number(periodArr[1]) * 3600000 : 0;
    const getMilisOfMinutes = periodArr[2] ? Number(periodArr[2]) * 60000 : 0;

    return getMilisOfDay + getMilisOfHours + getMilisOfMinutes;
  }
  return +period * 86400000;
};

export const timeBasedConvert = (value: number) => {
  const minutes = Math.floor((value / 1000 / 60) % 60);
  const hours = Math.floor((value / 1000 / 60 / 60) % 24);
  const days = Math.floor(value / 1000 / 60 / 60 / 24);

  return [
    days.toString().padStart(2, '0'),
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
  ].join(':');
};

export const getPassTimeFromTimeStamp = (time: ITimeStamp) =>
  time ? formatTime(time.toMillis(), MMMM_DD_YYYY) : 'Unlimited';

export const getTimeCompleteSession = (date: Date, time?: Date | null) =>
  moment(
    `${formatTime(date.valueOf(), MMMM_DD_YYYY)} ${formatTime(
      time ? time.valueOf() : new Date().valueOf(),
    )}`,
  ).valueOf();

export const firstDayOfMonth = (timestamp: Date) =>
  new Date(timestamp.getFullYear(), timestamp.getMonth(), 1);

export const lastDayOfMonth = (timestamp: Date) =>
  new Date(timestamp.getFullYear(), timestamp.getMonth() + 1, 0);

import { ValidationInterface } from 'App/api/types/Validation';
import { IMask } from 'react-imask';
import { v4 as uuidv4 } from 'uuid';
import {
  getMillisecondsOfPeriod,
  getTimeOfMaxParkTimeRoster,
  timeBasedConvert,
} from '../dates';
import { priceMask } from '../masks';
import { TMask } from '../masks/types';
import { getCents, getDollars } from '../pricing';

export const VALIDATION_OF_TYPE = [
  { text: 'Resets at specific reset time', value: 'specificTime' },
  { text: 'Reset 24 hours from the time of entry', value: 'hours' },
  { text: 'Doesnt reset', value: 'noReset' },
];

export const TYPE_OF_VALIDATION = [
  { id: uuidv4(), text: 'Time-based discount', value: 'timeBased' },
  { id: uuidv4(), text: 'Percentage discount', value: 'percent' },
  { id: uuidv4(), text: 'Dollar amount discount', value: 'fixed' },
  { id: uuidv4(), text: 'Flat fee rate', value: 'flatFee' },
  { id: uuidv4(), text: 'Capped rate', value: 'capped' },
];

export const TYPE_OF_SCHEDULE = [
  { id: uuidv4(), text: 'Resets at specific reset time', value: 'fixedTime' },
  {
    id: uuidv4(),
    text: 'Resets 24 hours from the time of entry',
    value: 'everyDay',
  },
  { id: uuidv4(), text: 'Doesn’t reset', value: 'none' },
];

const VALIDATION_TYPE_MASK_SCHEDULE = {
  label: 'Reset time',
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
    },

    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
    },
  },
};

const VALIDATION_TYPE_MASK: TMask[] = [
  {
    type: 'timeBased',
    label: 'day(99):hours(23):minutes(59)',
    mask: 'DD:HH:MM',
    blocks: {
      DD: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
      },

      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
      },

      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
  },
  priceMask,
  {
    type: 'flatFee',
    label: 'Amount',
    mask: '$DD.CC',
    blocks: {
      DD: {
        maxLength: 4,
        mask: /^[0-9]+$/,
      },
      CC: {
        maxLength: 2,
        mask: /^[0-9][0-9]$|^[0-9]$/,
      },
    },
  },
  {
    type: 'capped',
    label: 'Amount',
    mask: '$DD.CC',
    blocks: {
      DD: {
        maxLength: 4,
        mask: /^[0-9]+$/,
      },
      CC: {
        maxLength: 2,
        mask: /^[0-9][0-9]$|^[0-9]$/,
      },
    },
  },
  {
    type: 'percent',
    label: '%100',
    mask: '%NNN',
    blocks: {
      NNN: {
        mask: /^(\d|[1-9]\d|100)$/,
        from: 0,
        to: 100,
        maxLength: 3,
      },
    },
  },
];

const VALIDATE_PRIORITY: TMask[] = [
  {
    type: 'priority',
    label: '0',
    mask: 'NNN',
    blocks: {
      NN: {
        mask: /^(\d|[1-9]\d|100)$/,
        from: 0,
        to: 999,
        maxLength: 3,
      },
    },
  },
];

export const getMask = (type: string) => {
  const result = VALIDATION_TYPE_MASK.find((item) => item.type === type);
  return result;
};

export const maskScheduled = () => {
  return VALIDATION_TYPE_MASK_SCHEDULE;
};
export const getMaskPriority = (type: string) => {
  const result = VALIDATE_PRIORITY.find((item) => item.type === type);
  return result;
};

export const getDiscount = (
  type: ValidationInterface['type'],
  value: ValidationInterface['value'],
) =>
  (type === 'timeBased' &&
    value &&
    `${getTimeOfMaxParkTimeRoster(value)?.result} free`) ||
  (type === 'fixed' && value && `$${getDollars(value)}`) ||
  (type === 'percent' && `${value}% off`) ||
  (type === 'flatFee' && value && `$${getDollars(value)} per day`) ||
  (type === 'capped' && value && `$${getDollars(value)} max per day`);

export const conversionValue = (type: string, value: any) =>
  (type === 'timeBased' && getMillisecondsOfPeriod(value)) ||
  (type === 'fixed' && getCents(Number(value.slice(1)))) ||
  (type === 'percent' && Number(value.slice(1))) ||
  (type === 'flatFee' && getCents(Number(value.slice(1)))) ||
  (type === 'capped' && getCents(Number(value.slice(1)))) ||
  value;

export const reverceConversionValue = (type: string, value: number) =>
  (type === 'timeBased' && timeBasedConvert(value)) ||
  (type === 'fixed' && `$${getDollars(value)}`) ||
  (type === 'percent' && `${value}`) ||
  (type === 'flatFee' && `$${getDollars(value)}`) ||
  (type === 'capped' && `$${getDollars(value)}`) ||
  value;

import { Form, useFormikContext } from 'formik';
import { Box, TextField, Typography } from '@mui/material';

import { SelectInput } from 'shared/controls';
import { states } from 'shared';

export const AddBusinessesForm = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<any>();

  return (
    <Form>
      <Box p={3}>
        <Typography variant="h4" component="h1" mb={3}>
          {values.businessName ? 'Edit Business' : 'New Business'}
        </Typography>
        <Typography variant="h6" component="h1" mb={3}>
          Name
        </Typography>
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          type="text"
          label="Business name"
          name="businessName"
          value={values.businessName}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.businessName && Boolean(errors.businessName)}
          helperText={touched.businessName && errors.businessName}
        />
        <Typography variant="h6" component="h1" mb={3}>
          Address
        </Typography>
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          type="text"
          label="Street"
          name="street"
          value={values.street}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.street && Boolean(errors.street)}
          helperText={touched.street && errors.street}
        />
        <TextField
          fullWidth
          sx={{ mb: 3 }}
          type="text"
          label="City"
          name="city"
          value={values.city}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city && errors.city}
        />

        <SelectInput
          sx={{ mb: 3 }}
          onBlur={handleBlur}
          name="state"
          options={states}
          label="Select state"
        />

        <TextField
          fullWidth
          sx={{ mb: 3 }}
          type="text"
          label="Country"
          name="country"
          value={values.country}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.country && Boolean(errors.country)}
          helperText={touched.country && errors.country}
        />
        <TextField
          fullWidth
          type="text"
          label="Zip code"
          name="zipCode"
          value={values.zipCode}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.zipCode && Boolean(errors.zipCode)}
          helperText={touched.zipCode && errors.zipCode}
        />
      </Box>
    </Form>
  );
};

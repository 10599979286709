import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

import { generateRoutePath, RouteName } from 'App/helpers';
import { useResetPassword } from '../hooks';
import { EyeComponent } from '../../EyeComponent';

export function ChangePassword({ code }: { code: string }) {
  const {
    resetPassword,
    validationSchemaPasswords,
    showPassword,
    handleShowPassword,
  } = useResetPassword();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchemaPasswords,
    onSubmit: async ({ password }, { setSubmitting }) => {
      await resetPassword(code, password);
      setSubmitting(false);
    },
  });

  const isPasswordOrText = showPassword ? 'text' : 'password';

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" component="h1" fontSize="large" mb={3}>
        Please enter a new password.
      </Typography>
      <TextField
        sx={{ mb: 3 }}
        fullWidth
        id="password"
        type={isPasswordOrText}
        name="password"
        label="New Password"
        onBlur={formik.handleBlur}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <EyeComponent
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          ),
        }}
      />
      <TextField
        sx={{ mb: 3 }}
        fullWidth
        type={isPasswordOrText}
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm New Password"
        onBlur={formik.handleBlur}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        InputProps={{
          endAdornment: (
            <EyeComponent
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          ),
        }}
      />
      <Button
        size="large"
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={!formik.isValid}
      >
        Change Password
      </Button>
      <Box textAlign="center" mt={2}>
        <Link
          style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
          to={generateRoutePath({ name: RouteName.SignIn })}
        >
          Back to login
        </Link>
      </Box>
    </form>
  );
}

import { Form, useFormikContext, FieldArray } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { MaskInput } from 'shared/controls/maskInput';
import { TRate } from 'shared/validations/rate';
import { priceMask } from 'shared/utils/masks/price';
import { PricePerTime } from '../PricePerTime';

type PropsType = {
  isBusy: boolean;
  closeModal: () => void;
};

export const pricingRow = {
  price: '',
  time: '',
};

export const EditBaseRateForm = ({ isBusy, closeModal }: PropsType) => {
  const { isValid, submitForm, values } = useFormikContext<TRate>();

  return (
    <Form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" component="div" fontWeight={700} mb={2}>
          Step Ladder
        </Typography>
        <Typography variant="body2" component="div" mb={1}>
          Select the times at which the price increases
        </Typography>
        <MaskInput
          type="text"
          name="basePrice"
          mask={priceMask.mask}
          label="Initial Amount"
          blocks={priceMask.blocks}
          placeholder=""
        />
        <FieldArray
          name="pricingRows"
          render={({ push, remove }) => (
            <>
              {values.pricingRows &&
                values.pricingRows.map((_, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    mt={2}
                  >
                    <PricePerTime removeBox={remove} index={index} />
                  </Box>
                ))}
              <Button
                sx={{ width: '150px', mt: 2 }}
                variant="contained"
                onClick={() =>
                  push({
                    ...pricingRow,
                  })
                }
              >
                Add Row
              </Button>
            </>
          )}
        />
      </Box>
      <Divider sx={{ mt: 5, mx: -4 }} />
      <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={2}>
        <Button
          sx={{ width: '85px', mr: 1 }}
          size="large"
          variant="contained"
          disabled={!isValid || isBusy}
          onClick={submitForm}
        >
          {isBusy ? <CircularProgress size={20} color="inherit" /> : 'Save'}
        </Button>
        <Button
          sx={{ mr: '10px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

import { createContext } from 'react';

type Context = {
  busy: boolean;
  setBusy: (b: boolean) => void;
};

const BusyContext = createContext<Context>({
  busy: false,
  setBusy: () => {},
});

export const BusyProvider = BusyContext.Provider;
export default BusyContext;

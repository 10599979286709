import { Typography } from '@mui/material';

type Props = {
  label: string;
  color: string;
  bgcolor: string;
  capitalize?: boolean;
};

export const Badge = ({
  label,
  color,
  bgcolor,
  capitalize,
}: Props): JSX.Element => {
  return (
    <Typography
      variant="caption"
      fontWeight={700}
      color={color}
      component="span"
      bgcolor={bgcolor}
      sx={{
        ...(capitalize && { textTransform: 'capitalize' }),
        display: 'inline-block',
        borderRadius: '6px',
        px: '8px',
        py: '1px',
      }}
    >
      {label}
    </Typography>
  );
};

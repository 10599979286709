export const PERIOD = [
  {
    text: 'Days',
    value: 'days',
  },
  {
    text: 'Weeks',
    value: 'weeks',
  },
  {
    text: 'Months',
    value: 'months',
  },
  {
    text: 'Years',
    value: 'years',
  },
];

export const RULE = {
  startDate: new Date(),
  enterAfter: null,
  enterBefore: null,
  enterAfterOffset: null,
  enterBeforeOffset: null,
  exitAfter: null,
  exitBefore: null,
  exitAfterOffset: null,
  exitBeforeOffset: null,
  maxParkingTime: {
    days: null,
    hours: null,
    minutes: null,
  },
  minParkingTime: {
    days: null,
    hours: null,
    minutes: null,
  },
  recurrenceData: {
    frequencyData: {
      freq: 'NEVER',
    },
    recurrenceOption: {
      changeMonthField: 'BYMONTHDAY',
      typeEndRecurrence: 'NEVER',
      interval: undefined,
      count: null,
      byweekday: null,
      bymonthday: null,
      bymonth: null,
      bymonthPos: null,
      bysetpos: null,
      until: null,
    },
  },
  recurrence: '',
};

export const SUBSCRIPTION = {
  name: '',
  interval: '',
  period: '',
  amount: '',
  parkLimit: '',
  isCustomParkingLimit: true,
  validity: {
    rules: [],
    exceptions: [],
  },
  newRule: RULE,
};

export const SUCHEDUAL = {
  priority: '',
  name: '',
  type: '',
  amount: '',
  isOverPopulationGroup: false,
  resetLogic: { type: '', value: '' },
  validity: {
    rules: [],
    exceptions: [],
  },
  newRule: {
    ...RULE,
    partiallyApplicable: { enter: true, exit: true },
  },
};

import { useCallback, useMemo, useState } from 'react';
import { pathOr } from 'rambda';
import {
  useApi,
  useApiFunction,
  useBusy,
  useGarageData,
  useToast,
} from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

export const useOverview = () => {
  const toast = useToast();
  const garageData = useGarageData();
  const { employee } = useApi();
  const { busy: busyBasicDetails, setBusy: setBusyBasicDetails } = useBusy();
  const { busy: busyLocation, setBusy: setBusyLocation } = useBusy();

  const { garageId, garage } = garageData;
  const { coordinate, address, status, name } = garage ?? {};

  const [isEditBasicDetails, setIsEditBasicDetails] = useState<boolean>(true);
  const [isEditLocation, setIsEditLocation] = useState<boolean>(true);

  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const saveBasicDetails = useCallback(
    async (payload) => {
      try {
        setBusyBasicDetails(true);
        await apiFn({
          action: 'garages_edit_details',
          data: {
            garageId,
            details: payload,
          },
        });
        setBusyBasicDetails(false);
        setIsEditBasicDetails(true);
        toast.success('Basic Details updated!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
        if (e instanceof Error) {
          setBusyBasicDetails(false);
          setIsEditBasicDetails(true);
        }
      }
    },
    [apiFn, garageId, setBusyBasicDetails, setErrorContent, toast],
  );

  const saveLocation = useCallback(async () => {
    try {
      setBusyLocation(true);
      // await saveGarage({
      //   ...payload,
      //   address: {
      //     ...payload.address,
      //   },
      //   coordinate: {
      //     latitude: payload.coordinate.lat,
      //     longitude: payload.coordinate.long,
      //   },
      // });
    } catch (e) {
      if (e instanceof Error) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      }
    } finally {
      toast.success('Location updated!');
      setBusyLocation(false);
      setIsEditLocation(true);
    }
  }, [setBusyLocation, toast, setErrorContent]);

  const canEdit = useMemo(
    () => pathOr(false, 'permissions.garages', employee),
    [employee],
  );

  const checkPermission = useCallback(
    (cb) => (canEdit ? cb : () => toast.error('Insufficient permissions.')),
    [canEdit, toast],
  );

  return {
    garageData,
    garageId,
    garage,
    saveLocation,
    locationData: { coordinate, address },
    editLocation: { setIsEditLocation, isEditLocation },
    busyLocation,
    saveBasicDetails,
    basicDetailsData: { status, name },
    busyBasicDetails,
    editBasicDetails: { isEditBasicDetails, setIsEditBasicDetails },
    checkPermission,
  };
};

import { useState, useCallback, useMemo, useRef } from 'react';
import { normalizeCollection } from 'shared/utils';
import {
  TDocumentData,
  TFBCollection,
  TFDocumentRef,
  useBusy,
  useFirebaseApi,
  useToast,
  useAuth,
  getPhotosGarage,
  TFBQuerySnapshot,
  TUnsubscribe,
} from 'shared';
// import { GarageConverter } from './types';
import { IGarage } from './types/Garage';

function useGarages() {
  const { dbQuery } = useFirebaseApi();
  const { setBusy } = useBusy();
  const { user } = useAuth();
  const toast = useToast();

  const [garages, setGarages] = useState<IGarage[] | null>(null);
  const [garagesRef, setGaragesRef] = useState<TFDocumentRef[]>([]);

  const garageUnsubscribe = useRef<TUnsubscribe | null>(null);

  const authorizedGarages = useMemo(
    () => user && user?.authorizedGarages?.map((garage) => garage.id),
    [user],
  );

  const getGarages = useCallback(async () => {
    try {
      setBusy(true);

      garageUnsubscribe.current = (dbQuery('garages') as TFBCollection)
        // .withConverter(GarageConverter)
        .onSnapshot(async (resGarages) => {
          const operatorGarages = resGarages.docs.filter((d) =>
            authorizedGarages?.includes(d.id),
          );
          setGaragesRef(
            operatorGarages.map((element: TDocumentData) => element.ref),
          );
          const normalizeGragesData = normalizeCollection(
            operatorGarages as unknown as TFBQuerySnapshot,
          );
          const garagesPhoto = await Promise.all(
            normalizeGragesData.map((garage) =>
              getPhotosGarage(garage.id, 'garages'),
            ),
          );
          const operatorGaragesWithPhoto = normalizeGragesData.map(
            (garage, i) => ({
              ...garage,
              photos: garagesPhoto[i].length ? garagesPhoto[i] : null,
            }),
          );

          setGarages(operatorGaragesWithPhoto as IGarage[]);
        });
    } catch (e) {
      setGarages([]);
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      setBusy(false);
    }
  }, [authorizedGarages, dbQuery, setBusy, toast]);

  return {
    garages,
    garagesRef,
    fetchGarages: getGarages,
    garageUnsubscribe,
  };
}

export default useGarages;

import { SetStateAction } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Chip,
} from '@mui/material';
import { CurrencyCircleDollar, PencilSimple } from 'phosphor-react';
import {
  conversionValue,
  getDiscount,
} from 'shared/utils/data_tables/validations';
import pelette from 'App/theme/palette';
import { IScheduledRate } from 'App/api/types/GarageScheduledRate';

type Props = {
  scheduleRateData: IScheduledRate;
  handleOpenModal: () => void;
  setScheduleRateData: React.Dispatch<SetStateAction<IScheduledRate | null>>;
};

export const ScheduledCard = ({
  scheduleRateData,
  handleOpenModal,
  setScheduleRateData,
}: Props) => {
  const { name, type, amount, priority } = scheduleRateData;
  return (
    <Card
      sx={{
        border: '1px solid black',
        boxShadow: 'none',
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          />
        </Box>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div" noWrap>
            {name}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => {
              setScheduleRateData(scheduleRateData);
              handleOpenModal();
            }}
          >
            <PencilSimple size={18} />
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            color={pelette.grey[600]}
          >
            <CurrencyCircleDollar />
            <Typography variant="subtitle1" component="div" ml={0.5}>
              {type ? getDiscount(type, conversionValue(type, amount)) : ''}
            </Typography>
          </Box>
          <Chip
            sx={{ px: 2, height: 26, borderRadius: 1 }}
            color="primary"
            label={priority}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

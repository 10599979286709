import { Formik } from 'formik';
import { IBusiness } from 'App/api/types/Business';
import { CustomizedDialog } from 'shared/components';
import { IAccount } from 'App/api/types/GarageAccount';
import { IModal } from 'shared';
import { accountValidation } from 'shared/validations/account';
import { CreateAccountForm } from './forms';
import { useAccountActions } from '../hooks';

type PropsType = {
  busy: boolean;
  modal: IModal;
  accountData: IAccount | null;
  setCurrentAccount: React.Dispatch<React.SetStateAction<IAccount | null>>;
  businesses: IBusiness[];
};

export const CreateAccountModal = ({
  modal,
  busy,
  accountData,
  setCurrentAccount,
  businesses,
}: PropsType) => {
  const title = accountData ? 'Edit account' : 'Create account';
  const { createAccount, editAccount } = useAccountActions();

  return (
    <Formik
      initialValues={{
        name: accountData?.name || '',
        businessId: accountData?.business?.id || '',
      }}
      enableReinitialize
      validationSchema={accountValidation}
      onSubmit={async (value) => {
        const { name, businessId } = value;

        if (accountData) {
          await editAccount(name, businessId, accountData.id);
          modal.handleCloseModal();
        } else {
          await createAccount(name, businessId);
          modal.handleCloseModal();
        }
      }}
    >
      {({ setValues, initialValues, submitForm, isValid }) => (
        <>
          <CustomizedDialog
            open={modal.openModal}
            handleClose={modal.handleCloseModal}
            cleanData={() => {
              setCurrentAccount(null);
              setValues(initialValues);
            }}
            submitForm={submitForm}
            busy={busy}
            isValid={isValid}
          >
            <CreateAccountForm title={title} businesses={businesses} />
          </CustomizedDialog>
        </>
      )}
    </Formik>
  );
};

import { alpha } from '@mui/material/styles';

declare module '@mui/material' {
  interface Color {
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

const PRIMARY = {
  lighter: '#B2B2B2',
  light: '#666666',
  main: '#000000',
  dark: '#666666',
  darker: '#B2B2B2',
};
const SECONDARY = {
  lighter: '#E2DFFF',
  light: '#B9B2FF',
  main: '#7A6EFF',
  dark: '#574AE1',
  darker: '#2D21B4',
};
const INFO = {
  lighter: '#D8F1FE',
  light: '#8AC9FC',
  main: '#3D93F8',
  dark: '#1E55B2',
  darker: '#0B2977',
};
const SUCCESS = {
  lighter: '#EEFCE5',
  light: '#BCF1AD',
  main: '#74D16F',
  dark: '#379642',
  darker: '#15642B',
};
const WARNING = {
  lighter: '#FEF9D9',
  light: '#FCE68E',
  main: '#F5C944',
  dark: '#B08522',
  darker: '#75500D',
};
const ERROR = {
  lighter: '#FFE3DA',
  light: '#FF9891',
  main: '#FF485E',
  dark: '#B72451',
  darker: '#7A0D42',
};

const GREY = {
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const CHART_COLORS = { green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'] };

const pelette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  background: { paper: '#fff', default: '#fff', neutral: GREY[100] },
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    selectedOpacity: 0.08,
    disabledOpacity: 0.48,
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};

export default pelette;

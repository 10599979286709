import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getRuleFormAutocomplete,
  normalizeCollection,
  TFBCollection,
  useFirebaseApi,
  useGarageData,
  useModal,
} from 'shared';
import {
  GarageSubscriptionPassOfferingConverter,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { TSubsctiptions } from '../types';

export const useSubscriptions = (): TSubsctiptions => {
  const { dbQuery } = useFirebaseApi();
  const { garageId, timezone } = useGarageData();
  const modalSubscriptions = useModal();
  const modalRule = useModal();
  const modalException = useModal();

  const [subscriptions, setSubscriptions] = useState(null);
  const [subscriptionData, setSubscriptionData] =
    useState<ISubscriptionPassOffering | null>(null);
  const [ruleData, setRuleData] = useState<any>(null);

  useEffect(() => {
    let unsubscribe: () => void;
    if (garageId) {
      unsubscribe = (
        dbQuery(
          `garages/${garageId}/subscriptionPassOfferings`,
        ) as TFBCollection
      )
        .withConverter(GarageSubscriptionPassOfferingConverter)
        .onSnapshot((docs) => {
          const normalaizeData =
            normalizeCollection<ISubscriptionPassOffering>(docs);
          const subscriptionsDataNormalaize = normalaizeData.map(
            (subscription) => {
              return {
                ...subscription,
                validity: {
                  rules:
                    subscription?.validity?.rules &&
                    subscription.validity.rules.map((rule: any) => {
                      return { ...rule, id: uuidv4() };
                    }),
                  exceptions:
                    subscription?.validity?.exceptions &&
                    subscription.validity.exceptions.map((exception: any) => {
                      return { ...exception, id: uuidv4() };
                    }),
                },
              };
            },
          );
          setSubscriptions(subscriptionsDataNormalaize as any);
          return () => {
            unsubscribe();
          };
        });
    }

    return () => {
      setSubscriptions(null);
      unsubscribe();
    };
  }, [dbQuery, garageId]);

  return {
    subscriptions,
    modalSubscriptions,
    timezone,
    editSubscription: { subscriptionData, setSubscriptionData },
    modalRule,
    editRule: {
      ruleData:
        ruleData && (getRuleFormAutocomplete(ruleData) as unknown as any),
      setRuleData,
    },
    modalException,
  };
};

import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';

import { IAccount } from 'App/api/types/GarageAccount';
import { IValidation } from 'App/api/types/Validation';
import {
  normalizeCollection,
  TFBCollection,
  TFBQuerySnapshot,
  useFirebaseApi,
  useGarageData,
} from 'shared';
import { IBusiness } from 'App/api/types/Business';
import { ISubscriptionPassOffering } from 'App/api/types';

function useLocation() {
  const { garageId } = useGarageData();
  const { dbQuery } = useFirebaseApi();

  const [validations, setValidations] = useState<IValidation[] | null>(null);
  const [accounts, setAccounts] = useState<IAccount[] | null>(null);
  const [businesses, setBusinesses] = useState<IBusiness[] | null>(null);
  const [passes, setPasses] = useState<ISubscriptionPassOffering[] | null>(
    null,
  );

  const getValidations = () => {
    const refValidations = dbQuery(
      `garages/${garageId}/validations`,
    ) as TFBCollection;
    return onSnapshot(refValidations, (res) => {
      const normalizeUsersData = normalizeCollection<IValidation>(
        res as unknown as TFBQuerySnapshot,
      );
      setValidations(normalizeUsersData);
    });
  };
  const getAccounts = () => {
    const refAccounts = dbQuery(
      `garages/${garageId}/accounts`,
    ) as TFBCollection;
    return onSnapshot(refAccounts, (res) => {
      const normalizeUsersData = normalizeCollection<IAccount>(
        res as unknown as TFBQuerySnapshot,
      );
      setAccounts(normalizeUsersData);
    });
  };

  const getBusinesses = () => {
    const refBusinesses = dbQuery(
      `garages/${garageId}/businesses`,
    ) as TFBCollection;
    return onSnapshot(refBusinesses, (res) => {
      const normalizeUsersData = normalizeCollection<IBusiness>(
        res as unknown as TFBQuerySnapshot,
      );
      setBusinesses(normalizeUsersData);
    });
  };

  const getPasses = () => {
    const refPasses = dbQuery(
      `garages/${garageId}/subscriptionPassOfferings`,
    ) as TFBCollection;
    return onSnapshot(refPasses, (res) => {
      const normalizeUsersData = normalizeCollection<ISubscriptionPassOffering>(
        res as unknown as TFBQuerySnapshot,
      );
      setPasses(normalizeUsersData);
    });
  };

  useEffect(() => {
    getValidations();
    getAccounts();
    getBusinesses();
    getPasses();

    return () => {
      getValidations();
      getAccounts();
      getBusinesses();
      getPasses();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [garageId]);

  return { validations, accounts, businesses, passes };
}

export default useLocation;

/* eslint-disable react/jsx-props-no-spreading */
import React, { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { SelectInput } from 'shared/controls';
import { TSettingsGarage } from 'shared/validations/settingsGarage';
import { ButtonLocation } from 'shared/components/ButtonLocation';
import { optionsGracePeriod } from '../../constants/settingsGarage';

type PropsType = {
  busy: boolean;
  isEditSettings: boolean;
  setIsEditSettings: React.Dispatch<SetStateAction<boolean>>;
};

export const SettingsForm = ({
  busy,
  isEditSettings,
  setIsEditSettings,
}: PropsType): JSX.Element => {
  const {
    isValid,
    submitForm,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    initialValues,
  } = useFormikContext<TSettingsGarage>();

  return (
    <Form>
      <Typography variant="h6" component="div" mb={2}>
        Grace Period
      </Typography>
      <Typography variant="body1" component="div" mb={2}>
        Set the amount of time that users can park at no cost.
      </Typography>
      <SelectInput
        onBlur={handleBlur}
        name="gracePeriod.value"
        label="Grace Period"
        disabled={isEditSettings}
        options={optionsGracePeriod}
      />
      <Typography variant="h6" component="div" mt={5} mb={2}>
        Reset Time
      </Typography>
      <Typography variant="body1" component="div" mb={2}>
        Select whether the rate table restarts 24 hours from the time of entry
        or at a specific time every day.
      </Typography>
      <FormControlLabel
        sx={{ mb: 2 }}
        label={<Typography variant="body1">No Reset Time</Typography>}
        disabled={isEditSettings}
        control={
          <Checkbox
            checked={values.isNoResetTime}
            onChange={handleChange}
            name="isNoResetTime"
          />
        }
      />
      <Box width="100%">
        <TimePicker
          label="Reset Time"
          value={values.isNoResetTime ? null : values.resetTime}
          disabled={isEditSettings || values.isNoResetTime}
          onChange={(value) => setFieldValue('resetTime', value)}
          renderInput={(params) => (
            <TextField onBlur={handleBlur} fullWidth {...params} />
          )}
          shouldDisableTime={(timeValue, clockType) => {
            return clockType === 'minutes' && !Number.isInteger(timeValue / 5);
          }}
        />
      </Box>

      <ButtonLocation
        isBusy={busy}
        setIsEdit={setIsEditSettings}
        isEdit={isEditSettings}
        isValid={isValid}
        setInicialValues={() => setValues(initialValues)}
        onSubmit={submitForm}
      />
    </Form>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import { XCircle } from 'phosphor-react';
import { MaskInput } from 'shared/controls';
import { days, hours, minutes } from 'shared/utils/masks';
import { ISchedulesModal } from '../../types';

type PropsType = {
  type: 'max' | 'min';
  title: string;
};

type TDuration = 'maxParkingTime' | 'minParkingTime';

export const Duration = ({ title, type }: PropsType) => {
  const { setFieldValue, initialValues } = useFormikContext<ISchedulesModal>();

  const typeDuration: TDuration = `${type}ParkingTime`;

  return (
    <>
      <Typography variant="h6" component="div" mt={5} mb={2}>
        {title}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <MaskInput
          type="number"
          name={`newRule.${typeDuration}.days`}
          mask={days.mask}
          label={days.label}
          placeholder="99 days"
          blocks={days.blocks}
        />
        <MaskInput
          type="number"
          sx={{ ml: 3 }}
          name={`newRule.${typeDuration}.hours`}
          mask={hours.mask}
          label={hours.label}
          placeholder="23 hours"
          blocks={hours.blocks}
        />
        <MaskInput
          type="number"
          sx={{ ml: 3 }}
          name={`newRule.${typeDuration}.minutes`}
          mask={minutes.mask}
          label={minutes.label}
          placeholder="59 minutes"
          blocks={minutes.blocks}
        />
        <IconButton
          onClick={() => {
            setFieldValue(
              `newRule.${typeDuration}`,
              initialValues.newRule[typeDuration],
            );
          }}
          color="default"
        >
          <XCircle width={28} height={28} />
        </IconButton>
      </Box>
    </>
  );
};

import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { generateRoutePath, RouteName } from 'App/routes/helpers';
import { useLogin } from './hooks';
import { EyeComponent } from '../EyeComponent';

export function Login() {
  const theme = useTheme();
  const {
    signin,
    validationSchema,
    busy,
    setBusy,
    handleShowPassword,
    showPassword,
  } = useLogin();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const { email, password } = values;
      setBusy(true);
      await signin(email, password);
      setBusy(false);
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" component="h1" fontSize="large" mb={1}>
        Sign in
      </Typography>
      <Typography variant="body2" component="div" fontSize="large" mb={3}>
        Enter your details below
      </Typography>
      <TextField
        sx={{ mb: 2 }}
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        sx={{ mb: 2 }}
        fullWidth
        id="password"
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <EyeComponent
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          ),
        }}
      />
      <Typography fontSize="medium" mb={1}>
        <Link
          style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
          to={generateRoutePath({ name: RouteName.ResetPass })}
        >
          Forgot password?
        </Link>
      </Typography>

      <Button
        size="large"
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        {busy ? <CircularProgress size={20} color="inherit" /> : 'Sign In'}
      </Button>
    </form>
  );
}

import { useState, useEffect, useMemo, useCallback } from 'react';
import { FunctionsError } from 'firebase/functions';
import moment from 'moment';

import {
  useApiFunction,
  useModal,
  useResponsive,
  useBusy,
  useToast,
  TAddress,
  useApi,
  useFilteredData,
} from 'shared';
import { IGarage } from 'App/api/types/Garage';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';

export function useLocations() {
  const isDesktop = useResponsive('up', 'sm');
  const isMobile = useResponsive('down', 788);

  const [locations, setLocations] = useState<IGarage[] | null>(null);
  const { busy, setBusy } = useBusy();
  const { garages, fetchGarages } = useApi();
  const toast = useToast();
  const { openModal, handleOpenModal, handleCloseModal } = useModal();

  const { setErrorContent } = useErrorHandler();
  const setNewGarage = useApiFunction();

  const { setPattern, output } = useFilteredData<IGarage[] | null>(locations, [
    'name',
    'address',
  ]);

  const locationsInfo = useMemo(() => {
    if (!output) {
      return '0 locations';
    }
    return output.length === 1
      ? `${output.length} location`
      : `${output?.length} locations`;
  }, [output]);

  const createGarage = useCallback(
    async (data) => {
      try {
        setBusy(true);
        await setNewGarage({
          action: 'set_new_garage',
          data: {
            garagesData: data,
          },
        });
        fetchGarages();
        toast.success('Garage created!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },

    [fetchGarages, setBusy, setErrorContent, setNewGarage, toast],
  );

  const getCoordinate = useCallback(
    (address: TAddress, setFieldValue: (a: string, b: string) => void) => {
      if (!address.street && !address.city && !address.state) {
        setFieldValue('coordinate.latitude', '');
        setFieldValue('coordinate.longitude', '');
        setFieldValue('timezone.identifier', '');
      }

      const getData = async () => {
        const addressName = `${address.street},${address.city},${address.state}`;
        try {
          setBusy(true);
          const responseGeo = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${addressName}&key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
          );
          const { results } = await responseGeo.json();
          const coord = results[0]?.geometry?.location || {};

          const responseTimezone = await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${
              coord.lat
            },${coord.lng}&timestamp=${moment().unix()}&key=${
              process.env.REACT_APP_FIREBASE_API_KEY
            }`,
          );

          const { timeZoneId } = await responseTimezone.json();

          setFieldValue('timezone.identifier', timeZoneId || '');
          setFieldValue('coordinate.latitude', coord.lat || '');
          setFieldValue('coordinate.longitude', coord.lng || '');
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        } finally {
          setBusy(false);
        }
      };
      if (address.street && address.city && address.state) {
        getData();
      }
    },
    [setBusy, toast],
  );

  useEffect(() => {
    if (garages) {
      setLocations(garages as IGarage[]);
    }
  }, [garages]);

  return {
    locations: output as IGarage[],
    locationsInfo,
    setPattern,
    isDesktop,
    isMobile,
    openModal,
    handleOpenModal,
    handleCloseModal,
    getCoordinate,
    createGarage,
    busy,
  };
}

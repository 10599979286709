import { Box, Container } from '@mui/material';
import { LanesTable } from './components';
import { useLanes } from './hooks';

export const Lanes = (): JSX.Element | null => {
  const { lanes, isDesktop, garage, openGateFn } = useLanes();

  return garage ? (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: isDesktop ? '15px' : '40px',
        }}
      />
      {lanes && <LanesTable data={lanes} openGateFn={openGateFn} />}
    </Container>
  ) : null;
};

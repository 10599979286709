import { ReactNode } from 'react';
import {
  LocationProvider as Provider,
  ILocationContext,
} from './LocationContext';
import useLocationData from './UseLocationData';

type Props = {
  children: ReactNode;
};

function LocationProvider({ children }: Props) {
  const api: ILocationContext = useLocationData();

  return <Provider value={api}>{children}</Provider>;
}

export default LocationProvider;

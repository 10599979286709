import { Box, IconButton, TextField, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Form, useFormikContext } from 'formik';
import { CaretCircleLeft } from 'phosphor-react';
import { TMemberPopulationGroup } from 'shared/validations/memberPopulationGroup';

type Props = {
  handleClose: () => void;
  cleanData: () => void;
  title: string;
};

export const AddMemberForm = ({ handleClose, cleanData, title }: Props) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<TMemberPopulationGroup>();
  return (
    <Form>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              cleanData();
              handleClose();
            }}
          >
            <CaretCircleLeft size={32} />
          </IconButton>
          <Typography variant="h4" component="div" ml={1}>
            {title}
          </Typography>
        </Box>
        <Box paddingY={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <TextField
              sx={{ mr: 2 }}
              fullWidth
              label="First name"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              name="user.firstName"
              type="text"
              value={values.user.firstName}
              error={touched.user?.firstName && Boolean(errors.user?.firstName)}
              helperText={touched.user?.firstName && errors.user?.firstName}
            />
            <TextField
              fullWidth
              label="Last name"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              name="user.lastName"
              type="text"
              value={values.user.lastName}
              error={touched.user?.lastName && Boolean(errors.user?.lastName)}
              helperText={touched.user?.lastName && errors.user?.lastName}
            />
          </Box>
          <ReactPhoneInput
            value={values.user.phone}
            onChange={(value) => setFieldValue('user.phone', value)}
            component={TextField}
            inputProps={{
              name: 'user.phone',
              disabled: values.isEdit,
              error: touched.user?.phone && Boolean(errors.user?.phone),
              helperText: touched.user?.phone && errors.user?.phone,
              onBlur: handleBlur,
              onChange: handleChange,
            }}
          />
        </Box>
      </Box>
    </Form>
  );
};

import { FC } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';

type PropsType = {
  btnColor?: 'primary' | 'error' | 'success';
  btnTitle?: string;
  open: boolean;
  busy: boolean;
  isValid?: boolean;
  handleClose: () => void;
  submitForm?: () => Promise<void>;
  cleanData?: () => void;
};

export const CustomizedDialog: FC<PropsType> = ({
  children,
  btnColor = 'primary',
  btnTitle = 'Submit',
  open,
  handleClose,
  submitForm,
  cleanData,
  busy,
  isValid,
}): JSX.Element => {
  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleClose();
    if (cleanData) cleanData();
  };

  return (
    <Dialog
      onClose={(e) => onClose(e as React.MouseEvent<HTMLElement>)}
      open={open}
      fullWidth
    >
      {children}
      <Divider />
      <Box py={1}>
        <DialogActions onClick={(e) => e.stopPropagation()}>
          {submitForm ? (
            <Button
              sx={{ mr: 1 }}
              size="medium"
              color={btnColor}
              variant="contained"
              disabled={!isValid || busy}
              onClick={async () => {
                await submitForm();
                if (cleanData) cleanData();
              }}
            >
              {busy ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Typography variant="button" color="white">
                  {btnTitle}
                </Typography>
              )}
            </Button>
          ) : null}
          <Button
            sx={{ mr: '10px' }}
            color="inherit"
            size="medium"
            variant="contained"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { UserInterface } from './interface';
import { UserValidator } from './validator';

export const UserConverter: firebase.firestore.FirestoreDataConverter<UserInterface> =
  {
    toFirestore(user: UserInterface): firebase.firestore.DocumentData {
      if (UserValidator(user)) return user;
      throw new ConverterError(
        'User Model validation error.',
        JSON.stringify(UserValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): UserInterface {
      const user = snapshot.data();
      if (UserValidator(user)) return user as UserInterface;
      throw new ConverterError(
        'User Model validation error.',
        JSON.stringify(UserValidator.errors),
      );
    },
  };

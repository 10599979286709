import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getRuleFormAutocomplete,
  normalizeCollection,
  TFBCollection,
  useFirebaseApi,
  useGarageData,
  useModal,
} from 'shared';
import { GarageScheduledRateConverter, IScheduledRate } from 'App/api/types';
import { reverceConversionValue } from 'shared/utils/data_tables/validations';
import { TScheduled } from '../types';

export const useScheduled = (): TScheduled => {
  const { dbQuery } = useFirebaseApi();
  const { garageId, timezone } = useGarageData();
  const modalScheduleRate = useModal();
  const modalRule = useModal();
  const modalException = useModal();

  const [schedulerate, setScheduledrate] = useState(null);
  const [scheduleRateData, setScheduleRateData] =
    useState<IScheduledRate | null>(null);
  const [ruleData, setRuleData] = useState<any>(null);

  const normalizeRule = (item: any) => {
    const { enter, exit } = item;
    return {
      id: uuidv4(),
      enterBefore: enter.before.time,
      enterAfter: enter.after.time,
      exitBefore: exit.before.time,
      exitAfter: exit.after.time,
      partiallyApplicable: {
        enter: enter.partiallyApplicable,
        exit: exit.partiallyApplicable,
      },
      ...item,
    };
  };

  useEffect(() => {
    let unsubscribe: () => void;

    if (garageId) {
      unsubscribe = (
        dbQuery(`garages/${garageId}/scheduledRates`) as TFBCollection
      )
        .withConverter(GarageScheduledRateConverter)
        .onSnapshot((docs) => {
          const normalaizeData = normalizeCollection(docs);
          const scheduleRateDataNormalaize = normalaizeData.map(
            (scheduled: any) => {
              return {
                ...scheduled,
                amount:
                  scheduled?.type &&
                  scheduled?.amount &&
                  (reverceConversionValue(
                    scheduled?.type,
                    scheduled?.amount,
                  ) as any),
                validity: {
                  rules:
                    scheduled?.validity?.rules &&
                    scheduled.validity.rules.map((rule: any) => {
                      return normalizeRule(rule);
                    }),
                  exceptions:
                    scheduled?.validity?.exceptions &&
                    scheduled.validity.exceptions.map((exception: any) => {
                      return normalizeRule(exception);
                    }),
                },
              };
            },
          );
          setScheduledrate(scheduleRateDataNormalaize as any);
        });
    }

    return () => {
      setScheduledrate(null);
      unsubscribe();
    };
  }, [dbQuery, garageId]);

  return {
    schedulerate,
    modalScheduleRate,
    timezone,
    editScheduleRate: { scheduleRateData, setScheduleRateData },
    modalRule,
    editRule: {
      ruleData:
        ruleData && (getRuleFormAutocomplete(ruleData) as unknown as any),
      setRuleData,
    },
    modalException,
  };
};

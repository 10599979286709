import { Dispatch, ReactNode, useCallback } from 'react';
import Box from '@mui/material/Box';
import { Modal, IconButton } from '@mui/material';
import { XCircle } from 'phosphor-react';
import { useResponsive } from 'shared';

type PropType = {
  open: boolean;
  setClose: () => void;
  children: ReactNode;
  width?: string;
  btnClose?: boolean;
  setEditItem?: Dispatch<React.SetStateAction<string>>;
  cleanData?: () => void;
};

const style = {
  position: 'absolute' as const,
  maxHeight: '100%',
  overflow: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

export const BasicModal = ({
  open,
  setClose,
  setEditItem,
  children,
  width = '750px',
  btnClose = true,
  cleanData,
}: PropType): JSX.Element => {
  const isMobile = useResponsive('down', 'md');

  const handleClose = useCallback(() => {
    if (setEditItem) setEditItem('');
    if (cleanData) cleanData();
    setClose();
  }, [cleanData, setClose, setEditItem]);

  return (
    <Modal sx={{ my: 4 }} open={open} onClose={handleClose}>
      <Box width={isMobile ? '95%' : width} sx={style}>
        {btnClose && (
          <Box justifyContent="flex-end" display="flex" m={-3}>
            <IconButton
              onClick={handleClose}
              aria-label="delete"
              color="primary"
            >
              <XCircle />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useBusy } from 'shared';
import { generateRoutePath, RouteName } from 'App/routes/helpers';
import { useResetPassword } from '../hooks';

export function RequestToChangePassword() {
  const navigate = useNavigate();
  const { resetPasswordWithEmail, validationSchemaEmail } = useResetPassword();
  const { busy } = useBusy();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchemaEmail,
    onSubmit: async ({ email }, { setSubmitting }) => {
      await resetPasswordWithEmail(email);
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" component="h1" fontSize="large" mb={2}>
          Forgot your password?
        </Typography>
        <Typography fontSize="medium" mb={2} textAlign="center">
          Please enter the email address associated with your account and We
          will email you a link to reset your password.
        </Typography>
      </Box>

      <TextField
        sx={{ mb: 3 }}
        fullWidth
        id="email"
        name="email"
        label="Email address"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Button
        sx={{ mb: 1 }}
        size="large"
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        {busy ? <CircularProgress size={20} color="inherit" /> : 'Send Request'}
      </Button>
      <Button
        size="large"
        color="inherit"
        variant="contained"
        fullWidth
        onClick={() => navigate(generateRoutePath({ name: RouteName.SignIn }))}
      >
        Back
      </Button>
    </form>
  );
}

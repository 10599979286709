import { Box, Button, Typography } from '@mui/material';
import { AccordionGeneral } from 'shared/components';
import { usePayment } from '../../hooks/usePayments';

export const Payments = () => {
  const { managePyment } = usePayment();
  const onSubmit = async () => {
    await managePyment();
  };
  return (
    <AccordionGeneral title="Payments">
      <Box>
        <Typography variant="body1" component="div" mb={2}>
          Vend Pay
        </Typography>
        <Button
          sx={{ width: '85px' }}
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
          Manage
        </Button>
      </Box>
    </AccordionGeneral>
  );
};

import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type CollapseDrawerContextProps = {
  isCollapse?: boolean;
  collapseClick: boolean;
};

const initialState: CollapseDrawerContextProps = {
  collapseClick: false,
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};

function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [collapseClick, setCollapseClick] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setCollapseClick(false);
    }
  }, [isMobile]);

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapseClick,
        collapseClick,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };

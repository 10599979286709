import { Formik } from 'formik';
import { Box } from '@mui/material';
import { CustomizedDialog, IModal } from 'shared';
import { supportInquiry } from 'shared/validations/supportInquiry';
import { SupportInquiryForm } from './form/SupportInquiryForm';

type Props = {
  modal: IModal;
  busy: boolean;
  sessionId: string;
  supportInquiryFn: <T>(data: T) => Promise<void>;
};

export const SupportInquiryModal = ({
  modal,
  busy,
  sessionId,
  supportInquiryFn,
}: Props) => {
  const { openModal, handleCloseModal } = modal;

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
        sessionId,
      }}
      enableReinitialize
      validationSchema={supportInquiry}
      onSubmit={async (values, { resetForm }) => {
        await supportInquiryFn(values);
        resetForm();
      }}
    >
      {({ submitForm, isValid }) => (
        <CustomizedDialog
          btnTitle="Submit"
          busy={busy}
          submitForm={submitForm}
          open={openModal}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <Box sx={{ width: '100%' }}>
            <SupportInquiryForm />
          </Box>
        </CustomizedDialog>
      )}
    </Formik>
  );
};

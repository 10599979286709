import { FunctionsError } from 'firebase/functions';
import { useCallback } from 'react';
import { useApiFunction, useBusy, useToast } from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { usePricing } from './usePricing';

export function usePayment() {
  const { baseRate, garageId } = usePricing();
  const { busy, setBusy } = useBusy();

  const toast = useToast();
  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const managePyment = useCallback(async () => {
    try {
      setBusy(true);
      const responce = await apiFn({
        action: 'create_stripe_link',
        data: { garageID: garageId },
      });

      if (responce) {
        window.open(`${responce.data.link.url}`, '_blank');
      }
      toast.success('Payment link created succesfully');
    } catch (e) {
      const err = e as FunctionsError;
      setErrorContent({
        errorHeader: err.message,
        errorBody: err.details as string,
        openError: true,
      });
    } finally {
      setBusy(false);
    }
  }, [apiFn, garageId, setBusy, setErrorContent, toast]);

  return {
    managePyment,
    baseRate,
    busy,
  };
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { useResponsive, useCollapseDrawer } from 'shared';
import {
  DASHBOARD_NAVBAR_WIDTH,
  DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
} from 'App/config';
import { RootStyle } from './styles';
import ContentNavbar from './ContentNavbar';

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardNavbar({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? DASHBOARD_NAVBAR_COLLAPSE_WIDTH
            : DASHBOARD_NAVBAR_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: DASHBOARD_NAVBAR_WIDTH } }}
        >
          <ContentNavbar />
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DASHBOARD_NAVBAR_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
            },
          }}
        >
          <ContentNavbar />
        </Drawer>
      )}
    </RootStyle>
  );
}

import { Form, useFormikContext } from 'formik';
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import { MaskInput } from 'shared/controls/maskInput';
import { priceMask } from 'shared/utils/masks';

export const RefundForm = ({ supportId }: { supportId: string }) => {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <Form>
      <Box p={3}>
        <Typography variant="h4" component="h1">
          Refund
        </Typography>
        <Typography variant="subtitle2" component="span" color="text.secondary">
          Park ID: {supportId}
        </Typography>
        <Typography sx={{ mt: 5 }} variant="body1" component="div">
          Refunding this session means the full or partial amount collected from
          the park will be reallocated back to the parker, If you wish to
          complete this task please click ‘Refund’ below.
        </Typography>
        <Box my={4}>
          <FormControlLabel
            control={
              <Radio
                checked={values.isPartial}
                onChange={() => {
                  setFieldValue('isPartial', true);
                }}
                name="isCustomParkingLimit"
              />
            }
            label={<Typography variant="body2">Partial</Typography>}
          />
          <FormControlLabel
            control={
              <Radio
                checked={!values.isPartial}
                onChange={() => {
                  setFieldValue('isPartial', false);
                }}
                name="isCustomParkingLimit"
              />
            }
            label={<Typography variant="body2">Full</Typography>}
          />
        </Box>
        {values.isPartial ? (
          <MaskInput
            sx={{ width: '100%' }}
            type="text"
            name="amount"
            mask={priceMask.mask}
            label="Add amount"
            placeholder={priceMask.label}
            blocks={priceMask.blocks}
          />
        ) : null}
      </Box>
    </Form>
  );
};

import { useState, useCallback } from 'react';
import { TQuery, useBusy, useToast } from 'shared';
import { normalizeDoc } from 'shared/utils';
import { IUser } from './types';

interface IOperator {
  operator: {
    id: string;
    name: string;
    shortName: string;
  };
}

interface IEmployee extends IUser, IOperator {}

function useEmployee(dbQuery: (path: string) => TQuery) {
  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const { setBusy } = useBusy();
  const toast = useToast();
  const storeEmployee = useCallback((operator, user) => {
    const { id, name, shortName } = normalizeDoc(operator);
    const result = {
      ...normalizeDoc(user),
      operator: {
        id,
        name,
        shortName,
      },
    };
    setEmployee(result as IEmployee);
  }, []);

  const fetchEmployee = useCallback(
    async (operatorId, userId) => {
      try {
        setBusy(true);
        const operator = await dbQuery(`operators/${operatorId}`).get();
        const res = await dbQuery(
          `operators/${operatorId}/employees/${userId}`,
        ).get();
        storeEmployee(operator, res);
      } catch (e) {
        setEmployee(null);
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
    },
    [dbQuery, setBusy, storeEmployee, toast],
  );
  return {
    employee,
    fetchEmployee,
  };
}

export default useEmployee;

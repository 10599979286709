export const STATUS_GARAGE = [
  {
    label: 'Available',
    value: 'available',
  },
  {
    label: 'Full',
    value: 'full',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
];

/* eslint-disable no-param-reassign */
import {
  Box,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { MagnifyingGlass } from 'phosphor-react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Form, Formik, FormikErrors } from 'formik';
import { typesOfSearch } from 'pages/support/helpers';
import { SessionInterface } from 'App/api/types/Session';
import { checkPhoneNumber } from 'shared/validations/changePhoneNumber';

type Props = {
  setData: React.Dispatch<React.SetStateAction<SessionInterface[]>>;
  phone: string;
  loading: boolean;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  typeOfSearch: 'phone' | 'parkId' | 'licensePlate';
  setTypeOfSearch: React.Dispatch<
    React.SetStateAction<'phone' | 'parkId' | 'licensePlate'>
  >;
  setUserSearch: React.Dispatch<React.SetStateAction<any>>;
  setVehicleSearch: React.Dispatch<React.SetStateAction<any>>;
};

interface FormValues {
  phone: string;
  searchType: string;
}

export const SupportSearchForm = ({
  setData,
  phone,
  setPhone,
  typeOfSearch,
  setTypeOfSearch,
  loading,
  setUserSearch,
  setVehicleSearch,
}: Props) => {
  const validPhoneNumber = (
    phoneNumber: string,
    errors: FormikErrors<FormValues>,
  ) => {
    if (!phoneNumber) {
      errors.phone = 'Phone Number Required';
    } else if (!checkPhoneNumber(phoneNumber)) {
      errors.phone = 'Invalid Phone Number';
    }
  };

  const validParkId = (parkId: string, errors: FormikErrors<FormValues>) => {
    if (!parkId) {
      errors.phone = 'Park ID Required';
    } else if (parkId.length !== 6) {
      errors.phone = 'Park ID must be of length 6';
    }
  };

  const validLicensePlate = (
    licencePlate: string,
    errors: FormikErrors<FormValues>,
  ) => {
    if (!licencePlate) {
      errors.phone = 'Licence Plate Required';
    } else if (licencePlate.toUpperCase() !== licencePlate) {
      errors.phone = 'Invalid Licence Plate';
    } else if (!(licencePlate.length >= 2 && licencePlate.length <= 10)) {
      errors.phone = 'Invalid Licence Plate';
    }
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    switch (values.searchType) {
      case 'phone':
        validPhoneNumber(values.phone, errors);
        break;
      case 'parkId':
        validParkId(values.phone, errors);
        break;
      case 'licensePlate':
        validLicensePlate(values.phone, errors);
        break;
      default:
        break;
    }

    return errors;
  };

  return (
    <Formik
      onSubmit={(values) => {
        setPhone(values.phone);
      }}
      validate={validate}
      // validateOnMount
      initialValues={{
        searchType: typeOfSearch,
        phone,
      }}
    >
      {({
        submitForm,
        values,
        setFieldValue,
        isValid,
        touched,
        errors,
        handleBlur,
      }) => {
        return (
          <Form>
            <Box display="flex" flexDirection="row" alignItems="center" my={3}>
              {typesOfSearch.map((type) => (
                <FormControlLabel
                  key={type.value}
                  control={
                    <Radio
                      checked={type.value === values.searchType}
                      onChange={() => {
                        setUserSearch(null);
                        setVehicleSearch(null);
                        setPhone('');
                        setData([]);
                        setTypeOfSearch(type.value);
                        setFieldValue('searchType', type.value);
                        setFieldValue('phone', '');
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ typography: { sm: 'body1', xs: 'caption' } }}
                    >
                      {type.label}
                    </Typography>
                  }
                />
              ))}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              my={3}
            >
              {values.searchType === 'phone' ? (
                <ReactPhoneInput
                  value={values.phone}
                  onChange={(...args) => setFieldValue('phone', args[3])}
                  component={TextField}
                  inputStyle={{ width: 250 }}
                  inputProps={{
                    name: 'phone',
                    error: touched?.phone && Boolean(errors?.phone),
                    helperText: touched?.phone && errors?.phone,
                    onBlur: handleBlur,
                  }}
                />
              ) : (
                <TextField
                  sx={{ width: 250 }}
                  value={values.phone}
                  label={
                    values.searchType === 'parkId' ? 'Park ID' : 'License plate'
                  }
                  name="phone"
                  type={values.searchType === 'parkId' ? 'number' : 'text'}
                  onChange={({ target: { value } }) =>
                    setFieldValue('phone', value)
                  }
                  onBlur={handleBlur}
                  error={touched?.phone && Boolean(errors?.phone)}
                  helperText={touched?.phone && errors?.phone}
                />
              )}
              <LoadingButton
                loading={loading}
                sx={{ ml: 2, height: 56 }}
                size="large"
                variant="contained"
                disabled={!isValid || loading}
                onClick={submitForm}
              >
                <>
                  <MagnifyingGlass size={20} />
                  <Box sx={{ display: { sm: 'block', xs: 'none' }, ml: 1 }}>
                    <span>Search</span>
                  </Box>
                </>
              </LoadingButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

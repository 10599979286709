import { IValidation } from 'App/api/types/Validation';
import { FunctionsError } from 'firebase/functions';
import { drop } from 'rambda';
import { useCallback, useState } from 'react';
import {
  getCents,
  useApiFunction,
  useBusy,
  useGarageData,
  useModal,
  useToast,
} from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';

export const useValidationAction = () => {
  const { garageId } = useGarageData();
  const apiFn = useApiFunction();
  const { busy, setBusy } = useBusy();
  const { setErrorContent } = useErrorHandler();
  const toast = useToast();
  const applyValidationModal = useModal();
  const editValidationModal = useModal();
  const viewQRModal = useModal();
  const [currentValidation, setCurrentValidation] =
    useState<IValidation | null>(null);

  const selectValidation = useCallback(
    (validation: IValidation, handleOpen) => {
      setCurrentValidation(validation);
      handleOpen();
    },
    [],
  );

  const editValidation = useCallback(
    async (sponsorId, data, values) => {
      try {
        const type = values.businessCharged ? 'validatedRate' : 'perUse';
        setBusy(true);
        await apiFn({
          action: 'garages_validations_edit',
          data: {
            garageID: values.garageId,
            validationData: {
              id: values.validationId,
              name: values.name,
              description: values.description,
              type: values.type,
              value: data.value,
              isPublic: values.isVisible,
              businessID: sponsorId,
              billToMaster: {
                type: values.isUserPays ? 'none' : type,
                ...(!values.businessCharged && {
                  amount: getCents(+drop(1, values.businessValue)),
                }),
              },
            },
          },
        });

        toast.success('Validation has been changed!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, toast, setErrorContent],
  );

  const applyValidation = useCallback(
    async (sponsorId, validationId) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_validations_apply',
          data: {
            garageID: garageId,
            validationID: validationId,
            supportID: String(sponsorId.supportID),
          },
        });

        toast.success('Validation applied!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
      }
    },
    [apiFn, setBusy, toast, garageId, setErrorContent],
  );
  return {
    applyValidationModal,
    editValidationModal,
    viewQRModal,
    selectValidation,
    currentValidation,
    applyValidation,
    busy,
    editValidation,
  };
};

import {
  Box,
  Button,
  Card,
  CardContent,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { PencilSimple } from 'phosphor-react';
import { IBusiness } from 'App/api/types/Business';
import { useBusinesses } from '../hooks';
import EditBusiness from './EditBusiness';

const ButtonCustomized = styled(Button)`
  min-width: 38px;
  height: 26px;
  padding: 0;
`;

type PropsType = {
  business: IBusiness;
};

export const CardBusinesses = ({ business }: PropsType) => {
  const theme = useTheme();
  const { modalEditBusiness } = useBusinesses();
  const boxShadow = theme.customShadows.z12;

  return (
    <Card
      sx={{
        boxShadow,
      }}
    >
      <CardContent>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div" noWrap>
            {business.name}
          </Typography>
          <ButtonCustomized
            variant="outlined"
            size="small"
            color="inherit"
            onClick={modalEditBusiness.handleOpenModal}
          >
            <PencilSimple size={18} />
          </ButtonCustomized>
        </Box>

        <Typography variant="body2" component="div" mt={3}>
          {business.address?.street}, {business.address?.city},{' '}
          {business.address?.state} {business.address?.zip},{' '}
          {business.address?.country}
        </Typography>
      </CardContent>
      <EditBusiness
        business={business}
        openModal={modalEditBusiness.openModal}
        handleCloseModal={modalEditBusiness.handleCloseModal}
      />
    </Card>
  );
};

import firebase from 'firebase/compat/app';
import { ConverterError } from '../converterError';
import { BusinessInterface } from './interface';
import { BusinessValidator } from './validator';

export const BusinessConverter: firebase.firestore.FirestoreDataConverter<BusinessInterface> =
  {
    toFirestore(business: BusinessInterface): firebase.firestore.DocumentData {
      if (BusinessValidator(business)) return business;
      throw new ConverterError(
        'Model Business validation error.',
        JSON.stringify(BusinessValidator.errors),
      );
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): BusinessInterface {
      const business = snapshot.data();
      if (BusinessValidator(business)) return business as BusinessInterface;
      throw new ConverterError(
        'Model Business validation error.',
        JSON.stringify(BusinessValidator.errors),
      );
    },
  };

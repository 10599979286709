import { useState, useEffect, useCallback, useRef } from 'react';
import 'firebase/auth';

import { useFirebase } from 'App/firebase';
import {
  normalizeDoc,
  TFBDocumentSnapshot,
  TFBDocument,
  useFirebaseApi,
  useToast,
  TUnsubscribe,
} from 'shared';
import { UserInterface } from 'App/api/types/User/interface';
import * as sessionStore from './PersistentStorage';

export type TUser = {
  uid: string;
  token: string;
  claims: { operator: string; user_id: string };
  refreshToken: string;
  email: string;
};

enum SessionStatus {
  Initializing = 'Initializing',
  Authenticated = 'Authenticated',
  Deauthenticated = 'Deauthenticated',
}

const defaultToken = sessionStore.get('refresh_token') || null;

const useProvideAuth = () => {
  const toast = useToast();
  const firebase = useFirebase();
  const { dbQuery } = useFirebaseApi();
  const [status, setStatus] = useState<SessionStatus>(
    SessionStatus.Initializing,
  );
  const [user, setUser] = useState<UserInterface | null>(null);

  const [organization, setOrganization] = useState<string | null>('');

  const [token, setToken] = useState<string | null>(defaultToken);

  const userUnsubscribe = useRef<TUnsubscribe | null>(null);
  const organizationUnsubscribe = useRef<TUnsubscribe | null>(null);

  const getUser = useCallback(
    async (userData: TUser) => {
      userUnsubscribe.current = (
        dbQuery(
          `operators/${userData.claims.operator}/employees/${userData.claims.user_id}`,
        ) as TFBDocument
      ).onSnapshot((docs) => {
        const normalaizeOperator = normalizeDoc(docs as TFBDocumentSnapshot);
        setUser(normalaizeOperator as UserInterface);
      });
    },
    [dbQuery],
  );

  const getOrganization = useCallback(
    async (userData: TUser) => {
      organizationUnsubscribe.current = (
        dbQuery(`operators/${userData.claims.operator}`) as TFBDocument
      ).onSnapshot(async (docs) => {
        const normalaizeOrganization = normalizeDoc(
          docs as TFBDocumentSnapshot,
        );
        setOrganization(normalaizeOrganization.name as string);
      });
    },
    [dbQuery],
  );

  const authenticate = useCallback(
    async (userData: TUser) => {
      setToken(userData.token);
      setStatus(SessionStatus.Authenticated);
      sessionStore.set({ refresh_token: userData.refreshToken });
      if (userData.claims) {
        await getUser(userData);
        await getOrganization(userData);
      }
    },
    [getOrganization, getUser],
  );

  const destroyUser = () => {
    setUser(null);
    setToken(null);
    sessionStore.clear();
    setStatus(SessionStatus.Deauthenticated);
  };

  function inStatus(s: SessionStatus): boolean {
    return status === s;
  }

  const signout = useCallback(() => {
    return firebase
      ?.auth()
      .signOut()
      .then(() => {
        destroyUser();
      });
  }, [firebase]);

  const signin = useCallback(
    async (email, password) => {
      const auth = firebase.auth();
      try {
        const userAuth = await auth.signInWithEmailAndPassword(email, password);
        if (!userAuth) {
          signout();
        }
        authenticate(userAuth as unknown as TUser);
        return userAuth?.user;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
      return null;
    },
    [authenticate, firebase, signout, toast],
  );

  useEffect(() => {
    const auth = firebase.auth();
    const checkUser = async (userData: unknown) => {
      if (userData) {
        const tokenUser = await auth.currentUser?.getIdTokenResult();
        if (!tokenUser) {
          signout();
        } else {
          authenticate(tokenUser as unknown as TUser);
        }
      } else {
        signout();
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkUser);
    return () => {
      unsubscribe();
      userUnsubscribe?.current?.();
      organizationUnsubscribe?.current?.();
    };
  }, [authenticate, firebase, signout]);

  return {
    initializing: inStatus(SessionStatus.Initializing),
    authenticated: inStatus(SessionStatus.Authenticated),
    authenticate,
    organization,
    user,
    signin,
    signout,
    token,
  };
};

export default useProvideAuth;

import { Props as ScrollbarProps } from 'simplebar-react';
import { Box, SxProps } from '@mui/material';
import './styles/scrollbar.css';
import { RootStyle, SimpleBarStyle } from './styles';

interface Props extends ScrollbarProps {
  sx?: SxProps;
}

export default function Scrollbar({ children, sx }: Props) {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );

  if (isMobile) {
    return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>;
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}

import { UserInterface } from 'App/api/types/User/interface';
import { createContext } from 'react';
import { TUser } from './UseProvideAuth';

export interface TAuthContext {
  initializing: boolean;
  user: UserInterface | null;
  organization: string | null;
  authenticated: boolean;
  signout: () => void;
  signin: (email: string, password: string) => void;
  authenticate: (user: TUser) => void;
  token: string | null;
}

const AuthContext = createContext<TAuthContext>({
  initializing: true,
  user: null,
  organization: null,
  authenticated: false,
  authenticate: () => {},
  signout: () => {},
  signin: () => {},
  token: null,
});

export const AuthProvider = AuthContext.Provider;
export default AuthContext;

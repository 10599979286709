import { useCallback, useEffect, useState, useRef } from 'react';
import {
  TFBCollection,
  useApiFunction,
  useBusy,
  useFirebaseApi,
  useToast,
  normalizeCollection,
  useFilteredData,
  useModal,
  TUnsubscribe,
} from 'shared';
import { UserConverter, UserInterface } from 'App/api/types/User';
import { IPopulationGroup } from 'App/api/types/GaragePopulationGroup';
import { removeSymbolsForPhoneNumber } from 'shared/utils/phone';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';
import { usePricing } from './usePricing';

export function usePopulationGroup() {
  const { garageId } = usePricing();
  const { dbQuery } = useFirebaseApi();
  const { busy, setBusy } = useBusy();

  const apiFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();
  const toast = useToast();
  const modalAddMember = useModal();

  const [users, setUsers] = useState<UserInterface[] | null>(null);
  const [populationGroups, setPopulationGroups] = useState<IPopulationGroup[]>(
    [],
  );
  const [busyUser, setBusyUser] = useState<string>('');
  const [busyMember, setBusyMember] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<string>('');
  const { setPattern, output } = useFilteredData(users ?? [], [
    'firstName',
    'lastName',
    'phone',
  ]);

  const populationGroupUnsubscribe = useRef<TUnsubscribe | null>(null);
  const usersUnsubscribe = useRef<TUnsubscribe | null>(null);

  const savePopulationGroup = useCallback(
    async (values, populationGroupId, closeModal) => {
      try {
        setBusy(true);
        await apiFn({
          action: populationGroupId
            ? 'garages_pricing_population_groups_edit'
            : 'garages_pricing_population_groups_create',
          data: {
            garageID: garageId,
            populationGroupData: {
              ...values,
              ...(populationGroupId ? { id: populationGroupId } : {}),
            },
          },
        });

        toast.success(`Population group updated!`);
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        closeModal();
        if (populationGroupId) setEditItem('');
      }
    },
    [apiFn, garageId, setBusy, setErrorContent, toast],
  );

  const addMemberPopulationGroup = useCallback(
    async (values, closeModal) => {
      const normalizePhoneNumber = removeSymbolsForPhoneNumber(
        values.user.phone,
      );
      try {
        setBusyMember(true);
        await apiFn({
          action: 'garages_pricing_population_groups_members_add',
          data: {
            garageID: values.garageId,
            populationGroupID: values.populationGroupId,
            userData: {
              ...(values.user.firstName
                ? { firstName: values.user.firstName }
                : {}),
              ...(values.user.lastName
                ? { lastName: values.user.lastName }
                : {}),
              phone: normalizePhoneNumber,
            },
          },
        });
        toast.success('The member has been added!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusyMember(false);
        closeModal();
      }
    },
    [apiFn, setErrorContent, toast],
  );

  const removeMemberPopulationGroup = useCallback(
    async (values) => {
      try {
        setBusyUser(values.user.phone);
        await apiFn({
          action: 'garages_pricing_population_groups_members_remove',
          data: {
            garageID: values.garageId,
            populationGroupID: values.populationGroupId,
            userID: values.user.id,
          },
        });
        toast.success('The member has been removed!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusyUser('');
      }
    },
    [apiFn, setErrorContent, toast],
  );

  const fetchUsers = useCallback(async () => {
    try {
      setBusy(true);
      usersUnsubscribe.current = (dbQuery(`users`) as TFBCollection)
        .where(
          `populationGroups`,
          'array-contains',
          (
            dbQuery(`garages/${garageId}/populationGroups`) as TFBCollection
          ).doc(editItem),
        )
        .withConverter(UserConverter)
        .onSnapshot((docs) => {
          const normalaizeData = normalizeCollection(docs);
          setUsers(normalaizeData as UserInterface[]);
        });
    } catch (e) {
      setUsers(null);
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      setBusy(false);
    }
  }, [dbQuery, editItem, garageId, setBusy, toast]);

  const getPopulationGroups = useCallback(
    async (garageID) => {
      try {
        setBusy(true);
        populationGroupUnsubscribe.current = (
          dbQuery(`garages/${garageID}/populationGroups`) as TFBCollection
        ).onSnapshot((docs) => {
          const normalaizeData = normalizeCollection(docs);
          setPopulationGroups(normalaizeData as IPopulationGroup[]);
        });
      } catch (e) {
        setPopulationGroups([]);
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
    },
    [dbQuery, setBusy, toast],
  );

  useEffect(() => {
    let mounted = true;
    if (mounted && garageId) {
      getPopulationGroups(garageId);
    }
    return () => {
      mounted = false;
      populationGroupUnsubscribe.current?.();
    };
  }, [garageId, getPopulationGroups]);

  useEffect(() => {
    let mounted = true;
    if (mounted && editItem) fetchUsers();
    return () => {
      mounted = false;
      usersUnsubscribe.current?.();
    };
  }, [editItem, fetchUsers]);

  return {
    populationGroups,
    savePopulationGroup,
    addMemberPopulationGroup,
    removeMemberPopulationGroup,
    busy,
    busyUser,
    busyMember,
    editItem,
    setEditItem,
    users: output as UserInterface[],
    setPattern,
    cleanUsers: () => setUsers(null),
    modalAddMember,
  };
}

import { SetStateAction, Dispatch } from 'react';
import { Box, Typography } from '@mui/material';
import { Phone, Ticket, User } from 'phosphor-react';
import { IModal } from 'shared';
import { itemsMember } from '../helpers';
import { MemberType } from '../types';
import WrapperCard from './WrapperCard';

export const MembersCard = ({
  member,
  setCurrentData,
  businessName,
  editModal,
  refetchData,
}: {
  member: MemberType;
  setCurrentData: Dispatch<SetStateAction<any>>;
  businessName?: string;
  editModal: IModal;
  refetchData: () => Promise<void>;
}) => {
  const firstName = member?.name.trim() ? member?.name : 'Unknow';

  return (
    <WrapperCard
      data={member}
      editModal={editModal}
      actionItems={itemsMember}
      setCurrentData={setCurrentData}
      businessName={businessName}
      refetchData={refetchData}
    >
      <Box>
        <Box mb={1} display="flex" alignItems="center">
          <User size={20} weight="bold" />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {firstName}
          </Typography>
        </Box>
        <Box mb={1} display="flex" alignItems="center">
          <Phone size={20} weight="bold" />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {member.phone}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" position="relative">
          <Ticket size={20} weight="bold" />
          <Typography ml="13px" variant="h6" fontWeight={800}>
            {member?.passName}
          </Typography>
        </Box>
      </Box>
    </WrapperCard>
  );
};

import { SetStateAction } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { ISubscriptionPassOffering } from 'App/api/types';
import { Car, PencilSimple, Recycle } from 'phosphor-react';

type PropsType = {
  subscriptionsData: ISubscriptionPassOffering;
  setSubscriptionData: React.Dispatch<
    SetStateAction<ISubscriptionPassOffering | null>
  >;
  handleOpenModal: () => void;
};

const WrapperPassCard = styled(Box)`
  padding: 24px;
  border: 1px solid #000;
  border-radius: 16px;
`;

const ButtonCustomized = styled(Button)`
  min-width: 38px;
  height: 26px;
  padding: 0;
`;

export const SubscriptionsCard = ({
  subscriptionsData,
  handleOpenModal,
  setSubscriptionData,
}: PropsType) => {
  const theme = useTheme();
  const { parkLimit, name, period, interval } = subscriptionsData;
  const days = parkLimit ? `${parkLimit} park limit` : 'Unlimited parks';
  const renews = `Renews every ${interval} ${period}`;

  return (
    <WrapperPassCard>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography noWrap maxWidth="210px" variant="h6" component="div">
          {name}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <ButtonCustomized
            variant="outlined"
            color="inherit"
            onClick={() => {
              setSubscriptionData(subscriptionsData);
              handleOpenModal();
            }}
          >
            <PencilSimple size={18} />
          </ButtonCustomized>
        </Box>
      </Box>
      <Box mt="10px">
        <Box
          display="flex"
          flexDirection="column"
          color={theme.palette.grey[600]}
        >
          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Recycle size={20} style={{ marginRight: '10px' }} />
            {renews}
          </Typography>

          <Typography
            variant="subtitle1"
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Car size={20} style={{ marginRight: '10px' }} />
            {days}
          </Typography>
        </Box>
      </Box>
    </WrapperPassCard>
  );
};

import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot } from 'shared';
import { IAccount } from './types/GarageAccount';

function useAccounts() {
  const [accounts, setAccounts] = useState<IAccount[] | null>(null);

  useEffect(() => {
    const ref = firebase.firestore().collectionGroup('accounts');
    const unsubscribe = onSnapshot(ref, (resUsers: unknown) => {
      const normalizeAccountsData = normalizeCollection(
        resUsers as unknown as TFBQuerySnapshot,
      );
      setAccounts(normalizeAccountsData as IAccount[]);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    accounts,
  };
}

export default useAccounts;

import { Form, useFormikContext } from 'formik';
import { Box, TextField, Typography } from '@mui/material';
import { TSupportInquiry } from 'shared/validations/supportInquiry';
import pelette from 'App/theme/palette';

export const SupportInquiryForm = () => {
  const { values, handleChange, errors, touched } =
    useFormikContext<TSupportInquiry>();
  return (
    <Form>
      <Box p={3}>
        <Typography variant="h4" component="h1" mb={3}>
          Support inquiry
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Typography variant="h6" component="div" mb={3}>
            User email
          </Typography>
          <Typography
            variant="h6"
            component="div"
            color={pelette.grey[600]}
            mb={3}
          >
            (optional)
          </Typography>
        </Box>

        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="email"
          name="email"
          label="user@mail.com"
          value={values.email}
          onChange={handleChange}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
        <Typography variant="h6" component="div" mb={3}>
          Comments
        </Typography>
        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="message"
          name="message"
          label="Write comment here..."
          value={values.message}
          onChange={handleChange}
          error={touched.message && Boolean(errors.message)}
          helperText={touched.message && errors.message}
        />
      </Box>
    </Form>
  );
};

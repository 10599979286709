import { Form, useFormikContext } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { SelectInput } from 'shared/controls';
import { TGarage } from 'shared/validations/addGarage';
import states from 'shared/constants/states';
import { TAddress } from 'shared';

type PropsType = {
  isBusy: boolean;
  getCoordinate: (
    address: TAddress,
    field: (field: string, value: string) => void,
  ) => void;
};

export const AddGarageForm = ({ isBusy, getCoordinate }: PropsType) => {
  const {
    isValid,
    submitForm,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<TGarage>();
  const disabledGetCoordinateButton =
    !!values.address.street &&
    !!values.address.city &&
    !!values.address.state &&
    !!values.arrivalInstructions;

  return (
    <Form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          onBlur={handleBlur}
          sx={{ mb: '16px' }}
          fullWidth
          label="Name Garage"
          name="name"
          type="text"
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <TextField
          onBlur={handleBlur}
          fullWidth
          label="Arrival Information"
          name="arrivalInstructions"
          type="text"
          onChange={handleChange}
          error={
            touched.arrivalInstructions && Boolean(errors.arrivalInstructions)
          }
          helperText={touched.arrivalInstructions && errors.arrivalInstructions}
          multiline
          minRows={4}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          my={2}
        >
          <Typography variant="overline" component="span">
            Address
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '16px',
            }}
          >
            <TextField
              onBlur={handleBlur}
              sx={{ width: '48%' }}
              label="City Name"
              name="address.city"
              type="text"
              onChange={handleChange}
              error={touched.address?.city && Boolean(errors.address?.city)}
              helperText={touched.address?.city && errors.address?.city}
            />
            <TextField
              onBlur={handleBlur}
              sx={{ width: '48%' }}
              label="Street"
              name="address.street"
              type="text"
              onChange={handleChange}
              error={touched.address?.street && Boolean(errors.address?.street)}
              helperText={touched.address?.street && errors.address?.street}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <SelectInput
              onBlur={handleBlur}
              sx={{ width: '33%' }}
              name="address.state"
              options={states}
              label="State"
            />
            <TextField
              onBlur={handleBlur}
              sx={{ width: '33%' }}
              label="Country"
              name="address.country"
              type="text"
              onChange={handleChange}
              error={
                touched.address?.country && Boolean(errors.address?.country)
              }
              helperText={touched.address?.country && errors.address?.country}
            />
            <TextField
              onBlur={handleBlur}
              sx={{ width: '33%' }}
              label="Zip"
              name="address.zip"
              type="text"
              onChange={handleChange}
              error={touched.address?.zip && Boolean(errors.address?.zip)}
              helperText={touched.address?.zip && errors.address?.zip}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          my={2}
        >
          <Typography variant="overline" component="span">
            Coordinate
          </Typography>
        </Box>
        {!values.timezone.identifier && (
          <Box mb={2}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              type="button"
              disabled={!disabledGetCoordinateButton}
              onClick={() =>
                getCoordinate(values.address as TAddress, setFieldValue)
              }
            >
              {isBusy ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                'Get coordinates by address'
              )}
            </Button>
          </Box>
        )}
        {values.timezone.identifier && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: '16px',
              }}
            >
              <TextField
                sx={{ width: '48%' }}
                name="coordinate.latitude"
                type="text"
                placeholder="Latitude"
                label={values.coordinate.latitude}
                disabled
              />
              <TextField
                sx={{ width: '48%' }}
                name="coordinate.longitude"
                type="text"
                placeholder="Longitude"
                label={values.coordinate.longitude}
                disabled
              />
            </Box>
            <TextField
              name="timezone.identifier"
              type="text"
              placeholder="Timezone"
              label={values.timezone.identifier}
              disabled
            />
          </Box>
        )}
      </Box>
      <Box>
        <Button
          size="large"
          variant="contained"
          disabled={!isValid || isBusy}
          onClick={submitForm}
        >
          Create Garage
        </Button>
      </Box>
    </Form>
  );
};

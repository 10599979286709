import { Formik } from 'formik';
import { CustomizedDialog } from 'shared';
import { useBusinesses } from '../hooks';
import { AddBusinessesForm } from './form/AddBusinessesForm';

type PropType = {
  handleCloseModal: () => void;
  openModal: boolean;
};

const NEW_BUSINESSES = {
  businessName: '',
  street: '',
  city: '',
  business: '',
  country: 'United States',
  zipCode: '',
  // garagId: '',
};

export default function AddBusinesses({
  openModal,
  handleCloseModal,
}: PropType): JSX.Element {
  const { creatBusiness } = useBusinesses();
  return (
    <Formik
      initialValues={NEW_BUSINESSES}
      onSubmit={async (value) => {
        await creatBusiness(value);
        handleCloseModal();
      }}
    >
      {({ submitForm, isValid }) => (
        <>
          <CustomizedDialog
            open={openModal}
            handleClose={handleCloseModal}
            submitForm={submitForm}
            busy={false}
            isValid={isValid}
          >
            <AddBusinessesForm />
          </CustomizedDialog>
        </>
      )}
    </Formik>
  );
}

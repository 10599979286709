import { alpha } from '@mui/material';
import { IAction } from 'App/api/types';

type TValidationStatuses = {
  status: string;
  label: string;
  buttonLabel: string;
  todoStatus: string;
  colorButton:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  color: string;
  bgcolor: string;
};

export const VALIDATION_STATUSES: TValidationStatuses[] = [
  {
    status: 'disabled',
    label: 'Disabled',
    buttonLabel: 'Submit to Business',
    colorButton: 'info',
    todoStatus: 'ready-to-activate',
    color: 'error.main',
    bgcolor: alpha('#FF4842', 0.16),
  },
  {
    status: 'active',
    label: 'Active',
    buttonLabel: 'Disable',
    colorButton: 'error',
    todoStatus: 'disabled',
    color: 'primary.dark',
    bgcolor: alpha('#74D16F', 0.16),
  },
  {
    status: 'ready-to-activate',
    label: 'Pending Sponsor',
    buttonLabel: 'Disable',
    colorButton: 'error',
    todoStatus: 'disabled',
    color: 'info.dark',
    bgcolor: alpha('#0C53B7', 0.16),
  },
];

export const items: IAction[] = [
  { label: 'Edit validation' },
  { label: 'View QR code' },
  { label: 'Apply to session' },
  // { label: 'Archive validation' },
];

import { Container } from '@mui/material';
import PaymentProvider from 'App/helpers/payment/PaymentProvider';
import { StartSessionModal, SupportTable } from './components'; // SupportTable
import { useSupport } from './hooks';

export const Support = () => {
  const {
    modalStartSession,
    startSessionFn,
    lanes,
    setCurrentGarageId,
    busy,
    data,
    setData,
    loading,
    phone,
    setPhone,
    garages,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    typeOfSearch,
    setTypeOfSearch,
    setUserSearch,
    setVehicleSearch,
  } = useSupport();
  const { handleOpenModal } = modalStartSession;
  return (
    <PaymentProvider>
      <Container>
        {garages && (
          <>
            <SupportTable
              data={data}
              setUserSearch={setUserSearch}
              setVehicleSearch={setVehicleSearch}
              setData={setData}
              loading={loading}
              phone={phone}
              setPhone={setPhone}
              typeOfSearch={typeOfSearch}
              setTypeOfSearch={setTypeOfSearch}
              handleOpenModal={handleOpenModal}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
            <StartSessionModal
              modalStartSession={modalStartSession}
              garages={garages}
              lanes={lanes}
              startSession={startSessionFn}
              setCurrentGarageId={setCurrentGarageId}
              busy={busy}
            />
          </>
        )}
      </Container>
    </PaymentProvider>
  );
};

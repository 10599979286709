import * as Yup from 'yup';

export const AddPopulationGroup = Yup.object({
  data: Yup.object({
    name: Yup.string().required('Required'),
    priority: Yup.number(),
    type: Yup.string().required('Required'),
    amount: Yup.string().required('Required'),
  }).required('Required'),
});

export type TPopulationGroup = Yup.InferType<typeof AddPopulationGroup>;

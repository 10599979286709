import { IValidation } from 'App/api/types/Validation';
import { ValidationQrForm } from './form/ValidationQrForm';

type PropType = {
  validation: IValidation | null;
};

export default function ValidationQr({
  validation,
}: PropType): JSX.Element | null {
  return validation ? <ValidationQrForm validation={validation} /> : null;
}

import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';

interface PropsType extends ToggleButtonGroupProps {
  defaultValue: any;
  name: string;
  options: {
    text: string;
    value: string;
    number: number;
  }[];
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const ToggleButtonsMultiple = ({
  defaultValue,
  options,
  setValue,
  name,
  size,
  sx,
}: PropsType) => {
  const [state, setState] = useState<null | Array<any>>(defaultValue);

  const handleState = (
    _event: React.MouseEvent<HTMLElement>,
    newStates: string[],
  ) => {
    setState(newStates);
    setValue(name, newStates);
  };

  return (
    <ToggleButtonGroup
      sx={sx}
      color="primary"
      size={size}
      value={state}
      onChange={handleState}
    >
      {options.map((option: any) => (
        <ToggleButton key={option.value} value={option.number}>
          {option.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

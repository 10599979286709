import { useState, useCallback, useMemo } from 'react';
import { useApiFunction, useBusy, useToast } from 'shared';
import {
  checkDayForResetTime,
  getDifferentTimezone,
  formatTime,
  getMilisFromString,
  getMillisFromMinutes,
} from 'shared/utils';
import { TIME } from 'shared/constants/time';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';
import { usePricing } from './usePricing';

export function useGracePeriod() {
  const { resetTime, gracePeriod, garageId, timezone } = usePricing();
  const { setBusy } = useBusy();
  const toast = useToast();

  const [isEditSettings, setIsEditSettings] = useState<boolean>(true);

  const apiFn = useApiFunction();

  const { setErrorContent } = useErrorHandler();

  const diffTimezoneMiliseconds = useMemo<number>(
    () => getDifferentTimezone(timezone || 'Etc/Utc'),
    [timezone],
  );

  const savePeriod = useCallback(
    async (values) => {
      const timeReset =
        getMilisFromString(formatTime(values.resetTime), TIME) -
        diffTimezoneMiliseconds;

      const data = {
        gracePeriod: getMillisFromMinutes(values.gracePeriod.value),
        resetTime:
          Number.isInteger(checkDayForResetTime(timeReset)) &&
          !values.isNoResetTime
            ? checkDayForResetTime(timeReset)
            : -1,
      };
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_pricing_edit_settings',
          data: {
            garageId,
            details: data,
          },
        });
        toast.success('Settings updated!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setIsEditSettings(true);
        setBusy(false);
      }
    },
    [apiFn, diffTimezoneMiliseconds, garageId, setBusy, setErrorContent, toast],
  );

  return {
    gracePeriod,
    savePeriod,
    resetTime,
    diffTimezoneMiliseconds,
    isEditSettings,
    setIsEditSettings,
  };
}

import { Button } from '@mui/material';
import { useResponsive } from 'shared';
import { ReactComponent as Menu } from 'App/assets/Menu.svg';
import { RootStyle, ToolbarStyle } from './styles';

type Props = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardHeader({
  onOpenSidebar,
}: Props): JSX.Element | null {
  const isDesktop = useResponsive('up', 'lg');

  return !isDesktop ? (
    <RootStyle>
      <ToolbarStyle>
        <Button color="inherit" onClick={onOpenSidebar}>
          <Menu />
        </Button>
      </ToolbarStyle>
    </RootStyle>
  ) : null;
}

import { Formik } from 'formik';
import { CustomizedDialog, IModal } from 'shared';
import {
  applyValidationData,
  TApplyValidationData,
} from 'shared/validations/applyValidation';
import { ApplyValidationForm } from './form/ApplyValidationForm';
import { useValidationAction } from '../hooks/useValidationAction';

type Props = {
  validationId?: string;
  modal: IModal;
};

export const ApplyValidationModal = ({ modal, validationId }: Props) => {
  const { applyValidation, busy } = useValidationAction();
  const { openModal, handleCloseModal } = modal;

  const initialValues: TApplyValidationData = {
    supportID: 0,
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={applyValidationData}
      onSubmit={async (value) => {
        await applyValidation(value, validationId);
        handleCloseModal();
      }}
    >
      {({ isValid, resetForm, submitForm }) => (
        <CustomizedDialog
          busy={busy}
          cleanData={resetForm}
          open={openModal}
          submitForm={submitForm}
          handleClose={handleCloseModal}
          isValid={isValid}
        >
          <ApplyValidationForm />
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { useCallback } from 'react';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import { split, length } from 'rambda';

import { useFirebase } from 'App/firebase';
import { TFunction, TQuery } from 'shared/types';

type ReturnValues = {
  dbQuery: (path: string) => TQuery;
  apiFunction: () => TFunction;
};

const isEven = (n: number): boolean => n % 2 === 0;

export function useFirebaseApi(): ReturnValues {
  const firebase = useFirebase();

  const dbQuery = useCallback(
    (path) => {
      const dbCall = firebase.firestore();
      return isEven(length(split('/', path)))
        ? dbCall.doc(path)
        : dbCall.collection(path);
    },
    [firebase],
  );

  const apiFunction = useCallback(
    () => firebase.functions().httpsCallable('dashboard'),
    [firebase],
  );

  return {
    dbQuery,
    apiFunction,
  };
}

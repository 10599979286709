import { Formik } from 'formik';
import { CustomizedDialog, IModal, useBusy } from 'shared';
import { ActionMemberForm } from './forms';

type ActionType = 'Reactivate' | 'Suspend' | 'Remove' | null;

type PropType = {
  action: ActionType;
  modal: IModal;
  name?: string;
  businessName?: string;
  member: any;
  handleActionWithMember: (
    action: ActionType,
    member: any,
  ) => Promise<void | null>;
  refetchData?: () => Promise<void>;
};

export const MemberActionsModal = ({
  action,
  modal,
  name,
  businessName,
  member,
  handleActionWithMember,
  refetchData,
}: PropType) => {
  const { openModal, handleCloseModal } = modal;
  const { busy } = useBusy();

  const remove = action === 'Remove' && 'Remove user';
  const suspend = action === 'Suspend' && 'Suspend user';
  const reactivate = action === 'Reactivate' && 'Reactivate';

  return (
    <Formik
      initialValues={{ memberId: member?.id }}
      onSubmit={async () => {
        await handleActionWithMember(action, member);
        if (refetchData) {
          await refetchData();
        }
      }}
    >
      {({ submitForm }) => (
        <CustomizedDialog
          btnColor={action === 'Reactivate' ? 'success' : 'error'}
          btnTitle={(remove || suspend || reactivate) as string}
          open={openModal}
          submitForm={submitForm}
          isValid
          busy={busy}
          handleClose={handleCloseModal}
        >
          <ActionMemberForm
            action={action}
            name={name}
            businessName={businessName}
          />
        </CustomizedDialog>
      )}
    </Formik>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { PlusCircle } from 'phosphor-react';
import { UserInterface } from 'App/api/types/User';
import { CustomizedDialog, Search } from 'shared/components';
import { useFormikContext } from 'formik';
import { TMemberPopulationGroup } from 'shared/validations/memberPopulationGroup';
import { TableUsers } from '../TableUsers';
import { AddMemberForm } from './AddMemberForm';

type PropsType = {
  isBusy: boolean;
  isBusyMember: boolean;
  busyUser: string;
  closeModal: () => void;
  cleanData: () => void;
  users: UserInterface[];
  setEditItem: Dispatch<SetStateAction<string>>;
  setPattern: Dispatch<SetStateAction<string>>;
  modalAddMember: {
    openModal: boolean;
    handleOpenModal: () => void;
    handleCloseModal: () => void;
  };
  data: {
    populationGroupId?: string;
    garageId?: string;
  };
  removeMemberPopulationGroup: (values: any) => Promise<void>;
};

export const EditMembersForm = ({
  isBusy,
  isBusyMember,
  busyUser,
  closeModal,
  users,
  data,
  setEditItem,
  setPattern,
  cleanData,
  modalAddMember,
  removeMemberPopulationGroup,
}: PropsType) => {
  const {
    values,
    submitForm,
    isValid,
    setValues,
    initialValues,
    setTouched,
    initialTouched,
    setErrors,
    initialErrors,
  } = useFormikContext<TMemberPopulationGroup>();

  const cleanFormik = () => {
    setErrors(initialErrors);
    setTouched(initialTouched);
    setValues(initialValues);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h6" component="h6" mb={2}>
        Members
      </Typography>
      <Button
        sx={{ maxWidth: '126px', mb: 4 }}
        color="secondary"
        size="small"
        variant="outlined"
        startIcon={<PlusCircle />}
        onClick={modalAddMember.handleOpenModal}
      >
        Add Member
      </Button>
      <Typography variant="body2" mb={2}>
        {users.length === 1 ? `${users.length} user` : `${users.length} users`}
      </Typography>
      <Search
        callback={setPattern}
        placeholder="Search by phone number or name"
        mb={2}
      />
      <TableUsers
        setValues={setValues}
        openModalMember={modalAddMember.handleOpenModal}
        users={users}
        data={data}
        removeMemberPopulationGroup={removeMemberPopulationGroup}
        busyUser={busyUser}
      />

      <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={2}>
        <Button
          sx={{ width: '85px', mr: 1 }}
          size="large"
          variant="contained"
          disabled
          // onClick={submitForm}
        >
          {isBusy ? <CircularProgress size={20} color="inherit" /> : 'Save'}
        </Button>
        <Button
          sx={{ mr: '10px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={() => {
            setEditItem('');
            cleanData();
            closeModal();
          }}
        >
          Cancel
        </Button>
      </Box>
      <CustomizedDialog
        open={modalAddMember.openModal}
        handleClose={modalAddMember.handleCloseModal}
        submitForm={submitForm}
        cleanData={cleanFormik}
        busy={isBusyMember}
        isValid={isValid}
        btnTitle={!values.isEdit ? 'Add' : 'Edit'}
      >
        <AddMemberForm
          title={!values.isEdit ? 'Add Member' : 'Edit Member'}
          handleClose={modalAddMember.handleCloseModal}
          cleanData={cleanFormik}
        />
      </CustomizedDialog>
    </Box>
  );
};

import { SetStateAction } from 'react';
import { Formik } from 'formik';
import { BasicModal, SUCHEDUAL } from 'shared/components';
import { ISchedules, IScheduledRate } from 'App/api/types';
import { conversionValue } from 'shared/utils/data_tables/validations';
import { ScheduledForm } from './form';
import { useScheduledPricing } from '../../hooks';

type PropsType = {
  handleCloseModal: () => void;
  openModal: boolean;
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  editScheduleRate: {
    scheduleRateData: IScheduledRate | null;
    setScheduleRateData: React.Dispatch<SetStateAction<IScheduledRate | null>>;
  };
};

export const ScheduledModal = ({
  handleCloseModal,
  openModal,
  editRule,
  editScheduleRate,
}: PropsType) => {
  const { scheduleRateData, setScheduleRateData } = editScheduleRate;
  const { ruleData } = editRule;
  const { saveSchedule } = useScheduledPricing();

  const title = scheduleRateData
    ? 'Edit Scheduled Rates'
    : 'Create Scheduled Rates';

  return (
    <Formik
      initialValues={
        scheduleRateData
          ? {
              ...scheduleRateData,
              newRule: ruleData || SUCHEDUAL.newRule,
            }
          : { ...SUCHEDUAL }
      }
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        const payload = {
          amount:
            values.type &&
            values.amount &&
            conversionValue(values.type, String(values.amount)),
        };
        await saveSchedule({ ...values, ...payload } as unknown as ISchedules);
        resetForm();
        handleCloseModal();
      }}
    >
      {({ setValues, initialValues }) => (
        <>
          <BasicModal
            open={openModal}
            setClose={() => {
              handleCloseModal();
              setScheduleRateData(null);
              setValues(initialValues);
            }}
          >
            <ScheduledForm
              title={title}
              busySchedule={false}
              handleCloseModal={handleCloseModal}
              editScheduleRate={editScheduleRate}
              editRule={editRule}
            />
          </BasicModal>
        </>
      )}
    </Formik>
  );
};

import { useMemo } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { PlusCircle } from 'phosphor-react';
import { SessionInterface } from 'App/api/types/Session';
import { EmptyPage } from 'shared';
import { SessionsGrid } from 'shared/components/SessionsGrid';
import { DocumentReference } from 'firebase/firestore';
import { UserInterface } from 'App/api/types/User';
import { SupportSearchForm } from './form/supportSearchForm';

type Props = {
  data: SessionInterface[] | null;
  setData: React.Dispatch<React.SetStateAction<SessionInterface[]>>;
  loading: boolean;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  handleOpenModal: () => void;
  page: number;
  rowsPerPage: number;
  handleChangeRowsPerPage: (pageSize: number) => void;
  handleChangePage: (newPage: number) => void;
  typeOfSearch: 'phone' | 'parkId' | 'licensePlate';
  setTypeOfSearch: React.Dispatch<
    React.SetStateAction<'phone' | 'parkId' | 'licensePlate'>
  >;
  setUserSearch: React.Dispatch<
    React.SetStateAction<DocumentReference<UserInterface> | null>
  >;
  setVehicleSearch: React.Dispatch<
    React.SetStateAction<DocumentReference | null>
  >;
};

const LoadingAndEmptyScreen = ({ loading }: { loading: boolean }) => {
  return !loading ? (
    <EmptyPage title="Please enter a phone number, parkId or license plate to display parking sessions" />
  ) : (
    <Box
      sx={{
        height: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export const SupportTable = ({
  data,
  setData,
  loading,
  phone,
  setPhone,
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  handleOpenModal,
  typeOfSearch,
  setTypeOfSearch,
  setUserSearch,
  setVehicleSearch,
}: Props) => {
  const rowsPerPageOptions = useMemo(() => {
    if (data && data.length > 50 && data.length <= 100) return [25, 50];
    if (data && data.length > 100) return [25, 50, 100];
    return [25];
  }, [data]);

  const fileName = useMemo(() => `${phone} - Summary`, [phone]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          flexDirection: 'row',
          alignItems: 'right',
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<PlusCircle size={24} weight="bold" />}
          onClick={handleOpenModal}
        >
          <Typography
            variant="subtitle1"
            component="div"
            fontFamily="Mont-Bold"
          >
            New session
          </Typography>
        </Button>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="flex-start" my={3}>
        <SupportSearchForm
          setUserSearch={setUserSearch}
          setVehicleSearch={setVehicleSearch}
          setData={setData}
          phone={phone}
          setPhone={setPhone}
          typeOfSearch={typeOfSearch}
          setTypeOfSearch={setTypeOfSearch}
          loading={loading}
        />
      </Box>
      {data?.length && !loading ? (
        <SessionsGrid
          data={data}
          fileName={fileName}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <LoadingAndEmptyScreen loading={loading} />
      )}
    </Box>
  );
};

import { getDollars, getTimeOfMaxParkTimeRoster } from 'shared';
import { ReactComponent as SessionImg } from 'App/assets/sessionImg.svg';
import { ReactComponent as RevenueImg } from 'App/assets/revenueImg.svg';
import { ReactComponent as AverageDurationImg } from 'App/assets/averageDurationImg.svg';

export type StatisticsType = {
  title: string;
  value: string | undefined;
  percent: number;
  image: React.ReactNode;
};

const calculatePercentage = (currentData: number, prevData: number) =>
  ((currentData - prevData) / prevData) * 100;

export const getStatistics = (data: any, prevDate: any): StatisticsType[] => {
  const parkingSessions =
    prevDate?.length > 0
      ? calculatePercentage(data?.length, prevDate?.length)
      : 0;

  const netRevenue = data?.reduce(
    (previousValue: any, currentValue: any) =>
      previousValue + currentValue.netRevenue,
    0,
  );

  const netRevenuePrev = prevDate.reduce(
    (previousValue: any, currentValue: any) =>
      previousValue + currentValue.netRevenue,
    0,
  );

  const netRevenuePercent =
    netRevenuePrev > 0 ? calculatePercentage(netRevenue, netRevenuePrev) : 0;

  const averageDuration =
    data?.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + currentValue.duration,
      0,
    ) / data?.length;

  const averageDurationPrev =
    prevDate.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + currentValue.duration,
      0,
    ) / prevDate.length;

  const averageDurationPercent = averageDurationPrev
    ? calculatePercentage(averageDuration, averageDurationPrev)
    : 0;

  const statistics = [
    {
      title: 'Parking Sessions',
      value: String(data?.length),
      percent: Number(parkingSessions.toFixed(2)),
      image: <SessionImg />,
    },
    {
      title: 'Net Revenue',
      value: `$${getDollars(netRevenue)}`,
      percent: Number(netRevenuePercent.toFixed(2)),
      image: <RevenueImg />,
    },
    {
      title: 'Average Duration',
      value: getTimeOfMaxParkTimeRoster(averageDuration).result,
      percent: Number(averageDurationPercent.toFixed(2)),
      image: <AverageDurationImg />,
    },
  ];
  return statistics;
};

import { Link } from 'react-router-dom';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ArrowRight } from 'phosphor-react';
import GarageDefaultImg from 'App/assets/GarageDefaultImg.svg';
import { routeMapping, routeTo } from 'App/helpers';
import StatusIcon from './StatusIcon';

type Props = {
  name?: string;
  address?: string;
  status: string;
  imgSrc?: string;
  garageId: string;
};
export const CardGarage = ({
  imgSrc,
  name,
  address,
  status,
  garageId,
}: Props) => {
  return (
    <Card
      sx={{
        m: '0 auto',
        maxWidth: 358,
        position: 'relative',
      }}
    >
      <StatusIcon status={status} />
      <CardMedia
        sx={{
          width: 358,
          height: 235,
        }}
        component="img"
        alt="cardImg"
        image={imgSrc || GarageDefaultImg}
      />
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700} color="primary.main">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {address}
          </Typography>
        </Box>
        <Link
          style={{ color: '#212B36' }}
          to={routeTo(`${routeMapping.location}/overview`, {
            locationId: garageId,
          })}
        >
          <ArrowRight size="28px" />
        </Link>
      </CardContent>
    </Card>
  );
};

import { createContext } from 'react';
import { TFDocumentRef } from 'shared';
import { IKeysOperator, ISubscriptionPassOffering, IUser } from './types';
import { IBusiness } from './types/Business';
import { IGarage } from './types/Garage';
import { IAccount } from './types/GarageAccount';
import { UserInterface } from './types/User';
import { IValidation } from './types/Validation';

export interface IApiContext {
  employee: UserInterface | null;
  fetchGarages: () => Promise<void>;
  garages: IGarage[] | null;
  garagesRef: TFDocumentRef[];
  keysOperator: IKeysOperator | null;
  users: IUser[] | null;
  accounts: IAccount[] | null;
  validations: IValidation[] | null;
  businesses: IBusiness[] | null;
  passes: ISubscriptionPassOffering[] | null;
}

const ApiContext = createContext<IApiContext>({
  employee: null,
  fetchGarages: () => new Promise(() => {}),
  garages: null,
  garagesRef: [],
  keysOperator: null,
  users: null,
  accounts: null,
  validations: null,
  businesses: null,
  passes: null,
});

export const ApiProvider = ApiContext.Provider;
export default ApiContext;

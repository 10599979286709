import { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  TextField,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { IScheduledRate } from 'App/api/types';
import { MaskInput, RulesAndExceptions } from 'shared';
import {
  getMask,
  maskScheduled,
  TYPE_OF_VALIDATION,
  TYPE_OF_SCHEDULE,
} from 'shared/utils/data_tables/validations';

type PropsType = {
  title: string;
  busySchedule: boolean;
  editRule: {
    setRuleData: React.Dispatch<SetStateAction<any | null>>;
    ruleData: any | null;
  };
  editScheduleRate: {
    scheduleRateData: IScheduledRate | null;
    setScheduleRateData: React.Dispatch<SetStateAction<IScheduledRate | null>>;
  };
  handleCloseModal: () => void;
};

export const ScheduledForm = ({
  handleCloseModal,
  busySchedule,
  title,
  editScheduleRate,
  editRule,
}: PropsType) => {
  const {
    handleBlur,
    isValid,
    submitForm,
    values,
    touched,
    errors,
    handleChange,
    setValues,
    setFieldValue,
    initialValues,
  } = useFormikContext<any>();

  const mask = getMask(values.type);
  const reset = maskScheduled();

  return (
    <Form>
      <Typography variant="h4" component="div" mb={5}>
        {title}
      </Typography>
      <Typography variant="h6" component="div" mb={2}>
        Name
      </Typography>
      <Typography variant="body2" mb={2}>
        Set the user-facing name of the subscription pass offering.
      </Typography>
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        name="name"
        type="text"
        value={values.name}
        error={touched?.name && Boolean(errors?.name)}
        helperText={touched?.name && errors?.name}
      />

      <Typography variant="h6" component="div" fontWeight={700} mt={2} mb={2}>
        Priority
      </Typography>
      <Typography variant="body2" component="div" mb={2}>
        Select a priority between 0 and 999. Population groups with a higher
        priority number will take precedence when multiple population groups are
        applicable.
      </Typography>
      <TextField
        fullWidth
        onBlur={handleBlur}
        value={values.priority}
        label="Priority"
        name="priority"
        type="number"
        InputProps={{ inputProps: { max: 100, min: 10 } }}
        onChange={handleChange}
        error={touched.priority && Boolean(errors.priority)}
        helperText={touched.priority && errors.priority}
      />

      <FormControlLabel
        control={
          <Checkbox onChange={handleChange} name="isOverPopulationGroup" />
        }
        label={
          <Typography mt={0.1} variant="body2">
            Should this scheduled rate take precedence above population groups?
          </Typography>
        }
      />

      <Typography variant="h6" component="div" fontWeight={700} mt={2} mb={2}>
        Discount
      </Typography>
      <Typography variant="body2" component="div" mb={2}>
        Choose the pricing type for the population group. Flat fee and capped
        modify the user to a new rate, which can be more or less than the
        garage’s base rate. Time-based, percentage, and dollar amount discounts
        modify and decrease the price charged to the user.
      </Typography>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {TYPE_OF_VALIDATION.map((item) => (
          <FormControlLabel
            key={item.value}
            control={
              <Radio
                checked={values.type === item.value}
                onChange={() => setFieldValue('type', item.value)}
                name="type"
              />
            }
            label={<Typography variant="body2">{item.text}</Typography>}
          />
        ))}
      </Box>
      {values.type && mask && (
        <MaskInput
          sx={{ mt: 1 }}
          type="text"
          name="amount"
          mask={mask.mask}
          label={mask.label}
          blocks={mask.blocks}
          placeholder={mask.label}
        />
      )}

      <Typography variant="h6" component="div" fontWeight={700} mb={2} mt={2}>
        Price Reset
      </Typography>
      <Typography variant="body2" component="div" mb={1}>
        Decide how the price accumulates or if it is a fixed amount that does
        not reset. At the reset time, the parking fare begins accumulating
        again.
      </Typography>

      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {TYPE_OF_SCHEDULE.map((item) => (
          <FormControlLabel
            key={item.value}
            control={
              <Radio
                checked={values.resetLogic.type === item.value}
                onChange={() => setFieldValue('resetLogic.type', item.value)}
                name="resetLogic.type"
              />
            }
            label={<Typography variant="body2">{item.text}</Typography>}
          />
        ))}
      </Box>

      {values.resetLogic.type && reset && (
        <MaskInput
          type="text"
          sx={{ mt: 2 }}
          name="resetLogic.value"
          mask={reset.mask}
          label={reset.label}
          blocks={reset.blocks}
          placeholder={reset.label}
        />
      )}

      <RulesAndExceptions
        rules={values.validity.rules}
        exceptions={values.validity.exceptions}
        editRule={editRule}
        editSubscription={{
          setSubscriptionData: editScheduleRate.setScheduleRateData as any,
          subscriptionData: editScheduleRate.scheduleRateData,
        }}
      />

      <Divider sx={{ mt: 4, mx: -4 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          paddingTop: '32px',
        }}
      >
        <Button
          sx={{ width: '85px' }}
          size="large"
          variant="contained"
          disabled={!isValid}
          onClick={submitForm}
        >
          {busySchedule ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            'Save'
          )}
        </Button>
        <Button
          sx={{ ml: '12px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={() => {
            handleCloseModal();
            setValues(initialValues);
            editScheduleRate.setScheduleRateData(null);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

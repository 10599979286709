import { SetStateAction, useMemo } from 'react';
import { Formik } from 'formik';
import { AccordionGeneral } from 'shared/components';
import {
  getFormatedTime,
  checkDayForResetTime,
  getDifferentTimezone,
  currentTimezone,
  getMinutesFromGracePeriod,
} from 'shared/utils';
import { useBusy } from 'shared';
import moment from 'moment';
import { SettingsForm } from './SettingsForm';
import { optionsGracePeriod } from '../../constants/settingsGarage';

type Props = {
  gracePeriod: number;
  diffTimezoneMiliseconds: number;
  resetTime: number | null;
  savePeriod: <T>(payload: T) => void;
  isEditSettings: boolean;
  setIsEditSettings: React.Dispatch<SetStateAction<boolean>>;
};

type TSettings = {
  gracePeriod?: {
    text: string;
    value: string;
  };
  resetTime: Date | null;
  isNoResetTime: boolean;
};

export const Settings = ({
  gracePeriod,
  resetTime,
  savePeriod,
  isEditSettings,
  setIsEditSettings,
  diffTimezoneMiliseconds,
}: Props) => {
  const { busy } = useBusy();

  const timeReset = useMemo(
    () =>
      typeof resetTime === 'number' &&
      Number.isInteger(resetTime) &&
      resetTime !== -1
        ? new Date(
            getFormatedTime(checkDayForResetTime(resetTime)) +
              diffTimezoneMiliseconds -
              getDifferentTimezone(currentTimezone),
          )
        : new Date(moment(0).valueOf() - getDifferentTimezone(currentTimezone)),
    [diffTimezoneMiliseconds, resetTime],
  );

  const SETTINGS: TSettings = {
    gracePeriod: optionsGracePeriod.find(
      (item) => item.value === getMinutesFromGracePeriod(gracePeriod),
    ),
    resetTime: timeReset,
    isNoResetTime: resetTime === -1,
  };

  return (
    <AccordionGeneral title="Settings">
      <Formik initialValues={SETTINGS} enableReinitialize onSubmit={savePeriod}>
        <SettingsForm
          busy={busy}
          isEditSettings={isEditSettings}
          setIsEditSettings={setIsEditSettings}
        />
      </Formik>
    </AccordionGeneral>
  );
};

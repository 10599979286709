import { createContext } from 'react';

export interface IPaymentContext {
  supportPaymentMethodsSetup: (sessionID: string) => Promise<void>;
  handleSubmitCreatePaymentMethod: (
    stripe: any,
    elements: any,
  ) => Promise<void>;
  setClientSecret: (clinetSecret: string | undefined) => void;
  clientSecret: string | undefined;
}

const PaymentContext = createContext<IPaymentContext>({
  supportPaymentMethodsSetup: () => new Promise(() => {}),
  handleSubmitCreatePaymentMethod: () => new Promise(() => {}),
  setClientSecret: () => {},
  clientSecret: undefined,
});

export const PaymentProvider = PaymentContext.Provider;
export default PaymentContext;

import { Form, useFormikContext } from 'formik';
import { Box, TextField, Typography } from '@mui/material';
import { TApplyValidationData } from 'shared/validations/applyValidation';

export const ApplyValidationForm = () => {
  const { handleBlur, handleChange } = useFormikContext<TApplyValidationData>();

  return (
    <Form>
      <Box p={3} width="100%">
        <Typography variant="h4" component="h1">
          Apply Validation
        </Typography>
        <Typography variant="body2" mt={3} mb={2}>
          Ask the user for ther 6 digit park ID and enter it below to apply a
          validation to their session. The park ID can be found in the top right
          of the parking session screen. You can also apply a validation from
          the Support tab by searching a users phone number.
        </Typography>
        <TextField
          sx={{ mb: 7 }}
          fullWidth
          label="6 Digit park ID"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          name="supportID"
          type="number"
        />
      </Box>
    </Form>
  );
};

import { Button, Typography, Box, Container, IconButton } from '@mui/material';
import { PlusCircle } from 'phosphor-react';
import { Search, useResponsive } from 'shared';
import { RenderBusinesses } from './components';
import AddBusinesses from './components/AddBusinesses';
import { useBusinesses } from './hooks';

const Businesses = () => {
  const {
    garage,
    openModal,
    handleCloseModal,
    handleOpenModal,
    businessesData,
    isLoading,
    businessInfo,
    setPattern,
  } = useBusinesses();
  const isDesktop = useResponsive('up', 'sm');

  return (
    <Container>
      {garage && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: isDesktop ? 4 : 2,
            }}
          >
            <Search
              callback={setPattern}
              placeholder="Search by business name"
            />
            {isDesktop && (
              <Button
                size="large"
                variant="outlined"
                startIcon={<PlusCircle size={24} weight="bold" />}
                onClick={handleOpenModal}
              >
                <Typography variant="subtitle1">New business</Typography>
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: isDesktop ? '15px' : '40px',
            }}
          >
            <Typography variant="body1" component="span">
              {businessInfo}
            </Typography>
            {!isDesktop && (
              <IconButton onClick={handleOpenModal} type="submit" sx={{ p: 1 }}>
                <PlusCircle weight="light" size={24} />
              </IconButton>
            )}
          </Box>
          <RenderBusinesses businesses={businessesData} isLoading={isLoading} />
          <AddBusinesses
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        </>
      )}
    </Container>
  );
};

export default Businesses;

import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import { PlusCircle } from 'phosphor-react';
import { Search } from 'shared/components';

import { CreateAccountModal } from './components';
import { RenderAccounts } from './components/RenderAccounts';
import { useAccounts } from './hooks';

export const Accounts = () => {
  const {
    accounts,
    currentAccount,
    setCurrentAccount,
    businesses,
    busy,
    setPattern,
    isDesktop,
    accountsInfo,
    garage,
    modal,
    loading,
    goToAccount,
  } = useAccounts();
  return (
    <Container>
      {garage && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: isDesktop ? 4 : 2,
            }}
          >
            <Search
              callback={setPattern}
              placeholder="Search by account or business name"
            />
            {isDesktop && (
              <Button
                size="large"
                variant="outlined"
                startIcon={<PlusCircle size={24} weight="bold" />}
                onClick={modal.handleOpenModal}
              >
                <Typography variant="subtitle1">Add account</Typography>
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: isDesktop ? '15px' : '40px',
            }}
          >
            <Typography variant="body1" component="span">
              {accountsInfo}
            </Typography>
            {!isDesktop && (
              <IconButton
                type="submit"
                sx={{ p: 1 }}
                color="primary"
                onClick={modal.handleOpenModal}
              >
                <PlusCircle weight="light" size={24} />
              </IconButton>
            )}
          </Box>
          <RenderAccounts
            accounts={accounts}
            loading={loading}
            editModal={modal}
            setCurrentAccount={setCurrentAccount}
            goToAccount={goToAccount}
          />

          <CreateAccountModal
            modal={modal}
            busy={busy}
            accountData={currentAccount}
            businesses={businesses}
            setCurrentAccount={setCurrentAccount}
          />
        </>
      )}
    </Container>
  );
};

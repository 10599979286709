import { Box, Typography } from '@mui/material';
import { AccordionGeneral } from 'shared/components';

import { useRateTables } from '../../hooks';
import { RateCard } from './RateCard';

export const BaseRate = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  const { baseRate, busy } = useRateTables();

  return (
    <AccordionGeneral title="Base Rate">
      <Box>
        <Typography variant="body1" component="div" mb={2}>
          Select the rate table that will be used to charge users for parking.
          This rate table will be used for every visitor by default, as well as
          for users that overstay the valid duration of a pass.
        </Typography>

        <Box>
          {baseRate && (
            <RateCard
              handleOpenModal={handleOpenModal}
              data={baseRate}
              busy={busy}
            />
          )}
        </Box>
      </Box>
    </AccordionGeneral>
  );
};

import { Formik } from 'formik';

import { CustomizedDialog, getDollars, useGarageData } from 'shared';
import {
  addValidationsValidation,
  TValidation,
} from 'shared/validations/addValidation';
import {
  conversionValue,
  reverceConversionValue,
} from 'shared/utils/data_tables/validations';
import { IBusiness } from 'App/api/types/Business';
import { IValidation } from 'App/api/types/Validation';
import { AddValidationForm } from './form/AddValidationForm';
// import { useValidations } from '../hooks/useValidations';

type PropType = {
  handleCloseModal: () => void;
  openModal: boolean;
  validation?: IValidation | null;
  sponsors: IBusiness[];
  createValidation: any;
  busy: boolean;
};

const NEW_VALIDATION: TValidation = {
  validationId: '',
  name: '',
  business: '',
  type: 'hours',
  isVisible: false,
  isUserPays: true,
  businessCharged: true,
  businessValue: '',
  description: '',
  garageId: '',
  value: '',
};

export default function AddValidation({
  openModal,
  handleCloseModal,
  validation,
  sponsors,
  createValidation,
  busy,
}: PropType): JSX.Element {
  const { garageId } = useGarageData();
  const title = validation ? 'Edit validation' : 'New validation';
  const editValues = {
    validationId: validation?.id,
    name: validation?.name,
    business: validation?.business.id,
    description: validation?.description,
    type: validation?.type,
    value:
      validation?.type &&
      validation?.value &&
      reverceConversionValue(validation?.type, validation?.value),
    isVisible: validation?.isPublic,
    isUserPays: validation?.billToMaster?.type === 'none',
    businessCharged: validation?.billToMaster?.type !== 'perUse',
    businessValue:
      (validation?.billToMaster?.type === 'perUse' &&
        validation.billToMaster.amount &&
        `$${getDollars(validation.billToMaster.amount)}`) ||
      '',
  };

  return (
    <Formik
      initialValues={{
        ...(validation ? editValues : NEW_VALIDATION),
        garageId,
      }}
      enableReinitialize
      validationSchema={addValidationsValidation}
      onSubmit={async (values) => {
        const data = {
          value:
            values.type &&
            values.value &&
            conversionValue(values.type, String(values.value)),
        };
        await createValidation(values.business, data, values);

        handleCloseModal();
      }}
    >
      {({ submitForm, isValid, resetForm }) => (
        <>
          <CustomizedDialog
            open={openModal}
            cleanData={resetForm}
            handleClose={handleCloseModal}
            submitForm={submitForm}
            busy={busy}
            isValid={isValid}
          >
            <AddValidationForm title={title} sponsors={sponsors} />
          </CustomizedDialog>
        </>
      )}
    </Formik>
  );
}

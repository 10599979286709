import { useState, useEffect, useCallback } from 'react';
import { isEmpty, complement } from 'rambda';

import {
  getPhotoName,
  getSampleImage,
  readAsDataURL,
} from 'shared/utils/images';
import { useApiFunction, useBusy, useToast } from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';
import { useOverview } from './useOverview';
import { TPhoto } from '../components/Photos/TPhoto';

export const notEmpty = complement(isEmpty);

export const usePhotos = () => {
  const { garage, garageId, checkPermission } = useOverview();
  const { logo: logoApi, photos: photosApi } = garage || {};
  const { busy, setBusy } = useBusy();
  const toast = useToast();

  const [logo, setLogo] = useState<string>('');
  const [isEditPhotos, setIsEditPhotos] = useState<boolean>(true);
  const [viewedLogo, setViewedLogo] = useState('');
  const [photos, setPhotos] = useState<string[]>([]);

  const removeLogo = useApiFunction();
  const setLogoFn = useApiFunction();
  const setPhotosFn = useApiFunction();
  const removePhotoFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const deleteLogo = useCallback(
    async (img) => {
      try {
        await removeLogo({
          garageId,
          logo: {
            name: getPhotoName(img),
          },
        });
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      }
    },
    [garageId, removeLogo, setErrorContent],
  );

  const setNewLogo = useCallback(
    async (file) => {
      try {
        const payload = await readAsDataURL('PNG', file[0], 800);
        await setLogoFn({
          garageId,
          logo: payload,
        });
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      }
    },
    [garageId, setErrorContent, setLogoFn],
  );

  const setNewPhotos = useCallback(
    async (images) => {
      try {
        const payload = await Promise.all(
          images.map((image: Blob) => {
            return readAsDataURL('JPEG', image, 600);
          }),
        );
        await setPhotosFn({
          garageId,
          images: payload,
        });
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      }
    },
    [garageId, setErrorContent, setPhotosFn],
  );

  const deletePhoto = useCallback(
    async (img) => {
      try {
        await removePhotoFn({
          garageId,
          images: [
            {
              name: getPhotoName(img),
            },
          ],
        });
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      }
    },
    [garageId, removePhotoFn, setErrorContent],
  );

  const savePhotos = async (payload: TPhoto) => {
    try {
      setBusy(true);
      if (payload.deleteLogo) {
        await deleteLogo(payload.deleteLogo);
      }
      if (Array.isArray(payload.logo) && payload.logo.length) {
        await setNewLogo(payload.logo);
      }
      if (payload.newPhotos.length) {
        await setNewPhotos(payload.newPhotos);
      }
      if (payload.deletePhotos.length) {
        await Promise.all(
          payload.deletePhotos.map((photo: string) => deletePhoto(photo)),
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      setBusy(false);
      setIsEditPhotos(true);
      toast.success('Photos updated!');
    }
  };

  useEffect(() => {
    if (logoApi && garageId)
      getSampleImage(garageId, setLogo, 'garageLogos', true);
    else setLogo('');
    if (photosApi && notEmpty(photosApi) && garageId) {
      getSampleImage(garageId, setPhotos, 'garagePhotos', false);
    }
    if (isEmpty(photosApi)) {
      setPhotos([]);
    }
    return () => {
      setLogo('');
      setPhotos([]);
    };
  }, [logoApi, garageId, photosApi]);

  const photosData = { logo, photos };
  const editPhotos = { isEditPhotos, setIsEditPhotos };

  return {
    busy,
    photosData,
    editPhotos,
    savePhotos: checkPermission(savePhotos),
    viewedLogo,
    setViewedLogo,
  };
};

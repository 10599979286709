import { Box, TextField, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Form, useFormikContext } from 'formik';
import { isValidNumber } from 'libphonenumber-js';
import { SelectInput } from 'shared';
import { TMember } from 'shared/validations/member';

type TPassOptions = { text: string; value: string };

type PropsType = {
  isMemberData: boolean;
  subscriptionPassOfferingOptions: TPassOptions[];
};

export const AddMemberForm = ({
  isMemberData,
  subscriptionPassOfferingOptions,
}: PropsType) => {
  const { values, setFieldValue, handleBlur, handleChange, touched } =
    useFormikContext<TMember>();

  const title = isMemberData ? 'Edit member' : 'Add member';

  return (
    <Box p={3}>
      <Form>
        <Typography variant="h4" component="div" mb={3}>
          {title}
        </Typography>
        <Typography variant="h6" component="div" mb={2}>
          User Information
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '16px',
          }}
        >
          <TextField
            sx={{ width: '48%' }}
            label="First name (optional)"
            variant="outlined"
            onBlur={handleBlur}
            onChange={handleChange}
            name="firstName"
            type="text"
            value={values.firstName}
          />
          <TextField
            sx={{ width: '48%' }}
            label="Last name (optional)"
            variant="outlined"
            onBlur={handleBlur}
            onChange={handleChange}
            name="lastName"
            type="text"
            value={values.lastName}
          />
        </Box>
        <ReactPhoneInput
          value={values.phone}
          onChange={(e) => setFieldValue('phone', `+${e}`)}
          disabled={isMemberData}
          component={TextField}
          inputProps={{
            onBlur: handleBlur,
            name: 'phone',
            error: touched.phone && !isValidNumber(values.phone),
            helperText:
              touched.phone && !isValidNumber(values.phone) && 'Invalid',
          }}
        />

        <Typography variant="h6" component="div" mt={7} mb={2}>
          Subscription Pass
        </Typography>
        <SelectInput
          onBlur={handleBlur}
          name="subscriptionPassOfferingID"
          options={subscriptionPassOfferingOptions}
          label="Offering"
        />
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Spot number (optional)"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          name="spotNumber"
          type="text"
          value={values.spotNumber}
        />
      </Form>
    </Box>
  );
};

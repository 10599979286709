import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Button } from '@mui/material';

import { useAuth, useCollapseDrawer } from 'shared';
import { ReactComponent as Logo } from 'App/assets/Logo.svg';
import { authenticatedRoutes, generateRoutePath, RouteName } from 'App/helpers';
import Scrollbar from './scrollbar/Scrollbar';
import NavbarDocs from './NavbarDocs';
import NavSection from './nav-section';
import UserProfile from '../profile';

export default function ContentNavbar() {
  const { isCollapse } = useCollapseDrawer();
  const { user, organization, signout } = useAuth();

  const userName = useMemo(
    () => user && `${user.firstName} ${user.lastName}`,
    [user],
  );

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 10,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to={generateRoutePath({ name: RouteName.Home })}>
            <Logo />
          </Link>
        </Stack>
        <UserProfile userName={userName} organization={organization} />
      </Stack>

      <NavSection
        navConfig={authenticatedRoutes}
        isCollapse={isCollapse}
        permissions={user && user?.permissions}
      />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && <NavbarDocs userName={userName} />}
      <Stack
        spacing={3}
        sx={{
          px: 5,
          pb: 5,
          width: 1,
          textAlign: 'center',
          display: 'block',
        }}
      >
        <Button variant="contained" onClick={signout}>
          Exit
        </Button>
      </Stack>
    </Scrollbar>
  );
}

import { memo, ReactNode, useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';

import { getLocationsRoutes } from 'App/helpers';
import { useAuth, useBusy, useGarageData } from 'shared';
import ErrorModel from 'shared/components/ErrorSnackbar';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { RootStyle, MainStyle } from './styles';
import DashboardNavbar from './navbar';
import DashboardHeader from './header';
import { Loader } from './TopBarProgress';
import BreadCrumbs from './breadcrumbs/BreadCrumbs';
import { NavBarLocations } from './navBarLocations/NavBarLocations';

const Layout = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuth();

  const { busy } = useBusy();
  const { garage } = useGarageData();
  const params = useParams();
  const { errorContent } = useErrorHandler();
  const locationsRoutes = getLocationsRoutes(params);

  return (
    <RootStyle>
      {user ? (
        <>
          <DashboardHeader onOpenSidebar={() => setOpen(true)} />
          <DashboardNavbar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <MainStyle>
            <Box
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              {errorContent.openError && <ErrorModel />}
              {busy && <Loader />}
              <BreadCrumbs />
              {garage && (
                <NavBarLocations
                  locationsRoutes={locationsRoutes}
                  permissions={user && user.permissions}
                />
              )}
              {children}
            </Box>
          </MainStyle>
        </>
      ) : (
        <Loader />
      )}
    </RootStyle>
  );
};

export default memo(Layout);

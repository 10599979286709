import { Container, Typography, Box, Button, IconButton } from '@mui/material';
import { PlusCircle } from 'phosphor-react';

import { BasicModal, Search } from 'shared';
import { useLocations } from './hooks';
import { RenderLocations, AddGarage } from './components';

export const Locations = () => {
  const {
    isDesktop,
    locationsInfo,
    locations,
    setPattern,
    openModal,
    handleOpenModal,
    handleCloseModal,
  } = useLocations();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: isDesktop ? 4 : 2,
        }}
      >
        <Search callback={setPattern} placeholder="Search by name or address" />
        {isDesktop && (
          <Button
            size="large"
            variant="outlined"
            startIcon={<PlusCircle size={24} weight="bold" />}
            onClick={handleOpenModal}
          >
            <Typography variant="subtitle1">New Location</Typography>
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: isDesktop ? '15px' : '40px',
        }}
      >
        <Typography variant="body1" component="span">
          {locationsInfo}
        </Typography>
        {!isDesktop && (
          <IconButton onClick={handleOpenModal} type="submit" sx={{ p: 1 }}>
            <PlusCircle weight="light" size={24} color="green" />
          </IconButton>
        )}
      </Box>
      <RenderLocations locations={locations} />
      <BasicModal open={openModal} setClose={handleCloseModal}>
        <AddGarage closeModal={handleCloseModal} />
      </BasicModal>
    </Container>
  );
};

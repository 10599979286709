import * as Yup from 'yup';
import { coordinatesValidationScheme } from './address';
import { zipcodeRex } from './regexp';

export const addGarageValidation = Yup.object({
  name: Yup.string()
    .min(6, 'Must be 6 characters or more')
    .required('Required'),
  address: Yup.object({
    street: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip: Yup.string()
      .matches(zipcodeRex, 'Zip Code is not valid')
      .required('Required'),
    state: Yup.string().required('Required'),
  }),
  arrivalInstructions: Yup.string()
    .max(2000, 'Must be 2000 characters or less')
    .required('Required'),
  coordinate: coordinatesValidationScheme,
  timezone: Yup.object({
    identifier: Yup.string().required('Required'),
  }),
});

export type TGarage = Yup.InferType<typeof addGarageValidation>;

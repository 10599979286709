import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ISchedules, IRules } from 'App/api/types';
import {
  getRuleFormAutocomplete,
  TFBCollection,
  useApiFunction,
  useBusy,
  useFirebaseApi,
  useGarageData,
  useModal,
  useToast,
} from 'shared';
import { normalizeCollection } from 'shared/utils';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

export const usePasses = () => {
  const { garageId, timezone } = useGarageData();
  const { dbQuery } = useFirebaseApi();
  const { busy: busySchedule, setBusy } = useBusy();
  const toast = useToast();
  const modalSchedules = useModal();
  const modalRule = useModal();
  const modalException = useModal();

  const [schedules, setSchedules] = useState<ISchedules[] | null>(null);
  const [scheduleData, setScheduleData] = useState<ISchedules | null>(null);
  const [ruleData, setRuleData] = useState<IRules | null>(null);
  const [exceptionData, setExceptionData] = useState<IRules | null>(null);

  const setOneSchedulesFn = useApiFunction();
  const { setErrorContent } = useErrorHandler();

  const saveSchedule = useCallback(
    async (data) => {
      try {
        setBusy(true);
        await setOneSchedulesFn({
          action: data.id
            ? 'garages_edit_subscription_pass_offering'
            : 'garages_create_subscription_pass_offering',
          data: {
            garageID: garageId,
            subscriptionPassOfferingData: {
              ...(data.id ? { subscriptionID: data.id } : {}),
              name: data.name,
              parkLimit: Number(data.parkLimit),
              interval: data.interval,
              period: data.period,
              validity: {
                rules: data.validity.rules.map(
                  ({
                    startDate,
                    enterAfter,
                    enterBefore,
                    exitAfter,
                    exitBefore,
                    ...rest
                  }: IRules) => ({
                    items: {
                      startDate: startDate?.toMillis(),
                      enter: {
                        after: { time: enterAfter, offset: 1 },
                        before: { time: enterBefore, offset: 1 },
                      },
                      exit: {
                        after: { time: exitAfter, offset: 1 },
                        before: { time: exitBefore, offset: 1 },
                      },
                      ...rest,
                    },
                  }),
                ),
                exceptions: data.validity.exceptions.map(
                  ({
                    startDate,
                    enterAfter,
                    enterBefore,
                    exitAfter,
                    exitBefore,
                    ...rest
                  }: IRules) => ({
                    items: {
                      startDate: startDate?.toMillis(),
                      enter: {
                        after: { time: enterAfter, offset: 1 },
                        before: { time: enterBefore, offset: 1 },
                      },
                      exit: {
                        after: { time: exitAfter, offset: 1 },
                        before: { time: exitBefore, offset: 1 },
                      },
                      ...rest,
                    },
                  }),
                ),
              },
            },
          },
        });

        if (data.id) toast.success('Subscription Pass Offering Edited!');
        else toast.success('Subscription Pass Offering Created!');
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
        modalSchedules.handleCloseModal();
      }
    },
    [
      garageId,
      modalSchedules,
      setBusy,
      setErrorContent,
      setOneSchedulesFn,
      toast,
    ],
  );

  // ! Check collection name of scheduledRates
  useEffect(() => {
    let unsubscribe: () => void;
    if (garageId) {
      unsubscribe = (
        dbQuery(`garages/${garageId}/scheduledRates`) as TFBCollection
      ).onSnapshot((docs) => {
        const normalaizeData = normalizeCollection(docs) as ISchedules[];
        const setIdrules = normalaizeData.map((shedule) => {
          return {
            ...shedule,
            rules: shedule?.rules?.map((rule) => {
              return { ...rule, id: uuidv4() };
            }),
            exceptions: shedule?.exceptions?.map((exception) => {
              return { ...exception, id: uuidv4() };
            }),
          };
        });

        setSchedules(setIdrules);
      });
    }

    return () => {
      setSchedules(null);
      unsubscribe();
    };
  }, [dbQuery, garageId, setErrorContent]);

  return {
    timezone,
    schedules,
    modalSchedules,
    modalRule,
    saveSchedule,
    busySchedule,
    editSchedule: { scheduleData, setScheduleData },
    editRule: {
      ruleData:
        ruleData && (getRuleFormAutocomplete(ruleData) as unknown as IRules),
      setRuleData,
    },
    modalException,
    editException: {
      exceptionData:
        exceptionData &&
        (getRuleFormAutocomplete(exceptionData) as unknown as IRules),
      setExceptionData,
    },
  };
};

import { Box, TextField, Typography } from '@mui/material';
import { IBusiness } from 'App/api/types/Business';
import { Form, useFormikContext } from 'formik';
import { SelectInput } from 'shared/controls';
import { TAccount } from 'shared/validations/account';

type Props = {
  title: string;
  businesses: IBusiness[];
};

export const CreateAccountForm = ({ title, businesses }: Props) => {
  const { handleBlur, values, touched, errors, handleChange } =
    useFormikContext<TAccount>();

  const businessOptions = businesses.map((business: IBusiness) => {
    return { text: business.name ?? '', value: business.id };
  });

  return (
    <Box p={3}>
      <Form>
        <Typography variant="h4" component="div" mb={6}>
          {title}
        </Typography>
        <Typography variant="h6" component="div" mb={2}>
          Name
        </Typography>
        <Typography variant="body2" mb={2}>
          Set the user-facing name of the account.
        </Typography>
        <TextField
          sx={{ mb: 7 }}
          fullWidth
          label="Name"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          name="name"
          type="text"
          value={values.name}
          error={touched?.name && Boolean(errors?.name)}
          helperText={touched?.name && errors?.name}
        />

        <Typography variant="h6" component="div" mb={2}>
          Business
        </Typography>
        <Typography variant="body2" mb={2}>
          Select the business that this account is linked to
        </Typography>
        <SelectInput
          onBlur={handleBlur}
          name="businessId"
          options={businessOptions}
          label="Business"
        />
      </Form>
    </Box>
  );
};

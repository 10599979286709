import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { FunctionsError } from 'firebase/functions';
import { routeMapping } from 'App/helpers';
import {
  normalizeCollection,
  normalizeDoc,
  TFBCollection,
  TFBDocumentSnapshot,
  TUnsubscribe,
  useApiFunction,
  useBusy,
  useFilteredData,
  useFirebaseApi,
  useModal,
  useToast,
} from 'shared';
import {
  GarageSubscriptionPassOfferingConverter,
  ISubscriptionPassOffering,
} from 'App/api/types';
import { IAccount } from 'App/api/types/GarageAccount';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { BusinessConverter, IBusiness } from 'App/api/types/Business';
import { MemberType } from '../types';

type StateType = {
  state: {
    status: 'disabled' | 'active' | undefined;
    name: string;
    businessName: string;
    business: {
      businessId: string;
    };
  };
};

export const useAccount = () => {
  const { dbQuery } = useFirebaseApi();
  const apiFn = useApiFunction();
  const navigate = useNavigate();
  const toast = useToast();
  const { accountId, locationId } = useParams();
  const { state } = useLocation() as StateType;
  const { busy, setBusy } = useBusy();

  const modalEditAccount = useModal();
  const modalAddMember = useModal();
  const { setErrorContent } = useErrorHandler();
  const [members, setMembers] = useState<MemberType[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAccount, setCurrentAccount] = useState<IAccount | null>(null);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [subscriptionPassOfferings, setSubscriptionPassOfferings] = useState<
    ISubscriptionPassOffering[]
  >([]);
  const [filterMember, setFilterMember] = useState('all');

  const businessesUnsubscribe = useRef<TUnsubscribe | null>(null);
  const supscriptionPassOfferingsUnsubscribe = useRef<TUnsubscribe | null>(
    null,
  );

  const handleFilterMember = (
    _event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setFilterMember(newValue);
  };

  const accountData = {
    id: accountId,
    name: state.name,
    businessName: state.businessName,
    business: state.business,
    status: state.status,
  };

  const { setPattern, output } = useFilteredData<MemberType[] | null>(members, [
    'name',
    'phone',
    'passName',
  ]);

  const filteredMembers = useMemo(() => {
    if (filterMember === 'all') return output;

    return output?.filter(
      (member: MemberType) => member.status === filterMember,
    );
  }, [filterMember, output]);

  const membersInfo = useMemo(() => {
    if (!filteredMembers) return null;

    if (filteredMembers.length > 1 || filteredMembers.length === 0)
      return `${filteredMembers.length} members`;

    return `${filteredMembers?.length} member`;
  }, [filteredMembers]);

  const goToBack = () =>
    navigate(
      `${routeMapping.locations}/${locationId}${routeMapping.accountPrefix}`,
    );

  const getMembers = useCallback(async () => {
    try {
      const response = await dbQuery(
        `/garages/${locationId}/accounts/${accountId}/members/`,
      ).get();
      const normalaizeMembers = normalizeCollection<any>(response as any);

      setIsLoading(true);
      await Promise.all(
        normalaizeMembers.map(async (member) => {
          const passData = await dbQuery(
            member.subscriptionPassOffering.path,
          ).get();
          const normalizePassData = normalizeDoc(
            passData as TFBDocumentSnapshot,
          );

          const userData = await dbQuery(member.user.path).get();
          const normalizeuserData = normalizeDoc(userData as any);

          return {
            ...member,
            name: `${normalizeuserData?.firstName} ${normalizeuserData?.lastName}`,
            phone: normalizeuserData?.phone,
            passName: normalizePassData.name,
          };
        }),
      )
        .then((res) => setMembers(res))
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  }, [accountId, dbQuery, locationId, toast]);

  const createMember = useCallback(
    async ({
      firstName,
      lastName,
      phone,
      subscriptionPassOfferingID,
      spotNumber,
    }) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_members_create',
          data: {
            garageID: locationId,
            accountID: accountId,
            accountMemberData: {
              ...(firstName ? { firstName } : null),
              ...(lastName ? { lastName } : null),
              phone,
              subscriptionPassOfferingID,
              spotNumber,
            },
          },
        });
        await getMembers();
        toast.success('Member created!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        setBusy(false);
        modalAddMember.handleCloseModal();
      }
    },
    [
      setBusy,
      apiFn,
      locationId,
      accountId,
      getMembers,
      toast,
      setErrorContent,
      modalAddMember,
    ],
  );

  const editMember = useCallback(
    async ({ id, status, firstName, lastName, subscriptionPassOfferingID }) => {
      try {
        setBusy(true);
        await apiFn({
          action: 'garages_accounts_members_edit',
          data: {
            garageID: locationId,
            accountID: accountId,
            accountMemberData: {
              id,
              ...(firstName ? { firstName } : null),
              ...(lastName ? { lastName } : null),
              subscriptionPassOfferingID,
              status,
            },
          },
        });

        toast.success('Member Edited!');
      } catch (e) {
        if (e instanceof Error) {
          const err = e as FunctionsError;
          setErrorContent({
            errorHeader: err.message,
            errorBody: err.details as string,
            openError: true,
          });
        }
      } finally {
        getMembers();
        setBusy(false);
        modalAddMember.handleCloseModal();
      }
    },
    [
      accountId,
      setErrorContent,
      apiFn,
      getMembers,
      locationId,
      modalAddMember,
      setBusy,
      toast,
    ],
  );

  const getBusinesses = useCallback(() => {
    businessesUnsubscribe.current = (
      dbQuery(`garages/${locationId}/businesses`) as TFBCollection
    )
      .withConverter(BusinessConverter)
      .onSnapshot(async (docs) => {
        const normalaizeValidations = normalizeCollection(docs);
        setBusinesses(normalaizeValidations as IBusiness[]);
      });
  }, [dbQuery, locationId]);

  const getSubscriptionPassOfferings = useCallback(() => {
    supscriptionPassOfferingsUnsubscribe.current = (
      dbQuery(
        `garages/${locationId}/subscriptionPassOfferings`,
      ) as TFBCollection
    )
      .withConverter(GarageSubscriptionPassOfferingConverter)
      .onSnapshot(async (docs) => {
        const normalaizeValidations = normalizeCollection(docs);
        setSubscriptionPassOfferings(
          normalaizeValidations as ISubscriptionPassOffering[],
        );
      });
  }, [dbQuery, locationId]);

  useEffect(() => {
    getMembers();
    getBusinesses();
    getSubscriptionPassOfferings();

    return () => {
      setMembers(null);
      setBusinesses([]);
      setSubscriptionPassOfferings([]);
      supscriptionPassOfferingsUnsubscribe?.current?.();
      businessesUnsubscribe?.current?.();
    };
  }, [getBusinesses, getMembers, getSubscriptionPassOfferings]);

  return {
    accountData: accountData as unknown as IAccount,
    businessName: state?.businessName,
    membersData: filteredMembers as MemberType[] | null,
    membersInfo,
    name: state?.name,
    accountStatus: state?.status,
    accountId,
    isLoading,
    goToBack,
    setPattern,
    currentAccount,
    setCurrentAccount,
    modalEditAccount,
    busy,
    businesses,
    handleFilterMember,
    filterMember,
    modalAddMember,
    subscriptionPassOfferings,
    createMember,
    editMember,
    refetchMembersData: getMembers,
  };
};

/* eslint-disable react/jsx-props-no-spreading */
import { SetStateAction } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Box,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { CaretCircleLeft } from 'phosphor-react';
import { DatePicker } from '@mui/x-date-pickers';
import {
  IRules,
  IRulesObj,
  IScheduledRate,
  ISubscriptionPassOffering,
} from 'App/api/types';

import { Between } from './Between';
import { Duration } from './Duration';
import { Recurrence } from './Recurrence';
import { convertRuleObject } from '../../helpers';

type PropsType = {
  timezone: string | null;
  type: 'exceptions' | 'rules';
  handleCloseModal: () => void;
  ruleData: IRules | null;
  setSubscriptionData: React.Dispatch<
    SetStateAction<ISubscriptionPassOffering | IScheduledRate | null>
  >;
};

export const RuleForm = ({
  timezone,
  type,
  handleCloseModal,
  ruleData,
  setSubscriptionData,
}: PropsType) => {
  const { values, initialValues, setFieldValue } = useFormikContext<any>();

  const tabTitile = type === 'rules' ? 'Rule' : 'Exceptions';
  const title = ruleData ? `Edit ${tabTitile}` : `Create ${tabTitile}`;

  const createRule = (payload: IRulesObj) => {
    setFieldValue(`validity.${type}`, [...values?.validity[type], payload]);
    setFieldValue('newRule', initialValues.newRule);
    setSubscriptionData((subscription: any) => {
      return (
        subscription && {
          ...subscription,
          validity: {
            ...subscription.validity,
            [type]: [...values.validity?.[type], payload],
          },
        }
      );
    });
    handleCloseModal();
  };

  const updateRule = (payload: IRulesObj) => {
    setSubscriptionData((subscription: any) => {
      return (
        subscription && {
          ...subscription,
          validity: {
            ...subscription.validity,
            [type]: subscription.validity?.[type].map((rule: IRules) => {
              if (rule.id === ruleData?.id) {
                return payload;
              }
              return rule;
            }),
          },
        }
      );
    });
    setFieldValue(
      `validity.${type}`,
      values.validity[type].map((r: IRules) => {
        if (r.id === ruleData?.id) {
          return { ...r, ...payload };
        }
        return r;
      }),
    );
    setFieldValue('newRule', initialValues.newRule);
    handleCloseModal();
  };

  return (
    <Form>
      <Box display="flex" alignItems="center" mb={5}>
        <IconButton onClick={handleCloseModal} type="submit" sx={{ p: 0 }}>
          <CaretCircleLeft size={32} />
        </IconButton>
        <Typography variant="h4" component="div" ml={1}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h6" component="div" mb={2}>
        Pattern
      </Typography>
      <Typography variant="body2" mb={2}>
        Choose the first instance of the rule, and on what pattern it repeats
      </Typography>
      <DatePicker
        label="Start Date"
        value={values.newRule.startDate}
        minDate={ruleData ? undefined : new Date()}
        onChange={(newValue) => {
          setFieldValue('newRule.startDate', newValue);
        }}
        renderInput={(params) => <TextField fullWidth {...params} />}
      />
      <Recurrence />
      <Between
        type="enter"
        title="Enter Between:"
        startTime={values.newRule.enterAfter}
        endTime={values.newRule.enterBefore}
      />

      {values.newRule.partiallyApplicable && type === 'rules' ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={values.newRule.partiallyApplicable.enter}
              onChange={(_, checked) =>
                setFieldValue('newRule.partiallyApplicable.enter', checked)
              }
              name="newRule.partiallyApplicable.enter"
            />
          }
          label={
            <Typography mt={0.1} variant="body2">
              Partially apply rate if user arrives early?
            </Typography>
          }
        />
      ) : null}

      <Between
        type="exit"
        title="Exit Between:"
        startTime={values.newRule.exitAfter}
        endTime={values.newRule.exitBefore}
      />

      {values.newRule.partiallyApplicable && type === 'rules' ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={values.newRule.partiallyApplicable.exit}
              onChange={(_, checked) =>
                setFieldValue('newRule.partiallyApplicable.exit', checked)
              }
              name="newRule.partiallyApplicable.exit"
            />
          }
          label={
            <Typography mt={0.1} variant="body2">
              Partially apply rate if user overstays?
            </Typography>
          }
        />
      ) : null}

      <Duration type="min" title="Minimum Duration (optional)" />
      <Duration type="max" title="Maximum Duration (optional)" />

      <Divider sx={{ mt: 4, mx: -4 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          paddingTop: '32px',
        }}
      >
        <Button
          sx={{ width: '85px' }}
          size="large"
          variant="contained"
          onClick={() => {
            const objRule = convertRuleObject(values.newRule, timezone);
            if (!ruleData) {
              createRule(objRule);
            } else {
              updateRule(objRule);
            }
          }}
        >
          Save
        </Button>
        <Button
          sx={{ ml: '12px' }}
          color="inherit"
          size="large"
          variant="contained"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

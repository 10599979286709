import { Formik } from 'formik';
import { Typography } from '@mui/material';
import { useLocations } from 'pages/locations/hooks';
import { addGarageValidation, TGarage } from 'shared/validations/addGarage';
import { AddGarageForm } from './form/AddGarageForm';

type PropType = {
  closeModal: () => void;
};

const NEW_GARAGE: TGarage = {
  name: '',
  address: {
    zip: '',
    city: '',
    state: '',
    country: '',
    street: '',
  },
  arrivalInstructions: '',
  coordinate: {
    latitude: 0,
    longitude: 0,
  },
  timezone: {
    identifier: '',
  },
};

export const AddGarage = ({ closeModal }: PropType): JSX.Element => {
  const { getCoordinate, createGarage, busy } = useLocations();

  return (
    <>
      <Typography variant="h4" component="h1" mb={3}>
        Create Garage
      </Typography>
      <Formik
        initialValues={NEW_GARAGE}
        validationSchema={addGarageValidation}
        onSubmit={async ({ address, ...rest }) => {
          await createGarage({
            address: { ...address },
            ...rest,
          });
          closeModal();
        }}
      >
        <AddGarageForm isBusy={busy} getCoordinate={getCoordinate} />
      </Formik>
    </>
  );
};

import { ComponentType } from 'react';
import { generatePath, useNavigate, Params } from 'react-router-dom';
import {
  Buildings,
  Lifebuoy,
  UsersThree,
  IconProps,
  Stack,
} from 'phosphor-react';
import { has, map, reject } from 'rambda';
import {
  Login,
  Locations,
  ResetPassword,
  Validations,
  Businesses,
  Overview,
  Pricing,
  Passes,
  Support as SupportPage,
  Accounts,
  Account,
  Lanes,
  Blank,
  Parks,
} from 'pages';

export interface RouteConfig {
  label: string;
  path: string;
  Component: ComponentType;
  authenticated: boolean;
  icon?: React.ForwardRefExoticComponent<IconProps>;
  showInMenu?: boolean;
  permissionName: string;
}

export type TLocationRoutes = {
  label: string;
  path: string;
  Component: ComponentType;
  permissionName: string;
};

export enum RouteName {
  SignIn = 'SignIn',
  Home = 'Locations',
  ResetPass = 'ResetPass',
  Auditing = 'Auditing',
  Support = 'Support',
  Team = 'Team',
}

export enum PathAuth {
  login = '/login',
  resetPassword = '/actions/reset_password',
}

export const routeMapping = {
  locations: '/locations',
  location: '/locations/:locationId',
  support: '/support',
  team: '/team',
  auditing: '/auditing',

  accountPrefix: '/accounts',
  accaunt: '/locations/:locationId/accounts/:accountId',
};

const routes: Record<RouteName, RouteConfig> = {
  [RouteName.SignIn]: {
    label: 'Sign In',
    path: PathAuth.login,
    permissionName: 'login',
    Component: Login,
    authenticated: false,
  },
  [RouteName.ResetPass]: {
    label: 'Reset Password',
    path: PathAuth.resetPassword,
    permissionName: 'resetPassword',
    Component: ResetPassword,
    authenticated: false,
  },
  [RouteName.Home]: {
    label: 'Locations',
    path: routeMapping.locations,
    permissionName: 'garages',
    Component: Locations,
    authenticated: true,
    showInMenu: true,
    icon: Buildings,
  },
  [RouteName.Support]: {
    label: 'Support',
    path: routeMapping.support,
    permissionName: 'support',
    Component: SupportPage,
    authenticated: true,
    showInMenu: true,
    icon: Lifebuoy,
  },
  [RouteName.Team]: {
    label: 'Team',
    path: routeMapping.team,
    permissionName: 'team',
    Component: Blank,
    authenticated: true,
    showInMenu: true,
    icon: UsersThree,
  },
  [RouteName.Auditing]: {
    label: 'Auditing',
    path: routeMapping.auditing,
    permissionName: 'auditing',
    Component: Blank,
    authenticated: true,
    showInMenu: true,
    icon: Stack,
  },
};

export const locationRoutes = [
  {
    label: 'Overview',
    path: `${routeMapping.location}/overview`,
    permissionName: 'garages',
    Component: Overview,
  },

  {
    label: 'Pricing',
    path: `${routeMapping.location}/pricing`,
    permissionName: 'pricing',
    Component: Pricing,
  },
  {
    label: 'Passes',
    path: `${routeMapping.location}/passes`,
    permissionName: 'passes',
    Component: Passes,
  },
  {
    label: 'Businesses',
    path: `${routeMapping.location}/businesses`,
    permissionName: 'businesses',
    Component: Businesses,
  },
  {
    label: 'Accounts',
    path: `${routeMapping.location}/accounts`,
    permissionName: 'accounts',
    Component: Accounts,
  },
  {
    label: 'Validations',
    path: `${routeMapping.location}/validations`,
    permissionName: 'validations',
    Component: Validations,
  },
  {
    label: 'Lanes',
    path: `${routeMapping.location}/lanes`,
    permissionName: 'lanes',
    Component: Lanes,
  },
  {
    label: 'Parks',
    path: `${routeMapping.location}/parks`,
    permissionName: 'parks',
    Component: Parks,
  },
  {
    label: 'Reports',
    path: `${routeMapping.location}/reports`,
    permissionName: 'reports',
    Component: Blank,
  },
];

export const locationChildrenRoutes = [
  {
    label: 'SinglAccount',
    path: routeMapping.accaunt,
    Component: Account,
  },
];

export const authenticatedRoutes: RouteConfig[] = [];
export const unauthenticatedRoutes: RouteConfig[] = [];
Object.values(routes).forEach((route) => {
  const dest = route.authenticated
    ? authenticatedRoutes
    : unauthenticatedRoutes;
  dest.push(route);
});

export const protectedRoutes = reject(
  ({ Component }) => !Component,
  [...authenticatedRoutes, ...locationChildrenRoutes, ...locationRoutes],
);

export function useGotoRoute<T>(name: RouteName): (params?: T) => void {
  const navigate = useNavigate();
  const route = routes[name];
  return function gotoRoute() {
    const path = generatePath(route.path);
    navigate(path);
  };
}

export function generateRoutePath({ name }: { name: RouteName }): string {
  const route = routes[name];
  return generatePath(route.path);
}

export const routeTo = (route: string, params: Params) =>
  generatePath(route, params);

const getDynamicRoutes = (
  routesLocations: TLocationRoutes[],
  params: Params,
) => {
  return map(
    (route) =>
      has('type', route)
        ? route
        : { ...route, path: routeTo(route.path, params) },
    routesLocations,
  );
};

export const getLocationsRoutes = (params: Readonly<Params<string>>) => {
  const isLocations = has('locationId', params);
  return isLocations ? getDynamicRoutes(locationRoutes, params) : null;
};

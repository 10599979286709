import { useCallback, useMemo, useState } from 'react';
import { useBusy, useApiFunction, useToast } from 'shared';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { FunctionsError } from 'firebase/functions';

function useProvidePayment() {
  const { setBusy } = useBusy();
  const paymentApi = useApiFunction();
  const { setErrorContent } = useErrorHandler();
  const toast = useToast();

  const [clientSecret, setClientSecret] = useState(undefined);

  const supportPaymentMethodsSetup = useCallback(
    async (sessionID) => {
      try {
        setBusy(true);
        const { data } = await paymentApi({
          action: 'support_payment_methods_setup',
          data: { sessionID },
        });
        setClientSecret(data.clientSecret);
      } catch (e) {
        const err = e as FunctionsError;
        setErrorContent({
          errorHeader: err.message,
          errorBody: err.details as string,
          openError: true,
        });
      } finally {
        setBusy(false);
      }
    },
    [paymentApi, setBusy, setErrorContent, setClientSecret],
  );

  const handleSubmitCreatePaymentMethod = useCallback(
    async (stripe, elements) => {
      setBusy(true);
      if (!stripe || !elements) {
        setErrorContent({
          errorHeader: '',
          errorBody: 'Unknown Payment Processing error',
          openError: true,
        });
        return;
      }

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url:
            process.env.REACT_APP_STRIPE_CREATE_PM_RETURN_URL ??
            'https://dashboard.vendpark.io',
        },
      });

      if (!error) {
        toast.success('Payment method is created');
      } else {
        setErrorContent({
          errorHeader: '',
          errorBody: error.message ?? '',
          openError: true,
        });
      }
      setBusy(false);
    },
    [setBusy, setErrorContent, toast],
  );

  const memorizedValue = useMemo(
    () => ({
      clientSecret,
      supportPaymentMethodsSetup,
      handleSubmitCreatePaymentMethod,
      setClientSecret,
    }),
    [
      clientSecret,
      supportPaymentMethodsSetup,
      handleSubmitCreatePaymentMethod,
      setClientSecret,
    ],
  );

  return memorizedValue;
}

export default useProvidePayment;

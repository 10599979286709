import { find } from 'rambda';
import { Container } from '@mui/material';
import { BasicModal, RuleAndExeptionProvider } from 'shared/components';
import { BaseRate } from './components/BaseRate';
import { EditRateModal } from './components/BaseRate/EditRateModal';
import {
  PopulationGroup,
  EditPopulationGroupModal,
  EditMembersModal,
} from './components/PopulationGroups';
import { Settings } from './components/Settings/Settings';
import { useGracePeriod, usePopulationGroup } from './hooks';
import { usePricing } from './hooks/usePricing';
import { Payments } from './components/payments/Payments';
import { ScheduledRates } from './components/scheduledRates/components';
import { useScheduled } from './components/scheduledRates/hooks';

export const Pricing = () => {
  const { garage, modalRate, modalPopulationGroup, modalMembers, baseRate } =
    usePricing();
  const {
    populationGroups,
    editItem,
    setEditItem,
    users,
    cleanUsers,
    setPattern,
    busy,
    busyMember,
    busyUser,
    savePopulationGroup,
    addMemberPopulationGroup,
    removeMemberPopulationGroup,
    modalAddMember,
  } = usePopulationGroup();
  const {
    savePeriod,
    gracePeriod,
    resetTime,
    isEditSettings,
    setIsEditSettings,
    diffTimezoneMiliseconds,
  } = useGracePeriod();

  const memberData = {
    populationGroupId: find(({ id }) => id === editItem, populationGroups)?.id,
    garageId: garage?.id,
  };
  const value = useScheduled();

  const { openModal, handleCloseModal, handleOpenModal } = modalRate;
  const { openGroup, handleCloseGroup, handleOpenGroup } = modalPopulationGroup;
  const { openMembers, handleCloseMembers, handleOpenMembers } = modalMembers;

  return (
    <Container>
      {garage && typeof gracePeriod === 'number' && (
        <>
          <Settings
            gracePeriod={gracePeriod}
            resetTime={resetTime}
            savePeriod={savePeriod}
            diffTimezoneMiliseconds={diffTimezoneMiliseconds}
            isEditSettings={isEditSettings}
            setIsEditSettings={setIsEditSettings}
          />
          <Payments />
          <BaseRate handleOpenModal={handleOpenModal} />
          <PopulationGroup
            values={populationGroups}
            handleOpenModal={handleOpenGroup}
            handleOpenMembers={handleOpenMembers}
            setEditItem={setEditItem}
          />
          <RuleAndExeptionProvider value={value}>
            <ScheduledRates />
          </RuleAndExeptionProvider>
        </>
      )}

      <BasicModal open={openModal} setClose={handleCloseModal}>
        <EditRateModal closeModal={handleCloseModal} data={baseRate} />
      </BasicModal>
      <BasicModal
        open={openGroup}
        setClose={handleCloseGroup}
        setEditItem={setEditItem}
        btnClose={false}
      >
        <EditPopulationGroupModal
          closeModal={handleCloseGroup}
          data={find(({ id }) => id === editItem, populationGroups)}
          setEditItem={setEditItem}
          savePopulationGroup={savePopulationGroup}
          busy={busy}
        />
      </BasicModal>
      <BasicModal
        open={openMembers && !!users}
        setClose={handleCloseMembers}
        setEditItem={setEditItem}
        cleanData={cleanUsers}
        btnClose={false}
      >
        <EditMembersModal
          closeModal={handleCloseMembers}
          cleanData={cleanUsers}
          data={memberData}
          users={users}
          busy={busy}
          busyMember={busyMember}
          busyUser={busyUser}
          setEditItem={setEditItem}
          setPattern={setPattern}
          addMemberPopulationGroup={addMemberPopulationGroup}
          removeMemberPopulationGroup={removeMemberPopulationGroup}
          modalAddMember={modalAddMember}
        />
      </BasicModal>
    </Container>
  );
};

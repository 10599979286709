import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Container, IconButton, Menu } from '@mui/material';
import { List } from 'phosphor-react';
import styled from '@emotion/styled';
import { IPermissions } from 'App/api/types/User';

import { TLocationRoutes } from 'App/helpers';
import { useResponsive } from 'shared';
import { ICON_NAV_BAR_LOCATIONS } from './constants/iconNavBar';

type PropsType = {
  locationsRoutes: TLocationRoutes[] | null;
  permissions: IPermissions | null;
};

const NavLinkLocations = styled(NavLink)`
  color: #637381;
  text-decoration: none;
  min-height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  &.active {
    color: #000000;
    border-bottom: 2px solid #000000;
  }
  :not(:first-of-type) {
    margin-left: 40px;
  }
`;

const NavLinkMobile = styled(NavLinkLocations)`
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  :not(:first-of-type) {
    margin-left: 0px;
  }
  &.active {
    color: #fff;
    background-color: #000000;
  }
`;

function MobileNavBarLocations({ locationsRoutes, permissions }: PropsType) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <List size={25} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 220,
            width: '15ch',
          },
        }}
      >
        {locationsRoutes &&
          locationsRoutes
            .filter((item) => permissions && permissions[item.permissionName])
            .map((location: TLocationRoutes) => (
              <NavLinkMobile
                onClick={handleClose}
                key={location.label}
                to={location.path}
              >
                {location.label}
              </NavLinkMobile>
            ))}
      </Menu>
    </div>
  );
}

export const NavBarLocations = ({
  locationsRoutes,
  permissions,
}: PropsType): JSX.Element => {
  const isMobile = useResponsive('down', 1140);

  return (
    <Container sx={{ fontFamily: 'Mont-SemiBold' }}>
      {!isMobile && locationsRoutes ? (
        <Box display="flex" flexDirection="row" mb={3}>
          {locationsRoutes
            .filter((item) => permissions && permissions[item.permissionName])
            .map((location: TLocationRoutes) => (
              <NavLinkLocations
                key={location.label}
                to={location.path}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {
                  ICON_NAV_BAR_LOCATIONS.find(
                    (icon) => location.label === icon.label,
                  )?.icon
                }
                {location.label}
              </NavLinkLocations>
            ))}
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" mt={-8} mb={4}>
          <MobileNavBarLocations
            locationsRoutes={locationsRoutes}
            permissions={permissions}
          />
        </Box>
      )}
    </Container>
  );
};
